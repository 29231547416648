<app-error-summary [error]="scope.error" *ngIf="scope.error"></app-error-summary>
<div class="banner" *ngIf="scope.updated">
    <h2 class="banner__text" [innerText]="scope.getText | transform:'RESUME_UPDATED'"></h2>
</div>
<div *ngIf="(scope.user$ | async) as user" [ngClass]="{'disabled--loading': scope.loading}">
    <dl class="dl">
        <dt [innerText]="user.latestResume?.filename"></dt>
        <ng-container *ngIf="(scope.getDate | transform:user.latestResume?.lastUpdated:scope) as date">
            <dt>{{scope.getText | transform:'LAST_UPDATED':{date: date} }}</dt>
        </ng-container>
    </dl>

    <p [innerText]="scope.getText | transform:'UPLOAD_A_NEW_RESUME'"></p>
    <app-upload-file (uploadEvent)="scope.resumeUpdate($event)" id="update-resume-upload"></app-upload-file>
</div>