<mat-nav-list *ngIf="components$ | async as components">
    <a mat-list-item routerLink="/admin/pageConfiguration/home" *ngIf="components['home']">
        Home
    </a>
    <a mat-list-item routerLink="/admin/pageConfiguration/nav">
        Navigation
    </a>
    <a mat-list-item routerLink="/admin/pageConfiguration/login" *ngIf="components['login']">
        Login
    </a>
    <a mat-list-item routerLink="/admin/pageConfiguration/register" *ngIf="components['register']">
        Register
    </a>
    <!-- <a mat-list-item routerLink="/admin/pageConfiguration/resetPassword">
        Reset Password
    </a> -->
    <a mat-list-item routerLink="/admin/pageConfiguration/forgotPassword" *ngIf="components['forgot-password']">
        Forgot Password
    </a>
    <a mat-list-item href="javascript:void(0)" (click)="openJobSubMenu = !openJobSubMenu" *ngIf="components['opportunity-list']">
        <a href="javascript:void(0)" matline>Job Search</a>
        <mat-icon>keyboard_arrow_down</mat-icon>
    </a>
    <mat-nav-list class="submenu_items" *ngIf="openJobSubMenu">
        <a routerLink="/admin/pageConfiguration/jobs" mat-list-item>
            Overview
        </a>
        <a mat-list-item routerLink="/admin/pageConfiguration/detail/admin">
            Job Details
        </a>
    </mat-nav-list>
    <a mat-list-item routerLink="/admin/pageConfiguration/dashboard" *ngIf="components['dashboard']">
        <a>Dashboard</a>
    </a>
    <!-- <mat-nav-list class="submenu_items" *ngIf="opensubMenu">
        <a routerLink="/admin/pageConfiguration/dashboard" mat-list-item>
            Overview
        </a>
        <a mat-list-item routerLink="/admin/pageConfiguration/questionnaire/demo/admin" >
            Questionnaire
        </a>
    </mat-nav-list> -->
    <a mat-list-item routerLink="/admin/pageConfiguration/footer" *ngIf="components['footer']">
        Footer
    </a>
    <a mat-list-item (click)="onFaviconClick()">
        Update Favicon
    </a>
    <ng-container *ngIf="staticPageRoutes.length">
        <p class="custom__template-header"><span>Custom Pages </span></p>
        <ng-container *ngFor="let route of staticPageRoutes">
            <div class="custom__template">
                <a mat-list-item (click)="navigateTo(route.path)">
                    {{route.data.displayName}}
                    <ng-container *ngIf="route.data.hideNavigation">(Hidden)</ng-container>
                </a>
                <mat-icon class="custom__template-delete" (click)="onDelete(route)">delete</mat-icon>
            </div>
        </ng-container>
    </ng-container>
    <mat-divider></mat-divider>
    <button mat-icon-button class="create__new-template" (click)="onCreateClick($event)">
        <mat-icon>add</mat-icon> Create New
    </button>
</mat-nav-list>