import { BrowserModule } from "@angular/platform-browser";
import { NgModule, APP_INITIALIZER, Inject, Type } from "@angular/core";
import { BrowserAnimationsModule } from "@angular/platform-browser/animations";
import { AppRoutingModule } from "./app-routing.module";
import { AppComponent } from "./app.component";
import { NgxsModule } from "@ngxs/store";
import { NgxsStoragePluginModule, StorageOption } from "@ngxs/storage-plugin";
import { NgxsRouterPluginModule } from "@ngxs/router-plugin";
import { NgxsReduxDevtoolsPluginModule } from "@ngxs/devtools-plugin";
import { LoadingBarRouterModule } from "@ngx-loading-bar/router";
import { ngxsConfig } from "projects/pocket-career/ngxs.config";
import { AuthenticationModule } from "./authentication/authentication.module";
import { CoreModule } from "./core/core.module";
import { OpportunityListModule } from "./opportunity-list/opportunity-list.module";
import { HTTP_INTERCEPTORS } from "@angular/common/http";
import { AuthInterceptor } from "./authentication/services/auth.interceptor";
import { StaticPagesModule } from "./static-pages/static-pages.module";
import { StaticPageComponent } from "./static-pages/static-page/static-page.component";
import { routerActions } from "./routes";
import { ExternalUrlComponent } from "./static-pages/external-url/external-url.component";
import { ActionsModule } from "./actions/actions.module";
import { UserModule } from "./user/user.module";
import { FontAwesomeModule } from "@fortawesome/angular-fontawesome";
import { SuggestionsModule } from "./suggestions/suggestions.module";
import { PocketPortal } from "./core/models/pocket-portal.model";
import { MatToolbarModule } from "@angular/material/toolbar";
import { DEFAULT_LAYER_BACKDROP_CLASS } from "kleos-ui";
import { AdminInterceptor } from "./authentication/services/admin-interceptor.service";
import { PortalService } from "./core/services/portal.service";
import { UserService } from "./core/services/user.service";
import { HomeModule } from "./home/home.module";
import { PageConfigurationModule } from "./page-configuration/page-configuration.module";
import { MatLegacyButtonModule as MatButtonModule } from "@angular/material/legacy-button";
import { GoogleTagManagerConfiguration, GoogleTagManagerModule } from "ng-google-tag-manager";
import { AuthState } from "./authentication/store/states/auth.state";

interface CustomRouteConfig {
  path: string;
  data: { displayName: string; order: number, hideNavigation?: boolean };
  component: Type<any>;
}

interface ExternalCustomRouteConfig extends CustomRouteConfig {
  data: CustomRouteConfig["data"] & { externalUrl: string };
}

function generateExternalURLConfig(key, component): ExternalCustomRouteConfig {
  return {
    path: key,
    component: ExternalUrlComponent,
    data: {
      displayName: component.textMap.routeDisplayName || "Unknown",
      order: component.order,
      externalUrl: component.textMap.externalUrl,
    },
  };
}

function generateStaticPageConfig(key, component): CustomRouteConfig {
  const staticRoute = {
    path: key,
    component: StaticPageComponent,
    data: {
      displayName: component.textMap.routeDisplayName || "Unknown",
      order: routerActions.STATIC_PAGE_ROUTE_ORDER,
      hideNavigation: component.adminOnly.hideNavigation
    },
  };
  routerActions.addStaticPageRoute(staticRoute);
  return staticRoute;
}

export function setupRoutes(data: PocketPortal) {
  routerActions.resetStaticPageRoutes();
  const staticPages = Object.keys(data.components)
  .filter(
    (x) =>
      data.components[x].adminOnly &&
      (data.components[x].adminOnly.staticPage ||
        data.components[x].adminOnly.externalPage)
  )
  .map((key) => {
    return data.components[key].adminOnly.staticPage
      ? generateStaticPageConfig(key, data.components[key])
      : generateExternalURLConfig(key, data.components[key]);
  });
  routerActions.setRouteOrder(data.components).addToRoutes(staticPages);
}

export function setupGTM(data: PocketPortal, googleTagManagerConfiguration: GoogleTagManagerConfiguration) {
  if( data.configuration?.googleTagManagerId ) {
    googleTagManagerConfiguration.set({id: data.configuration.googleTagManagerId});
  }
}

export function getPortal(
  portalService: PortalService,
  userService: UserService,
  googleTagManagerConfiguration: GoogleTagManagerConfiguration
) {
  return async () => {
    //exception handling done in main.ts and forwards to /404.html
    //must use regex to get preview param for IE support
    // const preview = new RegExp(/\?preview=/).test(window.location.href);
    await userService
      .getUser()
      .toPromise()
      .then(async (_) => {
        await portalService
          .findPortalFromURL()
          .toPromise()
          .then((data) => {
            setupRoutes(data);
            setupGTM(data, googleTagManagerConfiguration);
          });
      });
  };
}
@NgModule({
  declarations: [AppComponent],
  imports: [
    NgxsModule.forRoot([AuthState], ngxsConfig),
    NgxsReduxDevtoolsPluginModule.forRoot(),
    NgxsRouterPluginModule.forRoot(),
    AuthenticationModule,
    // NgxsStoragePluginModule.forRoot({
    //   key: ['core.portal'],
    //  storage: StorageOption.SessionStorage
    //}),
    GoogleTagManagerModule.forRoot(),
    BrowserModule,
    BrowserAnimationsModule,
    AppRoutingModule,
    HomeModule,
    MatToolbarModule,
    MatButtonModule,
    SuggestionsModule,
    OpportunityListModule,
    CoreModule,
    UserModule,
    StaticPagesModule,
    ActionsModule,
    LoadingBarRouterModule,
    FontAwesomeModule,
    PageConfigurationModule,
    MatToolbarModule,
  ],
  providers: [
    { provide: HTTP_INTERCEPTORS, useClass: AuthInterceptor, multi: true },
    { provide: HTTP_INTERCEPTORS, useClass: AdminInterceptor, multi: true },
    {
      provide: DEFAULT_LAYER_BACKDROP_CLASS,
      useValue: "cdk-custom-layer-backdrop",
    },
    {
      provide: "googleTagManagerMode",
      useValue: "silent"
    },
    {
      provide: APP_INITIALIZER,
      useFactory: getPortal,
      deps: [PortalService, UserService, GoogleTagManagerConfiguration],
      multi: true,
    }
  ],
  bootstrap: [AppComponent]
})
export class AppModule {}
