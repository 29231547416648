import { Injectable } from "@angular/core";
import { CanActivate, Router } from "@angular/router";
import { Store } from "@ngxs/store";
import { map, take } from "rxjs/operators";
import { UserService } from "@career/core/services/user.service";

@Injectable({
  providedIn: "root",
})
export class NotAuthGuard implements CanActivate {
  isAdmin = false;
  constructor(
    private store: Store,
    private userSvc : UserService,
    private router: Router
  ) {
    this.userSvc.isAdmin().subscribe((isAdmin) => {
      this.isAdmin = isAdmin;
    });
  }

  canActivate() {
    return this.userSvc.getUser(true).pipe(
      map((user) => {
        if (user === null) {
          return true;
        } else if (this.isAdmin) {
          this.router.navigate(["/admin/pageConfiguration"]);
        } else {
          this.router.navigate(["/"]);
        }
        return false;
      })
    );
  }
}
