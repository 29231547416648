<div class="upload" (appDropZone)="scope.upload($event)">
    <img [src]="scope.component.assets.image" class="upload__upload-cloud" [alt]="scope.getText | transform:'UPLOAD_FILE'">
    <div class="upload__upload-section">
        <p class="upload__upload-text" [innerText]="scope.getText | transform:'DRAG_AND_DROP_RESUME':{resumeOrCv: resumeOrCvText}" *ngIf="!scope.resume"></p>
        <p class="upload__upload-text" *ngIf="scope.resume" [innerText]="scope.getText | transform:'DRAG_AND_DROP_FILE'"></p>
        <p class="upload__upload-or" [innerText]="scope.getText | transform:'OR'"></p>
        <button mat-flat-button color="primary" for="hidden-input" class="upload__browse-files">
            <label class="button__label" for="hidden-input--{{scope.id}}" [innerText]="scope.getText | transform:'BROWSE_FILES'"></label>
        </button>
    </div>
    <input type="file" class="upload__hidden-input" id="hidden-input--{{scope.id}}" name="hidden-input--{{scope.id}}" (change)="scope.upload($event)">
</div>
