import { State, Action, StateContext, Selector } from '@ngxs/store';
import { OpportunitiesAction } from '../actions/opportunities.actions';
import { OpportunitiesStateModel } from '../../models/opportunities.model';
import { Injectable } from '@angular/core';

@State<OpportunitiesStateModel>({
    name: 'opportunities',
    defaults: {
        jobs: null
    }
  })
  @Injectable()
  export class OpportunitiesState {
  
    @Selector()
    static filters(state: OpportunitiesStateModel) {
        return state.filters;
    }
    @Action(OpportunitiesAction.List)
    list(ctx: StateContext<OpportunitiesStateModel>,  { payload }: OpportunitiesAction.List) {
      ctx.setState({
        ...ctx.getState(),
        jobs: payload
      })
    }

    @Action(OpportunitiesAction.Filters)
    filters(ctx: StateContext<OpportunitiesStateModel>,  { payload }: OpportunitiesAction.Filters) {
      ctx.setState({
        ...ctx.getState(),
        filters: {body: payload.body, sort: payload.sort}
      })
    }
  }