import { Injectable } from "@angular/core";
import { Routes } from "@angular/router";
import { CoreAction } from "@career/core/store/actions/core.actions";
import { routerActions } from "@career/routes";
import { Store } from "@ngxs/store";
import {
  INavRoute,
  UNAUTHENTICATED_NAV_ROUTES,
  AUTHENTICATED_NAV_ROUTES,
} from "../components/nav/constants/nav.constant";

@Injectable({
  providedIn: "root",
})
export class NavService {
  constructor(private store: Store) {}
  private mapToNavRoutes(routes: Routes): INavRoute[] {
    return routes.map((x) => {
      return {
        textMapKey: x.data.displayName,
        link: x.path,
        order: x.data.order,
        hideNavigation: x.data.hideNavigation
      };
    });
  }

  private setupNavRoutesOrder(
    navRoutes: INavRoute[],
    orderRouteMap
  ) {
    navRoutes.forEach((route) => {
      if (orderRouteMap[route.link]) {
        route.order = orderRouteMap[route.link];
      }
    });
    return navRoutes;
  }

  shuffleStaticPagesOrder(navRoutes: INavRoute[], isAuthenticated: boolean) {
    const staticPageRoutes = navRoutes.filter(
      (navRoute) => navRoute.order === routerActions.STATIC_PAGE_ROUTE_ORDER
    );
    const nonStaticPageRoutes = navRoutes.filter(
      (navRoute) => navRoute.order !== routerActions.STATIC_PAGE_ROUTE_ORDER
    );
    const addStaticPagesIndex = nonStaticPageRoutes.findIndex((route) =>
      isAuthenticated ? route.link === "/logout" : route.link === "/login"
    );
    nonStaticPageRoutes.splice(addStaticPagesIndex, 0, ...staticPageRoutes);
    return nonStaticPageRoutes;
  }

  getUnauthenticatedRoutes(routesWithOrder: any, dynamicRoutes: any[]): any[] {
    const sortedRoutes = [
      ...this.setupNavRoutesOrder(UNAUTHENTICATED_NAV_ROUTES, routesWithOrder),
      ...dynamicRoutes,
    ].sort((a, b) => a.order - b.order);
    return this.shuffleStaticPagesOrder(sortedRoutes, false);
  }

  getAuthenticatedRoutes(routesWithOrder: any, dynamicRoutes: INavRoute[]) {
    const authRoutes = [
      ...this.setupNavRoutesOrder(
        AUTHENTICATED_NAV_ROUTES,
        routesWithOrder
      ),
      ...dynamicRoutes,
    ].sort((a, b) => a.order - b.order);
    return this.shuffleStaticPagesOrder(authRoutes, true);
  }

  setupRoutes(config: { [k: string]: any }): void {
    const dynamicRoutes = this.mapToNavRoutes(routerActions.getDynamicRoutes()).filter(o => !o.hideNavigation);
    const routesWithOrder = routerActions.getSortableRouteOrders();
    const jobs = UNAUTHENTICATED_NAV_ROUTES.findIndex(
      (route) => route.link === "/jobs"
    );
    if (jobs > -1 && config.forwardJobSearchPage) {
      UNAUTHENTICATED_NAV_ROUTES[jobs].externalLink =
        config.forwardJobSearchPage;
    }

    //todo: rework this so it uses routes.ts instead of hardcoded values, and canActivate
    const routes = {
      unauthenticatedRoutes: this.getUnauthenticatedRoutes(
        routesWithOrder,
       [...dynamicRoutes]
      )
        .filter((route) => route.link !== "/" || config.enableHomePage)
        .filter(
          (route) => route.link !== "/jobs" || !config.disableJobSearchPage
        )
        .filter(
          (route) => route.link !== "/register" || !config.disableRegisterPage
        )
        .filter(
          (route) => route.link !== "/suggestions" || config.enableSuggestions
        ),
      authenticatedRoutes: this.getAuthenticatedRoutes(
        routesWithOrder,
        [...dynamicRoutes]
      )
        .filter(
          (route) => route.link !== "/jobs" || !config.disableJobSearchPage
        )
        .filter(
          (route) => route.link !== "/register" || !config.disableRegisterPage
        ),
    };
    this.store.dispatch(new CoreAction.SetRoutes(routes));
  }
}
