import { Injectable } from "@angular/core";
import { HttpClient } from "@angular/common/http";
import { Observable, throwError, of } from "rxjs";
import { map, catchError } from "rxjs/operators";
import { OpportunitiesAction } from "@career/opportunity-list/store/actions/opportunities.actions";
import { getCurrencySymbol } from "@angular/common";
import { Store } from "@ngxs/store";
import { PortalService } from "./portal.service";
import { Opportunity } from "@career/opportunity-detail/models/opportunity.model";
import { OpportunitiesState } from "@career/opportunity-list/store/states/opportunities.state";
import { UtilsService } from "./utils.service";
import { OpportunitySearchBody } from "@career/opportunity-list/models/opportunities.model";
import { TemplateConfiguration } from "../models/component.model";

@Injectable({
  providedIn: "root",
})
export class JobService {
  constructor(
    protected http: HttpClient,
    protected portalService: PortalService,
    private utils: UtilsService,
    private store: Store
  ) {}

  getCachedSearchFilters() {
    return this.store.selectOnce(OpportunitiesState.filters);
  }
  setCachedSearchFilters(body: OpportunitySearchBody, sort?) {
    return this.store.dispatch(
      new OpportunitiesAction.Filters({ body: body, sort: sort })
    );
  }

  getDepartments(): Observable<any> {
    return this.http.get(
      `/api/portal/${this.portalService.getPortalId()}/departments` +
        "?populate=" +
        encodeURIComponent("manager|parent")
    );
  }

  getLocation(search): Observable<any> {
    return this.http.get("/api/location?search=" + search);
  }

  getDate(date): string {
    let d = new Date(date);
    try {
      return d.toLocaleString("default", {
        month: "long",
        day: "2-digit",
        year: "numeric",
      });
    } catch (err) {
      return d.getMonth() + "/" + d.getDay() + "/" + d.getFullYear();
    }
  }

  getOpps(body = null, sort = null, page = null, config: TemplateConfiguration): Observable<any> {
    if (!body) {
      body = {
        agency: this.portalService.getPortalId(),
      };
    } else {
      body.agency = this.portalService.getPortalId();
    }
    let url = `/api/portal/${this.portalService.getPortalId()}/opportunities?populate=${encodeURIComponent(
      "department:name|client:name"
    )}&size=10`;
    if (sort) url += "&sort=" + encodeURIComponent(sort);
    if (page) url += "&page=" + encodeURIComponent(page);
    return this.http.post(url, body).pipe(
      map((data: any) => {
        data.opportunities = data.opportunities.map((x) =>
          this.convertToOpp(x, config)
        );
        this.store.dispatch(
          new OpportunitiesAction.Filters({ body: body, sort: sort })
        );
        return data;
      }),
      catchError((err: any) => {
        return throwError(err);
      })
    );
  }

  getOpp(id: any): Observable<any> {
    return this.http.get(this.getOppUrl(id)).pipe(
      catchError((err: any) => {
        return throwError(err);
      })
    );
  }

  getOppUrl(id: string): string {
    return `/api/portal/${this.portalService.getPortalId()}/opportunity/${id}?populate=${encodeURIComponent(
      "client:name,brand|labels"
    )}`;
  }

  convertRate(rate: number) {
    if( rate % 10 > 0 ) {
      return rate.toLocaleString(undefined, {minimumFractionDigits: 2, maximumFractionDigits: 2});
    }
    return rate.toLocaleString();
  }

  convertToOpp(opportunity: Opportunity, config: TemplateConfiguration): Opportunity {
    opportunity.display = { pills: [], summary: null, client: null };
    if (
      opportunity &&
      opportunity.location &&
      opportunity.location.locations &&
      opportunity.location.locations[0]
    ) {
      opportunity.location.locations.forEach((location) => {
        opportunity.display.pills.push({
          name: "LOCATION",
          value: this.utils.createLocationString(location, config['shortLocations']) || "Any Location",
          icon: "location_on",
        });
      });
    }

    //TODO: take locale into account
    if (opportunity) {
      if (
        opportunity.employment &&
        opportunity.employment.compensation &&
        opportunity.employment.compensation[0] &&
        opportunity.employment.compensation[0].rate
      ) {
        const comp = opportunity.employment.compensation[0],
          model = comp.model
            ? `${comp.model.charAt(0).toUpperCase()}${comp.model
                .substring(1)
                .toLowerCase()} `
            : "Salary ",
          rate =
            model +
            (comp.rate.max
              ? (getCurrencySymbol(comp.currency, "narrow") || "") +
                this.convertRate(comp.rate.max)
              : "Competitive");
        opportunity.display.pills.push({
          name: "COMPENSATION",
          value: rate,
          icon: "payments",
        });
      }
      if( opportunity.externalSummary ) {
        opportunity.display.summary =
          opportunity.externalSummary.substring(0, 300) + "...";
      } else if (opportunity.summary) {
        opportunity.display.summary =
          opportunity.summary.substring(0, 300) + "...";
      }
      if (opportunity.client && opportunity.client.name) {
        opportunity.display.client = opportunity.client.name;
      }
    }

    return opportunity;
  }
}
