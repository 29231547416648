import { HttpErrorResponse } from "@angular/common/http";
import { Component, OnDestroy, OnInit } from "@angular/core";
import {
  UntypedFormBuilder,
  UntypedFormGroup,
  Validators,
} from "@angular/forms";
import { Router } from "@angular/router";
import {
  LoginRequest,
  LoginResponse,
} from "@career/authentication/models/auth.model";
import {
  TemplateComponent,
  TemplateConfiguration,
} from "@career/core/models/component.model";
import { ErrorSummary } from "@career/core/models/error.model";
import { AuthenticationService } from "@career/core/services/authentication.service";
import { PortalService } from "@career/core/services/portal.service";
import { UtilsService } from "@career/core/services/utils.service";
import { Subscription } from "rxjs";

@Component({
  selector: "app-admin-login",
  templateUrl: "./admin-login.component.html",
  styleUrls: ["./admin-login.component.scss"],
})
export class AdminLoginComponent implements OnInit, OnDestroy {
  loginForm!: UntypedFormGroup;
  showPassword = false;
  loading = false;
  config: TemplateConfiguration;
  component: TemplateComponent;
  getText: Function;
  email: string;
  loginConfig: any;
  mfaLoginConfig: any;
  error: ErrorSummary;
  subs: Subscription[] = [];
  mfaEnabled: boolean;
  showLoginContainer = true;
  loginModel: LoginRequest;

  constructor(
    private fb: UntypedFormBuilder,
    private authService: AuthenticationService,
    private router: Router,
    private portalService: PortalService,
    private utilSvc: UtilsService
  ) {}

  ngOnInit(): void {
    const sub = this.portalService
      .getComponentData("login")
      .subscribe((data) => {
        this.config = data.configuration;
        this.component = data.component;
        this.getText = this.utilSvc.getText(this.component);
      });
    this.subs.push(sub);
    this.initLoginConfig();
  }

  initLoginConfig() {
    this.loginConfig = [
      {
        type: "input",
        label: this.getText("EMAIL"),
        inputType: "email",
        autocomplete: "email",
        name: "email",
        value: this.email,
        validations: [
          {
            name: "required",
            validator: Validators.required,
            message: this.getText("EMAIL_REQUIRED"),
          },
        ],
      },
      {
        type: "input",
        label: this.getText("PASSWORD"),
        inputType: "password",
        name: "password",
        autocomplete: "new-password",
        validations: [
          {
            name: "required",
            validator: Validators.required,
            message: this.getText("PASSWORD_REQUIRED"),
          },
        ],
      },
      {
        type: "button",
        color: "primary",
        label: this.getText("LOGIN"),
      },
    ];
  }

  prepareMFALoginConfig() {
    this.mfaLoginConfig = [
      {
        type: "input",
        label: this.getText("Enter the MFA code"),
        inputType: "mfacode",
        autocomplete: "mfacode",
        name: "mfacode",
        placeholder: "MFA Code",
        validations: [
          {
            name: "required",
            validator: Validators.required,
            message: this.getText("MFA code required"),
          },
        ],
      },
      {
        type: "button",
        color: "primary",
        label: this.getText("LOGIN"),
      },
    ];
  }

  onMfaLogin(mfaLogin: LoginRequest): void {
    this.loginModel.mfacode = mfaLogin.mfacode;
    this.submitForm(this.loginModel, true);
  }

  onLogin(loginModel: LoginRequest): void {
    this.loginModel = { ...loginModel };
    this.submitForm(this.loginModel);
  }

  submitForm(loginRequestModel: LoginRequest, isFromMfa = false): void {
    const sub = this.authService.login(loginRequestModel, true).subscribe(
      (_: LoginResponse) => {
        this.router.navigate(["/admin/pageConfiguration"]);
      },
      (err: HttpErrorResponse) => {
        this.loading = false;
        switch (err.status) {
          case 401:
            this.handleUnauthorizedError(err.error, isFromMfa);
            break;
          case 404:
            this.error = new ErrorSummary(
              this.getText("WRONG_USER_OR_PASS"),
              `Error Code: ${err.status}`
            );
            break;
          default:
            this.error = new ErrorSummary(
              this.getText("UNKNOWN_ISSUE"),
              `Error Code: ${err.status}`
            );
        }
      }
    );
    this.subs.push(sub);
  }

  handleUnauthorizedError(error: any, isFromMfa: boolean) {
    if (!isFromMfa) {
      this.showLoginContainer = false;
      this.mfaEnabled = true;
      this.prepareMFALoginConfig();
    } else {
      this.error = new ErrorSummary(
        "Error confirming MFA code ",
        `Error Code: ${error.status}`
      );
    }
  }

  ngOnDestroy(): void {
    this.subs.forEach((sub) => sub.unsubscribe());
  }
}
