import { CommonModule } from "@angular/common";
import { NgModule } from "@angular/core";
import { CoreModule } from "@career/core/core.module";
import { PageConfigurationComponent } from "./page-configuration/page-configuration.component";
import { MatToolbarModule } from "@angular/material/toolbar";
import { MatIconModule } from "@angular/material/icon";
import { MatLegacyButtonModule as MatButtonModule } from "@angular/material/legacy-button";
import { MatSidenavModule } from "@angular/material/sidenav";
import { MatLegacyListModule as MatListModule } from "@angular/material/legacy-list";
import { MatLegacyMenuModule as MatMenuModule } from "@angular/material/legacy-menu";
import { PageConfigSideNavComponent } from "./page-configuration/page-config-side-nav/page-config-side-nav.component";
import { MatLegacyFormFieldModule as MatFormFieldModule } from "@angular/material/legacy-form-field";
import { MatLegacySelectModule as MatSelectModule } from "@angular/material/legacy-select";
import { MatLegacyPaginatorModule as MatPaginatorModule } from "@angular/material/legacy-paginator";
import { MatLegacyAutocompleteModule as MatAutocompleteModule } from "@angular/material/legacy-autocomplete";
import { FormsModule, ReactiveFormsModule } from "@angular/forms";
import { MatLegacyProgressBarModule as MatProgressBarModule } from "@angular/material/legacy-progress-bar";
import { MatLegacySnackBarModule as MatSnackBarModule } from "@angular/material/legacy-snack-bar";
import { PocketFormsModule } from "@career/pocket-forms/pocket-forms.module";
import { FontAwesomeModule } from "@fortawesome/angular-fontawesome";
import { UploadImageDialogComponent } from "./Shared/components/upload-image-dialog/upload-image-dialog.component";
import { MatLegacyDialogModule as MatDialogModule } from "@angular/material/legacy-dialog";
import { RouterModule } from "@angular/router";
import { SectionTextEditDialogComponent } from './Shared/components/section-text-edit-dialog/section-text-edit-dialog.component';
import { QuillModule } from "ngx-quill";
import { MatLegacyInputModule as MatInputModule } from "@angular/material/legacy-input";
import { EditConfigurationsComponent } from './Shared/components/edit-configurations/edit-configurations.component';
import { MatLegacySlideToggleModule as MatSlideToggleModule } from "@angular/material/legacy-slide-toggle";
import { ConfirmDialogComponent } from './Shared/components/confirm-dialog/confirm-dialog.component';
import { EmbeddedToolbarComponent } from './Shared/components/embedded-toolbar/embedded-toolbar.component';
import { CreateNewTemplateComponent } from './Shared/components/create-new-template/create-new-template.component';
import { LayersModule } from "kleos-ui";
import { MatDividerModule } from "@angular/material/divider";
import { AngularEditorModule } from "@kolkov/angular-editor";
import { MatLegacyCheckboxModule } from "@angular/material/legacy-checkbox";

@NgModule({
  declarations: [
    PageConfigurationComponent,
    PageConfigSideNavComponent,
    UploadImageDialogComponent,
    SectionTextEditDialogComponent,
    EditConfigurationsComponent,
    ConfirmDialogComponent,
    EmbeddedToolbarComponent,
    CreateNewTemplateComponent
  ],
  imports: [
    CommonModule,
    LayersModule,
    MatInputModule,
    MatFormFieldModule,
    MatDividerModule,
    MatIconModule,
    MatButtonModule,
    CoreModule,
    MatToolbarModule,
    MatIconModule,
    MatButtonModule,
    MatSidenavModule,
    MatListModule,
    MatInputModule,
    MatMenuModule,
    MatSelectModule,
    MatPaginatorModule,
    MatAutocompleteModule,
    MatLegacyCheckboxModule,
    FormsModule,
    ReactiveFormsModule,
    PocketFormsModule,
    MatProgressBarModule,
    FontAwesomeModule,
    MatSnackBarModule,
    MatDialogModule,
    RouterModule,
    MatSlideToggleModule,
    QuillModule.forRoot(),
    AngularEditorModule
  ],
  exports: [
    EmbeddedToolbarComponent
  ]
})
export class PageConfigurationModule {}
