import { Injectable } from "@angular/core";
import { CanActivate, Router } from "@angular/router";
import { UserService } from "@career/core/services/user.service";
import { map, take } from "rxjs/operators";

@Injectable({
  providedIn: "root",
})
export class NotAdminAuthGaurd implements CanActivate {
  constructor(
    private router: Router,
    private userSvc : UserService
  ) {
  }

  canActivate() {
    return this.userSvc.isAdmin().pipe(
      take(1),
      map((isAdmin) => {
        if ( !isAdmin ) {
          return true;
        }
        this.router.navigate(["/admin/pageConfiguration"]);
        return false;
      })
    );
  }
}
