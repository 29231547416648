<ngx-loading-bar color="var(--theme-accent-color-500)" includeSpinner=""></ngx-loading-bar>
<mat-toolbar *ngIf="isPreviewMode && isUserLoggedIn">
    <button mat-button class="preview__btn" (click)="onGoBack()">Go Back</button>
    <button mat-button class="preview__btn" (click)="onPublish()">Publish</button>
</mat-toolbar>
<app-mobile-nav *ngIf="!showPageConfiguration"></app-mobile-nav>
<app-nav *ngIf="!showPageConfiguration"></app-nav>
<div class="pr-app-container">
    <router-outlet id="mainContent"></router-outlet>
</div>
<app-footer *ngIf="!showPageConfiguration"></app-footer>
