import { Injectable } from "@angular/core";
import {
  HttpInterceptor,
  HttpRequest,
  HttpHandler,
  HttpEvent,
  HttpResponse,
} from "@angular/common/http";
import { Observable, of } from "rxjs";
import { Store } from "@ngxs/store";
import { UserState } from "@career/user/store/states/user.state";
import {
  DefaultOppAttachmentRes,
  DefaultOppResponse,
} from "@career/opportunity-detail/config/opportunity.config";

@Injectable({
  providedIn: "root",
})
export class AdminInterceptor implements HttpInterceptor {
  mockResponseUrls = [
    {
      key: /\/api\/portal\/(.+)\/opportunity\/(.+)\?populate=(.+)/g,
      response: DefaultOppResponse,
    },
    {
      key: /\/api\/attachment\/opportunity\/(.+)/g,
      response: DefaultOppAttachmentRes,
    },
  ];
  constructor(private store: Store) {}

  intercept(
    request: HttpRequest<any>,
    next: HttpHandler
  ): Observable<HttpEvent<any>> {
    const isAdmin = this.store.selectSnapshot(UserState.isAdminRole);
    if (isAdmin && this.getMockObject(request.url).length) {
      return of(new HttpResponse({ status: 200, body: this.getMockObject(request.url)[0].response }));
    }
  
    return next.handle(request);
  }

  getMockObject(requestUrl: string): any[] {
    return this.mockResponseUrls.filter((urlRegexObj) => {
      const match = requestUrl.match(urlRegexObj.key);
      return match && match.length;
    });
  }
}
