import { from, Observable } from "rxjs";
import { switchMap, mergeMap, concatMap } from "rxjs/operators";
import { EActionType, IAction } from "./action.model";
export abstract class ActionProvider {
    constructor() {}

    abstract fetch(): Observable<IAction>;
    populateTarget?(action: IAction): IAction;
    abstract setId(action: IAction): string;
}
