export namespace AuthAction {
    
    export class Login {
        static readonly type = '[Auth] Login';
        constructor(public payload: { token: string }) {}
    }

    export class ClearToken {
        static readonly type = '[Auth] Clearing Token';
    }
    
    export class LoginSuccess {
        static readonly type = '[Auth] Login Success';
    
        constructor(public payload: { candidate: any }) {}
    }
      
    export class LoginFailure {
        static readonly type = '[Auth] Login Failure';
        constructor(public payload: any) {}
    }
    
    export class LoginRedirect {
        static readonly type = '[Auth] Login Redirect';
    }
    
    export class Logout {
        static readonly type = '[Auth] Logout';
    }
}