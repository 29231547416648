import { Injectable } from "@angular/core";
import {
  Actions,
  ofActionCompleted,
  Store,
} from "@ngxs/store";
import { from, Observable, of } from "rxjs";
import {
  concatMap,
  map,
} from "rxjs/operators";
import {
  EActionType,
  IAction,
  OpportunityAction,
} from "../models/action.model";
import { ActionsAction } from "../store/actions/actions.actions";
import { ActionsState } from "../store/states/actions.state";
import { UserAction } from "@career/user/store/actions/user.actions";

@Injectable({
  providedIn: "root",
})
export class ActionsService {
  constructor(
    private store: Store,
    private actions$: Actions
  ) {
    this.init();
    this.actions$
      .pipe(ofActionCompleted(UserAction.Changed))
      .subscribe((data) => {
        this.init(data.action.context);
      });
  }

  getWorkflowActions(oppId: string): Observable<any> {
    return this.store.select(ActionsState.allOpportunityActions).pipe(
      map((data) => {
        return (
          data &&
          data.filter((i) => (i as OpportunityAction).opportunity._id === oppId)
        );
      })
    );
  }

  getProfileActions(): Observable<any> {
    return this.store.select(ActionsState.allProfileActions);
  }

  getCompleteProfileActions(): Observable<any> {
    return this.store.select(ActionsState.completeProfileActions);
  }

  getIncompleteProfileActions(): Observable<any> {
    return this.store.select(ActionsState.incompleteProfileActions);
  }

  init(context?: "PROFILE" | "OPPORTUNITY") {
    if (context) {
      const type =
        context === "PROFILE"
          ? EActionType.PROFILE
          : EActionType.QUESTIONNAIRES;
      this.load(type);
      return;
    }
    this.load();
  }

  load(type: EActionType | null = null): Observable<IAction> {
    return this.store.dispatch([new ActionsAction.Load(type)]);
  }

  getIncompleteWorkflowActions(oppId: string): Observable<any> {
    return this.store
      .select(ActionsState.incompleteOpportunityActions)
      .pipe(
        map(
          (data) =>
            data &&
            data.filter(
              (i) => (i as OpportunityAction).opportunity._id === oppId
            )
        )
      );
  }

  getNextIncompleteWorkflowActionsStream(oppId: string, completedId: string) {
    return this.getIncompleteWorkflowActions(oppId).pipe(
      concatMap((data) => {
        const arr = (data || []).filter(
          (a) => !completedId || (a.object || ({} as any))._id !== completedId
        );
        return (arr || []).length > 0 ? from(arr) : of({});
      })
    );
  }

  getIncompleteWorkflowActionsStream(oppId: string): Observable<any> {
    return this.getIncompleteWorkflowActions(oppId).pipe(
      concatMap((data) => ((data || []).length > 0 ? from(data) : of({})))
    );
  }

  getCompleteWorkflowActions(oppId: string): Observable<any> {
    return this.store
      .select(ActionsState.completeOpportunityActions)
      .pipe(
        map(
          (data) =>
            data &&
            data.filter(
              (i) => (i as OpportunityAction).opportunity._id === oppId
            )
        )
      );
  }

  getCompleteWorkflowActionsStream(oppId: string): Observable<any> {
    return this.getCompleteWorkflowActions(oppId).pipe(
      concatMap((data) => ((data || []).length > 0 ? from(data) : of({})))
    );
  }

  getIncompleteOpportunityActions(): Observable<any> {
    return this.store.select(ActionsState.incompleteOpportunityActions);
  }

  getIncompleteActions(): Observable<any> {
    //does the same thing right now
    return this.store.select(ActionsState.incomplete);
  }

  getCompletedOpportunityActions(): Observable<any> {
    return this.store.select(ActionsState.completeOpportunityActions);
  }

  // private initialize(): Observable<any> {
  //   return this.store.dispatch([new ActionsAction.Clear]).pipe(
  //     switchMap(() => from(Object.keys(EActionType).map(type => this.factory.createActions(EActionType[type])))),
  //     mergeAll(),
  //     toArray(),
  //     map((data) => this.store.dispatch([new ActionsAction.Add(data)]) && data)
  //   )
  // }
}
