import { HttpClient } from "@angular/common/http";
import { Injectable } from "@angular/core";
import { Observable } from "rxjs/internal/Observable";
import { PortalService } from "./portal.service";

@Injectable({
  providedIn: "root",
})
export class AttachmentService {
  constructor(
    protected http: HttpClient
  ) {}

  uploadFile(resume: File, candidateId): Observable<any> {
    const formData: FormData = new FormData();
    formData.append("file", resume);
    return this.http.post(`/api/attachment/candidate/${candidateId}?portalVisibility=VISIBLE`, formData);
  }

  extractBlob(data: any, file: any) {
    if (!data) {
      return;
    }
    let blob = new Blob([data]),
      link = document.createElement("a"),
      url = window.URL.createObjectURL(blob);
    link.href = url;
    link.download = file.fileName;
    link.click();
    window.URL.revokeObjectURL(url);
  }

  downloadFile(fileId): Observable<any> {
    return this.http.get("/api/attachment/" + fileId + "?encodingType=null", {
      responseType: "blob",
    });
  }

  deleteFile(fileId): Observable<any> {
    return this.http.delete("/api/attachment/" + fileId);
  }

  getAttachments(id: string, moduleName: string): Observable<any> {
    let url = `/api/attachment/${moduleName}/${id}?portalVisibility=VISIBLE`;
    return this.http.get(url);
  }

}
