import { Opportunity } from "@career/opportunity-detail/models/opportunity.model";
import { IUser } from "@career/user/models/user.model";
import { IActionTarget } from "./action-target.model";

export enum EActionType {
    QUESTIONNAIRES = "questionnaires",
    PROFILE = "profile"
    // COMPLETE_QUESTIONNAIRES = "complete_questionnaires"
}

export enum EDataSource {
    INCOMPLETE_QUESTIONNAIRES = "incomplete_questionnaires",
    COMPLETE_QUESTIONNAIRES = "complete_questionnaires",
    SOVA = "sova",
    USER = "user"
}

export enum EActionStatus {
    COMPLETE, INCOMPLETE
}

export interface IAction {
    id: string;
    type: EActionType;
    target: IActionTarget;
    user: IUser;
    timestamp: Date;
    status: EActionStatus;
    object: object;
    externalDetails: object;
}

export class BaseActionBuilder<T extends IAction> {
    protected action: T;

    constructor(private type: new () => T) {
        this.action = new this.type();
    }

    setType(type: EActionType) {
        this.action.type = type;
        return this;
    }

    setObject(obj: object) {
        this.action.object = obj;
        return this;
    }

    setTarget(target: IActionTarget) {
        this.action.target = target;
        return this;
    }

    setUser(user: any) {
        this.action.user = user;
        return this;
    }

    setTimestamp(timestamp: Date) {
        this.action.timestamp = timestamp;
        return this;
    }

    setStatus(status: EActionStatus) {
        this.action.status = status;
        return this;
    }

    setExternalDetails(obj: object) {
        this.action.externalDetails = obj;
        return this;
    }

    build(): IAction {
        return this.action;
    }
}

export class ActionBuilder extends BaseActionBuilder<Action> {
    constructor() {
        super(Action);
    }

    
    from(action: IAction) {
        return this
            .setStatus(action.status)
            .setTarget(action.target)
            .setUser(action.user)
            .setTimestamp(action.timestamp)
            .setType(action.type);
    }
}

export class Action implements IAction {
    private _type: EActionType;
    private _target: any;
    private _timestamp: Date;
    private _status: EActionStatus;
    private _user: IUser;
    private _object: object;
    private _externalDetails: object;
    private _id: string;

    get id() {
        return this._id;
    }

    set id(id: string) {
        this._id = id;
    }

    get externalDetails() {
        return this._externalDetails;
    }

    set externalDetails(details: object) {
        this._externalDetails = details;
    }

    get user() {
        return this._user;
    }

    set object(obj: object) {
        this._object = obj;
    }

    get object() {
        return this._object;
    }

    set user(newUser: IUser) {
        this._user = newUser;
    }

    get status() {
        return this._status;
    }

    set status(newStatus: EActionStatus) {
        this._status = newStatus;
    }

    get type() {
        return this._type;
    }

    set type(newName: EActionType) {
        this._type = newName;
    }

    get target() {
        return this._target;
    }

    set target(newTarget: any) {
        this._target = newTarget;
    }

    get timestamp() {
        return this._timestamp;
    }

    set timestamp(newTimestamp: any) {
        this._timestamp = newTimestamp;
    }

    constructor() {}
}

export class OpportunityActionBuilder extends BaseActionBuilder<OpportunityAction> {
    constructor() {
        super(OpportunityAction);
    }

    from(action: IAction) {
        return this
            .setStatus(action.status)
            .setTarget(action.target)
            .setUser(action.user)
            .setObject(action.object)
            .setTimestamp(action.timestamp)
            .setType(action.type)
            .setExternalDetails(action.externalDetails)
            .setOpportunity((action as OpportunityAction).opportunity)
    }

    setOpportunity(opp: Opportunity) {
        this.action.opportunity = opp;
        return this;
    }
}

export class OpportunityAction extends Action {
    private _opportunity: Opportunity;
    constructor(){
        super();
    }

    get opportunity() {
        return this._opportunity;
    }

    set opportunity(opp: Opportunity) {
        this._opportunity = opp;
    }
}