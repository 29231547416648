<ng-container *ngIf="!scope.config.disableJobSearchPage; else showNoAccessMsg">
    <ng-container *ngIf="!scope.forwardJobSearchPageExists ; else showForwardPageMsg">
        <div class="header" appImgEditContainer="opportunity-list" name="Main Image">
            <div class="header__img-container">
                <img [src]="scope.component.assets.image" class="header__img" [alt]="scope.getText | transform:'JOBS'">
            </div>
            <div class="header__overlay header__overlay--top">
                <div class="header__title-container">
                    <div class="header__title-row header__title-row--slim">
                        <h1 class="header__title" [innerText]="scope.getText | transform:'JOBS'"></h1>
                    </div>
                </div>
            </div>
        </div>
        <app-default-opportunities [scope]="scope"></app-default-opportunities>
    </ng-container>
    <ng-template #showForwardPageMsg>
        <div class="forward-page-msg">
            <mat-icon>settings</mat-icon>
            <h1>
                {{scope.translations.FORWARD_PAGE_MSG}} <a [href]="scope.config.forwardJobSearchPage"
                    target="_blank">{{scope.config.forwardJobSearchPage}}</a>
            </h1>
        </div>
    </ng-template>
</ng-container>
<ng-template #showNoAccessMsg>
    <div class="forward-page-msg">
        <mat-icon>settings</mat-icon>
        <h1>
            {{scope.translations.NO_ACCESS_JOB_SEARCH_PAGE}}
           </h1>
    </div>
</ng-template>