<div class="unsubscribe-component data-with-image data-with-image--min-height" *ngIf="scope?.unsubscribeOptions">
    <div class="container">
        <div class="main-content">
            <div class="data-with-image__text-container">
                <div class="heading" *ngIf="!scope.submitted">
                        <h1 class="data-with-image__heading data-with-image__heading--bold" [innerText]="scope.unsubscribeText"></h1>
                </div>
                <div class="heading" *ngIf="scope.submitted">
                    <div class="header-text" *ngIf="scope.error">{{scope.getText | transform:'ISSUE_PROCESSING_REQUEST'}}</div>
                    <div class="sub-header-text" *ngIf="!scope.error">{{scope.getText | transform:'UNSUBSCRIBE_COMPLETED'}}</div>
                    <div class="header-text" *ngIf="scope.error">{{scope.getText | transform:'CONTACT_SUPPORT':{supportEmail: 'support@pocketrecruiter.com'} }}</div>
                    <div class="sub-header-text" *ngIf="!scope.error">{{scope.getText | transform:'UNSUBSCRIBE_COMPLETED_MESSAGE'}}</div>
                </div>
            </div>
            <div class="unsubscribe-container">
                <div class="container" *ngIf="!scope.submitted">
                    <label for="unsubscribeOptions" class="form__label" [innerText]="scope.getText | transform:'WHY_DO_YOU_WANT_TO_UNSUBSCRIBE'"></label>
                    <mat-form-field appearance="outline" class="filters__dropdown" name="unsubscribeOptions">
                        <mat-select [(ngModel)]="scope.chosenOption">
                          <mat-option *ngFor="let reason of scope.unsubscribeOptions" [value]="reason.key">
                            {{reason.text}}
                          </mat-option>
                        </mat-select>
                      </mat-form-field>
                      <div *ngIf="scope.chosenOption === 'notlooking'" class="unsubscribe-radio-section">
                        <div class="unsubscribe-radio-title">{{scope.getText | transform:'WHEN_DO_YOU_WANT_US_TO_CONTACT_AGAIN'}}</div>
                        <mat-radio-group class="radio-group" [(ngModel)]="scope.whenToContact" [attr.aria-label]="scope.getText | transform:'WHEN_DO_YOU_WANT_US_TO_CONTACT_AGAIN'">
                          <mat-radio-button value="3" class="unsubscribe-radio-button">{{scope.getText | transform:'3_MONTHS'}}</mat-radio-button>
                          <mat-radio-button value="6" class="unsubscribe-radio-button">{{scope.getText | transform:'6_MONTHS'}}</mat-radio-button>
                          <mat-radio-button value="12" class="unsubscribe-radio-button">{{scope.getText | transform:'1_YEAR'}}</mat-radio-button>
                          <mat-radio-button value="24" class="unsubscribe-radio-button">{{scope.getText | transform:'2_YEARS'}}</mat-radio-button>
                          <mat-radio-button value="never" class="unsubscribe-radio-button">{{scope.getText | transform:'NEVER'}}</mat-radio-button>
                        </mat-radio-group>
                      </div>
                      <button class="data-with-image__button" mat-flat-button color="primary" (click)="scope.unsubscribe()">{{scope.unsubscribeText}}</button>
                </div>
            </div>
        </div>
    </div>
</div>
