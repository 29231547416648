import { Component, Input, OnInit } from '@angular/core';
import { faCheckCircle, faExclamationTriangle, faTimes } from '@fortawesome/free-solid-svg-icons';

@Component({
  selector: 'app-default-action-dialog',
  templateUrl: './default-action-dialog.component.html',
  styleUrls: ['./default-action-dialog.component.scss']
})
export class DefaultActionDialogComponent implements OnInit {

  @Input() scope;
  faExclamationTriangle = faExclamationTriangle;
  faCheckCircle = faCheckCircle;
  faTimes = faTimes;
  constructor() { }

  ngOnInit(): void {

  }

}
