import { Optional } from "@angular/core";
import { MatLegacyDialog as MatDialog } from "@angular/material/legacy-dialog";
import { TemplateConfiguration } from "@career/core/models/component.model";
import {
  PageConfigTypes,
} from "@career/page-configuration/models/page-config.model";
import { EditConfigurationsComponent } from "@career/page-configuration/Shared/components/edit-configurations/edit-configurations.component";
import { LayerService } from "kleos-ui";
import { take } from "rxjs/operators";

export abstract class ConfigurableComponent {
  constructor(@Optional() protected layerService: LayerService) {}
  abstract getTemplateConfiguration(): TemplateConfiguration;
  abstract getConfigurationType(): keyof typeof PageConfigTypes;
  onConfigurationSave(data: TemplateConfiguration): void {
    throw new Error("not implemented");
  }

  openConfiguration() {
    const layerRef = this.layerService.open({
      component: EditConfigurationsComponent,
      data: {
        configData: { ...this.getTemplateConfiguration() },
        configType: this.getConfigurationType(),
      }
    });
    layerRef?.afterClosed().pipe(take(1)).subscribe((data) => {
      if( data && this.onConfigurationSave ) {
        this.onConfigurationSave(data);
      }
    });
  }
}
