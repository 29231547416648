import { Component, Inject, OnInit } from "@angular/core";
import { Router } from "@angular/router";
import { setupRoutes } from "@career/app.module";
import { TemplateComponent } from "@career/core/models/component.model";
import { ErrorSummary } from "@career/core/models/error.model";
import { ContentService } from "@career/core/services/content.service";
import { PortalService } from "@career/core/services/portal.service";
import { DefaultStaticTemplateRes } from "@career/page-configuration/configs/static-template-mock";
import { routerActions } from "@career/routes";
import { ACTIVE_LAYER_CONFIG, LayerOverlayRef } from "kleos-ui";
import { of, Subscription } from "rxjs";
import { switchMap } from "rxjs/operators";

@Component({
  selector: "app-create-new-template",
  templateUrl: "./create-new-template.component.html",
  styleUrls: ["./create-new-template.component.scss"],
})
export class CreateNewTemplateComponent implements OnInit {
  error: ErrorSummary;
  templateName: string = "";
  hideNavigation: boolean = false;
  staticPageRoutes: any[];
  subscriptions: Subscription[] = [];
  existing: TemplateComponent;

  constructor(
    private portalSvc: PortalService,private router: Router, @Inject(ACTIVE_LAYER_CONFIG) private config: any, private layerRef: LayerOverlayRef, private contentService: ContentService) {}

  ngOnInit(): void {
    this.staticPageRoutes = routerActions.getStaticPageRoutes();
    if( this.config && (this.config.data || {}).existing ) {
      this.existing = this.config.data.existing;
      this.templateName = this.existing.textMap.routeDisplayName;
      this.hideNavigation = this.existing.adminOnly.hideNavigation;
    }
  }

  onSave() {
    const rName = this.prepareRouteNameFromTemplate(this.templateName);
    if (!this.checkRouteExists(rName, this.existing)) {
      const payload = this.existing || DefaultStaticTemplateRes;
      payload.textMap.routeDisplayName = this.templateName;
      payload.adminOnly.hideNavigation = this.hideNavigation;
      const sub = this.portalSvc.patchComponent(payload, rName)
      .pipe(
        switchMap(data => {
          if( this.existing && this.prepareRouteNameFromTemplate(this.existing.textMap.routeDisplayName) !== rName ) {
            return this.contentService.deleteCustomTemplate(this.prepareRouteNameFromTemplate(this.existing.textMap.routeDisplayName));
          }
          return of(data);
        })
      )
      .subscribe(
        (_) => {
          this.updateRoutes(rName);
          this.layerRef.close();
        },
        (_) => {
          this.error = new ErrorSummary("Error while creating template");
        }
      );
      this.subscriptions.push(sub);
    } else {
      this.error = new ErrorSummary("Name Already Exists");
    }
  }

  updateRoutes(path: string) {
    this.portalSvc.getPortalObj().subscribe((data) => {
      setupRoutes(data);
      this.portalSvc.toggleRoutesUpdated();
      this.router.navigate(["/admin/pageConfiguration/static/", path]);
    });
  }

  checkRouteExists(rName: string, existing: TemplateComponent) {
    return (
      this.staticPageRoutes.findIndex((route) => route.path === rName && route.path !== (this.existing == null || this.prepareRouteNameFromTemplate(this.existing.textMap.routeDisplayName))) > -1
    );
  }

  prepareRouteNameFromTemplate(tempName: string): string {
    return tempName.replace(/\s/g, "").toLocaleLowerCase();
  }

  ngOnDestroy(): void {
    this.subscriptions.forEach((sub) => sub.unsubscribe());
  }
}
