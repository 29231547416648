import { Component, Input, OnInit } from '@angular/core';

@Component({
  selector: 'app-social-reset-password',
  templateUrl: './social-reset-password.component.html',
  styleUrls: ['./social-reset-password.component.scss']
})
export class SocialResetPasswordComponent implements OnInit {

  @Input() scope;
  constructor() { }

  ngOnInit(): void {
  }

}
