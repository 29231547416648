import { Component, OnDestroy, OnInit } from "@angular/core";
import { ActivatedRoute, Router } from "@angular/router";
import { Validators } from "@angular/forms";
import { JobService } from "@career/core/services/job.service";
import { CandidateService } from "@career/core/services/candidate.service";
import { authComponentDef } from "@career/authentication/component-definition";
import { switchMap, map, catchError } from "rxjs/operators";
import { BehaviorSubject, Observable, of, Subject } from "rxjs";
import { UtilsService } from "@career/core/services/utils.service";
import { PortalService } from "@career/core/services/portal.service";
import { AuthenticationService } from "@career/core/services/authentication.service";
import {
  TemplateComponent,
  TemplateConfiguration,
} from "@career/core/models/component.model";
import { Title } from "@angular/platform-browser";
import { ErrorSummary } from "@career/core/models/error.model";
import { VALID_RESUME_UPLOAD_TYPES } from "@career/core/constants/file.constant";
import { WizardGroup } from "@career/pocket-forms/models/field";
import { ConsentService } from "@career/core/services/consent.service";
import { LocaleService } from "@career/core/services/locale.service";
import { LocationService } from "@career/core/services/location.service";
import { Store } from "@ngxs/store";
import { UserState } from "@career/user/store/states/user.state";
import { UserDTOFactory } from "@career/core/models/user-dto.model";
import { Subscription } from "rxjs";
import { MatLegacyDialog as MatDialog } from "@angular/material/legacy-dialog";
import { PageConfigTypes } from "@career/page-configuration/models/page-config.model";
import { Translations } from "@career/page-configuration/configs/page-config.config";
import { UserService } from "@career/core/services/user.service";
import { ConfigurableComponent } from "@career/page-configuration/Shared/components/configurable.component";
import { LayerService } from "kleos-ui";

@Component({
  selector: "app-register",
  template: `
    <app-base-template
      [component]="component"
      [componentDef]="componentDef"
      [scope]="this"
      *ngIf="component"
    ></app-base-template>
  `,
})
export class RegisterComponent
  extends ConfigurableComponent
  implements OnInit, OnDestroy
{
  id: string;
  config: TemplateConfiguration;
  component: TemplateComponent;
  candidate: any;
  opportunity: any;
  getText: Function;
  componentDef = authComponentDef["register"];
  status: Subject<"PENDING" | "LOADING" | "COMPLETED" | "REGISTERED"> = new BehaviorSubject("COMPLETED");
  loadStatus: string;
  error: ErrorSummary;
  wizard: WizardGroup[];
  groupValidators: Validators[];
  subscriptions: Subscription[] = [];
  isAdmin$: Observable<boolean>;
  translations = Translations;
  constructor(
    private titleService: Title,
    private store: Store,
    private locationService: LocationService,
    private router: Router,
    private route: ActivatedRoute,
    private consentService: ConsentService,
    private localeService: LocaleService,
    private candidateService: CandidateService,
    private authService: AuthenticationService,
    private jobService: JobService,
    private portalService: PortalService,
    private utils: UtilsService,
    layerService: LayerService,
    private userSvc: UserService
  ) {
    super(layerService);
    this.isAdmin$ = this.userSvc.isAdmin();
  }

  ngOnInit() {
    const sub = this.portalService
      .getComponentData("register")
      .subscribe((data) => {
        this.config = data.configuration;
        this.component = data.component;
        this.getText = this.utils.getText(this.component);
        this.titleService.setTitle(
          `${this.getText("REGISTER")} | ${this.portalService.getPortalName()}`
        );
        this.id = this.route.snapshot.paramMap.get("optionalId");
        if (this.id !== null) {
          this.jobService.getOpp(this.id).subscribe((data) => {
            this.opportunity = data;
          });
        }
        this.initCandidate();
      });
    this.subscriptions.push(sub);
  }

  setQuestionnaireFields(questionnaires) {
    return this.utils.createConsentQuestionnaireFields(
      questionnaires,
      this.getText
    );
  }

  verifyLocation(location): Observable<boolean> {
    this.error = null;
    const locationStr = this.utils.createLocationString({
      ...location,
      address1: null,
      address2: null,
    });
    return this.locationService.searchLocation(locationStr).pipe(
      map((data) => {
        if (data && data.locations && data.locations.length > 0) {
          return true;
        }
        this.error = new ErrorSummary(
          this.getText("UNKNOWN_LOCATION"),
          `No location found matching: ${locationStr}`
        );
        return false;
      })
    );
  }

  setupWizard() {
    this.groupValidators = [
      this.utils.confirmFieldValidator(
        "password",
        "confirmpassword",
        "passwordsNotSame"
      ),
    ];
    this.consentService.getConsents().subscribe((consents) => {
      this.wizard = [
        {
          title: this.getText("ENTER_CONTACT_INFO"),
          nextButtonText: this.getText("NEXT"),
          backButtonText: this.config.disableResumeRegistration
            ? null
            : this.getText("UPLOAD_A_DIFFERENT_RESUME", {
                resumeOrCv: this.localeService.getResumeOrCVText(),
              }),
          restart: () => {
            this.status.next("PENDING");
          },
          number: 0,
          fields: [
            {
              type: "input",
              label: this.getText("FIRST_NAME"),
              inputType: "text",
              name: "firstName",
              autocomplete: "given-name",
              value: this.candidate?.name?.firstName,
              validations: [
                {
                  name: "required",
                  validator: Validators.required,
                  message: this.getText("FIELD_REQUIRED", {
                    fieldName: this.getText("FIRST_NAME"),
                  }),
                },
                {
                  name: "pattern",
                  validator: Validators.pattern("^[\\-a-zA-ZÀ-ž ]+$"),
                  message: this.getText("PLEASE_ENTER_TEXT_ONLY"),
                },
              ],
            },
            {
              type: "input",
              label: this.getText("MIDDLE_NAME"),
              inputType: "text",
              name: "middleName",
              value: this.candidate?.name?.middleName,
              autocomplete: "middle-name",
              validations: [
                {
                  name: "pattern",
                  validator: Validators.pattern("^[\\-a-zA-ZÀ-ž ]+$"),
                  message: this.getText("PLEASE_ENTER_TEXT_ONLY"),
                },
              ],
            },
            {
              type: "input",
              label: this.getText("LAST_NAME"),
              inputType: "text",
              name: "lastName",
              value: this.candidate?.name?.lastName,
              autocomplete: "family-name",
              validations: [
                {
                  name: "required",
                  validator: Validators.required,
                  message: this.getText("FIELD_REQUIRED", {
                    fieldName: this.getText("LAST_NAME"),
                  }),
                },
                {
                  name: "pattern",
                  validator: Validators.pattern("^[\\-a-zA-ZÀ-ž ]+$"),
                  message: this.getText("PLEASE_ENTER_TEXT_ONLY"),
                },
              ],
            },
            //TODO: Replace with new intl. phone number input
            {
              type: "input",
              label: this.getText("PHONE_NUMBER"),
              inputType: "tel",
              name: "phones",
              value: this.candidate?.phones && this.candidate.phones[0]?.number,
              placeholder: this.localeService.isUSLocale()?"+1-###-###-####":"+44-###-####-####",
              autocomplete: "tel",
              validations: [
                this.config.requirePhoneNumber
                  ? {
                      name: "required",
                      validator: Validators.required,
                      message: this.getText("FIELD_REQUIRED", {
                        fieldName: this.getText("PHONE_NUMBER"),
                      }),
                    }
                  : null,
                {
                  name: "validPhone",
                  validator: this.utils.regexValidator(
                    new RegExp("^\\+((?:9[679]|8[035789]|6[789]|5[90]|42|3[578]|2[1-689])|9[0-58]|8[1246]|6[0-6]|5[1-8]|4[013-9]|3[0-469]|2[70]|7|1)(?:\\W*\\d){0,13}\\d$"),
                    { validPhone: true }
                  ),
                  message: this.getText("PLEASE_ENTER_A_VALID_PHONE_NUMBER"),
                },
              ].filter((v) => !!v),
            },
          ],
        },
        {
          title: this.getText("ENTER_LOCATION_INFO"),
          backButtonText: this.getText("BACK_TO_CONTACT_INFO"),
          nextButtonText: "Next",
          preSubmit: this.verifyLocation.bind(this),
          number: 1,
          fields: [
            {
              type: "input",
              label: this.getText("ADDRESS1"),
              inputType: "text",
              name: "address1",
              value: this.candidate?.location?.locations[0]?.address1,
              showIf: !!this.config.collectFullAddress,
              validations: [
                {
                  name: "required",
                  validator: Validators.required,
                  message: this.getText("FIELD_REQUIRED", {
                    fieldName: this.getText("ADDRESS1"),
                  }),
                },
              ],
            },
            {
              type: "input",
              label: this.getText("ADDRESS2"),
              inputType: "text",
              name: "address2",
              showIf: !!this.config.collectFullAddress,
              value: this.candidate?.location?.locations[0]?.address2,
            },
            {
              type: "input",
              label: this.getText("STATE"),
              inputType: "text",
              name: "stateName",
              value: this.candidate?.location?.locations[0]?.stateName,
              showIf: this.localeService.matchesLocale("en-US", "de-DE"),
              validations: [
                {
                  name: "required",
                  validator: Validators.required,
                  message: this.getText("FIELD_REQUIRED", {
                    fieldName: this.getText("STATE"),
                  }),
                },
              ],
            },
            {
              type: "input",
              label: this.getText("CITY"),
              value: this.candidate?.location?.locations[0]?.city,
              inputType: "text",
              name: "city",
              validations: [
                {
                  name: "required",
                  validator: Validators.required,
                  message: this.getText("FIELD_REQUIRED", {
                    fieldName: this.getText("CITY"),
                  }),
                },
              ],
            },
            {
              type: "input",
              label: this.getText("COUNTY"),
              inputType: "text",
              name: "county",
              value: this.candidate?.location?.locations[0]?.county,
              showIf: !this.localeService.matchesLocale("en-US", "de-DE"),
              validations: [
                {
                  name: "required",
                  validator: Validators.required,
                  message: this.getText("FIELD_REQUIRED", {
                    fieldName: this.getText("COUNTY"),
                  }),
                },
              ],
            },
            {
              type: "input",
              label: this.getText("POSTAL_CODE"),
              inputType: "text",
              value: this.candidate?.location?.locations[0]?.postalCode,
              name: "postalCode",
              validations: [
                {
                  name: "required",
                  validator: Validators.required,
                  message: this.getText("FIELD_REQUIRED", {
                    fieldName: this.getText("POSTAL_CODE"),
                  }),
                },
              ],
            },
            {
              type: "select",
              label: this.getText("COUNTRY"),
              name: "country",
              value: this.candidate?.location?.locations[0]?.country,
              options: this.portalService.getCountryList(),
              validations: [
                {
                  name: "required",
                  validator: Validators.required,
                  message: this.getText("FIELD_REQUIRED", {
                    fieldName: this.getText("COUNTRY"),
                  }),
                },
              ],
            },
            this.config.hideMaxCommuteDistance
              ? null
              : {
                  type: "input",
                  label: this.getText("MAX_COMMUTE_DISTANCE", {
                    units: this.getText("MILES")
                  }),
                  inputType: "number",
                  max: 500,
                  min: 0,
                  name: "commuteDistance",
                  value: this.candidate?.location?.commuteDistance,
                  validations: [
                    {
                      name: "max",
                      validator: Validators.max(500),
                      message: this.getText("MAXIMUM_DISTANCE", {
                        units: this.getText("MILES"),
                        distance: 500,
                      }),
                    },
                  ],
                },
          ],
        },
        {
          title: this.getText("ENTER_EMAIL_AND_PASSWORD"),
          backButtonText: this.getText("BACK_TO_LOCATION_INFO"),
          nextButtonText: this.getText("NEXT"),
          submitButtonText: this.getText("SUBMIT"),
          number: 2,
          validators: this.groupValidators,
          fields: [
            {
              type: "input",
              label: this.getText("EMAIL"),
              inputType: "email",
              autocomplete: "email",
              name: "email",
              value: this.candidate?.email,
              validations: [
                {
                  name: "required",
                  validator: Validators.required,
                  message: this.getText("FIELD_REQUIRED", {
                    fieldName: this.getText("EMAIL"),
                  }),
                },
                {
                  name: "pattern",
                  validator: Validators.pattern(
                    "^[a-zA-Z0-9._%+-]+@[a-zA-Z0-9.-]+.[a-zA-Z]{2,4}$"
                  ),
                  message: this.getText("INVALID_EMAIL"),
                },
              ],
            },
            {
              type: "input",
              label: this.getText("PASSWORD"),
              inputType: "password",
              name: "password",
              autocomplete: "new-password",
              validations: [
                {
                  name: "required",
                  validator: Validators.required,
                  message: this.getText("FIELD_REQUIRED", {
                    fieldName: this.getText("PASSWORD"),
                  }),
                },
                {
                  name: "minlength",
                  validator: Validators.minLength(8),
                  message: this.getText("MINIMUM_PASSWORD_LENGTH_REQUIRED", {
                    length: 8,
                  }),
                },
                {
                  name: "maxlength",
                  validator: Validators.maxLength(80),
                  message: this.getText("MAXIMUM_PASSWORD_LENGTH_REQUIRED", {
                    length: 8,
                  }),
                },
                {
                  name: "specialchars",
                  validator: this.utils.regexValidator(
                    new RegExp("[!@#$%&*()-+=^]+"),
                    { specialchars: true }
                  ),
                  message: this.getText("SPECIAL_CHARACTER_REQUIRED"),
                },
                {
                  name: "number",
                  validator: this.utils.regexValidator(new RegExp("[0-9]+"), {
                    number: true,
                  }),
                  message: this.getText("NUMBER_REQUIRED"),
                },
                {
                  name: "char",
                  validator: this.utils.regexValidator(new RegExp("[A-Z]+"), {
                    char: true,
                  }),
                  message: this.getText("UPPERCASE_CHARACTER_REQUIRED"),
                },
              ],
            },
            {
              type: "input",
              label: this.getText("CONFIRM_PASSWORD"),
              inputType: "password",
              name: "confirmpassword",
              validations: [
                {
                  name: "required",
                  validator: Validators.required,
                  message: this.getText("FIELD_REQUIRED", {
                    fieldName: this.getText("CONFIRM_PASSWORD"),
                  }),
                },
                {
                  name: "passwordsNotSame",
                  validator: null,
                  message: this.getText("PASSWORDS_MUST_MATCH"),
                },
              ],
            },
          ],
        },
        {
          title: this.getText("ENTER_CONSENTS"),
          backButtonText: this.getText("BACK_TO_ENTER_EMAIL_AND_PASSWORD"),
          nextButtonText: this.getText("NEXT"),
          submitButtonText: this.getText("SUBMIT"),
          number: 3,
          showIf: Boolean(consents && consents.sections),
          fields: this.setQuestionnaireFields(consents || []),
        },
        {
          title: this.getText("PREFERENCES"),
          backButtonText: Boolean(consents && consents.sections)?this.getText("BACK_TO_ENTER_CONSENTS"):this.getText("BACK_TO_ENTER_EMAIL_AND_PASSWORD"),
          nextButtonText: this.getText("NEXT"),
          submitButtonText: this.getText("SUBMIT"),
          number: 4,
          showIf: this.portalService.isPreferencesEnabled(),
          fields: [
            {
              type: "checkbox",
              label: this.getText('PREFER_TEXT_COMMUNICATIONS'),
              name: "prefertext",
              value: this.candidate?.login?.communications?.text
            }
          ]
        }
      ];
    });
  }

  createDTO(obj) {
    return UserDTOFactory.create(obj, "CREATE");
  }

  register(obj) {
    this.status.next("LOADING");
    this.loadStatus = this.getText("REGISTERING");
    try {
      const dto = this.createDTO(obj);
      this.candidateService
        .register(dto, (this.candidate || {})._id, this.id)
        .pipe(
          switchMap((data) => {
            if (this.config && this.config.disablePortalVerification) {
              return this.authService.login(dto);
            }
            return of(false);
          })
        )
        .subscribe(
          (loggedIn) => {
            if (!loggedIn) {
              this.status.next("REGISTERED");
              return;
            }

            if (this.id) {
              this.router.navigate([`/apply/${this.id}`], {
                skipLocationChange: true,
              });
              return;
            }

            this.router.navigate(["/dashboard"]);
          },
          (err) => {
            this.status.next("COMPLETED");
            //handle if already exists, or other unspecified error
            if (
              err.status === 500 &&
              (err.error || {}).message &&
              err.error.message.toLowerCase() === "invalid email"
            ) {
              this.error = new ErrorSummary(
                this.getText("INVALID_EMAIL_PROVIDED"),
                `Status Code: ${err.status}`
              );
              return;
            }

            if (
              err.status === 400 ||
              (err.status === 500 &&
                (err.error || {}).message &&
                (err.error.message.includes("already exists") ||
                  err.error.message === "This account is already registered" ||
                  err.error.message.includes("duplicate")))
            ) {
              this.router.navigate(["/login", { alreadyExists: true }]);
              return;
            } else {
              this.error = new ErrorSummary(
                this.getText("UNKNOWN_REGISTER_ERROR"),
                `Status Code: ${err.status}`
              );
            }
          }
        );
    } catch (e) {
      console.error(e);
      this.status.next("COMPLETED");
      this.error = new ErrorSummary(this.getText("UNKNOWN_REGISTER_ERROR"));
    }
  }

  parseCandidate(data) {
    this.candidate = this.utils.parseCandidate(data);
  }

  resumeUpload(event) {
    this.error = null;
    let file;
    if (event.target) {
      file = event.target.files[0] || null;
    } else {
      file = event[0];
    }
    if (VALID_RESUME_UPLOAD_TYPES.includes(file.type)) {
      this.status.next("LOADING");
      this.loadStatus = this.getText("UPLOADING_FILE", { name: file.name });
      this.candidateService
        .uploadResume(file)
        .pipe(
          catchError((err) => {
            this.status.next("PENDING");
            this.error = new ErrorSummary(
              this.getText("UNKNOWN_ISSUE"),
              `Error Code: ${err.status || "Unknown"}`
            );
            return of(null);
          })
        )
        .subscribe((data) => {
          this.parseCandidate(data);
          this.setupWizard();
          this.status.next("COMPLETED");
        });
    } else {
      //wrong file type
      this.error = new ErrorSummary(this.getText("INVALID_FILE_TYPE"));
    }
  }

  initCandidate() {
    let user = (this.store.selectSnapshot(UserState.suggested) || []).map(
      (suggestion) => suggestion.profile
    );
    user = user && user.length > 0 ? user[0] : null;
    if (user) {
      this.parseCandidate(user);
    }
    this.setupWizard();
    this.status.next(
      (user || this.config.disableResumeRegistration) ? "COMPLETED" : "PENDING"
    );
  }

  getConfigurationType(): keyof typeof PageConfigTypes {
    return PageConfigTypes.REGISTER;
  }

  getTemplateConfiguration(): TemplateConfiguration {
    return { ...this.config };
  }

  onConfigurationSave(data: TemplateConfiguration): void {
    this.initCandidate();
  }

  ngOnDestroy() {
    this.subscriptions.forEach((e) => e.unsubscribe());
  }
}
