import { Component, EventEmitter, Input, OnInit, Output } from '@angular/core';
import { UserService } from '@career/core/services/user.service';
import { Observable } from 'rxjs';

@Component({
  selector: 'app-embedded-toolbar',
  templateUrl: './embedded-toolbar.component.html',
  styleUrls: ['./embedded-toolbar.component.scss']
})
export class EmbeddedToolbarComponent implements OnInit {
  @Input() configurationName: string;
  @Output() onConfigure: EventEmitter<boolean> = new EventEmitter();
  isAdmin$: Observable<boolean> = this.userSvc.isAdmin();
  constructor(private userSvc: UserService) { }

  ngOnInit(): void {
  }

}
