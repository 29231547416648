import { NgModule } from '@angular/core';
import { CommonModule } from '@angular/common';
import { HomeComponent } from './components/home/home.component';
import { HomeRoutingModule } from './home-routing.module';
import { DefaultHomeComponent } from './components/home/templates/default/default-home.component';
import { CoreModule } from '@career/core/core.module';
import { MatLegacyFormFieldModule as MatFormFieldModule } from '@angular/material/legacy-form-field';
import { MatLegacyButtonModule as MatButtonModule } from '@angular/material/legacy-button';
import { MatLegacyInputModule as MatInputModule } from '@angular/material/legacy-input';
import { MatLegacySelectModule as MatSelectModule } from '@angular/material/legacy-select';
import { FormsModule, ReactiveFormsModule } from '@angular/forms';
import { PocketFormsModule } from '@career/pocket-forms/pocket-forms.module';
import { FontAwesomeModule } from '@fortawesome/angular-fontawesome';
import { MatLegacyAutocompleteModule as MatAutocompleteModule } from '@angular/material/legacy-autocomplete';
import { SocialHomeComponent } from './components/home/templates/social/social-home.component';
import { MatIconModule } from '@angular/material/icon';
import { MatToolbarModule } from '@angular/material/toolbar';
import { PageConfigurationModule } from '@career/page-configuration/page-configuration.module';
import { AngularEditorModule } from '@kolkov/angular-editor';



@NgModule({
  declarations: [
    HomeComponent,
    DefaultHomeComponent,
    SocialHomeComponent
  ],
  imports: [
    CommonModule,
    CoreModule,
    PageConfigurationModule,
    FontAwesomeModule,
    PocketFormsModule,
    MatFormFieldModule,
    MatAutocompleteModule,
    MatButtonModule,
    MatInputModule,
    MatSelectModule,
    MatIconModule,
    MatToolbarModule,
    ReactiveFormsModule,
    FormsModule,
    HomeRoutingModule,
    AngularEditorModule
  ]
})
export class HomeModule { }
