export namespace UserAction {
    export class Setup {
        static readonly type = '[User] Setup';
        constructor(public payload: { user: any }) {}
    }
    export class Update {
        static readonly type = '[User] Update';
        constructor(public payload: { user: any, context: "PROFILE"|"OPPORTUNITY" }) {}
    }

    export class Changed {
        static readonly type = '[User] Changed';
        constructor(public context?: "PROFILE"|"OPPORTUNITY") {}
    }

    export class Remove {
        static readonly type = '[User] Removed';
    }

    export class GetApplied {
        static readonly type = '[User] Get Applied Jobs';
        constructor(public payload: any) {}
    }

    
    export class ClearApplied {
        static readonly type = '[User] Clear Applied Jobs';
        constructor() {}
    }

    
    export class ClearSuggested {
        static readonly type = '[User] Clear Applied Jobs';
        constructor() {}
    }

    export class GetSuggested {
        static readonly type = '[User] Get Suggested Jobs';
        constructor(public payload: any) {}
    }
}
