import { Component, Input, OnInit } from '@angular/core';
import { ActivatedRoute } from '@angular/router';
import { ActionsDialogService } from '@career/actions/services/actions-dialog.service';
import { ActionsService } from '@career/actions/services/actions-service.service';
import { TemplateConfiguration, TemplateComponent } from '@career/core/models/component.model';
import { CandidateService } from '@career/core/services/candidate.service';
import { PortalService } from '@career/core/services/portal.service';
import { UserService } from '@career/core/services/user.service';
import { UtilsService } from '@career/core/services/utils.service';
import { userComponentDef } from '@career/user/component-definition';
import { Observable, Subscription } from 'rxjs';
import { filter, map, shareReplay, switchMap, take } from 'rxjs/operators';

@Component({
  selector: 'app-panels',
  template: `
  <app-base-template [component]="component" [componentDef]="componentDef" [scope]="this" *ngIf="component"></app-base-template>
  `
})
export class PanelsComponent implements OnInit {
  config: TemplateConfiguration;
  component: TemplateComponent;
  getText: Function;
  componentDef = userComponentDef['panels'];
  candidateConsents$: Observable<any[]>;
  skills$: Observable<any[]>;
  portalServiceSubscription: Subscription;
  actionCompleted: boolean = !!this.route.snapshot.paramMap.get("actionCompleted");
  completeActions$: Observable<any> = this.actionsService.getCompleteProfileActions();
  incompleteActions$: Observable<any> = this.actionsService.getIncompleteProfileActions();
  hasPhones$: Observable<any>;
  isPreferencesEnabled = false;
  @Input() myProfileOpen: boolean = false;
  @Input() suggestedJobsOpen: boolean = false;
  @Input() myResumeOpen: boolean = false;
  @Input() uploadFilesOpen: boolean = false;
  @Input() manageConsentsOpen: boolean = false;
  @Input() availabilityOpen: boolean = false;
  @Input() preferencesOpen: boolean = false;
    constructor(private portalService: PortalService, private route: ActivatedRoute, private actionsDialogSvc: ActionsDialogService, private actionsService: ActionsService, private utils: UtilsService, private candidateService: CandidateService, private userSvc : UserService) { }
  openDialog() {
    this.actionsDialogSvc.open();
  }
  
  getUser() {
    return this.userSvc.getUser().pipe(
      filter(user => user && user._id)
    );
  }

  ngOnInit() {
    this.portalServiceSubscription = this.portalService.getComponentData('panels').subscribe((data) => {
      this.config = data.configuration;
      this.component = data.component;
      this.isPreferencesEnabled = this.portalService.isPreferencesEnabled();
      this.hasPhones$ = this.getUser().pipe(
        filter(user => user && user.phones && (user.phones || []).length > 0)
      );
      this.getText = this.utils.getText(this.component);
      if( this.actionCompleted ) {
        this.incompleteActions$ = this.actionsService.getIncompleteProfileActions();
      }
      
      this.candidateConsents$ = this.getUser().pipe(
        filter(user => user && user._id),
        switchMap(user => this.candidateService.getCandidateConsents(user._id)),
        filter(candidateConsents => candidateConsents && candidateConsents.length > 0),
        shareReplay(1)
      );

      this.skills$ = this.getUser().pipe(
        map(user => user.authorization.agency._id),
        switchMap(agencyId => this.candidateService.getSkillsList(agencyId)),
        shareReplay(1)
      );
    });
  }

  ngOnDestroy() {
    this.portalServiceSubscription.unsubscribe();
  }
}
