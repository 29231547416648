import { ChangeDetectorRef, Component, OnDestroy, OnInit } from '@angular/core';
import { TemplateConfiguration, TemplateComponent } from '@career/core/models/component.model';
import { ErrorSummary } from '@career/core/models/error.model';
import { CandidateService } from '@career/core/services/candidate.service';
import { PortalService } from '@career/core/services/portal.service';
import { UserService } from '@career/core/services/user.service';
import { UtilsService } from '@career/core/services/utils.service';
import { FieldConfig } from '@career/pocket-forms/models/field';
import { userComponentDef } from '@career/user/component-definition';
import { Subscription } from 'rxjs';
import { switchMap, take } from 'rxjs/operators';

@Component({
  selector: 'app-manage-preferences',
  template: `
  <app-base-template [component]="component" [componentDef]="componentDef" [scope]="this" *ngIf="component"></app-base-template>
  `
})
export class ManagePreferencesComponent implements OnInit, OnDestroy {
  portalServiceSubscription: Subscription;
  config: TemplateConfiguration;
  component: TemplateComponent;
  getText: Function;
  componentDef = userComponentDef['manage-preferences'];
  fieldConfig: FieldConfig[];
  updated = false;
  loading = false;
  error: ErrorSummary;
  constructor(private utils: UtilsService, private userService: UserService, private changeDetector: ChangeDetectorRef, private portalService: PortalService,
    private candidateService: CandidateService) { }

  updatePreferences($event) {
    this.updated = false;
    const obj = {
      prefersText: Boolean($event.prefertext)
    };
    
    this.userService.getUser().pipe(
      take(1),
      switchMap(user => this.candidateService.updatePreferences(obj, user._id))
    ).subscribe(
      (_) => {
        this.loading = false;
        this.updated = true;
        this.changeDetector.markForCheck();
      },
      (err) => {
        this.loading = false;
        this.error = new ErrorSummary(this.getText("UNKNOWN_ISSUE"), `Error Code: ${err.status || 'Unknown'}`);
        this.changeDetector.markForCheck();
      }
    );
  }

  ngOnInit(): void {
    this.portalServiceSubscription = this.portalService.getComponentData('manage-preferences').subscribe((data) => {
      this.config = data.configuration;
      this.component = data.component;
      this.getText = this.utils.getText(this.component); 
      this.userService.getUser().pipe(take(1)).subscribe(user => {
        this.fieldConfig = [
          {
            type: "checkbox",
            label: this.getText('PREFER_TEXT_COMMUNICATIONS'),
            name: "prefertext",
            value: user.login?.prefersText
          },
          {
            type: "button",
            color: 'primary', 
            label: this.getText('SAVE')
          }
        ];
      });
    });
  }

  ngOnDestroy(): void {
    this.portalServiceSubscription.unsubscribe();
  }
}
