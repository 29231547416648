import { ChangeDetectionStrategy, Component, OnInit } from "@angular/core";
import { UntypedFormGroup } from "@angular/forms";
import { MatLegacyFormFieldAppearance as MatFormFieldAppearance } from "@angular/material/legacy-form-field";
import { FieldConfig } from "@career/pocket-forms/models/field";
@Component({
  selector: "app-input",
  template: `
  <div class="form" [ngClass]="{'error-handler': (group | hasValidationErrors:field)}">
    <ng-container *ngFor="let validation of (group | getValidationErrors:field)">
      <p *ngIf="group.get(field.name).hasError(validation.name)" class="error-handler__message">{{validation.message}}</p>
    </ng-container>
    <label class="form__label" [for]="field.name">{{field.label}} {{(group | isRequired:field)?'':' (optional)'}}</label>    
    <mat-form-field appearance="outline" [formGroup]="group" class="form__field">
      <input matInput [placeholder]="field.placeholder" [type]="field.inputType" [max]="field.max" [min]="field.min" [name]="field.name" [formControlName]="field.name" [id]="field.name" [value]="field.value"[autocomplete]="field.autocomplete">
    </mat-form-field>
  </div>
`,
  styleUrls: ['./input.component.scss']
})
export class InputComponent implements OnInit {
  field: FieldConfig;
  group: UntypedFormGroup;
  constructor() {}
  ngOnInit() {}
}