import { Component, OnInit } from '@angular/core';
//import { CookieService } from 'ngx-cookie-service';
import { ActivatedRoute, Router } from '@angular/router';
import { AuthenticationService } from '@career/core/services/authentication.service';

@Component({
  selector: 'app-activate',
  template: ''
})
export class ActivateComponent implements OnInit {

  constructor(private route: ActivatedRoute, private router: Router, private authentication: AuthenticationService) { } //, private cookies: CookieService

  ngOnInit() {
    const token = this.route.snapshot.queryParamMap.get('token'), applyTo = this.route.snapshot.queryParamMap.get('applyTo');
    if(!token) {
      this.router.navigate(['/']);
      return;
    }
    //need to set return URL header for activation
    this.authentication.activate(token).subscribe(
      (data) => {
        let user = JSON.parse(data);
        if(applyTo) {
          this.router.navigate(['/login', {email: user.email, id: applyTo}]);
        } else {
          this.router.navigate(['/login', {email: user.email}]);
        }
      },
      (error) => {
        this.router.navigate(['/login']);
      }
    );
  }
}