<app-embedded-toolbar (onConfigure)="scope.configure()" configurationName="Static Page Config"></app-embedded-toolbar>
<div class="default-template">
    <!-- <div class="data-with-image data-with-image--min-height"> -->
        <!-- <div [appImgEditContainer]="scope.path" name="Main Heading Image" class="data-with-image__img-container">
            <div>
                <img class="data-with-image__img" [src]="scope.component.assets.image"
                    [alt]="scope.getText | transform:'pageTitle'">
            </div>
        </div> -->
        <div class="data-with-image__text-container" [appTextEditContainer]="scope.path" [body]="'richText'"
            [title]="'pageTitle'" name="Page Content" [isStaticTemplate]="true">
            <h1 class="data-with-image__heading data-with-image__heading--bold">
                {{ scope.component.textMap.pageTitle }}
            </h1>
            <div>
                <p *ngIf="scope.hasText | transform:'richText'" [innerHtml]="scope.getText | transform:'richText'"
                    class="default__data"></p>
            </div>
        <!-- </div> -->
    </div>
</div>