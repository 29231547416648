import { HttpClient } from "@angular/common/http";
import { Injectable } from "@angular/core";
import { Store } from "@ngxs/store";
import { Observable, of } from "rxjs";
import { map, switchMap, take } from "rxjs/operators";
import { Asset } from "../models/asset.model";
import { CoreAction } from "../store/actions/core.actions";
import { CoreState } from "../store/states/core.state";
import { PortalService } from "./portal.service";

@Injectable({
  providedIn: "root",
})
export class ContentService {
  constructor(
    private http: HttpClient,
    private store: Store,
    private portalSvc: PortalService
  ) {}

  uploadImage(body: File, type = "images") {
    const formData: FormData = new FormData();
    formData.append("file", body);
    return this.postImageToBucket(formData, type).pipe(
      map((assets) => {
        return this.assetsAsUrls(assets, type);
      })
    );
  }

  getImages(portal: string, type = "images"): Observable<string[]> {
    return this.store.selectOnce(CoreState.getAdminImages).pipe(
      switchMap((cachedImages) => {
        if (cachedImages(type)) {
          return of(cachedImages(type));
        } else {
          return this.http
            .get<Asset[]>(`/api/agency/portal/${portal}/images?bucket=${type}`)
            .pipe(
              map((assets) => {
                return this.assetsAsUrls(assets, type);
              })
            );
        }
      })
    );
  }

  private assetsAsUrls(assets: Asset[], type: string): string[] {
    const images = assets.map((a) => a.url);
    this.store.dispatch(
      new CoreAction.SetAdminImages({ type: type, images: images })
    );
    return images;
  }

  updateTextContent(
    path: string,
    content: string,
    componentName: string
  ): Observable<any> {
    const textMap = { [path]: content };
    let requestBody = {};
    requestBody = {
      textMap: textMap,
    };
    return this.portalSvc.patchComponent(requestBody, componentName);
  }

  deleteCustomTemplate(path: string): Observable<any> {
    const id = this.portalSvc.getURLParam();
    return this.http
      .delete(`/api/agency/portal/${id}/component?key=${path}`)
      .pipe(
        map((res) => {
          this.store.dispatch(
            new CoreAction.DeleteComponent({
              pathName: path,
            })
          );
          return res;
        })
      );
  }

  postImageToBucket(
    formData: FormData,
    type: string,
    component = null
  ): Observable<any> {
    const id = this.portalSvc.getURLParam();
    let body = {};
    let url = `/api/agency/portal/${id}/uploadimage?bucket=${type}`;
    if (component) {
      url += `&component=${component}`;
      body = {
        observe: "response",
      };
    }
    return this.http.post<Asset[]>(url, formData, body);
  }
}
