import { Injector } from "@angular/core";
import { IWorkflow } from "@career/core/models/workflow.model";
import { CandidateService } from "@career/core/services/candidate.service";
import { QuestionnaireService } from "@career/core/services/questionnaire.service";
import { UserService } from "@career/core/services/user.service";
import { IQuestionnaire } from "@career/questionnaires/models/question";
import { IUser } from "@career/user/models/user.model";
import { Observable, from, of } from "rxjs";
import { switchMap, concatMap, take, map, filter, distinct, mergeMap } from "rxjs/operators";
import { ActionProvider } from "../action-data-source.model";
import { ActionTarget, EActionTargetType } from "../action-target.model";
import { IAction, EActionType, OpportunityActionBuilder, OpportunityAction, EActionStatus } from "../action.model";

export class CompleteQuestionnaireProvider extends ActionProvider {
    constructor(private candidateService: CandidateService, private questSvc: QuestionnaireService, private userSvc: UserService) {
        super();
    }

    populateTarget(action: IAction) {
        action.target = new ActionTarget();
        action.target.type = EActionTargetType.VIEW_ONLY;
        action.target.value = null;
        action.target.name = (action.object as IQuestionnaire).name;
        return action;
    }

    setId(action: IAction): string {
        return `questionnaire:${(action.object as IQuestionnaire)._id}:${(action as OpportunityAction).opportunity._id}`;
    }

    private transformToAction(questionnaire: IQuestionnaire, baseAction): IAction {
        const action = new OpportunityActionBuilder();
        const mutated = action
        .from(baseAction)
        .setObject(questionnaire)
        .setStatus(EActionStatus.COMPLETE)
        .setTimestamp(new Date())
        .setType(EActionType.QUESTIONNAIRES)
        .build();
        this.populateTarget(mutated);
        return mutated;
    }
    
    fetch(): Observable<IAction> {
        const baseAction = new OpportunityActionBuilder();
        return this.userSvc.getUser().pipe(
            take(1),
            filter((user) => user && user._id),
            switchMap((user: IUser) => baseAction.setUser(user) && this.candidateService.getApplied(user._id)),
            filter((applications) => applications && applications.matches),
            switchMap((applications) => from(applications.matches)),
            concatMap((match: any) => baseAction.setOpportunity(match.opportunity) && this.questSvc.findFilledQuestionnaires(match.profile._id, match.opportunity._id)),
            mergeMap((questionnaires: IQuestionnaire[]) => from(questionnaires)),
            map((questionnaire: IQuestionnaire) => this.transformToAction(questionnaire, baseAction.build()))
        );
    }
}
