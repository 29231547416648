import { Component, OnDestroy, OnInit } from "@angular/core";
import { Router, ActivatedRoute } from "@angular/router";
import { Opportunity } from "projects/pocket-career/src/app/opportunity-detail/models/opportunity.model";
import { UntypedFormControl } from "@angular/forms";
import { JobService } from "@career/core/services/job.service";
import { oppComponentDef } from "../../component-definition";
import { switchMap, map } from "rxjs/operators";
import { of, Subscription } from "rxjs";
import { UtilsService } from "@career/core/services/utils.service";
import { PortalService } from "@career/core/services/portal.service";
import { Store } from "@ngxs/store";
import { LocationService } from "@career/core/services/location.service";
import {
  TemplateComponent,
  TemplateConfiguration,
} from "@career/core/models/component.model";
import { Title } from "@angular/platform-browser";
import { UserState } from "@career/user/store/states/user.state";
import { Match } from "@career/core/models/match.model";
import { MatchService } from "@career/core/services/match.service";
import { MatLegacyDialog as MatDialog } from "@angular/material/legacy-dialog";
import { UserService } from "@career/core/services/user.service";
import { Translations } from "@career/page-configuration/configs/page-config.config";

@Component({
  selector: "app-opportunities",
  template: `
    <app-base-template
      [component]="component"
      [componentDef]="componentDef"
      [scope]="this"
      *ngIf="component"
    ></app-base-template>
  `,
})
export class OpportunitiesComponent implements OnInit, OnDestroy {
  component: TemplateComponent;
  config: TemplateConfiguration;
  getText: Function;
  componentDef = oppComponentDef["opportunity-list"];
  opps: Opportunity[];
  suggestions: { [k: string]: Match };
  hasSuggestions: boolean;
  locations: any;
  params = {
    sort: "lastUpdated:-1",
    location: new UntypedFormControl(),
    careerLevel: "",
    keywords: "",
    compensation: "",
  };
  body = {};
  pagination = { total: 0, page: 0 };
  noneFound: boolean = false;
  loading: boolean = false;
  isAdmin: boolean = false;
  createLocationString = this.utils.createLocationString;
  forwardJobSearchPageExists = false;
  translations = Translations;
  subs: Subscription[] = [];

  constructor(
    private titleService: Title,
    private matchService: MatchService,
    private store: Store,
    private portalService: PortalService,
    private jobService: JobService,
    private utils: UtilsService,
    private locationService: LocationService,
    private router: Router,
    private route: ActivatedRoute,
    private userSvc: UserService,
    public dialog: MatDialog
  ) {
    this.userSvc.isAdmin().subscribe((res) => {
      this.isAdmin = res;
    });
  }

  ngOnInit() {
    const sub = this.portalService
      .getComponentData("opportunity-list")
      .subscribe((data) => {
        this.config = data.configuration;
        if (
          this.config.forwardJobSearchPage &&
          this.config.forwardJobSearchPage !== ""
        ) {
          if (!this.isAdmin) {
            window.open(this.config.forwardJobSearchPage, "_self");
            return;
          }
          //this.router.navigate(["/login"]);
          this.forwardJobSearchPageExists = true;
          // return;
        }
        this.component = data.component;
        this.getText = this.utils.getText(this.component);
        this.titleService.setTitle(
          `${this.getText("FIND_JOBS")} | ${this.portalService.getPortalName()}`
        );
        const page = this.route.snapshot.queryParamMap.get("page");
        const search = this.route.snapshot.queryParamMap.get("search");
        const location = this.route.snapshot.queryParamMap.get("location");
        this.jobService
          .getCachedSearchFilters()
          .pipe(
            switchMap((cached) => {
              return !location
                ? of(null)
                : this.locationService.searchLocation(location).pipe(
                    map((initialLocations) => {
                      if (
                        initialLocations.locations &&
                        initialLocations.locations[0]
                      )
                        this.params.location.setValue(
                          initialLocations.locations[0]
                        );
                      return cached;
                    })
                  );
            })
          )
          .subscribe((cached) => {
            if (cached) {
              this.params["keywords"] = cached.body.keywords || "";
              this.params["careerLevel"] = cached.body.careerLevel || "";
              this.params["sort"] = cached.body.sort || "lastUpdated:-1";
              this.params["compensation"] = cached.body.compensationRate
                ? cached.body.compensationRate.min.toString()
                : "";
              if (typeof cached.body.location === "object") {
                this.params.location.setValue(cached.body.location);
              }
            }
            if (page) {
              this.pagination.page = parseInt(page);
            }
            if (search) {
              this.params["keywords"] = search;
            }

            this.search(true);
          });

        const sub = this.locationService
          .createLocationAutocomplete(this.params.location)
          .subscribe((data: any) => {
            if (data) {
              this.locations = data.locations;
            }
          });
        this.subs.push(sub);
        this.store
          .selectOnce(UserState.suggested)
          .pipe(
            map((data) => {
              return (data || []).reduce((acc, curr) => {
                acc[curr.opportunity._id] = this.convertToMatch(curr);
                return acc;
              }, {});
            })
          )
          .subscribe((mapped) => {
            this.hasSuggestions = Object.keys(mapped).length > 0;
            this.suggestions = mapped;
          });
      });
    this.subs.push(sub);
  }

  getOpps(body = null, sort = null, page = null) {
    return this.jobService.getOpps(body, sort, page + 1, this.config).subscribe((data) => {
      this.pagination.total = data.total;
      this.opps = data.opportunities;
      return data;
    });
  }

  changePage($event) {
    this.pagination.page = $event.pageIndex;
    if (this.pagination.page > 0) {
      this.router.navigate([], {
        relativeTo: this.route,
        queryParams: {
          ...this.route.snapshot.queryParams,
          page: this.pagination.page,
        },
      });
    } else {
      this.router.navigate([], {
        relativeTo: this.route,
        queryParams: { ...this.route.snapshot.queryParams },
      });
    }
    this.getOpps(this.body, this.params.sort, $event.pageIndex);
  }

  search(init = false, force = false) {
    if (this.loading && !force) return;
    this.loading = true;
    this.body = {};
    if (!init) this.pagination.page = 0;
    if (this.params.location.value && this.params.location.value.state) {
      this.body["distance"] = 50;
      this.body["location"] = this.params.location.value;
    }
    if (this.params.careerLevel) {
      this.body["careerLevel"] = this.params.careerLevel;
    }
    if (this.params.keywords) {
      this.body["name"] = this.params.keywords;
    }
    if (this.params.compensation) {
      this.body["compensationRate"] = {
        min: parseInt(this.params.compensation),
      };
    }
    this.getOpps(this.body, this.params.sort, this.pagination.page)
      .add(() => {
        this.noneFound = this.opps.length < 1;
      })
      .add(() => {
        this.loading = false;
      });
  }

  private convertToMatch(match: Match) {
    return this.matchService.convertToMatch(match, {
      TOP_MATCH: this.getText("TOP_MATCH"),
    }, this.config);
  }

  ngOnDestroy() {
    this.subs.forEach((e) => e.unsubscribe());
  }
}
