import { Component, Inject, OnInit } from '@angular/core';
import { MatLegacyDialogRef as MatDialogRef, MAT_LEGACY_DIALOG_DATA as MAT_DIALOG_DATA } from '@angular/material/legacy-dialog';
import { actionsComponentDef } from '@career/actions/component-definition';
import { IActionTarget } from '@career/actions/models/action-target.model';
import { EActionStatus, IAction } from '@career/actions/models/action.model';
import { ActionTargetService } from '@career/actions/services/action-target.service';
import { TemplateComponent, TemplateConfiguration } from '@career/core/models/component.model';
import { PortalService } from '@career/core/services/portal.service';
import { Observable, Subscription } from 'rxjs';
import { filter, map } from 'rxjs/operators';

@Component({
  selector: 'app-actions-dialog',
  template: `
  <app-base-template [component]="component" [componentDef]="componentDef" [scope]="this" *ngIf="component"></app-base-template>
  `,
  styleUrls: ['./actions-dialog.component.scss']
})
export class ActionsDialogComponent implements OnInit {

  portalServiceSubscription: Subscription;
  config: TemplateConfiguration;
  component: TemplateComponent;
  actions$: Observable<IAction[]>;
  displayedColumns = ['category', 'name', 'status'];
  
  componentDef = actionsComponentDef['actions-dialog'];

  constructor(@Inject(MAT_DIALOG_DATA) public dialogData, private dialogRef: MatDialogRef<ActionsDialogComponent>, private portalService: PortalService, private actionTargetSvc: ActionTargetService) { }

  close() {
    this.dialogRef.close();
  }

  performAction(action: IAction) {
    if( !action.target.value ) {
      return;
    }
    
    this.dialogRef.close(true);

    this.dialogRef.afterClosed().subscribe(() => {
      this.actionTargetSvc.process(action);
    });
   
  }

  isActionComplete(status: EActionStatus) {
    return status === EActionStatus.COMPLETE;
  }

  setupActions(filterFunc: Function) {
    this.actions$ = this.dialogData.actions$;
    if( filterFunc ) {
      this.actions$ = this.actions$.pipe(map((data: IAction[]) => data.filter(action => filterFunc(action))));
    }
  }

  ngOnInit(): void {
    this.portalServiceSubscription = this.portalService.getComponentData('actions-dialog').subscribe(data => {
      this.config = data.configuration;
      this.component = data.component;
      this.setupActions(this.dialogData.filter);
    });
  }

  ngOnDestroy() {
    this.portalServiceSubscription.unsubscribe();
  }

}
