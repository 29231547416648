<app-error-summary [error]="scope.error"></app-error-summary>
<loading-component *ngIf="scope.loading"></loading-component>
<div class="banner" *ngIf="scope.updated">
    <h2 class="banner__text" [innerText]="scope.getText | transform:'CONSENTS_UPDATED'"></h2>
</div>
<ng-container *ngIf="scope.candidateConsents">
    <div *ngIf="(scope.consentsConfig$ | async) as fieldConfig" [ngClass]="{'disabled--loading': scope.loading}">
        <dynamic-form (submit)="scope.updateConsents($event)" [ignoreErrors]="true" [fields]="fieldConfig"></dynamic-form>
    </div>
</ng-container>
