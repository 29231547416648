import { Component, Input, OnInit } from '@angular/core';
import { LocaleService } from '@career/core/services/locale.service';

@Component({
  selector: 'app-social-home',
  templateUrl: './social-home.component.html',
  styleUrls: ['./social-home.component.scss']
})
export class SocialHomeComponent implements OnInit {
  @Input() scope;
  resumeOrCvText = this.localeService.getResumeOrCVText();
  constructor(private localeService: LocaleService) { }

  ngOnInit(): void {
  }

}
