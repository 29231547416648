<ng-container *ngIf="!scope.config.disableRegisterPage; else showNoAccessMsg">
    <app-embedded-toolbar (onConfigure)="scope.openConfiguration()"
        [configurationName]="scope.translations.REGISTER_CONFIG"></app-embedded-toolbar>
    <div class="data-hero">
        <div class="data-hero__top">
            <div class="data-hero__top-container">
                <div class="data-hero__top-left">
                    <div class="data-hero__heading-container">
                        <h2 class="data-hero__main-heading" *ngIf="scope.opportunity"
                            [innerText]="scope.getText | transform:'FINISH_APPLYING'"></h2>
                        <h2 class="data-hero__main-heading" *ngIf="!scope.opportunity"
                            [innerText]="scope.getText | transform:'REGISTER_NOW'"></h2>
                        <p class="data-hero__text" [innerText]="scope.getText | transform:'REGISTER_DESC'"></p>
                    </div>
                </div>
                <div class="data-hero__top-right">
                    <div class="data-hero__img-container" appImgEditContainer="register" name="Main Image">
                        <div>
                            <img [src]="scope.component.assets.image" class="data-hero__img"
                                [alt]="scope.getText | transform:'REGISTER_NOW'">
                        </div>
                    </div>
                </div>
            </div>
        </div>
        <div class="register">
            <div class="register__container" *ngIf="scope.wizard">
                <app-error-summary [error]="scope.error" *ngIf="scope.error"></app-error-summary>
                <div [ngSwitch]="(scope.status | async)" [@inOutAnimation]>
                    <ng-container *ngSwitchDefault [ngTemplateOutlet]="PENDING"></ng-container>
                    <ng-container *ngSwitchCase="'LOADING'" [ngTemplateOutlet]="LOADING"></ng-container>
                    <ng-container *ngSwitchCase="'COMPLETED'" [ngTemplateOutlet]="COMPLETED"></ng-container>
                    <ng-container *ngSwitchCase="'REGISTERED'" [ngTemplateOutlet]="REGISTERED"></ng-container>
                </div>
                <!-- <dynamic-form class="form" (submit)="scope.register($event)" [fields]="regConfig" [groupValidators]="groupValidators"></dynamic-form> -->
                <!-- <app-consent-form (isValidOnChange)="scope.setConsentFormValidity($event)" (onComplete)="scope.onConsentFormComplete($event)" #consentForm></app-consent-form> -->
            </div>
        </div>
    </div>
</ng-container>
<ng-template #PENDING>
    <div class="register__upload-container">
        <div>
            <h2 class="register__heading"
                [innerText]="scope.getText | transform:'UPLOAD_YOUR_RESUME':{resumeOrCv: resumeOrCvText}"></h2>
            <p class="register__text" [innerText]="scope.getText | transform:'WE_WILL_PREFILL_YOUR_INFORMATION'"></p>
        </div>
        <div class="register__upload">
            <app-upload-file (uploadEvent)="scope.resumeUpload($event)" id="resume-upload" class="app-upload-file" disabledDiv></app-upload-file>
        </div>
    </div>
</ng-template>

<ng-template #LOADING>
    <div class="register__upload-container">
        <mat-progress-bar mode="indeterminate"></mat-progress-bar>
        <span tabindex="0">
            {{scope.loadStatus}}
        </span>
    </div>
</ng-template>

<ng-template #COMPLETED>
    <app-dynamic-form-wizard [groups]="scope.wizard" [groupValidators]="scope.groupValidators" (submit)="scope.register($event)"></app-dynamic-form-wizard>
</ng-template>

<ng-template #REGISTERED>
    <div>
        <h2 class="register__heading" [innerText]="scope.getText | transform:'SUCCESS'"></h2>
        <p class="register__text" [innerText]="scope.getText | transform:'CHECK_YOUR_EMAIL'"></p>
    </div>
</ng-template>
<ng-template #showNoAccessMsg>
    <div class="forward-page-msg">
        <mat-icon>settings</mat-icon>
        <h1>
            {{scope.translations.NO_ACCESS_REGISTER_PAGE}}
        </h1>
    </div>
</ng-template>