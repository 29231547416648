<ng-container *ngIf="!scope.config.disableRegisterPage; else showNoAccessMsg">
    <app-embedded-toolbar (onConfigure)="scope.openConfiguration()"
        [configurationName]="scope.translations.REGISTER_CONFIG"></app-embedded-toolbar>
    <div class="data-hero data-hero--min-height">
        <div class="header" appImgEditContainer="register" name="Main Image">
            <div class="header__img-container">
                <img [src]="scope.component.assets.image" class="header__img"
                    [alt]="scope.getText | transform:'REGISTER_NOW'">
            </div>
            <div class="header__overlay header__overlay--top">
                <div class="header__title-container">
                    <div class="header__title-row header__title-row--slim">
                        <h1 class="header__title" *ngIf="scope.opportunity"
                            [innerText]="scope.getText | transform:'FINISH_APPLYING'"></h1>
                        <h1 class="header__title" *ngIf="!scope.opportunity"
                            [innerText]="scope.getText | transform:'REGISTER_NOW'"></h1>
                    </div>
                </div>
            </div>
        </div>
        <div class="data-hero__text-container">
            <app-error-summary [error]="scope.error" *ngIf="scope.error"></app-error-summary>
            <div class="register" *ngIf="scope.wizard">
                <div [ngSwitch]="(scope.status | async)" [@inOutAnimation]>
                    <ng-container *ngSwitchDefault [ngTemplateOutlet]="PENDING"></ng-container>
                    <ng-container *ngSwitchCase="'LOADING'" [ngTemplateOutlet]="LOADING"></ng-container>
                    <ng-container *ngSwitchCase="'COMPLETED'" [ngTemplateOutlet]="COMPLETED"></ng-container>
                    <ng-container *ngSwitchCase="'REGISTERED'" [ngTemplateOutlet]="REGISTERED"></ng-container>
                </div>
            </div>
        </div>
    </div>
</ng-container>

<ng-template #PENDING>
    <div class="register__upload-container">
        <div>
            <h2 class="register__heading"
                [innerText]="scope.getText | transform:'UPLOAD_YOUR_RESUME':{resumeOrCv: resumeOrCvText}"></h2>
            <p class="register__text" [innerText]="scope.getText | transform:'WE_WILL_PREFILL_YOUR_INFORMATION'"></p>
        </div>
        <div class="register__upload">
            <app-upload-file (uploadEvent)="scope.resumeUpload($event)" id="resume-upload" class="app-upload-file" disabledDiv></app-upload-file>
        </div>
    </div>
</ng-template>

<ng-template #LOADING>
    <div class="register__upload-container">
        <mat-progress-bar mode="indeterminate"></mat-progress-bar>
        <span tabindex="0">
            {{scope.loadStatus}}
        </span>
    </div>
</ng-template>

<ng-template #COMPLETED>
    <app-dynamic-form-wizard [groups]="scope.wizard" [groupValidators]="scope.groupValidators" (submit)="scope.register($event)"></app-dynamic-form-wizard>
</ng-template>

<ng-template #REGISTERED>
    <div>
        <h2 class="data-hero__main-heading" [innerText]="scope.getText | transform:'SUCCESS'"></h2>
        <p class="data-hero__text" [innerText]="scope.getText | transform:'CHECK_YOUR_EMAIL'"></p>
    </div>
</ng-template>
<ng-template #showNoAccessMsg>
    <div class="forward-page-msg">
        <mat-icon>settings</mat-icon>
        <h1>
            {{scope.translations.NO_ACCESS_REGISTER_PAGE}}
        </h1>
    </div>
</ng-template>