import {
  Component,
  ElementRef,
  OnInit,
  ViewChild,
  OnDestroy,
  Inject,
} from "@angular/core";
import { VALID_IMG_UPLOAD_TYPES } from "@career/core/constants/file.constant";
import { ErrorSummary } from "@career/core/models/error.model";
import {
  ImageBucketTypes,
  ImageMaxWidthRestrictions,
  UploadImageIcon,
} from "@career/core/models/update-image-icon.enum";
import { ContentService } from "@career/core/services/content.service";
import { PortalService } from "@career/core/services/portal.service";
import { UtilsService } from "@career/core/services/utils.service";
import { CoreAction } from "@career/core/store/actions/core.actions";
import { Translations } from "@career/page-configuration/configs/page-config.config";
import { Store } from "@ngxs/store";
import { ACTIVE_LAYER_CONFIG, LayerConfig, LayerOverlayRef } from "kleos-ui";
import { Subscription } from "rxjs";

@Component({
  selector: "app-upload-image-modal",
  templateUrl: "./upload-image-dialog.component.html",
  styleUrls: ["./upload-image-dialog.component.scss"],
})
export class UploadImageDialogComponent implements OnInit, OnDestroy {
  @ViewChild("fileInput") fileInput: ElementRef;
  error: ErrorSummary;
  fileAttr = "Choose File";
  images = [];
  selectedImage: any;
  selectedFile: any;
  uploadNewFile: any;
  showUploadMessage = false;
  perviousSelectedImg: HTMLElement;
  imageBucketTypes = ImageBucketTypes;
  translations = Translations;
  loading: boolean;
  subs: Subscription[] = [];

  constructor(
    private portalService: PortalService,
    private contentSvc: ContentService,
    private utilService: UtilsService,
    private layerRef: LayerOverlayRef,
    @Inject(ACTIVE_LAYER_CONFIG) public config: LayerConfig
  ) {}

  ngOnInit(): void {
    this.getImageList();
  }

  getImageList() {
    const sub = this.contentSvc
      .getImages(
        this.portalService.getURLParam(),
        ImageBucketTypes[this.config.data.type]
      )
      .subscribe((data) => {
        this.images = data;
      });
    this.subs.push(sub);
  }

  uploadFileEvt(event: any) {
    let file;
    if (event.target) {
      file = event.target.files[0] || null;
    } else {
      file = event[0];
    }
    if (VALID_IMG_UPLOAD_TYPES.includes(file.type)) {
      this.loading = true;
      const sub = this.contentSvc
        .uploadImage(file, ImageBucketTypes[this.config.data.type])
        .subscribe(
          (response) => {
            this.utilService.openSnackbar("Image Uploaded successfully", 5000);
            this.loading = false;
            this.images = response;
          },
          (err) => {
            //TODO: handle errors here better
            this.utilService.openSnackbar(
              `Image Uploaded Failed: ${err.status} - ${err.message}`,
              5000
            );
            this.loading = false;
          }
        );
      this.subs.push(sub);
    } else {
      this.error = new ErrorSummary("INVALID_FILE_TYPE");
    }
  }

  checkForImgWidthRestrictions(imgWidth: number, type: string): boolean {
    const restriction = ImageMaxWidthRestrictions[type];
    return restriction["min"] <= imgWidth && imgWidth <= restriction["max"];
  }

  onImageListItemClick(image: any, element: HTMLElement) {
    if (image == this.selectedImage) {
      element.style.border = "";
      this.selectedImage = null;
      this.perviousSelectedImg = null;
    } else {
      element.style.border = "1px solid blue";
      this.selectedImage = image;
      if (this.perviousSelectedImg) {
        this.perviousSelectedImg.style.border = "";
      }
      this.perviousSelectedImg = element;
    }
  }

  onSave() {
    let sub;
    if (ImageBucketTypes[this.config.data.type] === "favicons") {
      sub = this.portalService
        .updateConfigurations({ favicon: this.selectedImage })
        .subscribe(
          (res) => {
            this.error = null;
            this.locationReload();
          },
          (error) => {
            this.error = new ErrorSummary(
              error.message || "Unknown",
              error.status || "unknown"
            );
          }
        );
    } else {
      const asset = {
        [this.getImagePath(this.config.data.path)]: this.selectedImage,
      };
      let requestBody = {};
      requestBody = {
        assets: asset,
      };
      sub = this.portalService
        .patchComponent(requestBody, this.config.data.component)
        .subscribe(
          (_) => {
            this.error = null;
            this.layerRef.close();
          },
          (error) => {
            this.error = new ErrorSummary(
              error.message || "Unknown",
              error.status || "unknown"
            );
          }
        );
    }
    this.subs.push(sub);
  }

  locationReload() {
    window.location.reload();
  }

  getImagePath(path: string): string {
    switch (path) {
      case UploadImageIcon.image:
        return "image";
      case UploadImageIcon.logo:
        return "logo";
      case UploadImageIcon.section1:
        return "section1";
      case UploadImageIcon.section2:
        return "section2";
      default:
        return path;
    }
  }

  ngOnDestroy(): void {
    this.subs.forEach((sub) => sub.unsubscribe());
  }
}
