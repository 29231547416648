<loading-component *ngIf="scope.loading"></loading-component>
<div class="applications" disabledDiv *ngIf="(scope.applied$ | async) as applied">
    <mat-paginator *ngIf="applied.length > scope.pageEvent.pageSize" class="paginator" [length]="scope.pageEvent.length"
        [pageIndex]="scope.pageEvent.pageIndex" [hidePageSize]="true" [pageSize]="scope.pageEvent.pageSize"
        (page)="scope.pageEvent = $event">
    </mat-paginator>
    <ul class="applications__list" *ngIf="applied.length > 0 else noResults">
        <ng-container *ngFor="let app of applied; let i = index;">
            <li class="application" *ngIf="i < (scope.pageEvent.pageIndex +1) * scope.pageEvent.pageSize &&
            i >= scope.pageEvent.pageIndex * scope.pageEvent.pageSize ">
                <app-action-required-warning [user]="scope.user" [opportunity]="app.opportunity"
                    [incompleteActions]="scope.incompleteActions$" [completeActions]="scope.completeActions$">
                </app-action-required-warning>
                <a class="application__job-name" [routerLink]="['/details', app.opportunity._id]"
                    [attr.aria-describedby]="scope.getText | transform:'VIEW_OPPORTUNITY_ARIA_DESCRIPTION':{name: app.opportunity.name}">
                    <strong>
                        {{app.opportunity.name}}
                        <span *ngIf="!scope.config.hideClient && app.opportunity.display.client">at
                            {{app.opportunity.display.client}}</span>
                    </strong>
                    <div *ngFor="let state of app.portalStates">
                        →
                        <span class="application__status">{{state.portalName}} {{scope.getText | transform:'ON'}}
                            {{scope.getDate | transform:state.timestamp:scope}}</span>
                    </div>
                </a>
                <div class="application__pills">
                    <app-data-pills [pills]="scope.combinePills | transform:app"></app-data-pills>
                </div>
            </li>
        </ng-container>
    </ul>
</div>

<ng-template #noResults>
    <p [innerText]="scope.getText | transform:'YOU_HAVE_NO_APPLICATIONS'"></p>
</ng-template>