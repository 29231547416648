import { Component, Input, OnInit } from '@angular/core';

@Component({
  selector: 'app-default-unsubscribe',
  styleUrls: ['./default-unsubscribe.component.scss'],
  templateUrl: './default-unsubscribe.component.html'
})
export class DefaultUnsubscribeComponent implements OnInit {

  @Input() scope;
  constructor() { }

  ngOnInit(): void {
  }

}
