import { Injectable } from '@angular/core';
import { Selector, State, StateContext, Store, NgxsOnChanges, Action, ofActionDispatched, Actions, NgxsSimpleChange, NgxsOnInit } from '@ngxs/store';
import { EActionStatus, EActionType, IAction, OpportunityAction } from '@career/actions/models/action.model';
import { merge} from 'rxjs';
import { finalize, map, mergeAll, switchMap, toArray } from 'rxjs/operators';
import { ActionsStateModel } from '../../models/actions-state.model';
import { ActionsAction } from '../actions/actions.actions';
import { ActionFactory } from '@career/actions/models/action-factory.model';
import { ActionDataSourceService } from '@career/actions/services/action-data-source.service';

const INITIAL_STATE = {complete: [], incomplete: []};
@State<ActionsStateModel>({
    name: 'actions',
    defaults: INITIAL_STATE
  })
  @Injectable()
  export class ActionsState {
    loading = false;
    factory = new ActionFactory(this.dataSourceService);
    constructor(private store: Store, private dataSourceService: ActionDataSourceService) {}
    @Selector()
    static incomplete<T>(state: ActionsStateModel) { 
      return state.incomplete;
    }

    @Selector()
    static incompleteOpportunityActions(state: ActionsStateModel) { 
      return state.incomplete.filter(i => i instanceof OpportunityAction);
    }

    @Selector()
    static completeOpportunityActions(state: ActionsStateModel) { 
      return state.complete.filter(i => i instanceof OpportunityAction);
    }

    @Selector()
    static allOpportunityActions(state: ActionsStateModel) { 
      return this.all(state).filter(i => i instanceof OpportunityAction);
    }
    
    @Selector()
    static allProfileActions(state: ActionsStateModel) { 
      return this.all(state).filter(i => !(i instanceof OpportunityAction));
    }

    @Selector()
    static incompleteProfileActions(state: ActionsStateModel) { 
      return state.incomplete.filter(i => i.type === EActionType.PROFILE);
    }
    
    @Selector()
    static completeProfileActions(state: ActionsStateModel) { 
      return state.complete.filter(i => i.type === EActionType.PROFILE);
    }

    @Selector()
    static all(state: ActionsStateModel) {
      return state.incomplete.concat(state.complete);
    }

    @Selector()
    static complete(state: ActionsStateModel) { 
      return state.complete;
    }

    @Action(ActionsAction.SetIncomplete)
    setIncomplete(ctx: StateContext<ActionsStateModel>, { payload }) {
      ctx.patchState({complete: payload.incomplete})
    }

    @Action(ActionsAction.SetComplete)
    setComplete(ctx: StateContext<ActionsStateModel>, { payload }) {
      ctx.patchState({complete: payload.complete})
    }

    private addAction(ctx: StateContext<ActionsStateModel>, action: IAction) {
      const toUpdate = action.status === EActionStatus.COMPLETE?'complete':'incomplete';
      const toRemove = action.status !== EActionStatus.COMPLETE?'complete':'incomplete';
      ctx.patchState({
        [toUpdate]: [
          ...ctx.getState()[toUpdate].filter(a => a.id !== action.id),
          action
        ],
        [toRemove]: [
          ...ctx.getState()[toRemove].filter(a => a.id !== action.id)
        ]
      });
    }

    private resetState(ctx: StateContext<ActionsStateModel>) {
      ctx.setState(INITIAL_STATE);
    }

    @Action(ActionsAction.Load)
    load(ctx: StateContext<ActionsStateModel>, {type}) {
      if( this.loading ) {
        return;
      }
      this.loading = true;
      return merge(Object.keys(EActionType).filter(t => type?type === EActionType[t]:true).map(type => this.factory.createActions(EActionType[type])))
      .pipe(
        mergeAll(),
        toArray(),
        map((data) => ctx.dispatch([new ActionsAction.Add(data)]) && data),
        finalize(() => this.loading = false)
      );
    }


    @Action(ActionsAction.Add)
    add(ctx: StateContext<ActionsStateModel>, { payload }) {
      payload.forEach(action => {
        this.addAction(ctx, action);
      });
    }

    @Action(ActionsAction.Clear)
    clear(ctx: StateContext<ActionsStateModel>) {
      this.resetState(ctx);
    }
  }