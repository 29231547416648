<div *ngIf="scope" class="actions">
    <div class="actions__container">
        <div class="actions__heading">
            <h1 class="actions__title">{{scope.getText | transform:'MY_ACTIONS'}}</h1>
            <h2 class="actions__desc">{{scope.getText | transform:'WELCOME_MESSAGE'}}</h2>
        </div>
        <ng-container *ngIf="(scope.actions$ | async) as actions">
              <table mat-table [dataSource]="actions" class="mat-elevation-z0 table">
                <ng-container matColumnDef="name">
                  <th mat-header-cell *matHeaderCellDef [innerText]="scope.getText | transform:'NAME'"></th>
                  <td mat-cell *matCellDef="let element">
                    <a class="actions__link" *ngIf="!(scope.isActionComplete | transform:element.status)" [innerText]="scope.getText | transform:element.target.name" href="" (click)="!!scope.performAction(element)"></a>
                    <a class="actions__link actions__link--completed" *ngIf="(scope.isActionComplete | transform:element.status)" [attr.aria-describedby]="scope.getText | transform:'ACTION_COMPLETE_ARIA_DESCRIPTION':{name: scope.getText(element.target.name)}" [innerText]="scope.getText | transform:element.target.name"></a>
                  </td>
                </ng-container>
                <ng-container matColumnDef="category">
                    <th mat-header-cell *matHeaderCellDef [innerText]="scope.getText | transform:'CATEGORY'"></th>
                    <td mat-cell *matCellDef="let element"> {{scope.getText | transform:element.type}} </td>
                </ng-container>
                <ng-container matColumnDef="status">
                    <th mat-header-cell *matHeaderCellDef [innerText]="scope.getText | transform:'STATUS'"></th>
                    <td mat-cell *matCellDef="let element">
                        <ng-container *ngIf="(scope.isActionComplete | transform:element.status)">
                            <span [innerText]="scope.getText | transform:'ACTION_COMPLETE'"></span>
                        </ng-container>
                        <ng-container *ngIf="!(scope.isActionComplete | transform:element.status)">
                            <span [innerText]="scope.getText | transform:'ACTION_INCOMPLETE'"></span>
                        </ng-container>
                    </td>
                </ng-container>
                <tr mat-header-row *matHeaderRowDef="scope.displayedColumns"></tr>
                <tr mat-row *matRowDef="let row; columns: scope.displayedColumns;"></tr>
              </table>
        </ng-container>
    </div>
    <div mat-dialog-actions class="close">
        <button mat-button mat-dialog-close cdkFocusInitial [innerText]="scope.getText | transform:'GO_BACK'"></button>
    </div>
</div>
