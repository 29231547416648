import { NgModule } from '@angular/core';
import { CommonModule } from '@angular/common';
import { ButtonComponent } from './components/button/button.component';
import { CheckboxComponent } from './components/checkbox/checkbox.component';
import { DynamicFormComponent } from './components/dynamic-form/dynamic-form.component';
import { InputComponent } from './components/input/input.component';
import { RadiobuttonComponent } from './components/radiobutton/radiobutton.component';
import { SelectComponent } from './components/select/select.component';
import { DynamicFieldDirective } from './components/dynamic-field/dynamic-field.directive';
import { FormsModule, ReactiveFormsModule } from '@angular/forms';
import { GetValidationErrorsPipe, HasValidationErrorsPipe, IsFieldRequiredPipe } from './models/validation.pipe';
import { MatLegacyButtonModule as MatButtonModule } from '@angular/material/legacy-button';
import { MatLegacyFormFieldModule as MatFormFieldModule } from '@angular/material/legacy-form-field';
import { MatLegacyInputModule as MatInputModule } from '@angular/material/legacy-input';
import { MatLegacyCheckboxModule as MatCheckboxModule } from '@angular/material/legacy-checkbox';
import { ErrorFormSummaryComponent } from './components/error-form-summary/error-form-summary.component';
import { DefaultErrorFormSummaryComponent } from './components/error-form-summary/templates/default-error-form-summary/default-error-form-summary.component';
import { CoreModule } from '@career/core/core.module';
import { ErrorSummaryComponent } from '@career/core/components/error-summary/error-summary.component';
import { MatLegacySelectModule as MatSelectModule } from '@angular/material/legacy-select';
import { DynamicFormWizardComponent } from './components/dynamic-form-wizard/dynamic-form-wizard.component';
import { ConsentComponent } from './components/consent/consent.component';


@NgModule({
  declarations: [
    ButtonComponent,
    CheckboxComponent,
    DynamicFieldDirective,
    IsFieldRequiredPipe,
    HasValidationErrorsPipe,
    GetValidationErrorsPipe,
    DynamicFormComponent,
    InputComponent,
    RadiobuttonComponent,
    SelectComponent,
    ErrorFormSummaryComponent,
    DefaultErrorFormSummaryComponent,
    DynamicFormWizardComponent,
    ConsentComponent
  ],
  imports: [
    CommonModule,
    ReactiveFormsModule,
    MatButtonModule,
    MatInputModule,
    MatCheckboxModule,
    MatFormFieldModule,
    MatSelectModule,
    CoreModule,
    FormsModule
  ],
  exports: [
    ButtonComponent,
    CheckboxComponent,
    DynamicFieldDirective,
    DynamicFormComponent,
    DynamicFormWizardComponent,
    ErrorSummaryComponent,
    ErrorFormSummaryComponent,
    InputComponent,
    RadiobuttonComponent,
    HasValidationErrorsPipe,
    GetValidationErrorsPipe,
    SelectComponent
  ]
})
export class PocketFormsModule { }
