import { Component, Input, OnInit } from '@angular/core';
import { LocaleService } from '@career/core/services/locale.service';

@Component({
  selector: 'app-default-home',
  templateUrl: './default-home.component.html',
  styleUrls: ['./default-home.component.scss']
})
export class DefaultHomeComponent implements OnInit {

  @Input() scope;
  resumeOrCvText = this.localeService.getResumeOrCVText();
  constructor(private localeService: LocaleService) { }

  ngOnInit(): void {
  }

}
