import { DOCUMENT } from '@angular/common';
import { Inject, Injectable } from '@angular/core';
import { MatLegacyDialogRef as MatDialogRef } from '@angular/material/legacy-dialog';
import { Router } from '@angular/router';
import { Store } from '@ngxs/store';
import { ActionsDialogComponent } from '../components/actions-dialog/actions-dialog.component';
import { EActionTargetType, IActionTarget } from '../models/action-target.model';
import { IAction } from '../models/action.model';

export interface IActionTargetService {
  link(url: string): void;
}

@Injectable({
  providedIn: 'root'
})
export class ActionTargetService implements IActionTargetService {

  constructor(@Inject(DOCUMENT) private document: Document, private store: Store, private router: Router) { }

  link(url: string) {
    this.router.navigate([url]);
  }

  scrollTo(id) {
    const elm = this.document.getElementById(id);
    if( elm ) {
      const child = (elm.children || []).length > 0 && (elm.children[0] as any);
      if( !child ) {
        return;
      }
      if( !child.classList.contains("mat-expanded") ) {
        child.click();
      }
      child.focus();
      child.scrollIntoView({behavior: 'smooth', block: 'start'});
    }
  }

  process(action: IAction) {
    if( !(action || {}).target ) {
      throw new Error("target is required");
    }

    switch(action.target.type) {
      case EActionTargetType.LINK:
        this.link(action.target.value as string);
        break;
      case EActionTargetType.EXTERNAL_LINK:
        window.open(action.target.value as string, "_blank");
        break;
      case EActionTargetType.VIEW_ONLY:
        return;
      case EActionTargetType.PAGE_ITEM:
        this.scrollTo(action.target.value);
        break;
      default:
        throw new Error("Unknown action type");
    }
  }

}
