import { Component, OnInit, Input } from '@angular/core';

@Component({
  selector: 'app-default-forgot-password',
  templateUrl: './default-forgot-password.component.html',
  styleUrls: ['./default-forgot-password.component.scss']
})
export class DefaultForgotPasswordComponent {

  @Input() scope;
  constructor() { }
}
