import { Component, OnInit, Inject, OnDestroy } from "@angular/core";
import { MatLegacyDialogRef as MatDialogRef, MAT_LEGACY_DIALOG_DATA as MAT_DIALOG_DATA } from "@angular/material/legacy-dialog";
import { ErrorSummary } from "@career/core/models/error.model";
import { PortalService } from "@career/core/services/portal.service";
import { ACTIVE_LAYER_CONFIG, LayerConfig, LayerOverlayRef } from "kleos-ui";
import { Observable, Subscription } from "rxjs";
import { AngularEditorConfig } from "@kolkov/angular-editor";
import { ImageBucketTypes } from "@career/core/models/update-image-icon.enum";
import { map } from "rxjs/operators";
import { Asset } from "@career/core/models/asset.model";
import { ContentService } from "@career/core/services/content.service";
import { HttpResponse } from "@angular/common/http";
import { editorConfigCustomTemplate } from "@career/page-configuration/constants/custom-template-editor-config.constant";
import { editorConfig } from "@career/page-configuration/constants/editor-config.constant";

@Component({
  selector: "app-section-text-edit-modal",
  templateUrl: "./section-text-edit-dialog.component.html",
  styleUrls: ["./section-text-edit-dialog.component.scss"],
})
export class SectionTextEditDialogComponent implements OnInit, OnDestroy {
  name: string;
  titlePath: string;
  bodyPath: string;
  titleHtmlContent = "";
  bodyHtmlContent = "";
  hasFocus = false;
  subject: string;
  error: ErrorSummary;
  subs: Subscription[] = [];
  isStaticTemplate: boolean;
  editorConfig: AngularEditorConfig;
  component: string;

  // modules = {
  //   toolbar: [
  //     ["bold", "italic", "underline"],
  //     [{ header: 1 }, { header: 2 }], // custom button values
  //     [{ list: "ordered" }, { list: "bullet" }],
  //     [{ size: ["small", false, "large", "huge"] }], // custom dropdown
  //     [{ header: [1, 2, 3, 4, 5, 6, false] }],
  //     [{ align: [] }],
  //   ],
  // };

  // editorConfig: AngularEditorConfig = {
  //   editable: true,
  //   spellcheck: true,
  //   height: "auto",
  //   minHeight: "0",
  //   maxHeight: "auto",
  //   width: "auto",
  //   minWidth: "0",
  //   translate: "yes",
  //   enableToolbar: true,
  //   showToolbar: true,
  //   placeholder: "Enter text here...",
  //   defaultParagraphSeparator: "",
  //   defaultFontName: "",
  //   defaultFontSize: "",
  //   fonts: [
  //     { class: "arial", name: "Arial" },
  //     { class: "times-new-roman", name: "Times New Roman" },
  //     { class: "calibri", name: "Calibri" },
  //     { class: "comic-sans-ms", name: "Comic Sans MS" },
  //   ],
  //   customClasses: [
  //     {
  //       name: "quote",
  //       class: "quote",
  //     },
  //     {
  //       name: "redText",
  //       class: "redText",
  //     },
  //     {
  //       name: "titleText",
  //       class: "titleText",
  //       tag: "h1",
  //     },
  //   ],
  //   uploadUrl: "v1/image",
  //   // upload: (file: File) => { ... }
  //   uploadWithCredentials: false,
  //   sanitize: true,
  //   toolbarPosition: "top",
  //   toolbarHiddenButtons: [["heading", "fontName"], ["customClasses"]],
  // };

  constructor(
    @Inject(ACTIVE_LAYER_CONFIG) public config: LayerConfig,
    private portalService: PortalService,
    public layerRef: LayerOverlayRef,
    private contentSvc: ContentService
  ) {}

  ngOnInit(): void {
    this.titleHtmlContent = this.config.data.titleHtmlContent;
    this.bodyHtmlContent = this.config.data.bodyHtmlContent;
    this.titlePath = this.config.data.titlePath;
    this.bodyPath = this.config.data.bodyPath;
    this.name = this.config.data.name;
    this.isStaticTemplate = this.config.data.isStaticTemplate;
    this.component = this.config.data.component;
    this.editorConfig = this.getEditorConfig();
  }

  getEditorConfig(): AngularEditorConfig {
    if (this.isStaticTemplate) {
      const config = { ...editorConfigCustomTemplate };
      config.upload = this.uploadFile.bind(this);
      return config;
    }
    return { ...editorConfig };
  }

  uploadFile(file: File): Observable<any> {
    const formData: FormData = new FormData();
    formData.append("file", file);
    return this.contentSvc
      .postImageToBucket(formData, ImageBucketTypes.IMAGE, this.component)
      .pipe(
        map((res: HttpResponse<any>) => {
          return new HttpResponse({
            body: this.getLatestUploadImgUrl(res.body, file),
          });
        })
      );
  }

  getLatestUploadImgUrl(images: Asset[], file: File): { imageUrl: string } {
    const regex = new RegExp("^.+" + this.escapeRegExp(file.name) + "$", "g");
    const imageUrl = images.find((image) => image.name.match(regex)).url;
    return {
      imageUrl: imageUrl,
    };
  }

  escapeRegExp(text): string {
    return text.replace(/[-[\]{}()*+?.,\\^$|#\s]/g, "\\$&");
  }

  onSave() {
    const sub = this.portalService
      .patchComponent(this.preparePayload(), this.config.data.component)
      .subscribe(
        (response) => {
          this.layerRef.close(response);
        },
        (error) => {
          this.error = new ErrorSummary(
            error.message || "Unknown",
            error.status || "unknown"
          );
        }
      );
    this.subs.push(sub);
  }

  preparePayload(): any {
    const textMap = { [this.titlePath]: this.titleHtmlContent };
    if (this.bodyPath) {
      textMap[this.bodyPath] = this.bodyHtmlContent;
    }
    let requestBody = {};
    requestBody = {
      textMap: textMap,
    };
    return requestBody;
  }

  ngOnDestroy() {
    this.subs.forEach((sub) => {
      sub.unsubscribe();
    });
  }
}
