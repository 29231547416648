import { Injectable } from '@angular/core';
import { HttpClient } from '@angular/common/http';
import { PocketLocations } from '../models/location.model';
import { Observable } from 'rxjs';

@Injectable({
  providedIn: 'root'
})

export class ListService {

  constructor(private http: HttpClient) { }

  getStateByCountry(country) {
    return this.http.get('/api/portal/geo/states?country='+country)
  }
  
  getLocation(state, country) {
    return this.http.get('/api/location?search='+state+','+country)
  }

  searchLocation(value): Observable<PocketLocations> {
    return this.http.get('/api/location?search='+encodeURIComponent(value)) as Observable<PocketLocations>;
  }

  searchUSLocation(value): Observable<PocketLocations> {
    return this.http.get('/api/location?search='+encodeURIComponent(value)+',country:US') as Observable<PocketLocations>;
  }

  getCountries() {
    return this.http.get('/api/portal/geo/countries');
  }
  
}
