<header class="header">
    <div class="header__container">
        <div appImgEditContainer="nav" [path]="scope.uploadImageIcon.logo" name="Logo">
            <a class="header__logo-container" disabledDiv [href]="scope.config.agencyWebsite">
                <img class="header__logo" [src]="scope.component.assets.logo" [alt]="scope.portalName" [ngStyle]="{'max-width': (scope.config.logoSize || '300')+'px'}">
            </a>
        </div>
        <ng-container *ngIf="(scope.userChanged$ | async) else unauthenticated">
            <ng-container *ngTemplateOutlet="authenticated"></ng-container>
        </ng-container>
    </div>
</header>

<ng-template #unauthenticated>
    <nav class="header__nav-items" disabledDiv *ngIf="(scope.routes$ | async) as routes">
        <ng-container *ngFor="let route of routes.unauthenticatedRoutes">
            <a class="header__nav-item" *ngIf="!route.externalLink" [routerLink]="route.link"
                [routerLinkActive]="['header__nav-item--active']"
                [routerLinkActiveOptions]="(route.routerLinkActiveOptions) || {}"
                [innerText]="scope.getText | transform:route.textMapKey"></a>
            <a class="header__nav-item" *ngIf="route.externalLink" [href]="route.externalLink"
                [innerText]="scope.getText | transform:route.textMapKey"></a>
        </ng-container>
    </nav>
</ng-template>
<ng-template #authenticated>
    <nav class="header__nav-items" disabledDiv *ngIf="(scope.routes$ | async) as routes">
        <ng-container *ngFor="let route of routes.authenticatedRoutes">
            <ng-container *ngIf="route.link === '/dashboard' && (scope.incompleteActions$ | async).length > 0">
                <a [matBadge]="(scope.incompleteActions$ | async).length" matBadgeColor="warn" matBadgeOverlap="true"
                    class="header__nav-item" [routerLink]="route.link" [routerLinkActive]="['header__nav-item--active']"
                    [routerLinkActiveOptions]="(route.routerLinkActiveOptions) || {}"
                    [innerText]="scope.getText | transform:route.textMapKey"></a>
            </ng-container>
            <ng-container *ngIf="route.link === '/logout'">
                <a class="header__nav-item" [class.disabled_div]="(scope.isPreviewMode$ | async)" [routerLink]="route.link"
                    [routerLinkActive]="['header__nav-item--active']"
                    [routerLinkActiveOptions]="(route.routerLinkActiveOptions) || {}"
                    [innerText]="scope.getText | transform:route.textMapKey"></a>
            </ng-container>
            <ng-container
                *ngIf="(route.link !== '/dashboard' || (scope.incompleteActions$ | async).length < 1) && route.link !== '/logout'">
                <a *ngIf="!route.externalLink" disabledDiv class="header__nav-item" [routerLink]="route.link"
                    [routerLinkActive]="['header__nav-item--active']"
                    [routerLinkActiveOptions]="(route.routerLinkActiveOptions) || {}"
                    [innerText]="scope.getText | transform:route.textMapKey"></a>
                <a *ngIf="route.externalLink" disabledDiv class="header__nav-item" [href]="route.externalLink"
                    [innerText]="scope.getText | transform:route.textMapKey"></a>
            </ng-container>
        </ng-container>
    </nav>
</ng-template>