<ng-container *ngIf="scope">
    <footer class="footer">
        <div class="footer__up">
            <div class="footer__logo-column">
                <div appImgEditContainer="footer" [path]="scope.uploadImageIcon.logo" name="Footer Logo"
                    [class.disabled_div]="(scope.isPreviewMode$ | async)">
                    <a [href]="(scope.isAdmin$ | async) ? 'javascript:void(0)': scope.config.agencyWebsite" class="footer__logo-container">
                        <img [src]="scope.component.assets.logo" [alt]="scope.portalName" loading="lazy"
                            [ngStyle]="{'max-width': (scope.config.footerSize || '300')+'px'}"
                            class="footer__logo">
                    </a>
                </div>
                <div class="footer__text" [innerHTML]="scope.getText | transform:'FOOTER_TEXT'"
                    *ngIf="scope.footerTextExists"></div>
                <div class="footer__soc2" *ngIf="scope.config.soc2Enabled">
                    <img src="https://pocketweb2.s3.amazonaws.com/soc2.png" width="85">
                </div>
            </div>
            <div class="footer__columns-container">
                <div class="footer__column" disabledDiv>
                    <div class="footer__column-heading-wrapper">
                        <h5 class="footer__column-heading">{{scope.getText | transform:'QUICK_LINKS'}}</h5>
                    </div>
                    <ng-container *ngIf="(scope.userChanged$ | async) else unauthenticated">
                        <ng-container *ngTemplateOutlet="authenticated"></ng-container>
                    </ng-container>
                </div>
            </div>
        </div>
        <div class="footer__down">
            <p class="footer__policy-and-copyright">
                <ng-container *ngIf="!scope.hasGtmEnabled">We use no tracking cookies as part of our commitment to privacy.</ng-container>
                <br>
                © Pocket Recruiter {{scope.year}}, All rights reserved.
            </p>
        </div>
    </footer>

    <ng-template #unauthenticated>
        <ng-container *ngFor="let route of scope.unauthenticatedRoutes">
            <a class="footer__link" *ngIf="!route.externalLink" [routerLink]="route.link"
                [routerLinkActive]="['header__nav-item--active']"
                [routerLinkActiveOptions]="(route.routerLinkActiveOptions) || {}"
                [innerText]="scope.getText | transform:route.textMapKey"></a>
            <a class="footer__link" *ngIf="route.externalLink" [href]="route.externalLink"
                [innerText]="scope.getText | transform:route.textMapKey"></a>
        </ng-container>
    </ng-template>

    <ng-template #authenticated>
        <ng-container *ngFor="let route of scope.authenticatedRoutes">
            <a *ngIf="!route.externalLink"
                [class.disabled_div]="route.link === '/logout' && (scope.isPreviewMode$ | async)" class="footer__link"
                [routerLink]="route.link" [routerLinkActive]="['header__nav-item--active']"
                [routerLinkActiveOptions]="(route.routerLinkActiveOptions) || {}"
                [innerText]="scope.getText | transform:route.textMapKey"></a>
            <a *ngIf="route.externalLink" class="footer__link" [href]="route.externalLink"
                [innerText]="scope.getText | transform:route.textMapKey"></a>
        </ng-container>
    </ng-template>
</ng-container>