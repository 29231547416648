import { Component, OnInit, Inject, OnDestroy } from "@angular/core";
import { MatLegacyDialogRef as MatDialogRef, MAT_LEGACY_DIALOG_DATA as MAT_DIALOG_DATA } from "@angular/material/legacy-dialog";
import { ErrorSummary } from "@career/core/models/error.model";
import { PortalService } from "@career/core/services/portal.service";
import { Subscription } from "rxjs";

@Component({
  selector: "app-confirm-dialog",
  templateUrl: "./confirm-dialog.component.html",
  styleUrls: ["./confirm-dialog.component.scss"],
})
export class ConfirmDialogComponent implements OnInit, OnDestroy {
  message: string =
    "Are you sure to publish your changes? This will immediately be available to all users.";
  confirmButtonText = "Confirm";
  cancelButtonText = "Go Back";
  error: ErrorSummary;
  subs: Subscription[] = [];
  constructor(
    @Inject(MAT_DIALOG_DATA) private data: any,
    private portalSvc: PortalService,
    public dialogRef: MatDialogRef<ConfirmDialogComponent>
  ) {}

  ngOnInit(): void {
    if (this.data.cancelButtonText) {
      this.cancelButtonText = this.data.cancelButtonText;
    }
    if (this.data.message) {
      this.message = this.data.message;
    }
  }

  onConfirmClick(): void {
    const sub = this.portalSvc.publishPortal().subscribe(
      (_) => {
        this.error = null;
        this.dialogRef.close(true);
      },
      (err) => {
        this.error = new ErrorSummary(err.message, err.status);
      }
    );
    this.subs.push(sub);
  }

  ngOnDestroy(): void {
    this.subs.forEach((sub) => sub.unsubscribe());
  }
}
