import { Component, OnDestroy, OnInit } from "@angular/core";
import { DomSanitizer, Title, SafeHtml } from "@angular/platform-browser";
import { ActivatedRoute, Router } from "@angular/router";
import {
  TemplateComponent,
  TemplateConfiguration,
} from "@career/core/models/component.model";
import { PortalService } from "@career/core/services/portal.service";
import { GetTextMethod } from "@career/core/services/utils.service";
import { staticPagesComponentDef } from "../component-definition";
import { Observable, Subscription } from "rxjs";
import { UserService } from "@career/core/services/user.service";
import { LayerService } from "kleos-ui";
import { DialogService } from "@career/core/services/dialog.service";
import { CreateNewTemplateComponent } from "@career/page-configuration/Shared/components/create-new-template/create-new-template.component";

@Component({
  selector: "app-static-page",
  templateUrl: "./static-page.component.html",
})
export class StaticPageComponent implements OnInit, OnDestroy {
  componentDef = staticPagesComponentDef["static-pages"];
  component: TemplateComponent;
  config: TemplateConfiguration;
  sanitizedRichText: SafeHtml;
  path: string;
  subscriptions: Subscription[] = [];
  isAdmin$: Observable<boolean>;

  constructor(
    private titleService: Title,
    private route: ActivatedRoute,
    private sanitizer: DomSanitizer,
    private router: Router,
    private portalService: PortalService,
    private userSvc: UserService,
    private dialogSvc: DialogService
  ) {}

  ngOnInit(): void {
    this.route.paramMap.subscribe((_) => {
      this.loadComponentData();
    });
    this.isAdmin$ = this.userSvc.isAdmin();
  }

  configure() {
    this.dialogSvc.openCreateNewTemplate(CreateNewTemplateComponent, null, {existing: this.component});
  }

  loadComponentData() {
    this.path = this.getPath();
    if (this.path) {
      const sub = this.portalService
        .getComponentData(this.path)
        .subscribe((data) => {
          if (data.component) {
            this.component = data.component;
            this.config = data.configuration;
            this.titleService.setTitle(
              `${
                this.route.snapshot.data.displayName
              } | ${this.portalService.getPortalName()}`
            );
            if (this.component.textMap && this.component.textMap.richText) {
              this.sanitizedRichText = this.sanitizer.bypassSecurityTrustHtml(
                this.component.textMap.richText
              );
            }

            return;
          }

          this.throwErr();
        });
      this.subscriptions.push(sub);
    } else {
      this.throwErr();
    }
  }

  getPath() {
    const routes = this.router.url.split("/");
    return routes[routes.length - 1];
  }

  throwErr() {
    this.router.navigate(["/404"], { replaceUrl: true });
  }

  overloadGetText(
    getText: GetTextMethod,
    context: StaticPageComponent
  ): GetTextMethod {
    return (key, replacement?) => {
      switch (key) {
        case "richText":
          return context.sanitizedRichText as any;
        default:
          return getText(key);
      }
    };
  }

  ngOnDestroy() {
    this.subscriptions.forEach((sub) => sub.unsubscribe());
  }
}
