import { DOCUMENT } from '@angular/common';
import { Inject, Injectable } from '@angular/core';
import { Observable, of } from 'rxjs';

@Injectable({
  providedIn: 'root'
})
export class FontService {

  constructor(@Inject(DOCUMENT) private document: Document) { }

  getNewFontType(name: string): string {
    //Inter v4.0
    return `
    @font-face { font-family:${name}; font-style:normal; font-weight:100; font-display:swap; src:url("https://prweb-assets-prod.s3.amazonaws.com/fonts/${name}-Thin.woff2") format("woff2"); }
    @font-face { font-family:${name}; font-style:italic; font-weight:100; font-display:swap; src:url("https://prweb-assets-prod.s3.amazonaws.com/fonts/${name}-ThinItalic.woff2") format("woff2"); }
    @font-face { font-family:${name}; font-style:normal; font-weight:200; font-display:swap; src:url("https://prweb-assets-prod.s3.amazonaws.com/fonts/${name}-ExtraLight.woff2") format("woff2"); }
    @font-face { font-family:${name}; font-style:italic; font-weight:200; font-display:swap; src:url("https://prweb-assets-prod.s3.amazonaws.com/fonts/${name}-ExtraLightItalic.woff2") format("woff2"); }
    @font-face { font-family:${name}; font-style:normal; font-weight:300; font-display:swap; src:url("https://prweb-assets-prod.s3.amazonaws.com/fonts/${name}-Light.woff2") format("woff2"); }
    @font-face { font-family:${name}; font-style:italic; font-weight:300; font-display:swap; src:url("https://prweb-assets-prod.s3.amazonaws.com/fonts/${name}-LightItalic.woff2") format("woff2"); }
    @font-face { font-family:${name}; font-style:normal; font-weight:400; font-display:swap; src:url("https://prweb-assets-prod.s3.amazonaws.com/fonts/${name}-Regular.woff2") format("woff2"); }
    @font-face { font-family:${name}; font-style:italic; font-weight:400; font-display:swap; src:url("https://prweb-assets-prod.s3.amazonaws.com/fonts/${name}-Italic.woff2") format("woff2"); }
    @font-face { font-family:${name}; font-style:normal; font-weight:500; font-display:swap; src:url("https://prweb-assets-prod.s3.amazonaws.com/fonts/${name}-Medium.woff2") format("woff2"); }
    @font-face { font-family:${name}; font-style:italic; font-weight:500; font-display:swap; src:url("https://prweb-assets-prod.s3.amazonaws.com/fonts/${name}-MediumItalic.woff2") format("woff2"); }
    @font-face { font-family:${name}; font-style:normal; font-weight:600; font-display:swap; src:url("https://prweb-assets-prod.s3.amazonaws.com/fonts/${name}-SemiBold.woff2") format("woff2"); }
    @font-face { font-family:${name}; font-style:italic; font-weight:600; font-display:swap; src:url("https://prweb-assets-prod.s3.amazonaws.com/fonts/${name}-SemiBoldItalic.woff2") format("woff2"); }
    @font-face { font-family:${name}; font-style:normal; font-weight:700; font-display:swap; src:url("https://prweb-assets-prod.s3.amazonaws.com/fonts/${name}-Bold.woff2") format("woff2"); }
    @font-face { font-family:${name}; font-style:italic; font-weight:700; font-display:swap; src:url("https://prweb-assets-prod.s3.amazonaws.com/fonts/${name}-BoldItalic.woff2") format("woff2"); }
    
    body {
      font-family: ${name} !important;
    }
    `
  }

  getDefaultFont(name: string): string {
    return `
    /* cyrillic-ext */
    @font-face {
      font-family: ${name};
      font-style: normal;
      font-weight: 400;
      font-display: swap;
      src: url(https://prweb-assets-prod.s3.amazonaws.com/fonts/${name}-cyrillic-ext.woff2) format('woff2');
      unicode-range: U+0460-052F, U+1C80-1C88, U+20B4, U+2DE0-2DFF, U+A640-A69F, U+FE2E-FE2F;
    }
    /* cyrillic */
    @font-face {
      font-family: ${name};
      font-style: normal;
      font-weight: 400;
      font-display: swap;
      src: url(https://prweb-assets-prod.s3.amazonaws.com/fonts/${name}-cyrillic.woff2) format('woff2');
      unicode-range: U+0301, U+0400-045F, U+0490-0491, U+04B0-04B1, U+2116;
    }
    /* vietnamese */
    @font-face {
      font-family: ${name};
      font-style: normal;
      font-weight: 400;
      font-display: swap;
      src: url(https://prweb-assets-prod.s3.amazonaws.com/fonts/${name}-vietnamese.woff2) format('woff2');
      unicode-range: U+0102-0103, U+0110-0111, U+0128-0129, U+0168-0169, U+01A0-01A1, U+01AF-01B0, U+1EA0-1EF9, U+20AB;
    }
    /* latin-ext */
    @font-face {
      font-family: ${name};
      font-style: normal;
      font-weight: 400;
      font-display: swap;
      src: url(https://prweb-assets-prod.s3.amazonaws.com/fonts/${name}-latin-ext.woff2)  format('woff2');
      unicode-range: U+0100-024F, U+0259, U+1E00-1EFF, U+2020, U+20A0-20AB, U+20AD-20CF, U+2113, U+2C60-2C7F, U+A720-A7FF;
    }
    /* latin */
    @font-face {
      font-family: ${name};
      font-style: normal;
      font-weight: 400;
      font-display: swap;
      src: url(https://prweb-assets-prod.s3.amazonaws.com/fonts/${name}-latin.woff2) format('woff2');
      unicode-range: U+0000-00FF, U+0131, U+0152-0153, U+02BB-02BC, U+02C6, U+02DA, U+02DC, U+2000-206F, U+2074, U+20AC, U+2122, U+2191, U+2193, U+2212, U+2215, U+FEFF, U+FFFD;
    }

    body {
        font-family: ${name}, sans-serif !important;
    }
    `;
  }

  setFont(name: string): Observable<HTMLStyleElement> {
    name = (name || 'montserrat').toLowerCase();
    let styles;
    if( name === 'inter' ) {
      styles = this.getNewFontType('Inter');
    } else {
      styles = this.getDefaultFont(name);
    }

    const node = this.document.createElement('style');
    node.innerHTML = styles;
    return of(this.document.body.appendChild(node));
  }
}
