import { Component, OnInit } from "@angular/core";
import { UntypedFormGroup } from "@angular/forms";
import { FieldConfig } from "@career/pocket-forms/models/field";
import { Observable } from "rxjs";
@Component({
  selector: "app-select",
  template: `
<div class="form" [ngClass]="{'error-handler': (group | hasValidationErrors:field)}">
<ng-container *ngFor="let validation of (group | getValidationErrors:field)">
  <p *ngIf="group.get(field.name).hasError(validation.name)" class="error-handler__message">{{validation.message}}</p>
</ng-container>
<label class="form__label" [for]="field.name" [innerText]="field.label"></label>
<mat-form-field [formGroup]="group" class="form__field" appearance="outline">
  <mat-select class="form__field" [formControlName]="field.name" [id]="field.name">
    <ng-container *ngIf="shouldSubscribe">
      <mat-option *ngFor="let item of (field.options | async)" [value]="item.value">{{item.key}}</mat-option>
    </ng-container>
    <ng-container *ngIf="!shouldSubscribe">
      <mat-option *ngFor="let item of field.options" [value]="item.value">{{item.key}}</mat-option>
    </ng-container>
  </mat-select>
</mat-form-field>
</div>
`,
  styleUrls: ['./select.component.scss']
})
export class SelectComponent implements OnInit {
  field: FieldConfig;
  group: UntypedFormGroup;
  shouldSubscribe: boolean = false;
  constructor() {}
  ngOnInit() {
    if( this.field.options instanceof Observable ) {
      this.shouldSubscribe = true;
    }
  }
}