import { DefaultErrorSummaryComponent } from "./components/error-summary/templates/default-error-summary/default-error-summary.component";
import { DefaultFooterComponent } from "./components/footer/templates/default/default-footer.component";
import { DefaultMobileNavComponent } from "./components/mobile-nav/templates/default-mobile-nav/default-mobile-nav.component";
import { DefaultNavComponent } from "./components/nav/templates/default/default-nav.component";
import { SocialNavComponent } from "./components/nav/templates/social/social-nav.component";
import { DefaultUnsubscribeComponent } from "./components/unsubscribe/templates/default-unsubscribe/default-unsubscribe.component";
import { DefaultUploadFileComponent } from "./components/upload-file/templates/default-upload-file/default-upload-file.component";

//Note: "mobile-nav" does not have an actual component (config) in the database, but piggybacks off of "nav".
export const coreComponentDef = {
    "nav": {
        "social": SocialNavComponent,
        "default": DefaultNavComponent
    },
    "mobile-nav": {
        "default": DefaultMobileNavComponent,
        "social": DefaultMobileNavComponent
    },
    "footer": {
        "default": DefaultFooterComponent,
        "social": DefaultFooterComponent
    },
    "error-summary": {
        "default": DefaultErrorSummaryComponent,
        "social": DefaultErrorSummaryComponent,
    },
    "unsubscribe": {
        "default": DefaultUnsubscribeComponent,
        "social": DefaultUnsubscribeComponent
    },
    "upload-file": {
        "default": DefaultUploadFileComponent,
        "social": DefaultUploadFileComponent
    }
}