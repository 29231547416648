export interface INavRoute {
    link: string;
    routerLinkActiveOptions?: object;
    textMapKey: string;
    externalLink?: string;
    hideNavigation?: boolean;
    order?: number;
}

const BASE_NAV_ROUTES: INavRoute[] = [
    {
        link: '/',
        routerLinkActiveOptions: {exact: true},
        textMapKey: 'HOME'
    },
    {
        link: '/jobs',
        routerLinkActiveOptions: {exact: true},
        textMapKey: "JOB_SEARCH"
    }
];

export const UNAUTHENTICATED_NAV_ROUTES: INavRoute[] = [
    ...BASE_NAV_ROUTES,
    {
        textMapKey: "FIND_ME_A_JOB",
        link: '/suggestions'
    },
    {
        textMapKey: "LOGIN",
        link: '/login'
    },
    {
        textMapKey: "REGISTER",
        link: '/register'
    }
]

export const AUTHENTICATED_NAV_ROUTES: INavRoute[] = [
    ...BASE_NAV_ROUTES,
    {
        textMapKey: "DASHBOARD",
        link: '/dashboard'
    },
    {
        textMapKey: "LOGOUT",
        link: '/logout'
    }
]