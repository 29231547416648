import { Component, OnDestroy, OnInit } from "@angular/core";
import { LegacyPageEvent as PageEvent } from "@angular/material/legacy-paginator";
import { ActionsService } from "@career/actions/services/actions-service.service";
import {
  TemplateComponent,
  TemplateConfiguration,
} from "@career/core/models/component.model";
import { Match } from "@career/core/models/match.model";
import { CandidateService } from "@career/core/services/candidate.service";
import { LocaleService } from "@career/core/services/locale.service";
import { MatchService } from "@career/core/services/match.service";
import { PortalService } from "@career/core/services/portal.service";
import { UserService } from "@career/core/services/user.service";
import { userComponentDef } from "@career/user/component-definition";
import { from, Observable, Subscription } from "rxjs";
import { filter, map, switchMap, toArray } from "rxjs/operators";

@Component({
  selector: "app-applications",
  template: `
    <app-base-template
      [component]="component"
      [componentDef]="componentDef"
      [scope]="this"
      *ngIf="component"
    ></app-base-template>
  `,
})
export class ApplicationsComponent implements OnInit, OnDestroy {
  component: TemplateComponent;
  config: TemplateConfiguration;
  getText: Function;
  portalServiceSubscription: Subscription;
  applied$: Observable<Match[]>;
  componentDef = userComponentDef["dashboard-applications"];
  incompleteActions$: Observable<any>;
  completeActions$: Observable<any>;
  loading: boolean;
  pageEvent: PageEvent = {
    pageIndex: 0,
    pageSize: 5
  } as PageEvent;

  constructor(
    private candidateService: CandidateService,
    private actionsService: ActionsService,
    private matchService: MatchService,
    private portalService: PortalService,
    private userSvc: UserService,
    public localeSvc: LocaleService
  ) {}

  convertToPortalName(match) {
    const workflow = match.opportunity.workflows["MATCH"];
    if (workflow && workflow.workflowStatus) {
      const changes = Object.keys(match.stateChanges).reduce((acc, current) => {
        let wfStatus = workflow.workflowStatus.find((o) => o.status === current);
        if (wfStatus && wfStatus.portalName) {
          acc.push({
            portalName: wfStatus.portalName,
            name: wfStatus.name,
            timestamp: match.stateChanges[wfStatus.status].timestamp,
            questions: wfStatus.questions,
            workflowId: workflow._id,
            order: wfStatus.order
          });
        }
        return acc;
      }, [])
      .sort((a, b) => a.order - b.order);
      //only return the first and last status
      return changes.length > 1
        ? [changes[0], changes[changes.length - 1]]
        : changes;
    }
    return null;
  }

  setApplied() {
    this.applied$ = this.userSvc.getUser().pipe(
      filter((user) => user && user._id),
      switchMap((user) => {
        return this.candidateService.getApplied(user._id);
      }),
      switchMap((data) => {
        return from(
          (data.matches || []).map((m) =>
            this.matchService.convertToMatch(m, {
              TOP_MATCH: this.getText("TOP_MATCH"),
            }, this.config)
          )
        ) as Observable<Match>;
      }),
      map((match) => {
        match.portalStates = this.convertToPortalName(match);
        return match;
      }),
      filter((match) => match.portalStates),
      toArray(),
      map((data) => {
        this.loading = false;
        this.pageEvent.length = data?.length;
        data.sort((a, b) => b.lastUpdated - a.lastUpdated);
        return data;
      })
    );
  }

  getDate(timestamp, scope) {
    return scope.localeSvc.getLocalDateStr(timestamp);
  }

  combinePills(application: Match) {
    return [
      ...application.opportunity.display.pills,
      ...application.display.pills,
    ];
  }

  ngOnInit(): void {
    this.portalServiceSubscription = this.portalService
      .getComponentData("dashboard-applications")
      .subscribe((data) => {
        this.component = data.component;
        this.config = data.configuration;
        this.incompleteActions$ =
          this.actionsService.getIncompleteOpportunityActions();
        this.completeActions$ =
          this.actionsService.getCompletedOpportunityActions();
        this.loading = true;
        this.setApplied();
      });
  }

  ngOnDestroy(): void {
    this.portalServiceSubscription.unsubscribe();
  }
}
