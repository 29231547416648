import { trigger, transition, style, animate } from '@angular/animations';
import { AfterViewInit, ChangeDetectorRef, Component, ElementRef, EventEmitter, Input, OnChanges, OnInit, Output, SimpleChanges, ViewChild } from '@angular/core';
import { Validators } from '@angular/forms';
import { ErrorSummary } from '@career/core/models/error.model';
import { FieldConfig, WizardGroup } from '@career/pocket-forms/models/field';
import { of } from 'rxjs';
import { catchError, take } from 'rxjs/operators';
import { DynamicFormComponent } from '../dynamic-form/dynamic-form.component';

@Component({
  selector: 'app-dynamic-form-wizard',
  templateUrl: './dynamic-form-wizard.component.html',
  styleUrls: ['./dynamic-form-wizard.component.scss'],
  animations: [
    trigger(
      'enterAnimation', 
      [
        transition(
          '*=>*', 
          [
            style({ height: 0, opacity: 0 }),
            animate('0.3s ease-in-out', 
                    style({ opacity: 1 }))
          ]
        )
      ]
    )
  ]
})
export class DynamicFormWizardComponent implements OnChanges {
  @Input() groups: WizardGroup[];
  @Input() groupValidators: Validators[];
  @Output() submit = new EventEmitter<any>();
  activeGroup: WizardGroup;
  output: any = {};
  fields: FieldConfig[] = [];
  disabled: boolean = false;
  error: ErrorSummary;
  @ViewChild("dynamicForm") dynamicForm: DynamicFormComponent;
  @ViewChild("formContainer", {read: ElementRef}) formContainer: ElementRef;
  constructor() { }

  createFields(group: WizardGroup) {
    this.fields = [
      ...group.fields.filter(field => !!field).map(field => {
        return {
          ...field,
          value: this.output[field.name] || field.value
        }
      })
    ];
  }

  persistValues() {
    this.output = {
      ...this.output,
      ...(this.dynamicForm && this.dynamicForm.value) || {}
    };
  }

  clearErrors() {
    if( this.dynamicForm && this.dynamicForm.form && this.dynamicForm.form.controls ) {
      Object.values(this.dynamicForm.form.controls).forEach(control => {
        control.setErrors(null);
      });
    }
  }

  back() {
    this.scrollIntoView();
    this.clearErrors();
    if( this.activeGroup.number-1 < 0 ) {
      if( this.activeGroup.restart ) {
        this.activeGroup.restart();
      }
      return;
    }
    this.setActiveGroup(this.groups[this.activeGroup.number-1]);
  }

  scrollIntoView() {
    (this.formContainer.nativeElement as Element).scrollIntoView({behavior: 'smooth', block: 'center'});
  }

  next() {
    this.scrollIntoView();
    if( this.activeGroup.number === this.groups.length-1 ) {
      //submit
      this.persistValues();
      this.submit.emit(this.output);
      return;
    }
    this.setActiveGroup(this.groups[this.activeGroup.number+1]);
  }

  submitStep($event) {
    if( this.activeGroup.preSubmit ) {
      this.disabled = true;
      this.activeGroup.preSubmit($event).pipe(
        take(1)
      ).subscribe((resp: boolean) => {
        if( resp ) {
          this.next();
        }
      }).add(() => {
        this.disabled = false;
      });
      return;
    }
    this.next();
  }

  setActiveGroup(group: WizardGroup) {
    this.persistValues();
    this.createFields(group)
    if( group.number === this.groups.length-1 ) {
      this.fields.push(            {
        type: "button",
        appearance: 'mat-flat-button',
        style: "width: unset",
        color: 'primary',
        label: group.submitButtonText
      });
    } else {
      this.fields.push(            {
        type: "button",
        appearance: 'mat-flat-button',
        style: "width: unset",
        color: 'primary',
        label: group.nextButtonText
      });
    }

    if( group.backButtonText ) {
      this.fields.push({
        type: "button",
        appearance: 'mat-stroked-button',
        style: "width: unset",
        color: 'primary',
        label: group.backButtonText,
        action: this.back.bind(this)
      });
    }

    this.activeGroup = group;
  }

  ngOnChanges(changes: SimpleChanges): void {
      if( changes.groups ) {
        this.groups = changes.groups.currentValue.sort((a, b) => a.number - b.number)
        .filter(g => !g.hasOwnProperty('showIf') || g.showIf !== false);
        this.setActiveGroup(this.groups[0]);
      }
  }

}
