import { Component, OnInit, ViewChild } from "@angular/core";
import { MatLegacyDialog as MatDialog } from "@angular/material/legacy-dialog";
import { Router } from "@angular/router";
import { UserAuthService } from "@career/core/services/user-auth.service";
import { take, concatMap, map } from "rxjs/operators";
import { Subscription } from "rxjs";
import {
  PageConfigTypes,
  SelectOption,
  Theme,
} from "../models/page-config.model";
import { EditConfigurationsComponent } from "../Shared/components/edit-configurations/edit-configurations.component";
import { PortalService } from "@career/core/services/portal.service";
import { FontFamilyConfig, Translations } from "../configs/page-config.config";
import { UserService } from "@career/core/services/user.service";
import { ConfirmDialogComponent } from "../Shared/components/confirm-dialog/confirm-dialog.component";
import { TemplateConfiguration } from "@career/core/models/component.model";
import { ConfigurableComponent } from "../Shared/components/configurable.component";
import { CoreAction } from "@career/core/store/actions/core.actions";
import { Store } from "@ngxs/store";
import { LayerService } from "kleos-ui";
import { DomSanitizer } from "@angular/platform-browser";
import { MatDrawer } from "@angular/material/sidenav";

@Component({
  selector: "app-page-configuration",
  templateUrl: "./page-configuration.component.html",
  styleUrls: ["./page-configuration.component.scss"],
})
export class PageConfigurationComponent
  extends ConfigurableComponent
  implements OnInit
{
  userName: any;
  pageConfigTypes = PageConfigTypes;
  subscriptions: Subscription[] = [];
  translations = Translations;
  themeDropdownConfig: SelectOption[] = [];
  templateConfig: SelectOption[] = [];
  fontFamilyConfig = FontFamilyConfig;
  selectedTheme: string;
  selectedTemplate: string;
  selectedFont: string;
  config: TemplateConfiguration;
  srcUrl: any;
  isPreviewMode$ = this.portalSvc.isPreviewMode();
  @ViewChild('drawer', { static: true }) public drawer!: MatDrawer;


  constructor(
    private router: Router,
    private userAuthService: UserAuthService,
    private portalSvc: PortalService,
    layerService: LayerService,
    private userSvc: UserService,
    private dialog: MatDialog
  ) {
    super(layerService);
  }

  onConfigurationSave(data: TemplateConfiguration): void {}

  ngOnInit(): void {
    this.getThemeAndTemplatesList();
    this.getPortalObject();
    this.userSvc
      .getUser()
      .pipe(take(1))
      .subscribe((user) => {
        this.userName = user?.name;
      });

      this.drawer.close();
  }

  closeLayer() {
    this.layerService.close();
    return true;
  }

  getThemeAndTemplatesList(): void {
    const sub = this.portalSvc.getThemeAndTemplatesList().subscribe(
      (res) => {
        this.themeDropdownConfig = this.getOptionsArray(
          res.themes.portalthemes
        );
        this.templateConfig = this.getOptionsArray(
          res.templates.portaltemplates,
          true
        );
      }
    );
    this.subscriptions.push(sub);
  }

  getOptionsArray(list: Theme[], isTemplateList = false): SelectOption[] {
    return list.map((listItem) => {
      return {
        name: listItem.name,
        value: isTemplateList ? listItem.value : listItem.name,
        id: listItem._id,
      };
    });
  }

  getPortalObject(): void {
    this.portalSvc.getPortalObj().subscribe((portal) => {
      this.selectedTemplate = portal.template;
      this.selectedFont = portal.fontFamily;
      this.selectedTheme = portal.theme.name;
      this.config = portal.configuration;
    });
  }

  onTemplateChange(): void {
    this.updateConfiguration({
      template: { _id: this.getId(this.selectedTemplate, this.templateConfig) },
    });
  }

  onThemeChange(): void {
    this.updateConfiguration({
      theme: { _id: this.getId(this.selectedTheme, this.themeDropdownConfig) },
    });
  }

  getId(selectedValue: string, arr: SelectOption[]): string {
    return arr.find((option) => option.value === selectedValue).id;
  }

  onFontChange(): void {
    this.updateConfiguration({ fontFamily: this.selectedFont });
  }

  updateConfiguration(payload: any): void {
    const sub = this.portalSvc
      .updateConfigurations(payload)
      .pipe(
        concatMap((configRes) => {
          return this.portalSvc.initializeApp(configRes, true, false);
        })
      )
      .subscribe();
    this.subscriptions.push(sub);
  }

  onPublish(): void {
    this.dialog.open(ConfirmDialogComponent, {
      minWidth: "500px",
      maxWidth: "600px",
      disableClose: true,
    });
  }

  onPreview(): void {
    this.portalSvc.togglePreviewMode();
    if(this.config.enableHomePage){
      this.router.navigate(['/']);
    } else {
      this.router.navigate(['/dashboard'])
    }
  }

  getConfigurationType(): keyof typeof PageConfigTypes {
    return PageConfigTypes.GLOBAL;
  }

  getTemplateConfiguration(): TemplateConfiguration {
    return { ...this.config };
  }

  signout() {
    this.userAuthService.logout(true).subscribe(() => {
      this.router.navigate(["/admin/login"]);
    });
  }

  ngOnDestroy(): void {
    this.subscriptions.forEach((e) => e.unsubscribe());
  }
}
