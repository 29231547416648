import { Component, OnDestroy, OnInit } from '@angular/core';
import { Router, ActivatedRoute } from '@angular/router';
import { AuthenticationService } from '@career/core/services/authentication.service';
import { authComponentDef } from '../../component-definition';
import { PortalService } from '@career/core/services/portal.service';
import { UtilsService } from '@career/core/services/utils.service';
import { ErrorSummary } from '@career/core/models/error.model';
import { TemplateComponent, TemplateConfiguration } from '@career/core/models/component.model';
import { Title } from '@angular/platform-browser';
import { FieldConfig } from '@career/pocket-forms/models/field';
import { Validators } from '@angular/forms';
@Component({
  selector: 'app-login',
  template: `
  <app-base-template [component]="component" [componentDef]="componentDef" [scope]="this" *ngIf="component"></app-base-template>
  `
})
export class LoginComponent implements OnInit, OnDestroy {

  config: TemplateConfiguration;
  component: TemplateComponent;
  id: string;
  portalServiceSubscription;
  email: string;
  alreadyExists: boolean;
  error: ErrorSummary;
  getText: Function;
  loading: boolean;
  componentDef = authComponentDef['login'];
  loginConfig: FieldConfig[];

  constructor(
    private route: ActivatedRoute,
    private router: Router,
    private titleService: Title,
    private authenticationService: AuthenticationService,
    private portalService: PortalService,
    private utils: UtilsService
  ) {}
  register() {
    if(this.id) {
      this.router.navigate(['/register/'+this.id]);
      return;
    }
    this.router.navigate(['/register']);
  }

  forgotPassword(): void {
    this.router.navigate(['/forgotPassword']);
  }

  submitForm($event): void {
    this.loading = true;
      this.authenticationService.login($event).subscribe(
        (res) => {
          if(this.id) {
            this.router.navigate(['/apply/'+this.id]);
            return;
          }
          this.router.navigate(['/dashboard']);
        },
        (err) => {
          this.loading = false;
          switch(err.status) {
            case 404:
              this.error = new ErrorSummary(this.getText("WRONG_USER_OR_PASS"), `Error Code: ${err.status}`);
              break;
            default:
              this.error = new ErrorSummary(this.getText("UNKNOWN_ISSUE"), `Error Code: ${err.status}`);
          }
        }
      );
  }

  initLoginConfig() {
    this.loginConfig = [
      {
        type: "input",
        label: this.getText('EMAIL'),
        inputType: "email",
        autocomplete: "email",
        name: "email",
        value: this.email,
        validations: [
          {
            name: "required",
            validator: Validators.required,
            message: this.getText("EMAIL_REQUIRED")
          }
        ]
      },
      {
        type: "input",
        label: this.getText('PASSWORD'),
        inputType: "password",
        name: "password",
        autocomplete: "new-password",
        validations: [
          {
            name: "required",
            validator: Validators.required,
            message: this.getText("PASSWORD_REQUIRED")
          }
        ]
      },
      {
        type: "button",
        color: 'primary', 
        label: this.getText('LOGIN')
      }
    ];
  }

  ngOnInit() {
    this.portalServiceSubscription = this.portalService.getComponentData('login').subscribe((data) => {
      this.config = data.configuration;
      this.component = data.component;
      this.getText = this.utils.getText(this.component);
      this.id = this.route.snapshot.paramMap.get('id');
      this.email = this.route.snapshot.paramMap.get('email');
      this.alreadyExists = !!this.route.snapshot.paramMap.get('alreadyExists');
      if( this.alreadyExists ) {
        this.error = new ErrorSummary(this.getText('ACCOUNT_ALREADY_EXISTS'), this.getText('ACCOUNT_ALREADY_EXISTS_CAPTION'));
      }
      this.titleService.setTitle(`${this.getText('LOGIN')} | ${this.portalService.getPortalName()}`);
      this.initLoginConfig();
    });
  }

  ngOnDestroy() {
    this.portalServiceSubscription.unsubscribe();
  }

}
