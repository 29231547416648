import { NgModule } from "@angular/core";
import { CommonModule } from "@angular/common";
import { AuthState } from "./store/states/auth.state";
import { NgxsModule } from "@ngxs/store";
import { MatLegacySnackBarModule as MatSnackBarModule } from "@angular/material/legacy-snack-bar";
import { ActivateComponent } from "./components/activate/activate.component";
import { LoginComponent } from "./components/login/login.component";
import { RegisterComponent } from "./components/register/register.component";
import { FormsModule, ReactiveFormsModule } from "@angular/forms";
import { CoreModule } from "../core/core.module";
import { ForgotPasswordComponent } from "./components/forgot-password/forgot-password.component";
import { ResetPasswordComponent } from "./components/reset-password/reset-password.component";
import { DefaultResetPasswordComponent } from "./components/reset-password/templates/default/default-reset-password.component";
import { FontAwesomeModule } from "@fortawesome/angular-fontawesome";
import { MatLegacyAutocompleteModule as MatAutocompleteModule } from "@angular/material/legacy-autocomplete";
import { MatLegacySelectModule as MatSelectModule } from "@angular/material/legacy-select";
import { PocketFormsModule } from "@career/pocket-forms/pocket-forms.module";
import { MatLegacyButtonModule as MatButtonModule } from "@angular/material/legacy-button";
import { MatLegacyProgressBarModule as MatProgressBarModule } from "@angular/material/legacy-progress-bar";
import { DefaultLoginComponent } from "./components/login/templates/default/default-login.component";
import { SocialLoginComponent } from "./components/login/templates/social/social-login.component";
import { DefaultRegisterComponent } from "./components/register/templates/default/default-register.component";
import { SocialRegisterComponent } from "./components/register/templates/social/social-register.component";
import { DefaultForgotPasswordComponent } from "./components/forgot-password/templates/default/default-forgot-password.component";
import { SocialForgotPasswordComponent } from "./components/forgot-password/templates/social/social-forgot-password.component";
import { SocialResetPasswordComponent } from "./components/reset-password/templates/social/social-reset-password.component";

import { MatIconModule } from "@angular/material/icon";
import { MatLegacyFormFieldModule as MatFormFieldModule } from "@angular/material/legacy-form-field";
import { MatLegacyInputModule as MatInputModule } from "@angular/material/legacy-input";
import { AdminLoginComponent } from "./components/admin-login/admin-login.component";
import { MatToolbarModule } from "@angular/material/toolbar";
import { PageConfigurationModule } from "@career/page-configuration/page-configuration.module";

@NgModule({
  declarations: [
    ActivateComponent,
    LoginComponent,
    RegisterComponent,
    ForgotPasswordComponent,
    ResetPasswordComponent,
    DefaultLoginComponent,
    DefaultRegisterComponent,
    DefaultForgotPasswordComponent,
    DefaultResetPasswordComponent,
    SocialLoginComponent,
    SocialRegisterComponent,
    SocialForgotPasswordComponent,
    SocialResetPasswordComponent,
    AdminLoginComponent
  ],
  imports: [
    NgxsModule.forFeature([AuthState]),
    CommonModule,
    PageConfigurationModule,
    FormsModule,
    PocketFormsModule,
    MatAutocompleteModule,
    MatProgressBarModule,
    MatSelectModule,
    FontAwesomeModule,
    MatSnackBarModule,
    MatButtonModule,
    ReactiveFormsModule,
    CoreModule,
    MatIconModule,
    MatFormFieldModule,
    MatInputModule,
    MatToolbarModule
  ],
})
export class AuthenticationModule {}
