import { Component, Input, OnInit } from '@angular/core';
import { inOutAnimation } from '@career/core/animations/fade-in-out';
import { LocaleService } from '@career/core/services/locale.service';

@Component({
  selector: 'app-default-suggestions',
  templateUrl: './default-suggestions.component.html',
  styleUrls: ['./default-suggestions.component.scss'],
  animations: [inOutAnimation]
})
export class DefaultSuggestionsComponent implements OnInit {

  @Input() scope;
  resumeOrCvText = this.localeService.getResumeOrCVText();
  constructor(private localeService: LocaleService) { }

  ngOnInit(): void {
  }

}
