import { HttpClient } from '@angular/common/http';
import { Injectable } from '@angular/core';
import { ActionsAction } from '@career/actions/store/actions/actions.actions';
import { CoreAction } from '@career/core/store/actions/core.actions';
import { UserAction } from '@career/user/store/actions/user.actions';
import { Selector, State, Action, StateContext, Store, NgxsOnInit } from '@ngxs/store';
import { of } from 'rxjs';
import { map, catchError } from 'rxjs/operators';
import { AuthStateModel } from '../../models/auth.model';
import { AuthAction } from '../actions/auth.actions';

@State<AuthStateModel>({
    name: 'auth',
    defaults: {
      token: localStorage.getItem('auth.token'),
      isLoading: true
    }
  })
  @Injectable()
  export class AuthState implements NgxsOnInit {
    constructor(private http: HttpClient, private store: Store){}
    ngxsOnInit(ctx: StateContext<any>): void {
      const token = ctx.getState().token;
      if( !token ) {
        ctx.patchState({isLoading: false});
        return;
      }
      let pop = encodeURIComponent("user|user.latestResume:lastUpdated,filename");
      this.http.get("/api/session/" + token + "?populate=" + pop).pipe(
        map((data: any) => {
          this.store.dispatch(new UserAction.Setup(data.user));
          return data.user;
        }),
        catchError((err) => {
          /*if we can't log in for some reason clear the token and user*/
          this.store.dispatch([
            new AuthAction.ClearToken(),
            new ActionsAction.Clear(),
            new CoreAction.SetAuditToken(),
          ]);
          return of(null);
        }))
        .subscribe(_ => {
          ctx.patchState({isLoading: false});
        });
    }

    @Selector()
    static isLoading(state: AuthStateModel) {
      return state.isLoading;
    }
  
    @Selector()
    static token(state: AuthStateModel) { 
      return state.token;
    }
    
    @Action(AuthAction.Login)
    login(ctx: StateContext<AuthStateModel>, { payload }: AuthAction.Login) {
      ctx.setState({
        ...ctx.getState(),
        ...payload
      })
      localStorage.setItem('auth.token', payload.token);
    }

    @Action(AuthAction.ClearToken)
    clearToken(ctx: StateContext<AuthStateModel>) {
      ctx.setState({
        ...ctx.getState(),
        token: null
      })
      localStorage.removeItem('auth.token');
    }
  }