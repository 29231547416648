import { ChangeDetectionStrategy, Component, OnInit } from '@angular/core';
import { ActivatedRoute, Router } from '@angular/router';
import { UserAuthService } from '@career/core/services/user-auth.service';
import { CandidateService } from '@career/core/services/candidate.service';
import { QuestionnaireService } from '@career/core/services/questionnaire.service';
import { JobService } from '@career/core/services/job.service';
import { UtilsService } from '@career/core/services/utils.service';
import { PortalService } from '@career/core/services/portal.service';
import { from, of, forkJoin, Observable, throwError } from 'rxjs';
import { map, catchError, concatMap, take, switchMap, mergeMap, filter } from 'rxjs/operators';
import { QUESTIONNAIRE_CONSTANTS } from '@career/questionnaires/models/question';
import { Actions, ofActionCompleted, ofActionDispatched, ofActionSuccessful, Store } from '@ngxs/store';
import { ActionsState } from '@career/actions/store/states/actions.state';
import { EActionType, IAction, OpportunityAction } from '@career/actions/models/action.model';
import { ActionTargetService } from '@career/actions/services/action-target.service';
import { ActionsAction } from '@career/actions/store/actions/actions.actions';
import { IWorkflow } from '@career/core/models/workflow.model';
import { ActionsService } from '@career/actions/services/actions-service.service';

@Component({
  templateUrl: './apply.component.html',
  changeDetection: ChangeDetectionStrategy.OnPush
})
export class ApplyComponent implements OnInit {

  source: string = this.route.snapshot.queryParamMap.get("src");
  completed: string = this.route.snapshot.queryParamMap.get("completed");
  constructor(private candidateService: CandidateService, private targetSvc: ActionTargetService, 
  private route: ActivatedRoute, private router: Router, private actionsService: ActionsService, private utils: UtilsService, private portalService: PortalService) { }

  ngAfterViewInit() {
      const id = this.route.snapshot.paramMap.get('id');
      const applicationSubscription = 
      this.candidateService.apply(id).pipe(
        switchMap(() => this.actionsService.getNextIncompleteWorkflowActionsStream(id, this.completed)),
        ).subscribe((action: IAction) => {
          if( action && action.target) {
            this.targetSvc.process(action);
          } else {
            // this.router.navigate(['/register/'+id]);
            this.router.navigate(['/dashboard']);
          }
          this.actionsService.load();
          applicationSubscription.unsubscribe();
        },
        (err) => {
          this.router.navigate(['/register/'+id]);
        }
      );
  }

  ngOnInit() {

  }

}