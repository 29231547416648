import { Component, EventEmitter, Input, OnDestroy, OnInit, Output } from '@angular/core';
import { UntypedFormControl } from '@angular/forms';
import { ActionsService } from '@career/actions/services/actions-service.service';
import { TemplateComponent, TemplateConfiguration } from '@career/core/models/component.model';
import { ErrorSummary } from '@career/core/models/error.model';
import { CandidateService } from '@career/core/services/candidate.service';
import { PortalService } from '@career/core/services/portal.service';
import { UserService } from '@career/core/services/user.service';
import { UtilsService } from '@career/core/services/utils.service';
import { userComponentDef } from '@career/user/component-definition';
import { Subscription, Observable, of } from 'rxjs';
import { catchError, filter, map, switchMap, take } from 'rxjs/operators';

@Component({
  selector: 'app-manage-skills',
  template: `
  <app-base-template [component]="component" [componentDef]="componentDef" [scope]="this" *ngIf="component"></app-base-template>
  `
})
export class ManageSkillsComponent implements OnInit, OnDestroy {
  @Output() onUpdate = new EventEmitter<boolean>();
  @Input() skills: {_id: string, skill: string}[];
  component: TemplateComponent;
  config: TemplateConfiguration;
  getText: Function;
  portalServiceSubscription: Subscription;
  componentDef = userComponentDef['manage-skills'];
  candidateSkills$: Observable<string[]>;
  loading: boolean;
  updated: boolean;
  error: ErrorSummary;
  skillsControl = new UntypedFormControl([]);
  constructor(private portalService: PortalService, private actionsService: ActionsService, private candidateService: CandidateService, private utils: UtilsService, private userSvc : UserService) { }

  loadCandidateSkills() {
    return this.userSvc.getUser().pipe(
      map(user => user.skills),
      map(skills => (skills || []).map(a => this.skills.find(b => a._id === b._id)).filter(skill => !!skill)),
      map(skills => {
        this.skillsControl.setValue(skills);
        return skills;
      }),
      catchError(err => {
        this.error = new ErrorSummary(this.getText('ERROR_LOADING'), `Error Code: ${err.status}`);
        return of(null);
      })
    );
  }

  ngOnInit(): void {
    this.portalServiceSubscription = this.portalService.getComponentData('manage-skills').subscribe((data) => {
      this.component = data.component;
      this.config = data.configuration;
      this.getText = this.utils.getText(this.component);
      this.candidateSkills$ = this.loadCandidateSkills();
    });
  }

  ngOnDestroy(): void {
    this.portalServiceSubscription.unsubscribe();
  }

  onSkillRemoved(skillObj) {
    const skills = this.skillsControl.value;
    this.skillsControl.setValue(skills.filter(s => s._id !== skillObj._id)); // To trigger change detection
  }
  
  save() {
    this.loading = true;
    this.updated = false;
    this.candidateSkills$ = this.userSvc.getUser().pipe(
      switchMap(user => {
        return this.candidateService.setSkills(this.skillsControl.value.map(v => {
          return {_id: v._id};
        }), user._id);
      }),
      catchError(err => {
        this.error = new ErrorSummary(this.getText('ERROR_SAVING'), `Error Code: ${err.status}`);
        return of(null);
      }),
      filter(data => data),
      switchMap(() => {
        this.loading = false;
        this.updated = true;
        this.onUpdate.next(true);
        return this.loadCandidateSkills()
      })
    );
  }
}
