<ng-container [ngSwitch]="scope.status">
    <ng-container *ngSwitchDefault [ngTemplateOutlet]="PENDING"></ng-container>
    <ng-container *ngSwitchCase="'UPLOADING'" [ngTemplateOutlet]="UPLOADING"></ng-container>
    <ng-container *ngSwitchCase="'COMPLETED'" [ngTemplateOutlet]="COMPLETED"></ng-container>
</ng-container>
<ng-template #PENDING>
    <div class="suggestions">
        <app-error-summary [error]="scope.error" *ngIf="scope.error"></app-error-summary>
        <div class="suggestions__container"  [@inOutAnimation]>
            <div class="suggestions__heading-container" [@inOutAnimation]>
                <h1 class="suggestions__heading" [innerText]="scope.getText | transform:'SUGGESTIONS_HEADING':{resumeOrCv: resumeOrCvText}"></h1>
                <p class="suggestions__text" [innerText]="scope.getText | transform:'SUGGESTIONS_DESCRIPTION'"></p>
            </div>
            <div class="suggestions__upload">
                <app-upload-file (uploadEvent)="scope.onUpload($event)" id="suggestions-upload" class="app-upload-file"></app-upload-file>
            </div>
        </div>
    </div>
</ng-template>

<ng-template #UPLOADING>
    <div class="suggestions">
        <div class="suggestions__container" [@inOutAnimation]>
            <div class="suggestions__upload suggestions__upload--uploading">
                <mat-progress-bar mode="indeterminate"></mat-progress-bar>
                <span class="suggestions__uploading-status" tabindex="0">                
                    {{scope.uploadStatus}}
                </span>
            </div>
        </div>
    </div>
</ng-template>

<ng-template #COMPLETED>
    <div class="suggestions__container"  [@inOutAnimation]>
        <div class="suggestions__heading-container suggestions__heading-container--results" [@inOutAnimation]>
            <h1 *ngIf="scope.suggestions?.length > 1" class="suggestions__heading" [innerText]="scope.getText | transform:'WE_FOUND_MULTIPLE_RESULTS':{number: scope.suggestions.length}"></h1>
            <h1 *ngIf="scope.suggestions?.length === 1" class="suggestions__heading" [innerText]="scope.getText | transform:'WE_FOUND_ONE_RESULT'"></h1>
            <h1 *ngIf="!scope.suggestions?.length" class="suggestions__heading" [innerText]="scope.getText | transform:'WE_FOUND_NO_MATCHING_RESULTS'"></h1>
        </div>
        <div class="suggestions__results" [@inOutAnimation] *ngIf="scope.suggestions">
            <app-data-card *ngFor="let sugg of scope.suggestions" [match]="sugg" class="match"></app-data-card>
        </div>
        <div class="suggestions__restart">
            <p class="suggestions__text" [innerText]="scope.getText | transform:'WANT_DIFFERENT_RESULTS'"></p>
            <button (click)="scope.restart()" mat-stroked-button class="button" [innerText]="scope.getText | transform:'UPLOAD_A_DIFFERENT_RESUME'" ></button>
        </div>
    </div>
</ng-template>