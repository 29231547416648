<loading-component *ngIf="scope.loading"></loading-component>
<app-error-summary [error]="scope.error"></app-error-summary>
<ng-container *ngIf="scope.candidateSkills$ | async">
    <div #banner>
        <div class="banner" *ngIf="scope.updated">
            <h2 class="banner__text" [innerText]="scope.getText | transform:'SKILLS_UPDATED'"></h2>
        </div>
    </div>
    <div class="instructions">
        <p [innerText]="scope.getText | transform:'CLICK_BELOW_TO_UPDATE_SKILLS'"></p>
    </div>
    <form (submit)="scope.save()" >
        <mat-form-field>
            <mat-label [innerText]="scope.getText | transform:'SKILLS'"></mat-label>
            <mat-select [formControl]="scope.skillsControl" multiple>
            <mat-select-trigger>
                <mat-chip-list>
                  <mat-chip *ngFor="let skillObj of scope.skillsControl.value"
                    [removable]="true" (removed)="scope.onSkillRemoved(skillObj)">
                    {{ skillObj.value }}
                    <mat-icon matChipRemove><fa-icon [icon]="faTimesCircle"></fa-icon></mat-icon>
                  </mat-chip>
                </mat-chip-list>
              </mat-select-trigger>
              <mat-option *ngFor="let skillObj of scope.skills" [value]="skillObj">{{skillObj.value}}</mat-option>
            </mat-select>
        </mat-form-field>
        <button mat-flat-button color="primary" class="button" type="submit" [innerText]="scope.getText | transform:'SAVE'"></button>
    </form>
</ng-container>