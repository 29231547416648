import { Injectable, ViewContainerRef, ComponentFactoryResolver, Injector, ComponentFactory } from '@angular/core';
import { COMPONENT_REF } from '../constants/components.constant';
@Injectable({
  providedIn: 'root'
})
export class TemplateService {

  constructor(private componentFactoryResolver: ComponentFactoryResolver) { }

  loadTemplateComponent(templateName: string, componentDef: object, templateRef: ViewContainerRef): {[scope: string]: any} {
    if(componentDef[templateName]) {
      const factory = this.componentFactoryResolver.resolveComponentFactory(componentDef[templateName]);
      if(factory) {
        const componentRef = templateRef.createComponent(factory);
        return componentRef.instance;
      }
      throw new Error("Cannot find componentDef in component factory");
    } else {
      throw new Error("Cannot find template in componentDef");
    }
  }
}
