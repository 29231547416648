<div class="data-with-image data-with-image--min-height">
    <div class="data-with-image__img-container" appImgEditContainer="reset-password" name="Main Image">
        <img class="data-with-image__img" [src]="scope.component.assets.image" [alt]="scope.getText | transform:'RESET_YOUR_PASSWORD'">
    </div>
    <div class="data-with-image__text-container">
        <app-error-summary [error]="scope.error" *ngIf="scope.error"></app-error-summary>
        <div [ngClass]="{'disabled--loading': scope.loading}">
            <h1 [innerText]="scope.getText | transform:'RESET_YOUR_PASSWORD'" class="data-with-image__heading data-with-image__heading--bold"></h1>
            <p class="data-with-image__text" [innerText]="scope.getText | transform:'ENTER_NEW_PASSWORD_BELOW'"></p>  
            <dynamic-form (submit)="scope.submitForm($event)" [groupValidators]="scope.groupValidators" [fields]="scope.resetPasswordConfig"></dynamic-form>
        </div>
    </div>
</div>