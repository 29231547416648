import { SelectionModel } from "@angular/cdk/collections";
import {
  Component,
  EventEmitter,
  OnDestroy,
  OnInit,
  Output,
} from "@angular/core";
import {
  TemplateComponent,
  TemplateConfiguration,
} from "@career/core/models/component.model";
import { ErrorSummary } from "@career/core/models/error.model";
import { CandidateService } from "@career/core/services/candidate.service";
import { LocaleService } from "@career/core/services/locale.service";
import { PortalService } from "@career/core/services/portal.service";
import { UserService } from "@career/core/services/user.service";
import { UtilsService } from "@career/core/services/utils.service";
import { userComponentDef } from "@career/user/component-definition";
import { CalendarEvent, CalendarView } from "angular-calendar";
import {
  addDays,
  addMonths,
  addWeeks,
  endOfDay,
  endOfMonth,
  endOfWeek,
  startOfDay,
  startOfMonth,
  startOfWeek,
  subDays,
  subMonths,
  subWeeks,
} from "date-fns";
import { Subject, Subscription } from "rxjs";
import { map, switchMap, take } from "rxjs/operators";
import { v4 as uuidv4 } from "uuid";

type CalendarPeriod = "day" | "week" | "month";
function addPeriod(period: CalendarPeriod, date: Date, amount: number): Date {
  return {
    day: addDays,
    week: addWeeks,
    month: addMonths,
  }[period](date, amount);
}

function subPeriod(period: CalendarPeriod, date: Date, amount: number): Date {
  return {
    day: subDays,
    week: subWeeks,
    month: subMonths,
  }[period](date, amount);
}

function startOfPeriod(period: CalendarPeriod, date: Date): Date {
  return {
    day: startOfDay,
    week: startOfWeek,
    month: startOfMonth,
  }[period](date);
}

function endOfPeriod(period: CalendarPeriod, date: Date): Date {
  return {
    day: endOfDay,
    week: endOfWeek,
    month: endOfMonth,
  }[period](date);
}

@Component({
  selector: "app-availability",
  template: `
    <app-base-template
      [component]="component"
      [componentDef]="componentDef"
      [scope]="this"
      *ngIf="component"
    ></app-base-template>
  `,
})
export class AvailabilityComponent implements OnInit, OnDestroy {
  @Output() onUpdate = new EventEmitter<boolean>();
  component: TemplateComponent;
  config: TemplateConfiguration;
  getText: Function;
  portalServiceSubscription: Subscription;
  componentDef = userComponentDef["availability"];
  error: ErrorSummary;
  viewDate: Date = new Date();
  events: CalendarEvent[] = [];
  view: CalendarView = CalendarView.Month;
  minDate: Date = addDays(new Date(), 1);
  prevBtnDisabled: boolean = false;
  nextBtnDisabled: boolean = false;
  refresh = new Subject<void>();
  selection = new SelectionModel<CalendarEvent>(true, []);
  displayedColumns = ["select", "start", "end", "period"];
  loading: boolean;
  updated: boolean;

  constructor(
    private portalService: PortalService,
    private localeService: LocaleService,
    private candidateService: CandidateService,
    private utils: UtilsService,
    private userSvc: UserService
  ) {}

  getEndPickerId(id) {
    return `end-${id}`;
  }

  getStartPickerId(id) {
    return `start-${id}`;
  }

  deleteDate() {
    this.update(
      this.events.filter(
        (e) => !this.selection.selected.some((s) => s.id === e.id)
      )
    );
  }

  sortEvents(events: CalendarEvent[]) {
    return events.sort(
      (a, b) =>
        ((a.start && a.start.getTime()) || -1) -
        ((b.start && b.start.getTime()) || -1)
    );
  }

  addDate() {
    const current =
      this.events[this.events.length - 1] &&
      this.events[this.events.length - 1].start;
    const start = addDays(current || new Date(), 1);
    const end = addDays(current || new Date(), 7);
    this.events = this.sortEvents([
      ...this.events,
      this.buildEvent(start, end, 8),
    ]);
  }

  /*
   * allow dates where the date is in the current event's range
   * the date is not in any other event's range
   * it's an end date and the current events start is less than the other event's start
   */
  pickerFilter(event: CalendarEvent, scope, end?: boolean) {
    return (date: Date) => {
      if (!date || !event.start || !event.end) {
        return true;
      }

      if (
        event.start.getMonth() === date.getMonth() &&
        date.getDate() >= event.start.getDate() &&
        date.getDate() <= event.end.getDate()
      ) {
        return true;
      }

      return (scope.events || [])
        .filter((e) => e !== event)
        .every(
          (e) =>
            e.start.getMonth() !== date.getMonth() ||
            date.getDate() < e.start.getDate() ||
            (date.getDate() > e.end.getDate() &&
              (!end || event.start.getDate() > e.end.getDate()))
        );
    };
  }

  refreshCalendar($event = null) {
    if ($event && $event.value) {
      this.changeDate($event.value);
    }
    this.refresh.next();
  }

  dateIsValid(date: Date): boolean {
    return date >= this.minDate;
  }

  increment(): void {
    this.changeDate(addPeriod(this.view, this.viewDate, 1));
  }

  decrement(): void {
    this.changeDate(subPeriod(this.view, this.viewDate, 1));
  }

  changeDate(date: Date): void {
    this.viewDate = date;
    this.dateOrViewChanged();
  }

  dateOrViewChanged(): void {
    this.prevBtnDisabled = !this.dateIsValid(
      endOfPeriod(this.view, subPeriod(this.view, this.viewDate, 1))
    );
    this.nextBtnDisabled = !this.dateIsValid(
      startOfPeriod(this.view, addPeriod(this.view, this.viewDate, 1))
    );
    if (this.viewDate < this.minDate) {
      this.changeDate(this.minDate);
    }
  }

  ngOnInit(): void {
    this.portalServiceSubscription = this.portalService
      .getComponentData("availability")
      .subscribe((data) => {
        this.component = data.component;
        this.config = data.configuration;
        this.getText = this.utils.getText(this.component);
        this.dateOrViewChanged();
        this.load();
      });
  }

  ngOnDestroy(): void {
    this.portalServiceSubscription.unsubscribe();
  }

  buildEventsDTO(events) {
    return events
      .filter((e) => e.start && e.end)
      .map((e) => {
        return {
          from: new Date(e.start).getTime(),
          to: new Date(e.end).getTime(),
          hoursPerDay: e.allDay ? 8 : 4,
        };
      });
  }

  buildEvent(start: Date, end: Date, hoursPerDay: number): CalendarEvent {
    return {
      title: "Available",
      id: uuidv4(),
      start: start,
      end: end,
      allDay: hoursPerDay >= 8,
      color: {
        primary: "#EA4335",
        secondary: "#EA4335",
      },
    };
  }

  createEvents(
    data: { from: number; to: number; hoursPerDay: number }[]
  ): CalendarEvent[] {
    return data.map((e) =>
      this.buildEvent(new Date(e.from), new Date(e.to), e.hoursPerDay)
    );
  }

  validate(events: CalendarEvent[]): boolean {
    this.error = null;
    const seen = {};
    for (const event of events) {
      event.meta = {};
      if (!event.start || !event.end) {
        this.error = new ErrorSummary(
          this.getText("PLEASE_SELECT_A_DATE_USING_THE_DATEPICKER"),
          this.getText("PLEASE_SELECT_USING_DATEPICKER_DESC")
        );
        event.meta = {
          error: this.getText("SELECT_DATE_USING_ICON"),
          startInvalid: !event.start,
          endInvalid: !event.end,
        };
        return false;
      }

      let startKey = `s-${event.start.getMonth()}-${event.start.getDate()}`;
      let endKey = `e-${event.start.getMonth()}-${event.start.getDate()}`;
      if (startOfDay(event.start) > startOfDay(event.end)) {
        this.error = new ErrorSummary(
          this.getText("START_IS_GREATER_THAN_END", {
            start: this.localeService.getLocalDateStr(event.start),
            end: this.localeService.getLocalDateStr(event.end),
          })
        );
        event.meta.startInvalid = true;
      } else if (seen[startKey]) {
        this.error = new ErrorSummary(
          this.getText("START_USED_MORE_THAN_ONCE", {
            date: this.localeService.getLocalDateStr(event.start),
          })
        );
        event.meta.startInvalid = true;
      } else if (seen[endKey]) {
        event.meta.endInvalid = true;
        this.error = new ErrorSummary(
          this.getText("END_USED_MORE_THAN_ONCE", {
            date: this.localeService.getLocalDateStr(event.end),
          })
        );
      }

      if (this.error) {
        event.meta = { error: this.getText("PLEASE_ENTER_A_VALID_DATE") };
        return false;
      }

      seen[startKey] = true;
      seen[endKey] = true;
    }
    return true;
  }

  save() {
    this.update(this.events);
  }

  update(events) {
    if (this.loading) {
      return;
    }
    this.updated = false;
    this.loading = true;
    if (!this.validate(events)) {
      this.loading = false;
      return;
    }
    this.userSvc
      .getUser()
      .pipe(
        switchMap((user) => {
          return this.candidateService.setAvailability(
            this.buildEventsDTO(events),
            user._id
          );
        }),
        map((user) => this.createEvents(user.availableDates)),
        take(1)
      )
      .subscribe((data) => {
        this.events = this.sortEvents(data);
        this.selection.clear();
        this.refreshCalendar();
        this.loading = false;
        this.updated = true;
        this.onUpdate.next(true);
      });
  }

  load() {
    this.userSvc
      .getUser()
      .pipe(
        map((user) => this.createEvents(user.availableDates || [])),
        take(1)
      )
      .subscribe((data) => {
        this.events = this.sortEvents(data);
        this.refreshCalendar();
      });
  }

  /** Whether the number of selected elements matches the total number of rows. */
  isAllSelected() {
    const numSelected = this.selection.selected.length;
    const numRows = this.events.length;
    return numSelected === numRows;
  }

  /** Selects all rows if they are not all selected; otherwise clear selection. */
  toggleAllRows() {
    if (this.isAllSelected()) {
      this.selection.clear();
      return;
    }

    this.selection.select(...this.events);
  }

  /** The label for the checkbox on the passed row */
  checkboxLabel(event?: CalendarEvent): string {
    if (!event) {
      return `${
        this.isAllSelected()
          ? this.getText("DESELECT_ALL")
          : this.getText("SELECT_ALL")
      }`;
    }
    if (this.selection.isSelected(event)) {
      return this.getText("SELECT_TIME_RANGE", {
        start: event.start && this.localeService.getLocalDateStr(event.start),
        end: event.end && this.localeService.getLocalDateStr(event.end),
      });
    }
    return this.getText("DESELECT_TIME_RANGE", {
      start: event.start && this.localeService.getLocalDateStr(event.start),
      end: event.end && this.localeService.getLocalDateStr(event.end),
    });
  }
}
