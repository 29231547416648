import { Injectable, Injector, ProviderToken } from '@angular/core';

@Injectable({
  providedIn: 'root'
})
//if this gets too big can break out into individual data source services
export class ActionDataSourceService {
  private appInjector: Injector;
  constructor(private injector: Injector) {
    this.appInjector = injector;
  }

  get(token: ProviderToken<any>) {
    return this.appInjector.get(token);
  }
}
