import { MatLegacyDialog as MatDialog } from '@angular/material/legacy-dialog';
import { Observable } from 'rxjs';
import { map, take } from 'rxjs/operators';
import { ConfirmationDialogComponent } from '../components/confirmation-dialog/confirmation-dialog.component';

export interface ConfirmationDialogButtons {
    confirmButtonText: string;
    cancelButtonText?: string;
}

export class ConfirmationDialog {
    dialog;
    constructor(dialog: MatDialog) {
        this.dialog = dialog;
    }

    confirm(title, message, buttonText: ConfirmationDialogButtons, passThroughData?: any): Observable<any> {
        const opened = this.dialog.open(ConfirmationDialogComponent, {
            maxWidth: '750px',
            width: '90vw',
            maxHeight: '90vh',
            data: {
                title: title,
                message: message,
                buttonText: buttonText
            }
        }).afterClosed().pipe(
            map(resp => {
                return resp && (passThroughData || resp);
            }),
            take(1)
        );

        return opened;
    }
}
