import { ChangeDetectorRef, Component, OnInit } from "@angular/core";
import { MatLegacyDialog as MatDialog } from "@angular/material/legacy-dialog";
import { VALID_FILE_UPLOAD_TYPES } from "@career/core/constants/file.constant";
import {
  TemplateComponent,
  TemplateConfiguration,
} from "@career/core/models/component.model";
import { ConfirmationDialog } from "@career/core/models/confirmation-dialog";
import { ErrorSummary } from "@career/core/models/error.model";
import { AttachmentService } from "@career/core/services/attachment.service";
import { LocaleService } from "@career/core/services/locale.service";
import { PortalService } from "@career/core/services/portal.service";
import { UserService } from "@career/core/services/user.service";
import { UtilsService } from "@career/core/services/utils.service";
import { userComponentDef } from "@career/user/component-definition";
import { OrderedSubject } from "@ngxs/store/src/actions-stream";
import { Observable, of, ReplaySubject, Subject, Subscription } from "rxjs";
import { filter, map, switchMap, take } from "rxjs/operators";

@Component({
  selector: "app-manage-files",
  template: `
    <app-base-template
      [component]="component"
      [componentDef]="componentDef"
      [scope]="this"
      *ngIf="component"
    ></app-base-template>
  `,
})
export class ManageFilesComponent implements OnInit {
  component: TemplateComponent;
  config: TemplateConfiguration;
  portalServiceSubscription: Subscription;
  componentDef = userComponentDef["manage-files"];
  attachmentsSubject$: Subject<any> = new ReplaySubject(1);
  attachments$: Observable<any> = this.attachmentsSubject$.asObservable();
  getText: Function;
  error: ErrorSummary;
  totalFiles: number;
  loading: boolean;

  constructor(
    private portalService: PortalService,
    private utils: UtilsService,
    private dialog: MatDialog,
    private attachmentService: AttachmentService,
    private userSvc: UserService,
    private changeDetectorRef: ChangeDetectorRef,
    public localeService: LocaleService
  ) {}

  selected(file) {
    return new ConfirmationDialog(this.dialog)
      .confirm(
        this.getText("CONFIRM_FILE_DOWNLOAD", { fileName: file.fileName }),
        null,
        {
          cancelButtonText: this.getText("CANCEL"),
          confirmButtonText: this.getText("CONFIRM_DOWNLOAD"),
        }
      )
      .pipe(
        switchMap((success) => {
          if (!success) {
            return of(null);
          }
          return this.attachmentService.downloadFile(file._id);
        }),
        take(1)
      )
      .subscribe(
        (data) => {
          this.attachmentService.extractBlob(data, file);
        },
        (err) => {
          this.error = new ErrorSummary(
            this.getText("UNKNOWN_ISSUE"),
            `Error Code: ${err.status}`
          );
        }
      );
  }

  remove(file) {
    return new ConfirmationDialog(this.dialog)
      .confirm(
        this.getText("CONFIRM_REMOVE_FILE", { fileName: file.fileName }),
        null,
        {
          cancelButtonText: this.getText("CANCEL"),
          confirmButtonText: this.getText("CONFIRM_REMOVE"),
        }
      )
      .pipe(
        switchMap((success) => {
          if (!success) {
            return of(null);
          }
          return this.attachmentService.deleteFile(file._id);
        }),
        take(1)
      )
      .subscribe(
        (data) => {
          if (!data) {
            return;
          }
          this.attachmentsSubject$.next();
        },
        (err) => {
          this.loading = false;
          this.error = new ErrorSummary(
            this.getText("UNKNOWN_ISSUE"),
            `Error Code: ${err.status}`
          );
        }
      );
  }

  uploadFile(event) {
    this.error = null;
    if (this.totalFiles > 10) {
      this.error = new ErrorSummary(
        this.getText("TOO_MANY_FILES_UPLOADED", { max: 10 })
      );
      return;
    }

    let file;
    if (event.target) {
      file = event.target.files[0] || null;
    } else {
      file = event[0];
    }
    if (VALID_FILE_UPLOAD_TYPES.includes(file.type)) {
      this.loading = true;
      this.userSvc
        .getUser()
        .pipe(
          filter((user) => user && user._id),
          switchMap((user) => this.attachmentService.uploadFile(file, user._id))
        )
        .subscribe(
          () => {
            this.attachmentsSubject$.next();
          },
          (err) => {
            this.loading = false;
            if( err.error?.message === "unsupported file extension" ) {
              this.error = new ErrorSummary(
                this.getText("INVALID_FILE_TYPE"),
                `Error Code: ${err.status}`
              );
            } else if( err.status === 413 ) {
              this.error = new ErrorSummary(
                this.getText("FILE_TOO_LARGE"),
                `Error Code: ${err.status}`
              );
            } else if( err.error?.message.includes("duplicate key") ) {
              this.error = new ErrorSummary(
                this.getText("DUPLICATE_FILE"),
                `Error Code: ${err.status}`
              );
            } else {
              this.error = new ErrorSummary(
                this.getText("UNKNOWN_ISSUE"),
                `Error Code: ${err.status}`
              );
            }
            this.changeDetectorRef.markForCheck();
          }
        );
    } else {
      //wrong file type
      this.error = new ErrorSummary(this.getText("INVALID_FILE_TYPE"));
      return;
    }
  }

  getDate(date, scope) {
    return scope.localeService.getLocalDateStr(date);
  }

  ngOnInit(): void {
    this.portalServiceSubscription = this.portalService
      .getComponentData("manage-files")
      .subscribe((data) => {
        this.component = data.component;
        this.config = data.configuration;
        this.getText = this.utils.getText(this.component);
        /*
        * TODO: this isn't great...especially with the whole this.loading thing everywhere. Revisit this later
        */
        this.attachments$ = this.attachmentsSubject$.asObservable().pipe(
          switchMap(_ => this.userSvc.getUser()),
          filter((user) => user && user._id),
          switchMap(user => {
            return this.attachmentService.getAttachments(user._id, "candidate")
          }),
          map((data) => {
            this.loading = false;
            this.totalFiles =
              (data && data.attachments && data.attachments.length) || 0;
            return data && data.attachments;
          })
        );
        this.attachmentsSubject$.next();
      });
  }
}
