<mat-toolbar class="page__config-toolbar" *ngIf="!(isPreviewMode$ | async)">
  <div class="nav__container">
    <div class="nav__container__left-section">
      <button mat-button (click)="closeLayer() && drawer.toggle()">
        <mat-icon>menu</mat-icon><span class="nav__container__left-section-config">Page Configuration</span>
      </button>
      <button mat-button class="nav__container__left-section-config" (click)="onPreview()">Preview</button>
      <button mat-button class="nav__container__left-section-config" (click)="openConfiguration()">
        {{ translations.GLOBAL_CONFIG }} </button>
    </div>
    <div class="nav__container__right-section">
      <div class="nav__template_font-section">
        <mat-form-field appearance="outline">
          <mat-label>{{translations.TEMPLATE}}</mat-label>
          <mat-select [(ngModel)]="selectedTemplate" (selectionChange)="onTemplateChange()">
            <mat-option *ngFor="let template of templateConfig" [value]="template.value">
              {{template.name}}
            </mat-option>
          </mat-select>
        </mat-form-field>
        <mat-form-field appearance="outline">
          <mat-label>{{translations.THEME}}</mat-label>
          <mat-select [(ngModel)]="selectedTheme" (selectionChange)="onThemeChange()">
            <mat-option *ngFor="let theme of themeDropdownConfig" [value]="theme.value">
              {{theme.name}}
            </mat-option>
          </mat-select>
        </mat-form-field>
        <mat-form-field appearance="outline">
          <mat-label>{{translations.FONT_FAMILY}}</mat-label>
          <mat-select [(ngModel)]="selectedFont" (selectionChange)="onFontChange()">
            <mat-option *ngFor="let font of fontFamilyConfig" [value]="font.value">
              {{font.name }}
            </mat-option>
          </mat-select>
        </mat-form-field>
      </div>
      <button mat-button>{{userName?.firstName}} {{userName?.lastName}}</button>
      <button mat-button (click)="signout()">Sign Out</button>
    </div>
  </div>
</mat-toolbar>

<mat-drawer-container>
  <mat-drawer #drawer mode="side">
    <app-page-config-side-nav></app-page-config-side-nav>
  </mat-drawer>
  <ng-container *ngTemplateOutlet="routerOutlet"></ng-container>
</mat-drawer-container>

<ng-template #routerOutlet>
  <div>
    <router-outlet></router-outlet>
  </div>
</ng-template>