import { Injectable } from "@angular/core";
import { CanActivate, Router } from "@angular/router";
import { Store } from "@ngxs/store";
import {  map, take } from "rxjs/operators";
import { UserService } from "@career/core/services/user.service";

@Injectable({
  providedIn: "root",
})
export class AuthGuard implements CanActivate {
  isAdmin = false;
  constructor(
    private store: Store,
    private router: Router,
    private userSvc : UserService
  ) {
    this.userSvc.isAdmin().subscribe((res) => {
      this.isAdmin = res;
    });
  }

  canActivate() {
    return this.userSvc.getUser(true).pipe(
      map((user) => {
        if( !user ) {
          this.router.navigate(['/']);
          return false;
        }
        return true;
      })
    );
  }
}
