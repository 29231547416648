import { State, Action, StateContext, Selector, Store } from "@ngxs/store";
import { CoreAction } from "../actions/core.actions";
import { CoreStateModel } from "../../models/core.model";
import { EPortalSourceTypes } from "../../constants/portal-source-types";
import { v4 as uuidv4 } from "uuid";
import { Injectable } from "@angular/core";

const AUDIT_TOKEN_NAME = "auditToken";
@State<CoreStateModel>({
  name: "core",
  defaults: {
    portal: JSON.parse(sessionStorage.getItem("core.portal")) || null,
    source:
      JSON.parse(localStorage.getItem("source")) || EPortalSourceTypes.PORTAL,
    auditToken: sessionStorage.getItem(AUDIT_TOKEN_NAME),
    images: {},
    routes: {authenticatedRoutes: [], unauthenticatedRoutes: []}
  },
})
@Injectable()
export class CoreState {
  constructor() {}

  @Selector()
  static getLocale(state: CoreStateModel): string {
    return this.getPortalObj(state).localization;
  }

  @Selector()
  static isPreferencesEnabled(state: CoreStateModel) {
    return this.getPortalObj(state).preferences;
  }

  @Selector()
  static routes(state: CoreStateModel) {
    return state.routes;
  }

  @Selector()
  static getAdminImages(state: CoreStateModel) {
    return (type: string) => {
      return state.images[type];
    };
  }

  @Selector()
  static getWorkflow(state: CoreStateModel) {
    return (id: string) => {
      return state.workflows ? state.workflows[id] : null;
    };
  }

  @Selector()
  static getPortalId(state: CoreStateModel) {
    return this.getPortalObj(state).portalId;
  }

  @Selector()
  static getPortalTemplate(state: CoreStateModel) {
    return this.getPortalObj(state).template;
  }

  @Selector()
  static getPortalLocalization(state: CoreStateModel) {
    return this.getPortalObj(state).localization;
  }

  @Selector()
  static getPortalName(state: CoreStateModel) {
    return this.getPortalObj(state).portalName;
  }

  @Selector()
  static getPortalObj(state: CoreStateModel) {
    return state.isAdmin ? state.previewPortal : state.portal;
  }

  @Selector()
  static getSource(state: CoreStateModel) {
    return state.source;
  }

  @Selector()
  static getAuditToken(state: CoreStateModel) {
    return state.auditToken;
  }

  @Action(CoreAction.Bootstrap)
  bootstrap(ctx: StateContext<CoreStateModel>, { payload }: any) {
    if (payload.isAdmin) {
      ctx.setState({
        ...ctx.getState(),
        previewPortal: payload.payload,
        isAdmin: true,
      });
    } else {
      ctx.setState({
        ...ctx.getState(),
        portal: payload.payload,
        isAdmin: false,
      });
    }
  }

  @Action(CoreAction.UpdateComponent)
  updateComponentData(ctx: StateContext<CoreStateModel>, { component }) {
    const currentState = ctx.getState();
    ctx.setState({
      ...currentState,
      previewPortal: {
        ...currentState.previewPortal,
        components: {
          ...currentState.previewPortal.components,
          [component.key]: component.value,
        },
      },
    });
    return ctx.getState();
  }

  @Action(CoreAction.SetRoutes)
  setRoutes(ctx: StateContext<CoreStateModel>, { routes }) {
    ctx.patchState({routes: routes});
  }

  @Action(CoreAction.DeleteComponent)
  deleteComponentData(ctx: StateContext<CoreStateModel>, { component }) {
    const currentState = ctx.getState();
    delete currentState.previewPortal.components[component.pathName];
    return ctx.getState();
  }

  @Action(CoreAction.UpdateConfiguration)
  updateConfigData(ctx: StateContext<CoreStateModel>, { config }: any) {
    ctx.setState({
      ...ctx.getState(),
      previewPortal: {
        ...ctx.getState().previewPortal,
        configuration: config,
      },
    });
  }

  @Action(CoreAction.AdminLogout)
  clearAdminPreviewData(ctx: StateContext<CoreStateModel>) {
    const getState = ctx.getState();
    delete getState?.previewPortal;
    getState.isAdmin = false;
    ctx.setState({
      ...getState,
    });
  }

  @Action(CoreAction.SetAuditToken)
  setAuditToken(ctx: StateContext<CoreStateModel>) {
    let token = sessionStorage.getItem(AUDIT_TOKEN_NAME);
    if (token) {
      return;
    }

    token = uuidv4();
    sessionStorage.setItem(AUDIT_TOKEN_NAME, token);
    ctx.setState({
      ...ctx.getState(),
      auditToken: token,
    });
  }

  @Action(CoreAction.ClearAuditToken)
  clearAuditToken(ctx: StateContext<CoreStateModel>) {
    sessionStorage.removeItem(AUDIT_TOKEN_NAME);
    ctx.setState({
      ...ctx.getState(),
      auditToken: null,
    });
  }

  @Action(CoreAction.SetSource)
  setSource(
    ctx: StateContext<CoreStateModel>,
    { payload }: CoreAction.SetSource
  ) {
    const source = payload.source || EPortalSourceTypes.PORTAL;
    localStorage.setItem("source", JSON.stringify(source));
    ctx.setState({
      ...ctx.getState(),
      source: source,
    });
  }

  @Action(CoreAction.ClearSource)
  clearSource(ctx: StateContext<CoreStateModel>) {
    localStorage.removeItem("source");
    ctx.setState({
      ...ctx.getState(),
      source: null,
    });
  }

  @Action(CoreAction.SetAdminImages)
  setAdminImages(ctx: StateContext<CoreStateModel>, { payload }) {
    const currentState = ctx.getState();
    ctx.setState({
      ...currentState,
      images: {
        ...(currentState.images || {}),
        [payload.type]: payload.images,
      },
    });
  }
}
