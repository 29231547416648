import { animation, trigger, transition, animate, style } from '@angular/animations';
  
  export const inOutAnimation = trigger(
      'inOutAnimation', 
      [
        transition(
          ':enter', 
          [
            style({ height: 0, opacity: 0 }),
            animate('0.3s ease-in-out', 
                    style({ opacity: 1 }))
          ]
        ),
        transition(
          ':leave', 
          [
            style({ opacity: 1 }),
            animate('0.3s ease-in-out', 
                    style({ height: 0, opacity: 0 }))
          ]
        )
      ]
    );