import { Component, OnInit } from '@angular/core';
import { UntypedFormGroup } from '@angular/forms';
import { MatLegacyDialog as MatDialog } from '@angular/material/legacy-dialog';
import { ConfirmationDialog } from '@career/core/models/confirmation-dialog';
import { FieldConfig } from '@career/pocket-forms/models/field';

@Component({
  selector: 'app-consent',
  template: `
  <div class="form" [ngClass]="{'error-handler': (group | hasValidationErrors:field)}" [formGroup]="group">
    <ng-container *ngFor="let validation of (group | getValidationErrors:field)">
      <p *ngIf="group.get(field.name).hasError(validation.name)" class="error-handler__message">{{validation.message}}</p>
    </ng-container>
    <label class="form__label" [for]="field.name">{{field.label}} {{(group | isRequired:field)?'':' (optional)'}}</label>    
    <p class="form__introduction" [innerText]="field.extras.introduction"></p>
    <div>
      <button mat-stroked-button (click)="openConsent($event)">{{field.extras.viewText}}</button>
    </div>
    <p class="checkbox"><mat-checkbox [disabled]="selectDisabled" [id]="field.name" [name]="field.name" [formControlName]="field.name">{{field.extras.acceptanceText}}</mat-checkbox></p>
  </div>
`,
  styleUrls: ['./consent.component.scss']
})
export class ConsentComponent implements OnInit {
  field: FieldConfig;
  group: UntypedFormGroup;
  selectDisabled;
  constructor(private dialog: MatDialog) { }

  ngOnInit(): void {
    this.selectDisabled = !this.field.value;
  }

  openConsent($event) {
    $event.preventDefault();
    const confirmation = new ConfirmationDialog(this.dialog);
    confirmation.confirm(this.field.label, this.field.extras.consent,  {confirmButtonText: this.field.extras.dialogConfirmation})
    .subscribe(() => {
      this.selectDisabled = false;
    });
  }
}
