
export enum EUnsubscribeTextMap {
    NOT_INTERESTED = "NOT_INTERESTED",
    NOT_LOOKING = "NOT_LOOKING",
    NOT_RELEVANT = "NOT_RELEVANT",
    EMAILS_WERE_ANNOYING = "EMAILS_WERE_ANNOYING",
    UNSUBSCRIBE = "UNSUBSCRIBE",
    DEACTIVATE_ACCOUNT = "DEACTIVATE_ACCOUNT",
    LOGIN_TO_DEACTIVATE_ACCOUNT = "LOGIN_TO_DEACTIVATE_ACCOUNT",
    ISSUE_PROCESSING_REQUEST = "ISSUE_PROCESSING_REQUEST",
    CONTACT_SUPPORT = "CONTACT_SUPPORT",
    UNSUBSCRIBE_COMPLETED_MESSAGE = "UNSUBSCRIBE_COMPLETED_MESSAGE",
    UNSUBSCRIBE_COMPLETED = "UNSUBSCRIBE_COMPLETED",
    WHY_DO_YOU_WANT_TO_UNSUBSCRIBE = "WHY_DO_YOU_WANT_TO_UNSUBSCRIBE",
    WHEN_DO_YOU_WANT_US_TO_CONTACT_AGAIN = "WHEN_DO_YOU_WANT_US_TO_CONTACT_AGAIN",
    '3_MONTHS' = "3_MONTHS",
    '6_MONTHS' = "6_MONTHS",
    '1_YEAR' = "1_YEAR",
    '2_YEARS' = "2_YEARS",
    NEVER = "NEVER",
    UNSUBSCRIBE_DISCLAIMER = "UNSUBSCRIBE_DISCLAIMER"
}
