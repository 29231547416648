<app-embedded-toolbar (onConfigure)="scope.configure()" configurationName="Static Page Config"></app-embedded-toolbar>
<div class="social-template">
    <div class="data-hero data-hero--min-height">
        <div class="header">
            <div [appImgEditContainer]="scope.path" name="Main Heading Image">
                <div class="header__img-container">
                    <img [src]="scope.component.assets.image" class="header__img"
                        [alt]="scope.getText | transform:'pageTitle'">
                </div>
            </div>
            <div class="header__overlay header__overlay--top">
                <div class="header__title-container">
                    <div class="header__title-row header__title-row--slim">
                        <h1 class="header__title">
                            <div [appTextEditContainer]="scope.path" name="Page Title" [title]="'pageTitle'">
                                <span>
                                    {{ scope.component.textMap.pageTitle }}
                                </span>
                            </div>
                        </h1>
                    </div>
                </div>
            </div>
        </div>
        <div class="data-hero__text-container">
            <div class="social__data" [appTextEditContainer]="scope.path" [isStaticTemplate]="true"
                name="Page Content" [body]="'richText'">
                <p [innerHTML]="scope.sanitizedRichText"></p>
            </div>
        </div>
    </div>
</div>