import { PortalService } from "@career/core/services/portal.service";
import { UserService } from "@career/core/services/user.service";
import { Observable } from "rxjs";
import { filter, map, switchMap } from "rxjs/operators";
import { ActionDataSourceService } from "../../services/action-data-source.service";
import { ActionProvider } from "../action-data-source.model";
import { ActionStrategy } from "../action-strategy.model";
import { ActionTarget, EActionTargetType } from "../action-target.model";
import {
  IAction,
  EActionType,
  ActionBuilder,
  EActionStatus,
} from "../action.model";

export class ResumeUploadActionStrategy extends ActionStrategy {
  constructor(protected dataSourceService: ActionDataSourceService) {
    super(dataSourceService);
  }

  getActionProvider(): ActionProvider {
    return new (class extends ActionProvider {
      constructor(
        private portalService: PortalService,
        private userSvc: UserService
      ) {
        super();
      }


      setId(action: IAction): string {
        return 'resume';
      }

      populateTarget(action: IAction) {
        action.target = new ActionTarget();
        action.target.name = "MY_RESUME";
        action.target.type = EActionTargetType.PAGE_ITEM;
        action.target.value = "resumeSection";
        return action;
      }

      fetch(): Observable<IAction> {
        const actionBuilder = new ActionBuilder();
        return this.userSvc.getUser().pipe(
          filter((user) => user && user._id),
          map((user) => actionBuilder.setUser(user) && user),
          switchMap(() => this.portalService.getPortalConfiguration()),
          map((config) => {
            return config.disableResumeRegistration && config.requireResume;
          }),
          map((requireResume) => {
            if (!requireResume) {
              return null;
            }
            const user = actionBuilder.build().user;
            return actionBuilder
              .setTimestamp(new Date())
              .setStatus(
                (user.latestResume || {})._id
                  ? EActionStatus.COMPLETE
                  : EActionStatus.INCOMPLETE
              )
              .setObject(user.latestResume)
              .setType(EActionType.PROFILE)
              .build();
          })
        );
      }
    })(
      this.dataSourceService.get(PortalService),
      this.dataSourceService.get(UserService)
    );
  }

  getType(): EActionType {
    return EActionType.PROFILE;
  }

  createActions(): Observable<IAction> {
    return this.transformActions(this, []);
  }
}
