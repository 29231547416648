<div class="wrapper">
  <h1 class="title">{{config.data.name | titlecase}}</h1>
  <div *ngIf="loading" class="loading">
    <mat-progress-bar mode="indeterminate"></mat-progress-bar>
  </div>
  <div *ngIf="!loading">
    <app-error-summary [error]="error" *ngIf="error"></app-error-summary>
    <mat-progress-bar *ngIf="!images" mode="indeterminate"></mat-progress-bar>
    <div class="row upload__modal__image-list">
      <ng-container *ngFor="let image of images">
        <div #imgContainer *ngIf="image" class="upload__modal__image-list-item"
          (click)="onImageListItemClick(image, imgContainer)">
          <img [src]="image">
        </div>
      </ng-container>
    </div>
    <mat-toolbar class="mat-toolbar">
      <button mat-button color="primary" (click)="onSave()" *ngIf="selectedImage">Update Selection</button>
    </mat-toolbar>
    <app-upload-file [resume]="true" id="resume-upload" (uploadEvent)="uploadFileEvt($event)" class="upload-file"></app-upload-file>
  </div>
</div>