import { NgModule } from '@angular/core';
import { CommonModule } from '@angular/common';
import { ActionRequiredWarningComponent } from './components/action-required-warning/action-required-warning.component';
import { ActionsDialogComponent } from './components/actions-dialog/actions-dialog.component';
import { CoreModule } from '@career/core/core.module';
import { MatLegacyTooltipModule as MatTooltipModule } from '@angular/material/legacy-tooltip';
import { FontAwesomeModule } from '@fortawesome/angular-fontawesome';
import { ActionsService } from './services/actions-service.service';
import { NgxsModule } from '@ngxs/store';
import { ActionsState } from './store/states/actions.state';
import { ActionDataSourceService } from './services/action-data-source.service';
import { ActionsDialogService } from './services/actions-dialog.service';
import { MatLegacyDialogModule as MatDialogModule } from '@angular/material/legacy-dialog';
import { ActionTargetService } from './services/action-target.service';
import { DefaultActionDialogComponent } from './components/actions-dialog/templates/default-action-dialog/default-action-dialog.component';
import { DefaultActionRequiredWarningComponent } from './components/action-required-warning/templates/default-action-required-warning.component';
import { MatLegacyButtonModule as MatButtonModule } from '@angular/material/legacy-button';
import { MatLegacyInputModule as MatInputModule } from '@angular/material/legacy-input';
import {MatLegacyTableModule as MatTableModule} from '@angular/material/legacy-table';


@NgModule({
  declarations: [ActionRequiredWarningComponent, ActionsDialogComponent, DefaultActionDialogComponent, DefaultActionRequiredWarningComponent],
  imports: [
    NgxsModule.forFeature([ActionsState]),
    CommonModule,
    MatTooltipModule,
    MatButtonModule,
    MatInputModule,
    MatTableModule,
    FontAwesomeModule,
    MatDialogModule,
    CoreModule
  ],
  exports: [
    ActionRequiredWarningComponent
  ]
})
export class ActionsModule { }
