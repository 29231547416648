import { DefaultManagePreferencesComponent } from '@career/user/components/manage-preferences/templates/default-manage-preferences/default-manage-preferences.component';
import { DefaultVerifyPhoneComponent } from '@career/user/components/verify-phone/default/default-verify-phone.component';
import { DefaultApplicationsComponent } from './components/applications/templates/default-applications/default-applications.component';
import { DefaultAvailabilityComponent } from './components/availability/templates/default/default-availability.component';
import { DefaultDashboardSuggestedJobsComponent } from './components/dashboard-suggested-jobs/templates/default-dashboard-suggested-jobs/default-dashboard-suggested-jobs.component';
import { DefaultDashboardComponent } from './components/dashboard/templates/default/default-dashboard.component';
import { SocialDashboardComponent } from './components/dashboard/templates/social/social-dashboard.component';
import { DefaultManageConsentsComponent } from './components/manage-consents/templates/default-manage-consents/default-manage-consents.component';
import { DefaultManageFilesComponent } from './components/manage-files/templates/default-manage-files/default-manage-files.component';
import { DefaultManageProfileComponent } from './components/manage-profile/templates/default-manage-profile/default-manage-profile.component';
import { DefaultManageResumeComponent } from './components/manage-resume/templates/default-manage-resume/default-manage-resume.component';
import { DefaultManageSkillsComponent } from './components/manage-skills/default/default-manage-skills.component';
import { DefaultPanelsComponent } from './components/panels/default/default-panels.component';

export const userComponentDef = {
    "dashboard": {
        "default": DefaultDashboardComponent,
        "social": SocialDashboardComponent
    },
    "dashboard-applications": {
        "default": DefaultApplicationsComponent,
        "social": DefaultApplicationsComponent
    },
    "verify-phone": {
        "default": DefaultVerifyPhoneComponent,
        "social": DefaultVerifyPhoneComponent
    },
    "manage-profile": {
        "default": DefaultManageProfileComponent,
        "social": DefaultManageProfileComponent
    },
    "dashboard-suggested-jobs": {
        "default": DefaultDashboardSuggestedJobsComponent,
        "social": DefaultDashboardSuggestedJobsComponent
    },
    "manage-resume": {
        "default": DefaultManageResumeComponent,
        "social": DefaultManageResumeComponent
    },
    "manage-consents": {
        "default": DefaultManageConsentsComponent,
        "social": DefaultManageConsentsComponent
    },
    "manage-files": {
        "default": DefaultManageFilesComponent,
        "social": DefaultManageFilesComponent
    },
    "manage-preferences": {
        "default": DefaultManagePreferencesComponent,
        "social": DefaultManagePreferencesComponent
    },
    "panels": {
        "default": DefaultPanelsComponent,
        "social": DefaultPanelsComponent
    },
    "availability": {
        "default": DefaultAvailabilityComponent,
        "social": DefaultAvailabilityComponent
    },
    "manage-skills": {
        "default": DefaultManageSkillsComponent,
        "social": DefaultManageSkillsComponent
    }
}