<div class="pills">
    <ng-container *ngIf="!asLink; else linkTemplate">
        <ng-container *ngFor="let pill of pills">
            <div class="pill" [ngClass]="{'pill--invert': invertBg}"
                [ngClass]="[asLabel ? 'label-background-' + pill.color : '']">
                <div class="pill-icon-container pill-icon-container--{{pill.icon}}" *ngIf="isIconApplicable">
                    <mat-icon fontIcon="{{pill.icon}}"></mat-icon>
                </div>
                <span class="pill-text">
                    {{pill.value}}
                </span>
            </div>
        </ng-container>
    </ng-container>
    <ng-template #linkTemplate>
        <div class="pill" *ngFor="let pill of pills" [ngClass]="{'pill--invert': invertBg}">
            <a href="javascript:void(0)" (click)="onLinkClicked(pill.id)" class="pill-download">
                <div class="pill-icon-container pill-icon-container--link">
                    <img *ngIf="isImage" [src]="pill.icon" class="pill-icon" width=20>
                    <i *ngIf="!isImage" class="icon-{{pill.icon}} pill-icon"></i>
                </div>
                <span class="pill-text"> {{pill.value}} <i class="icon-download action__icon icon-align"></i></span>
            </a>
        </div>
    </ng-template>
</div>