import { Pipe, PipeTransform } from '@angular/core';

@Pipe({
  name: 'transform'
})
export class TransformPipe implements PipeTransform {

  transform(method: Function, key, ...params: any[]): unknown {
    return method(key, ...params || null);
  }

}
