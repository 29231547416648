import { Injectable } from "@angular/core";
import { HttpClient } from "@angular/common/http";
import { Store, Actions, ofActionDispatched } from "@ngxs/store";
import { Observable, of, throwError } from "rxjs";
import { UserAction } from "@career/user/store/actions/user.actions";
import { map, catchError } from "rxjs/operators";
import { AuthAction } from "../../authentication/store/actions/auth.actions";
import { AuthState } from "../../authentication/store/states/auth.state";
import { CoreAction } from "../store/actions/core.actions";
import { ActionsAction } from "@career/actions/store/actions/actions.actions";

@Injectable({
  providedIn: "root",
})
export class UserAuthService {
  constructor(
    protected http: HttpClient,
    private store: Store,
    private actions$: Actions
  ) { }

  tryToken(token: string): Observable<boolean> {
    return this.setExistingSession(token).pipe(
      map(
        (data) => {
          return true;
        },
        (err) => {
          return false;
        }
      )
    );
  }

  onUserSetup(): Observable<any> {
    return this.actions$.pipe(ofActionDispatched(UserAction.Setup));
  }

  getToken(): string {
    const token = this.store.selectSnapshot((state) => state.auth.token);
    if (!token) {
      this.store.dispatch(CoreAction.SetAuditToken);
    }

    return token;
  }

  setExistingSession(token: string): Observable<any> {
    let pop = encodeURIComponent("user|user.latestResume:lastUpdated,filename");
    return this.http.get("/api/session/" + token + "?populate=" + pop).pipe(
      map((data: any) => {
        this.store.dispatch(new UserAction.Setup(data.user));
        return data.user;
      }),
      catchError((err) => {
        /*if we can't log in for some reason clear the token and user*/
        console.error(err);
        this.store.dispatch([
          new AuthAction.ClearToken(),
          new ActionsAction.Clear(),
          new CoreAction.SetAuditToken(),
        ]);
        return of(null);
      })
    );
  }

  logout(isAdmin = false): Observable<any> {
    //DeleteSession action on auth state
    const token = this.store.selectSnapshot(AuthState.token);
    return this.http.delete("/api/session/" + token).pipe(
      map((data) => {
        this.store.dispatch([
          new AuthAction.ClearToken(),
          new ActionsAction.Clear(),
          new UserAction.ClearApplied(),
          new UserAction.ClearSuggested(),
          new UserAction.Remove(),
        ]);
        if (isAdmin) {
          this.store.dispatch([new CoreAction.AdminLogout()])
        }
        return data;
      })
    );
  }

  unsubscribe(
    token?: string,
    note?: string,
    duration?: number
  ): Observable<any> {
    let url = "/api/candidate/unsubscribe";
    if (token) url += "/" + token;
    if (note) {
      url += `?reason=${encodeURIComponent(note)}`;
    }
    if (duration) {
      url += `${(note?'&':'?')}duration=${duration}`;
    }

    return this.http.put(url, {}).pipe(
      map((data) => {
        this.store.dispatch([
          AuthAction.ClearToken,
          UserAction.Remove,
          ActionsAction.Clear,
        ]);

        return data;
      })
    );
  }
}
