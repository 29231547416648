import { ChangeDetectorRef, Component, OnDestroy, OnInit } from '@angular/core';
import { MatLegacyDialog } from '@angular/material/legacy-dialog';
import { VALID_RESUME_UPLOAD_TYPES } from '@career/core/constants/file.constant';
import { TemplateComponent, TemplateConfiguration } from '@career/core/models/component.model';
import { ConfirmationDialog } from '@career/core/models/confirmation-dialog';
import { ErrorSummary } from '@career/core/models/error.model';
import { CandidateService } from '@career/core/services/candidate.service';
import { DialogService } from '@career/core/services/dialog.service';
import { LocaleService } from '@career/core/services/locale.service';
import { MatchService } from '@career/core/services/match.service';
import { PortalService } from '@career/core/services/portal.service';
import { UtilsService } from '@career/core/services/utils.service';
import { userComponentDef } from '@career/user/component-definition';
import { UserState } from '@career/user/store/states/user.state';
import { Store } from '@ngxs/store';
import { Observable, of, Subscription } from 'rxjs';
import { catchError, map, switchMap } from 'rxjs/operators';

@Component({
  selector: 'app-manage-resume',
  template: `
  <app-base-template [component]="component" [componentDef]="componentDef" [scope]="this" *ngIf="component"></app-base-template>
  `
})
export class ManageResumeComponent implements OnInit, OnDestroy {
  component: TemplateComponent;
  config: TemplateConfiguration;
  getText: Function;
  portalServiceSubscription: Subscription;
  componentDef = userComponentDef['manage-resume'];
  user$: Observable<any>;
  loading: boolean;
  updated: boolean;
  error: ErrorSummary;
  constructor(private localeService: LocaleService, private dialog: MatLegacyDialog, private changeDetectorRef: ChangeDetectorRef, private candidateService: CandidateService, private utils: UtilsService, private store: Store, private portalService: PortalService) { }

  getDate(timestamp, scope) {
    return scope.localeService.getLocalDateStr(timestamp);
  }

  resumeUpdate(event) {
    this.updated = false;
    this.loading = true;
    this.error = null;
    let file;
    if(event.target) {
      file = event.target.files[0] || null;
    } else {
      file = event[0];
    }
    if( VALID_RESUME_UPLOAD_TYPES.includes(file.type) ) {
      this.store.selectOnce(UserState.user)
      .pipe(
        switchMap((user) => {
          return this.candidateService.updateResume(file, user._id)
        }),
        catchError((err) => {
          this.loading = false;
          if( err?.error.message.includes('is already assigned to profile') ) {
            this.error = new ErrorSummary(this.getText('ALREADY_EXISTS'), `Error Code: ${err.status || 'Unknown'}`);
          } else {
            this.error = new ErrorSummary(this.getText("UNKNOWN_ISSUE"), `Error Code: ${err.status || 'Unknown'}`);
          }
          return of(null);
        })
      ).subscribe((data) => {
        this.loading = false;
        if( data ) {
          this.updated = true;
          this.user$ = of(data);
        }
        this.changeDetectorRef.markForCheck();
      });
    } else {
      //wrong file type
      this.error = new ErrorSummary(this.getText("INVALID_FILE_TYPE"));
    }
  }

  ngOnInit(): void {
    this.portalServiceSubscription = this.portalService.getComponentData('manage-resume').subscribe((data) => {
      this.component = data.component;
      this.config = data.configuration;
      this.getText = this.utils.getText(this.component);
      this.user$ = this.store.selectOnce(UserState.user);
    });
  }

  ngOnDestroy(): void {
    this.portalServiceSubscription.unsubscribe();
  }

}
