<div class="data-with-image data-with-image--min-height">
    <div class="data-with-image__img-container" appImgEditContainer="login" name="Main Image">
        <div>
            <img class="data-with-image__img" [src]="scope.component.assets.image"
                [alt]="scope.getText | transform:'LOGIN'">
        </div>
    </div>
    <div class="data-with-image__text-container">
        <app-error-summary [error]="scope.error" *ngIf="scope.error"></app-error-summary>
        <h1 [innerText]="scope.getText | transform:'LOGIN'"
            class="data-with-image__heading data-with-image__heading--bold"></h1>
        <div [ngClass]="{'disabled--loading': scope.loading}">
            <dynamic-form (submit)="scope.submitForm($event)" [fields]="scope.loginConfig"></dynamic-form>
            <div class="data-with-image__button-container" disabledDiv>
                <button class="data-with-image__button" mat-stroked-button
                    (click)="scope.forgotPassword()">{{scope.getText |
                    transform:'FORGOT_PASSWORD?'}}</button>
            </div>
        </div>
    </div>
</div>