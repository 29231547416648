import { Component, OnDestroy, OnInit } from '@angular/core';
import { Validators } from '@angular/forms';
import { ActivatedRoute, Router } from '@angular/router';
import { TemplateComponent } from '@career/core/models/component.model';
import { ErrorSummary } from '@career/core/models/error.model';
import { AuthenticationService } from '@career/core/services/authentication.service';
import { PortalService } from '@career/core/services/portal.service';
import { UtilsService } from '@career/core/services/utils.service';
import { FieldConfig, Validator } from '@career/pocket-forms/models/field';
import { authComponentDef } from '../../component-definition';

@Component({
  selector: 'app-reset-password',
  template: `
  <app-base-template [component]="component" [componentDef]="componentDef" [scope]="this" *ngIf="component"></app-base-template>
  `
})
export class ResetPasswordComponent implements OnInit, OnDestroy {

  branding: any;
  token: string;
  loading: boolean;
  portalServiceSubscription;
  component: TemplateComponent;
  getText: Function;
  error: ErrorSummary
  resetPasswordConfig: FieldConfig[];
  groupValidators: Validators[];
  form: {password: string, passwordConfirm: string} = {password: '', passwordConfirm: ''};
  componentDef = authComponentDef['reset-password'];
  
  constructor(
    private route: ActivatedRoute,
    private portalService: PortalService,
    private authenticationService: AuthenticationService,
    private router: Router,
    private utils: UtilsService
  ) {}
  
  submitForm($event) {
    this.loading = true;
    this.authenticationService.resetPassword(this.token, $event.password).subscribe(data => {
      if(data) {
        this.router.navigate(['/login']); 
        return;
      }
    }, 
    (err) => {
      this.loading = false;
      this.error = new ErrorSummary(this.getText("ERROR_RESETTING"), `Error Code: ${err.status}`);
    })
    this.loading = false;
  }

  setupConfig() {
    this.groupValidators = [this.utils.confirmFieldValidator("password", "passwordConfirm", "passwordNotSame")];
    this.resetPasswordConfig = [
      {
        type: "input",
        label: this.getText('PASSWORD'),
        inputType: "password",
        name: "password",
        validations: [
          {
            name: "required",
            validator: Validators.required,
            message: this.getText("PASSWORD_REQUIRED")
          }
        ]
      },
      {
        type: "input",
        label: this.getText('CONFIRM_PASSWORD'),
        inputType: "password",
        name: "passwordConfirm",
        validations: [
          {
            name: "required",
            validator: Validators.required,
            message: this.getText("CONFIRM_PASSWORD_REQUIRED")
          },
          {
            name: "passwordNotSame",
            validator: null,
            message: this.getText("PASSWORDS_MUST_MATCH")
          }
        ]
      },
      {
        type: "button",
        color: 'primary', 
        label: this.getText('SUBMIT')
      }
    ]
  }
  ngOnInit() {
    this.portalServiceSubscription = this.portalService.getComponentData('reset-password').subscribe((data) => {
      this.component = data.component;
      this.token = this.route.snapshot.queryParamMap.get('token');
      this.getText = this.utils.getText(this.component);
      if(!this.token) {
        this.router.navigate(['/login']);
        return;
      }
      this.setupConfig();
    });
  }

  ngOnDestroy() {
    this.portalServiceSubscription.unsubscribe();
  }

}
