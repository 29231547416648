<div class="error-summary" aria-labelledby="error-summary-title" role="alert" tabindex="-1" aria-live="assertive"
    aria-atomic="false" *ngIf="(scope.incompleteActions$ | async).length > 0">
    <h2 class="error-summary__title" id="error-summary-title">
        {{scope.getText | transform:'REQUIRED_INFORMATION_MISSING'}}
    </h2>
    <ul class="error-summary__list error-summary__list--thin">
        <li class="error-summary__list-item">
            {{scope.getText | transform:'REQUIRED_INFORMATION_INTRODUCTION'}}
            <button class="button" mat-stroked-button color="warn" (click)="scope.openDialog()" tabindex="0"
                role="link">
                {{scope.getText | transform:'VIEW_PENDING_ACTIONS'}}
                <i class="icon-link link"></i>
            </button>
        </li>
    </ul>
</div>
<app-verify-phone tabindex="-1"></app-verify-phone>
<div class="panels">
    <mat-accordion class="panels__accordion" multi id="panels">
        <mat-expansion-panel class="panels__panel mat-elevation-z0" [expanded]="true">
            <mat-expansion-panel-header>
                <mat-panel-title [innerText]="scope.getText | transform:'APPLICATIONS'"></mat-panel-title>
            </mat-expansion-panel-header>
            <app-applications></app-applications>
        </mat-expansion-panel>
        <mat-expansion-panel *ngIf="scope.config.enableSuggestions" class="panels__panel mat-elevation-z0"
            [expanded]="scope.suggestedJobsOpen" (opened)="scope.suggestedJobsOpen = true">
            <mat-expansion-panel-header>
                <mat-panel-title [innerText]="scope.getText | transform:'SUGGESTED_JOBS'"></mat-panel-title>
            </mat-expansion-panel-header>
            <app-dashboard-suggested-jobs *ngIf="scope.suggestedJobsOpen"></app-dashboard-suggested-jobs>
        </mat-expansion-panel>
        <mat-expansion-panel class="panels__panel mat-elevation-z0" (opened)="scope.myProfileOpen = true">
            <mat-expansion-panel-header>
                <mat-panel-title [innerText]="scope.getText | transform:'MY_PROFILE'"></mat-panel-title>
            </mat-expansion-panel-header>
            <app-manage-profile *ngIf="scope.myProfileOpen"></app-manage-profile>
        </mat-expansion-panel>
        <mat-expansion-panel *ngIf="!scope.config.disableResumeRegistration || scope.config.requireResume" id="resumeSection"
        class="panels__panel mat-elevation-z0" (opened)="scope.myResumeOpen = true">
            <mat-expansion-panel-header>
                <mat-panel-title [innerText]="scope.getText | transform:'MY_RESUME'"></mat-panel-title>
            </mat-expansion-panel-header>
            <app-manage-resume *ngIf="scope.myResumeOpen"></app-manage-resume>
        </mat-expansion-panel>
        <mat-expansion-panel class="panels__panel mat-elevation-z0" *ngIf="!scope.config.hideAttachedFiles" (opened)="scope.uploadFilesOpen = true">
            <mat-expansion-panel-header>
                <mat-panel-title [innerText]="scope.getText | transform:'UPLOADED_FILES'"></mat-panel-title>
            </mat-expansion-panel-header>
            <app-manage-files *ngIf="scope.uploadFilesOpen"></app-manage-files>
        </mat-expansion-panel>
        <mat-expansion-panel class="panels__panel mat-elevation-z0" (opened)="scope.manageConsentsOpen = true"
            *ngIf="(scope.candidateConsents$ | async) as candidateConsents">
            <mat-expansion-panel-header>
                <mat-panel-title [innerText]="scope.getText('MY_CONSENT_FORMS')"></mat-panel-title>
            </mat-expansion-panel-header>
            <app-manage-consents *ngIf="scope.manageConsentsOpen" [candidateConsents]="candidateConsents">
            </app-manage-consents>
        </mat-expansion-panel>
        <mat-expansion-panel class="panels__panel mat-elevation-z0" id="availabilitySection"
            (opened)="scope.availabilityOpen = true" *ngIf="scope.config.enableAvailability">
            <mat-expansion-panel-header>
                <mat-panel-title [innerText]="scope.getText('MY_AVAILABILITY')"></mat-panel-title>
            </mat-expansion-panel-header>
            <app-availability *ngIf="scope.availabilityOpen"></app-availability>
        </mat-expansion-panel>
        <mat-expansion-panel class="panels__panel mat-elevation-z0" id="skillsSection"
            (opened)="scope.skillsOpen = true" *ngIf="scope.config.enableSkills && (scope.skills$ | async) as skills">
            <mat-expansion-panel-header>
                <mat-panel-title [innerText]="scope.getText('MY_SKILLS')"></mat-panel-title>
            </mat-expansion-panel-header>
            <app-manage-skills *ngIf="scope.skillsOpen && skills && skills.length > 0" [skills]="skills">
            </app-manage-skills>
        </mat-expansion-panel>
        <mat-expansion-panel class="panels__panel mat-elevation-z0" id="prefsSection"
            (opened)="scope.preferencesOpen = true" *ngIf="scope.isPreferencesEnabled && (scope.hasPhones$ | async)">
            <mat-expansion-panel-header>
                <mat-panel-title [innerText]="scope.getText('MY_PREFERENCES')"></mat-panel-title>
            </mat-expansion-panel-header>
            <app-manage-preferences *ngIf="scope.preferencesOpen"></app-manage-preferences>
        </mat-expansion-panel>
    </mat-accordion>
</div>