import { Component, OnDestroy, OnInit } from "@angular/core";
import { ActivatedRoute } from "@angular/router";
import { AuthenticationService } from "@career/core/services/authentication.service";
import { PortalService } from "@career/core/services/portal.service";
import { UtilsService } from "@career/core/services/utils.service";
import { authComponentDef } from "@career/authentication/component-definition";
import { TemplateComponent } from "@career/core/models/component.model";
import { ErrorSummary } from "@career/core/models/error.model";
import { Validators } from "@angular/forms";
import { FieldConfig } from "@career/pocket-forms/models/field";
import { MatLegacyDialog as MatDialog } from "@angular/material/legacy-dialog";
import { AttachmentService } from "@career/core/services/attachment.service";

@Component({
  selector: "app-forgot-password",
  template: `
    <app-base-template
      [component]="component"
      [componentDef]="componentDef"
      [scope]="this"
      *ngIf="component"
    ></app-base-template>
  `,
})
export class ForgotPasswordComponent implements OnInit, OnDestroy {
  component: TemplateComponent;
  email: string;
  error: ErrorSummary;
  success: boolean;
  portalServiceSubscription;
  getText: Function;
  componentDef = authComponentDef["forgot-password"];
  forgotPasswordConfig: FieldConfig[];
  groupValidators: Validators[];
  configComponent = false;

  constructor(
    private route: ActivatedRoute,
    private portalService: PortalService,
    private utils: UtilsService,
    private authenticationService: AuthenticationService,
    public dialog: MatDialog,
    private attachmentService: AttachmentService
  ) {}

  submitForm($event) {
    this.authenticationService.forgotPassword($event.email).subscribe(
      () => {
        this.success = true;
      },
      (err) => {
        this.error = new ErrorSummary(
          this.getText('ERROR_FINDING_ACCOUNT'),
          `Error Code: ${err.status || 'Unknown'}`
        );
      }
    );
  }

  setupConfig() {
    this.groupValidators = [
      this.utils.confirmFieldValidator(
        "email",
        "confirmemail",
        "emailsNotSame"
      ),
    ];
    this.forgotPasswordConfig = [
      {
        type: "input",
        label: this.getText('EMAIL'),
        inputType: "email",
        autocomplete: "email",
        name: "email",
        value: this.email,
        validations: [
          {
            name: "required",
            validator: Validators.required,
            message: this.getText("EMAIL_REQUIRED")
          }
        ]
      },
      {
        type: "input",
        label: this.getText('CONFIRM_EMAIL'),
        inputType: "email",
        autocomplete: "email",
        name: "confirmemail",
        validations: [
          {
            name: "required",
            validator: Validators.required,
            message: this.getText("EMAIL_REQUIRED")
          },
          {
            name: "emailsNotSame",
            validator: null,
            message: this.getText("EMAILS_MUST_MATCH")
          }
        ]
      },
      {
        type: "button",
        color: "primary",
        label: this.getText("SUBMIT"),
      },
    ];
  }

  ngOnInit() {
    this.portalServiceSubscription = this.portalService
      .getComponentData("forgot-password")
      .subscribe((data) => {
        this.component = data.component;
        this.email = this.route.snapshot.paramMap.get("email");
        this.getText = this.utils.getText(this.component);
        this.setupConfig();
      });
  }

  ngOnDestroy() {
    this.portalServiceSubscription.unsubscribe();
  }
}
