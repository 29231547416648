import { Injectable } from "@angular/core";
import { AuthState } from "@career/authentication/store/states/auth.state";
import { UserState } from "@career/user/store/states/user.state";
import { Store } from "@ngxs/store";
import { Observable, of } from "rxjs";
import { filter, map, skipWhile, switchMap, take } from "rxjs/operators";
import { PortalService } from "./portal.service";
import { UserAuthService } from "./user-auth.service";

@Injectable({
  providedIn: "root",
})
export class UserService {
  constructor(
    private store: Store,
    private userAuthService: UserAuthService,
    private portalSvc: PortalService
  ) {}

  getUser(withToken = false): Observable<any> {
    return this.store.select(AuthState.isLoading).pipe(
      skipWhile(loading => loading),
      take(1),
      switchMap(_ => this.store.selectOnce(UserState.user)),
      switchMap((user) => {
        const token = this.store.selectSnapshot((state) => state.auth.token);
        if (withToken ? token && user : user) {
          return of(user);
        }
        return of(null);
      })
    );
  }

  isAdmin() {
    const user$ = this.getUser();
    return this.portalSvc.isPreviewMode().pipe(
      switchMap((isPreviewMode) =>
        user$.pipe(
          map(
            (userRes) =>
              userRes?.login?.permissions?.includes("WRITEPORTALS") &&
              !isPreviewMode
          )
        )
      )
    );
  }

  isLoggedInUserAdmin() {
    const userChanged$ = this.store.select(AuthState.token);
    return userChanged$.pipe(
      switchMap((_) =>
        this.getUser().pipe(
          map((user) => user?.login?.permissions?.includes("WRITEPORTALS"))
        )
      )
    );
  }

  isAdminRole() {
    return this.store.selectSnapshot(UserState.isAdminRole);
  }
}
