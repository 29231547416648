import { DefaultActionRequiredWarningComponent } from "./components/action-required-warning/templates/default-action-required-warning.component";
import { DefaultActionDialogComponent } from "./components/actions-dialog/templates/default-action-dialog/default-action-dialog.component";

export const actionsComponentDef = {
    "action-required-warning": {
        "default": DefaultActionRequiredWarningComponent,
        "social": DefaultActionRequiredWarningComponent
    },
    "actions-dialog": {
        "default": DefaultActionDialogComponent,
        "social": DefaultActionDialogComponent
    }
};
