import { DOCUMENT } from '@angular/common';
import { AfterViewInit, Component, ElementRef, Inject, Input, OnChanges, OnInit, SimpleChanges, ViewChild } from '@angular/core';
import { FormGroup } from '@angular/forms';
import { coreComponentDef } from '@career/core/component-definition';
import { TemplateComponent, TemplateConfiguration } from '@career/core/models/component.model';
import { ErrorSummary } from '@career/core/models/error.model';
import { PortalService } from '@career/core/services/portal.service';
import { FieldConfig } from '@career/pocket-forms/models/field';
import { Subscription } from 'rxjs';
import { v4 as uuidv4 } from 'uuid';

@Component({
  selector: 'app-error-summary',
  template: `
  <app-base-template [component]="component" [componentDef]="componentDef" [scope]="this" *ngIf="component"></app-base-template>
  `,
})
export class ErrorSummaryComponent implements OnInit, OnChanges {
  //https://designsystem.gov.scot/components/error-summary/
  @Input() title: string;
  @Input() confirmationText: string;
  @Input() error: ErrorSummary;
  @Input() hideTitle = false;
  forceClosed: boolean = false;
  portalServiceSubscription: Subscription;
  component: TemplateComponent;
  config: TemplateConfiguration;
  componentDef = coreComponentDef['error-summary'];
  constructor(private portalService: PortalService) { }
  ngOnChanges(changes: SimpleChanges): void {
    if( changes.error ) {
      this.forceClosed = false;
    }
  }

  ngOnInit(): void {
    this.portalServiceSubscription = this.portalService.getComponentData("error-summary").subscribe(data => {
      this.config = data.configuration;
      this.component = data.component;
    });
  }
}
