import { NgModule } from '@angular/core';
import { CommonModule } from '@angular/common';
import { DashboardComponent } from './components/dashboard/dashboard.component';
import { UserRoutingModule } from './user-routing.module';
import { NgxsModule } from '@ngxs/store';
import { FormsModule, ReactiveFormsModule } from '@angular/forms';
import { CoreModule } from '../core/core.module';
import { UserState } from '@career/user/store/states/user.state';
import { FontAwesomeModule } from '@fortawesome/angular-fontawesome';
import { MatLegacyAutocompleteModule as MatAutocompleteModule } from '@angular/material/legacy-autocomplete';
import { MatLegacySelectModule as MatSelectModule } from '@angular/material/legacy-select';
import { MatLegacyDialogModule as MatDialogModule } from '@angular/material/legacy-dialog';
import { ActionsModule } from '@career/actions/actions.module';
import { ApplicationsComponent } from './components/applications/applications.component';
import { ManageProfileComponent } from './components/manage-profile/manage-profile.component';
import { ManageResumeComponent } from './components/manage-resume/manage-resume.component';
import { ManageConsentsComponent } from './components/manage-consents/manage-consents.component';
import { DashboardSuggestedJobsComponent } from './components/dashboard-suggested-jobs/dashboard-suggested-jobs.component';
import { MatExpansionModule } from '@angular/material/expansion';
import { DefaultApplicationsComponent } from './components/applications/templates/default-applications/default-applications.component';
import { DefaultManageProfileComponent } from './components/manage-profile/templates/default-manage-profile/default-manage-profile.component';
import { PocketFormsModule } from '@career/pocket-forms/pocket-forms.module';
import { DefaultDashboardSuggestedJobsComponent } from './components/dashboard-suggested-jobs/templates/default-dashboard-suggested-jobs/default-dashboard-suggested-jobs.component';
import { DefaultManageResumeComponent } from './components/manage-resume/templates/default-manage-resume/default-manage-resume.component';
import { DefaultManageConsentsComponent } from './components/manage-consents/templates/default-manage-consents/default-manage-consents.component';
import { DefaultManageFilesComponent } from './components/manage-files/templates/default-manage-files/default-manage-files.component';
import { ManageFilesComponent } from './components/manage-files/manage-files.component';
import { MatLegacyChipsModule as MatChipsModule } from '@angular/material/legacy-chips';
import { DefaultDashboardComponent } from './components/dashboard/templates/default/default-dashboard.component';
import { SocialDashboardComponent } from './components/dashboard/templates/social/social-dashboard.component';
import { PanelsComponent } from './components/panels/panels.component';
import { AvailabilityComponent } from './components/availability/availability.component';
import { DefaultAvailabilityComponent } from './components/availability/templates/default/default-availability.component';
import { DefaultPanelsComponent } from './components/panels/default/default-panels.component';
import { CalendarModule, DateAdapter } from 'angular-calendar';
import { adapterFactory } from 'angular-calendar/date-adapters/date-fns';
import { MatLegacyButtonModule as MatButtonModule } from '@angular/material/legacy-button';
import { NgxMaterialTimepickerModule } from 'ngx-material-timepicker';
import { MatBadgeModule } from '@angular/material/badge';
import { ManageSkillsComponent } from './components/manage-skills/manage-skills.component';
import { DefaultManageSkillsComponent } from './components/manage-skills/default/default-manage-skills.component';
import { MatLegacyCheckboxModule as MatCheckboxModule } from '@angular/material/legacy-checkbox';
import { MatLegacyTableModule as MatTableModule } from '@angular/material/legacy-table';
import { MatLegacyInputModule as MatInputModule } from '@angular/material/legacy-input';
import { MatLegacyFormFieldModule as MatFormFieldModule } from '@angular/material/legacy-form-field';
import { MatDatepickerModule } from '@angular/material/datepicker';
import { MatNativeDateModule } from '@angular/material/core';
import { MatButtonToggleModule } from '@angular/material/button-toggle';
import { MatLegacyPaginatorModule as MatPaginatorModule } from '@angular/material/legacy-paginator';
import { MatToolbarModule } from '@angular/material/toolbar';
import { PageConfigurationModule } from '@career/page-configuration/page-configuration.module';
import { ManagePreferencesComponent } from './components/manage-preferences/manage-preferences.component';
import { DefaultManagePreferencesComponent } from './components/manage-preferences/templates/default-manage-preferences/default-manage-preferences.component';
import { VerifyPhoneComponent } from '@career/user/components/verify-phone/verify-phone.component';
import { DefaultVerifyPhoneComponent } from '@career/user/components/verify-phone/default/default-verify-phone.component';
import { NgOtpInputModule } from 'ng-otp-input';

@NgModule({
  declarations: [DashboardComponent, DefaultDashboardComponent, ApplicationsComponent, ManageProfileComponent, ManageResumeComponent, ManageConsentsComponent, DashboardSuggestedJobsComponent, DefaultApplicationsComponent, DefaultManageProfileComponent, DefaultDashboardSuggestedJobsComponent, DefaultManageResumeComponent, DefaultManageConsentsComponent, DefaultManageFilesComponent, ManageFilesComponent, SocialDashboardComponent, PanelsComponent, DefaultPanelsComponent, AvailabilityComponent, DefaultAvailabilityComponent, ManageSkillsComponent, DefaultManageSkillsComponent, ManagePreferencesComponent, 
    DefaultManagePreferencesComponent, DefaultVerifyPhoneComponent, VerifyPhoneComponent,],
  imports: [
    CommonModule,
    PageConfigurationModule,
    FormsModule,
    MatDialogModule,
    ActionsModule,
    PocketFormsModule,
    MatExpansionModule,
    ReactiveFormsModule,
    MatButtonModule,
    MatCheckboxModule,
    MatTableModule,
    NgOtpInputModule,
    NgxMaterialTimepickerModule,
    MatChipsModule,
    MatInputModule,
    MatDatepickerModule,
    MatButtonToggleModule,
    MatFormFieldModule,
    MatBadgeModule,
    FontAwesomeModule,
    MatAutocompleteModule,
    MatNativeDateModule,
    MatSelectModule,
    NgxsModule.forFeature([UserState]),
    CalendarModule.forRoot({
      provide: DateAdapter,
      useFactory: adapterFactory,
    }),
    UserRoutingModule,
    CoreModule,
    MatPaginatorModule,
    MatToolbarModule
  ]
})
export class UserModule { }
