import { Component, OnInit, Input, ViewEncapsulation } from '@angular/core';
import { AbstractControl, ValidationErrors, ValidatorFn, Validators } from '@angular/forms';
import { faUpload } from '@fortawesome/free-solid-svg-icons';
import { PortalService } from '@career/core/services/portal.service';
import { UtilsService } from '@career/core/services/utils.service';
import { FieldConfig, WizardGroup } from '@career/pocket-forms/models/field';
import { ConsentService } from '@career/core/services/consent.service';
import { LocaleService } from '@career/core/services/locale.service';
import { inOutAnimation } from '@career/core/animations/fade-in-out';
import { RegisterComponent } from '../../register.component';

@Component({
  selector: 'app-default-register',
  templateUrl: './default-register.component.html',
  styleUrls: ['./default-register.component.scss'],
  animations: [inOutAnimation],
  encapsulation: ViewEncapsulation.None
})
export class DefaultRegisterComponent {

  @Input() scope: RegisterComponent;
  faUpload = faUpload;
  resumeOrCvText = this.localeService.getResumeOrCVText();
  constructor(private localeService: LocaleService) { }
}
