<ng-container *ngIf="scope">
<div class="mobile-header">
    <mat-sidenav-container class="sidenav" [hasBackdrop]="true">
        <mat-sidenav #sidenav mode="over" class="sidenav__content" (click)="sidenav.toggle()">
            <mat-nav-list>
                <ng-container *ngIf="(scope.userChanged$ | async) else unauthenticated">
                    <ng-container *ngTemplateOutlet="authenticated"></ng-container>
                </ng-container>
            </mat-nav-list>
        </mat-sidenav>
        <mat-sidenav-content>
            <header class="header">
                <div class="header__container">
                    <a class="header__logo-container" [href]="scope.config.agencyWebsite">
                        <img class="header__logo" [src]="scope.component.assets.logo" [alt]="scope.portalName" [ngStyle]="{'max-width': (scope.config.logoSize || '300')+'px'}">
                    </a>
                    <button mat-button (click)="sidenav.toggle()" class="button">
                        <span class="button__menu">
                            Menu
                            <i class="header__icon icon-kebab"></i>
                        </span>
                    </button>
                </div>
            </header>
        </mat-sidenav-content>
    </mat-sidenav-container>
</div>

<ng-template #authenticated>
    <ng-container *ngIf="(scope.routes$ | async) as routes">
        <ng-container *ngFor="let route of routes.authenticatedRoutes">
            <a mat-list-item *ngIf="!route.externalLink" [routerLink]="route.link" [innerText]="scope.getText | transform:route.textMapKey"></a>
            <a mat-list-item *ngIf="route.externalLink" [href]="route.externalLink" [innerText]="scope.getText | transform:route.textMapKey"></a>
        </ng-container>
    </ng-container>
</ng-template>
<ng-template #unauthenticated>
    <ng-container *ngIf="(scope.routes$ | async) as routes">
        <ng-container *ngFor="let route of routes.unauthenticatedRoutes">
            <a mat-list-item *ngIf="!route.externalLink" [routerLink]="route.link">
                {{scope.getText | transform:route.textMapKey}}
                <ng-container *ngIf="route.link == '/dashboard' && (scope.incompleteActions$ | async).length > 0">
                    <mat-chip color="accent" selected [innerText]="(scope.incompleteActions$ | async).length"></mat-chip>
                </ng-container>
            </a>
            <a mat-list-item *ngIf="route.externalLink" [href]="route.externalLink" [innerText]="scope.getText | transform:route.textMapKey"></a>
        </ng-container>
    </ng-container>
</ng-template>
</ng-container>