import { Component, OnInit } from "@angular/core";
import { UntypedFormGroup } from "@angular/forms";
import { FieldConfig } from "@career/pocket-forms/models/field";
@Component({
  selector: "app-checkbox",
  template: `
<div class="input-wrapper" [formGroup]="group" [ngClass]="{'_error-handler': (group | hasValidationErrors:field)}">
  <ng-container *ngFor="let validation of (group | getValidationErrors:field)">
    <p *ngIf="group.get(field.name).hasError(validation.name)" class="_error-handler-message">{{validation.message}}</p>
  </ng-container>
  <label [for]="field.name">{{field.label}}</label>
  <input type="checkbox" [formControlName]="field.name">
</div>
`,
  styles: []
})
export class CheckboxComponent implements OnInit {
  field: FieldConfig;
  group: UntypedFormGroup;
  constructor() {}
  ngOnInit() {}
}