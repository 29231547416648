import { Component, OnInit, Input } from '@angular/core';
import { Validators } from '@angular/forms';
import { FieldConfig } from '@career/pocket-forms/models/field';

@Component({
  selector: 'app-default-login',
  templateUrl: './default-login.component.html',
  styleUrls: ['./default-login.component.scss']
})
export class DefaultLoginComponent implements OnInit {

  @Input() scope;
  constructor() { }

  ngOnInit(): void {
  }

}
