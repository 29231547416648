import { Component, OnInit, Inject } from "@angular/core";
import { ErrorSummary } from "@career/core/models/error.model";
import { PortalService } from "@career/core/services/portal.service";
import { QuestionnaireService } from "@career/core/services/questionnaire.service";
import { CoreAction } from "@career/core/store/actions/core.actions";
import { CoreState } from "@career/core/store/states/core.state";
import {
  ConfigMessages,
  SliderConfigs,
} from "@career/page-configuration/configs/page-config.config";
import {
  ConfigQuestion,
  GlobalConfig,
  PageConfigTypes,
  SelectOption,
  UpdateConfigRes,
} from "@career/page-configuration/models/page-config.model";
import { Store } from "@ngxs/store";
import { ACTIVE_LAYER_CONFIG, LayerConfig, LayerOverlayRef } from "kleos-ui";
import { Subscription } from "rxjs";
import { map } from "rxjs/operators";

@Component({
  selector: "app-edit-configurations",
  templateUrl: "./edit-configurations.component.html",
  styleUrls: ["./edit-configurations.component.scss"],
})
export class EditConfigurationsComponent implements OnInit {
  pageConfigType = PageConfigTypes;
  configMessages: typeof ConfigMessages = ConfigMessages;
  configType: string;
  checked = true;
  questionsList: ConfigQuestion[];
  sliderToggleValues: any = {};
  subscriptions: Subscription[] = [];
  adminQuestionnaireList: SelectOption[] = [];
  error: any;

  constructor(
    private portalService: PortalService,
    private store: Store,
    private questionnaireSvc: QuestionnaireService,
    private layerRef: LayerOverlayRef,
    @Inject(ACTIVE_LAYER_CONFIG) public config: LayerConfig,
  ) {}

  ngOnInit(): void {
    this.configType = this.config.data.configType;
    this.questionsList = SliderConfigs[this.configType];
    this.sliderToggleValues = this.config.data.configData;
    if (this.configType === PageConfigTypes.GLOBAL) {
      this.getQuestionnaireList();
    }
  }

  getQuestionnaireList(): void {
    const sub = this.questionnaireSvc
      .getAdminQuestionnairesList()
      .pipe(
        map((questions) => {
          return questions.map((question) => {
            return {
              name: question.name,
              id: question._id,
            };
          });
        })
      )
      .subscribe((questions) => {
        this.adminQuestionnaireList = questions;
      });
    this.subscriptions.push(sub);
  }

  onSave(): void {
    const sub = this.portalService
      .updateConfigurations({
        configuration: this.sliderToggleValues,
      })
      .pipe(map((res: UpdateConfigRes) => res.configuration))
      .subscribe(
        (res: GlobalConfig) => {
          this.error = null;
          this.store.dispatch(new CoreAction.UpdateConfiguration(res));
          this.layerRef.close(res);
        },
        (err) => {
          this.error = new ErrorSummary(
            err.message || "Unknown",
            `Error Code: ${err.status || "Unknown"}`
          );
        }
      );
    this.subscriptions.push(sub);
  }

  ngOnDestroy(): void {
    this.subscriptions.forEach((sub) => sub.unsubscribe());
  }
}
