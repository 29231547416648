<div class="data-hero data-hero--min-height">
    <div class="header" appImgEditContainer="login" name="Main Image">
        <div class="header__img-container">
            <img [src]="scope.component.assets.image" [alt]="scope.getText | transform:'LOGIN'" class="header__img">
        </div>
        <div class="header__overlay header__overlay--top">
            <div class="header__title-container">
                <div class="header__title-row header__title-row--slim">
                    <h1 class="header__title" [innerText]="scope.getText | transform:'LOGIN'"></h1>
                </div>
            </div>
        </div>
    </div>
    <div class="data-hero__text-container">
        <app-error-summary [error]="scope.error" *ngIf="scope.error"></app-error-summary>
        <div [ngClass]="{'disabled--loading': scope.loading}">
            <div class="data-hero__heading-container">
                <p class="data-hero__text" [innerText]="scope.getText | transform:'LOGIN_DESC'"></p>
            </div>
            <dynamic-form (submit)="scope.submitForm($event)" [fields]="scope.loginConfig"></dynamic-form>
            <div class="data-hero__button-container" disabledDiv>
                <button class="data-hero__button" mat-stroked-button
                    (click)="scope.forgotPassword()">{{scope.getText | transform:'FORGOT_PASSWORD?'}}</button>
            </div>
        </div>
    </div>
</div>