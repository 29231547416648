import { Component, Input, OnInit } from '@angular/core';

@Component({
  selector: 'app-default-error-form-summary',
  template: `
  <div class="error-summary" aria-labelledby="error-summary-title" role="alert" tabindex="-1" aria-live="assertive" aria-atomic="false" [attr.id]="scope.id">
    <ng-container *ngIf="scope.errors && scope.errors.length > 0">
      <h2 class="error-summary__title" id="error-summary-title">
        <ng-container *ngIf="scope.errors.length > 1">
          {{scope.getText | transform:'THERE_ARE_ERRORS_IN_SUBMISSION':{numberOfErrors: scope.errors.length} }}
        </ng-container>
        <ng-container *ngIf="scope.errors.length < 2">
        {{scope.getText | transform:'THERE_IS_ONE_ERROR_IN_SUBMISSION' }}
        </ng-container>
      </h2>
      <ul class="error-summary__list">
        <li *ngFor="let error of scope.errors">
            <a class="error-summary__link" (click)="scope.focus(error.id)" tabindex="0" role="link">
              <strong>{{error.label}} - </strong>
              {{error.message}}
            </a>
        </li>
      </ul>
    </ng-container>
  </div>
`
})
export class DefaultErrorFormSummaryComponent implements OnInit {
  @Input() scope;
  constructor() { }

  ngOnInit(): void {
  }

}
