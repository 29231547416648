<div class="data-hero data-hero--min-height">
    <div class="header" appImgEditContainer="forgot-password" name="Main Image">
        <div class="header__img-container">
            <img [src]="scope.component.assets.image" [alt]="scope.getText | transform:'FORGOT_PASSWORD?'" class="header__img">
        </div>
        <div class="header__overlay header__overlay--top">
            <div class="header__title-container">
                <div class="header__title-row header__title-row--slim">
                    <h1 class="header__title" [innerText]="scope.getText | transform:'FORGOT_PASSWORD?'"></h1>
                </div>
            </div>
        </div>
    </div>
    <div class="data-hero__text-container">
        <app-error-summary [error]="scope.error" *ngIf="scope.error"></app-error-summary>
        <ng-container *ngIf="!scope.success">
            <div class="data-hero__heading-container">
                <p class="data-hero__text" [innerText]="scope.getText | transform:'FORGOT_PASSWORD_DESC'"></p>
            </div>
            <dynamic-form (submit)="scope.submitForm($event)" [groupValidators]="scope.groupValidators" [fields]="scope.forgotPasswordConfig" [group]></dynamic-form>
        </ng-container>
        <ng-container *ngIf="scope.success">
            <h1 class="data-hero__main-heading" [innerText]="scope.getText | transform:'SUCCESS'"></h1>
            <p class="data-hero__text" [innerText]="scope.getText | transform:'CHECK_EMAIL_TO_RESET'"></p>
        </ng-container>
    </div>
</div>