import { ChangeDetectorRef, Component, Input, OnInit, ViewChild } from '@angular/core';
import { ActivatedRoute } from '@angular/router';
import { ActionsDialogService } from '@career/actions/services/actions-dialog.service';
import { ActionsService } from '@career/actions/services/actions-service.service';
import { TemplateConfiguration, TemplateComponent } from '@career/core/models/component.model';
import { ErrorSummary } from '@career/core/models/error.model';
import { CandidateService } from '@career/core/services/candidate.service';
import { PortalService } from '@career/core/services/portal.service';
import { UserService } from '@career/core/services/user.service';
import { UtilsService } from '@career/core/services/utils.service';
import { userComponentDef } from '@career/user/component-definition';
import { UserState } from '@career/user/store/states/user.state';
import { Store } from '@ngxs/store';
import { NgOtpInputComponent } from 'ng-otp-input';
import { Observable, of, ReplaySubject, Subject, Subscription } from 'rxjs';
import { catchError, filter, map, shareReplay, switchMap, take } from 'rxjs/operators';

@Component({
  selector: 'app-verify-phone',
  template: `
  <app-base-template [component]="component" [componentDef]="componentDef" [scope]="this" *ngIf="component"></app-base-template>
  `
})
export class VerifyPhoneComponent implements OnInit {
  config: TemplateConfiguration;
  component: TemplateComponent;
  componentDef = userComponentDef['verify-phone'];
  portalServiceSubscription: Subscription;
  getText: Function;
  show$: Observable<any>;
  updated = false;
  loading$: Subject<boolean> = new ReplaySubject(1);
  error: ErrorSummary|undefined;
  constructor(private portalService: PortalService, private store: Store, 
    private changeDetectorRef: ChangeDetectorRef, private userSvc : UserService, private candidateService: CandidateService, 
    private utils: UtilsService) { }

  requestPin() {
    this.error = null;
    this.updated = false;
    this.loading$.pipe(
      switchMap(loading => {
        if( !loading ) {
          this.loading$.next(true);
          return this.userSvc.getUser(); 
        }
        return of(null);
      }),
      filter(u => !!u),
      take(1),
      switchMap(user => this.candidateService.resendMobileVerificationPin(user._id))
    ).subscribe(
      (resp) => {
        if( resp ) {
          this.updated = true;
        }
        this.loading$.next(false);
      },
      (err) => {
        if( err.status === 409 ) {
          this.error = new ErrorSummary(this.getText("PIN_REQUESTED_TOO_SOON"), `Error Code: ${err.status}`);
        } else {
          this.error = new ErrorSummary(this.getText("RESEND_PIN_ERROR"), `Error Code: ${err.status}`);
        }
        this.changeDetectorRef.markForCheck();
        this.loading$.next(false);
      }
    );
  }

  verify(pin: string): Observable<number> {
    this.error = null;
    return this.loading$.pipe(
      switchMap(loading => {
        if( !loading ) {
          this.loading$.next(true);
          return this.userSvc.getUser(); 
        }
        return of(null);
      }),
      filter(u => !!u),
      take(1),
      switchMap(user => this.candidateService.verifyMobileNumber(pin, user._id)),
      catchError(_ => of(500)),
      map(status => {
          if( status === 500 ) {
            this.error = new ErrorSummary(this.getText('UNKNOWN_ISSUE'));
          } else if( status === 404 ) {
            this.error = new ErrorSummary(this.getText('WRONG_PIN_PROVIDED'));
          } else if( status === 410 ) {
            this.error = new ErrorSummary(this.getText('PIN_EXPIRED'));
          }
          return status;
      }),
      map(status => {
        this.loading$.next(false);
        this.changeDetectorRef.markForCheck();
        return status;
      })
    );
  }
  
  showIfValid(user: any) {
    if( this.portalService.isPreferencesEnabled() ) {
        if( user.login.prefersText && (user.phones || []).length > 0 && !user.phones.some(phoneObj => phoneObj.location === 'MOBILE' && phoneObj.verifiedDate) ) {
          this.loading$.next(false);
          return true;
        }
    }
    return false;
  }

  ngOnInit() {
    this.portalServiceSubscription = this.portalService.getComponentData('verify-phone').subscribe((data) => {
      this.config = data.configuration;
      this.component = data.component;
      this.getText = this.utils.getText(this.component);
      this.show$ = this.store.select(UserState.user).pipe(
        switchMap(_ => this.userSvc.getUser()),
        map(user => this.showIfValid(user))
      )
    });
  }

  ngOnDestroy() {
    this.portalServiceSubscription.unsubscribe();
  }
}
