import {
  Component,
  OnDestroy,
  OnInit,
  ApplicationRef,
  ChangeDetectorRef,
} from "@angular/core";
import { Location } from "@angular/common";
import {
  ActivatedRoute,
  NavigationEnd,
  NavigationStart,
  ParamMap,
  Router,
} from "@angular/router";
import { Meta, Title } from "@angular/platform-browser";
import { PortalService } from "./core/services/portal.service";
import { routerActions } from "./routes";
import { Actions, ofActionCompleted, ofActionDispatched, Store } from "@ngxs/store";
import { CoreAction } from "./core/store/actions/core.actions";
import { Subscription, BehaviorSubject } from "rxjs";
import { filter, map } from "rxjs/operators";
import { UserService } from "./core/services/user.service";
import { MatLegacyDialog as MatDialog } from "@angular/material/legacy-dialog";
import { ConfirmDialogComponent } from "./page-configuration/Shared/components/confirm-dialog/confirm-dialog.component";
import { UserAction } from "./user/store/actions/user.actions";
import { GoogleTagManagerService } from "ng-google-tag-manager";
@Component({
  selector: "app-root",
  templateUrl: "./app.component.html",
})
export class AppComponent implements OnInit, OnDestroy {
  url: string;
  showPageConfiguration: boolean;
  favIcon: HTMLLinkElement = document.querySelector("#favIcon");
  subs: Subscription[] = [];
  isUserLoggedIn: boolean;
  isPreviewMode: boolean;
  constructor(
    private title: Title,
    private portalService: PortalService,
    private router: Router,
    private store: Store,
    private route: ActivatedRoute,
    private meta: Meta,
    private userSvc: UserService,
    private dialog: MatDialog,
    private googleTagManagerService: GoogleTagManagerService,
    private actions$: Actions
  ) {}

  ngOnInit() {
    if (routerActions.needsRefresh()) {
      this.router.resetConfig(routerActions.getRoutes());
    }
    this.checkPageConfiguration();
    this.getPortalObj();
    this.getRouteParams();
    this.actions$.pipe(ofActionDispatched(UserAction.Changed)).subscribe(_ => {
      this.checkPageConfiguration();
    });
  }

  checkPageConfiguration() {
    this.userSvc.isAdmin().subscribe((res) => {
      this.showPageConfiguration = res;
    });
    this.userSvc.isLoggedInUserAdmin().subscribe((res) => {
      this.isUserLoggedIn = res;
      if( !res ) {
        this.googleTagManagerService.addGtmToDom()
        .catch(_ => {
          console.warn("Unable to add Google Tag Manager");
        })
      }
    });
    this.portalService.isPreviewMode().subscribe(
      (res) => (this.isPreviewMode = res)
    );
  }

  onPublish(): void {
    const dialgRef = this.dialog.open(ConfirmDialogComponent, {
      minWidth: "500px",
      maxWidth: "600px",
      disableClose: true,
    });
    dialgRef.afterClosed().subscribe((isSuccess) => {
      if (isSuccess) {
        this.onGoBack();
      }
    });
  }

  getPortalObj() {
    const sub = this.portalService.getPortalObj().subscribe((data) => {
      this.url = this.router.url;
      this.favIcon.href = data.favicon;
      this.title.setTitle(`${data.configuration.seoTitle || "Careers"} | ${data.portalName}`);
      if( data.configuration.seoDescription ) {
        this.meta.addTags([
          {name: "description", content: data.configuration.seoDescription},
          {name: 'og:description', content: data.configuration.seoDescription},
          {name: 'twitter:description', content: data.configuration.seoDescription}
        ]);
      } else {
        const footerText = data.components["footer"].textMap["FOOTER_TEXT"];
        this.meta.addTag({
          name: "description",
          content:
            footerText ||
            `${
              data.portalName || "My"
            } Career Portal. Apply to jobs, check your application status and more.`,
        });
      }

      if( data.configuration.seoKeywords ) {
        this.meta.addTag({name: 'keywords', content: data.configuration.seoKeywords});
      }
      if( data.configuration.seoTitle ) {
        this.meta.addTags([
          {name: 'og:title', content: data.configuration.seoTitle},
          {name: 'twitter:title', content: data.configuration.seoTitle},
          {name: 'og:type', content: 'website'}
        ]);
      }
      if( data.configuration.seoOgImage ) {
        this.meta.addTags([
          {name: 'og:image', content: data.configuration.seoOgImage},
          {name: 'twitter:card', content: data.configuration.seoOgImage}
        ]);
      }
    });
    this.subs.push(sub);
  }

  onGoBack(): void {
    this.portalService.togglePreviewMode();
    this.router.navigate(["/admin/pageConfiguration"]);
  }

  getRouteParams() {
    const sub = this.route.queryParamMap.subscribe((params: ParamMap) => {
      if (params.has("src")) {
        this.store.dispatch(
          new CoreAction.SetSource({ source: params.get("src") })
        );
      }
    });
    this.subs.push(sub);
  }

  ngOnDestroy() {
    this.subs.forEach((sub) => sub.unsubscribe());
  }
}
