import { NgModule } from '@angular/core';
import { CommonModule } from '@angular/common';
import { SuggestionsComponent } from './components/suggestions/suggestions.component';
import { DefaultSuggestionsComponent } from './components/suggestions/templates/default-suggestions/default-suggestions.component';
import { CoreModule } from '@career/core/core.module';
import { SuggestionsRoutingModule } from './suggestions-routing.module';
import { MatLegacyButtonModule as MatButtonModule } from '@angular/material/legacy-button';
import { MatLegacyProgressBarModule as MatProgressBarModule } from '@angular/material/legacy-progress-bar';



@NgModule({
  declarations: [
    SuggestionsComponent,
    DefaultSuggestionsComponent
  ],
  imports: [
    CommonModule,
    CoreModule,
    MatButtonModule,
    MatProgressBarModule,
    SuggestionsRoutingModule
  ]
})
export class SuggestionsModule { }
