import { DefaultResetPasswordComponent } from './components/reset-password/templates/default/default-reset-password.component';
import { DefaultLoginComponent } from "./components/login/templates/default/default-login.component";
import { SocialLoginComponent } from "./components/login/templates/social/social-login.component";
import { DefaultRegisterComponent } from './components/register/templates/default/default-register.component';
import { SocialRegisterComponent } from './components/register/templates/social/social-register.component';
import { SocialForgotPasswordComponent } from './components/forgot-password/templates/social/social-forgot-password.component';
import { DefaultForgotPasswordComponent } from './components/forgot-password/templates/default/default-forgot-password.component';
import { SocialResetPasswordComponent } from './components/reset-password/templates/social/social-reset-password.component';

export const authComponentDef = {
    "login": {
        "default": DefaultLoginComponent,
        "social": SocialLoginComponent
    },
    "register": {
        "default": DefaultRegisterComponent,
        "social": SocialRegisterComponent
    },
    "forgot-password": {
        "default": DefaultForgotPasswordComponent,
        "social": SocialForgotPasswordComponent
    },
    "reset-password": {
        "default": DefaultResetPasswordComponent,
        "social": SocialResetPasswordComponent
    }
}