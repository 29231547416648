import { Injectable, OnInit } from '@angular/core';
import { MatLegacyDialog as MatDialog } from '@angular/material/legacy-dialog';
import { ActivatedRoute } from '@angular/router';
import { Store } from '@ngxs/store';
import { Subscription } from 'rxjs';
import { map } from 'rxjs/operators';
import { ActionsDialogComponent } from '../components/actions-dialog/actions-dialog.component';
import { EDialogType } from '../models/action-dialog-types';
import { ActionsAction } from '../store/actions/actions.actions';
import { ActionsState } from '../store/states/actions.state';
import { ActionsService } from './actions-service.service';
@Injectable({
  providedIn: 'root'
})
export class ActionsDialogService {

  constructor(private dialog: MatDialog, private actionsService: ActionsService, private store: Store) { }
  open(opportunityId?: string) {
    this.dialog.open(ActionsDialogComponent, {
      maxWidth: '750px',
      width: '90vw',
      maxHeight: '90vh',
      closeOnNavigation: true,
      data: {
        actions$: opportunityId?this.actionsService.getWorkflowActions(opportunityId):this.actionsService.getProfileActions(),
        opportunityId: opportunityId
      }
    });
  }
}
