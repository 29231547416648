import { Component, OnInit } from '@angular/core';

@Component({
  selector: 'app-page-not-allowed',
  templateUrl: './page-not-allowed.component.html'
})
export class PageNotAllowedComponent implements OnInit {

  constructor() { }

  ngOnInit() {
  }

}
