<app-error-summary [error]="scope.error" *ngIf="scope.error"></app-error-summary>
<div #banner>
  <div class="banner" *ngIf="scope.updated">
    <h2 class="banner__text" [innerText]="scope.getText | transform:'AVAILABILITY_UPDATED'"></h2>
  </div>
</div>
<div class="heading">
  <p [innerText]="scope.getText | transform:'AVAILABILITY_INSTRUCTIONS'"></p>
</div>
<div disabledDiv>
  <div class="calendar__events" [ngClass]="{'disabled--loading': scope.loading}">
    <div class="calendar__header">
      <button class="calendar__button" mat-stroked-button (click)="scope.deleteDate()"
        [disabled]="!scope.selection.hasValue()" [innerText]="scope.getText | transform:'DELETE_DAYS'"></button>
      <button class="calendar__button" mat-stroked-button (click)="scope.addDate()"
        [innerText]="scope.getText | transform:'ADD_DAYS'"></button>
      <div class="calendar__save">
        <button mat-flat-button color="primary" class="calendar__button" (click)="scope.save()"
          [innerText]="scope.getText | transform:'SAVE_DAYS'"></button>
      </div>
    </div>
    <div class="table__container">
      <table mat-table [dataSource]="scope.events" class="mat-elevation-z0 table">
        <ng-container matColumnDef="select">
          <th mat-header-cell *matHeaderCellDef>
            <mat-checkbox (change)="$event ? scope.toggleAllRows() : null"
              [checked]="scope.selection.hasValue() && scope.isAllSelected()"
              [indeterminate]="scope.selection.hasValue() && !scope.isAllSelected()"
              [aria-label]="scope.checkboxLabel()">
            </mat-checkbox>
          </th>
          <td mat-cell *matCellDef="let event">
            <mat-checkbox class="calendar__checkbox" (click)="$event.stopPropagation()"
              (change)="$event ? scope.selection.toggle(event) : null" [checked]="scope.selection.isSelected(event)"
              [aria-label]="scope.checkboxLabel(event)">
            </mat-checkbox>
          </td>
        </ng-container>
        <ng-container matColumnDef="start">
          <th mat-header-cell *matHeaderCellDef [innerText]="scope.getText | transform:'START_DATE'"></th>
          <td mat-cell *matCellDef="let event" class="table__td">
            <div class="table__data"
              [ngClass]="{'table__data--invalid': event.meta?.error && event.meta?.startInvalid}">
              <mat-form-field appearance="outline">
                <input matInput [min]="scope.minDate" [id]="scope.getStartPickerId | transform:event.id"
                  [(ngModel)]="event.start" (dateChange)="scope.refreshCalendar($event)" [matDatepicker]="startPicker"
                  [matDatepickerFilter]="scope.pickerFilter | transform:event:scope">
                <mat-datepicker-toggle matSuffix [for]="startPicker"></mat-datepicker-toggle>
                <mat-datepicker #startPicker></mat-datepicker>
              </mat-form-field>
              <mat-error *ngIf="event.meta?.error && event.meta?.startInvalid">{{event.meta.error}}</mat-error>
            </div>
          </td>
        </ng-container>
        <ng-container matColumnDef="end">
          <th mat-header-cell *matHeaderCellDef [innerText]="scope.getText | transform:'END_DATE'"></th>
          <td mat-cell *matCellDef="let event" class="table__td">
            <div class="table__data" [ngClass]="{'table__data--invalid': event.meta?.error && event.meta?.endInvalid}">
              <mat-form-field appearance="outline">
                <input matInput [id]="scope.getEndPickerId | transform:event.id" [min]="scope.minDate"
                  [max]="scope.maxDate" [(ngModel)]="event.end" (dateChange)="scope.refreshCalendar($event)"
                  [matDatepickerFilter]="scope.pickerFilter | transform:event:scope:true" [matDatepicker]="endPicker">
                <mat-datepicker-toggle matSuffix [for]="endPicker"></mat-datepicker-toggle>
                <mat-datepicker #endPicker></mat-datepicker>
              </mat-form-field>
              <mat-error *ngIf="event.meta?.error && event.meta?.endInvalid">{{event.meta.error}}</mat-error>
            </div>
          </td>
        </ng-container>
        <ng-container matColumnDef="period">
          <th mat-header-cell *matHeaderCellDef [innerText]="scope.getText | transform:'TIME_AVAILABLE'"></th>
          <td mat-cell *matCellDef="let event">
            <mat-button-toggle-group [(ngModel)]="event.allDay"
              [attr.aria-label]="scope.getText | transform:'TIME_AVAILABLE'">
              <mat-button-toggle [value]="false">Half Days</mat-button-toggle>
              <mat-button-toggle [value]="true">Full Days</mat-button-toggle>
            </mat-button-toggle-group>
          </td>
        </ng-container>
        <tr mat-header-row *matHeaderRowDef="scope.displayedColumns"></tr>
        <tr mat-row *matRowDef="let row; columns: scope.displayedColumns;"></tr>
      </table>
    </div>
  </div>
  <div class="calendar__container">
    <div class="calendar__heading-container">
      <h3 class="calendar__heading">{{ scope.viewDate | calendarDate:(scope.view + 'ViewTitle'):'en' }}</h3>
    </div>
    <div class="navigation">
      <div class="navigation__buttons">
        <button class="navigation__previous" mat-stroked-button (click)="scope.decrement()"
          [disabled]="scope.prevBtnDisabled" [innerText]="scope.getText | transform:'PREVIOUS_MONTH'">
        </button>
        <button class="navigation__next" mat-stroked-button [disabled]="scope.nextBtnDisabled"
          (click)="scope.increment()" color="primary" [innerText]="scope.getText | transform:'NEXT_MONTH'">
        </button>
      </div>
      <div class="navigation__key">
        <p [innerText]="scope.getText | transform:'KEY'"></p>
        <p>
          <span class="navigation__badge" matBadgeColor="warn" matBadge="1" matBadgeOverlap="false"
            matBadgePosition="before" [innerText]="scope.getText | transform:'AVAILABILITY_REGISTERED'"></span>
        </p>
      </div>
    </div>
    <mwl-calendar-month-view [events]="scope.events" (dayClicked)="scope.focus($event)" [refresh]="scope.refresh"
      [viewDate]="scope.viewDate">
    </mwl-calendar-month-view>
  </div>
</div>