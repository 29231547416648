import { ResumeUploadActionStrategy } from "@career/actions/models/strategies/resume-upload-action-strategy";
import { merge, Observable } from "rxjs";
import { ActionDataSourceService } from "../services/action-data-source.service";
import { EActionType, IAction } from "./action.model";
import { AvailabilityActionStrategy, CompleteQuestionnaireActionStrategy, FirstSignInActionStrategy, IncompleteQuestionnaireActionStrategy, SkillsActionStrategy } from "./strategies";
export class ActionFactory {
    constructor(private dataSourceService: ActionDataSourceService) {}

    createActions(type: EActionType): Observable<IAction> {
        switch(type) {
            case EActionType.QUESTIONNAIRES:
               return merge(
                    new IncompleteQuestionnaireActionStrategy(this.dataSourceService).createActions(),
                    new CompleteQuestionnaireActionStrategy(this.dataSourceService).createActions()
                );
            case EActionType.PROFILE:
                return merge(
                    new AvailabilityActionStrategy(this.dataSourceService).createActions(),
                    new SkillsActionStrategy(this.dataSourceService).createActions(),
                    new FirstSignInActionStrategy(this.dataSourceService).createActions(),
                    new ResumeUploadActionStrategy(this.dataSourceService).createActions()
                );
            default:
                throw new Error("No action type exists matching token");
        }
    }
}