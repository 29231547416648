import { Component, OnDestroy, OnInit } from "@angular/core";
import { Router, Routes } from "@angular/router";
import { UserAuthService } from "@career/core/services/user-auth.service";
import { coreComponentDef } from "../../component-definition";
import { PortalService } from "@career/core/services/portal.service";
import { routerActions } from "@career/routes";
import { Store } from "@ngxs/store";
import { ActionsState } from "@career/actions/store/states/actions.state";
import { ActionsService } from "@career/actions/services/actions-service.service";
import { Subscription } from "rxjs";
import { map, switchMap } from "rxjs/operators";
import {
  INavRoute,
  UNAUTHENTICATED_NAV_ROUTES,
  AUTHENTICATED_NAV_ROUTES,
} from "../nav/constants/nav.constant";
import {
  TemplateComponent,
  TemplateConfiguration,
} from "@career/core/models/component.model";
import { AuthState } from "@career/authentication/store/states/auth.state";
import { UtilsService } from "@career/core/services/utils.service";
import { UploadImageIcon } from "@career/core/models/update-image-icon.enum";
import { UserService } from "@career/core/services/user.service";
import { GoogleTagManagerService } from "ng-google-tag-manager";

@Component({
  selector: "app-footer",
  template: `
    <app-base-template
      [component]="component"
      [componentDef]="componentDef"
      [scope]="this"
      *ngIf="component"
    ></app-base-template>
  `,
})
export class FooterComponent implements OnInit, OnDestroy {
  config: TemplateConfiguration;
  loggedIn: boolean;
  isInIframe: boolean;
  toggled: boolean = false;
  component: TemplateComponent;
  getText: Function;
  portalServiceSubscription;
  componentDef = coreComponentDef["footer"];
  portalName: string;
  year: number = new Date().getFullYear();
  private dynamicRoutes: INavRoute[] = this.mapToNavRoutes(
    routerActions.getDynamicRoutes()
  ).filter(o => !o.hideNavigation);
  private routesWithOrder = routerActions.getSortableRouteOrders();
  unauthenticatedRoutes: INavRoute[];
  authenticatedRoutes: INavRoute[];
  incompleteActions$ = this.actionsService.getIncompleteActions();
  userChanged$ = this.store.select(AuthState.token);
  footerTextExists: boolean;
  hasGtmEnabled: boolean;
  uploadImageIcon = UploadImageIcon;
  isAdmin$ = this.userSvc.isAdmin();
  isPreviewMode$ = this.portalService.isPreviewMode();

  constructor(
    private router: Router,
    private store: Store,
    private userSvc: UserService,
    private portalService: PortalService,
    private actionsService: ActionsService,
    private utils: UtilsService
  ) {}

  private mapToNavRoutes(routes: Routes): INavRoute[] {
    return routes.map((x) => {
      return {
        textMapKey: x.data.displayName,
        link: x.path,
        order: x.data.order,
        hideNavigation: x.data.hideNavigation
      };
    });
  }

  private setupNavRoutesOrder(navRoutes: INavRoute[], orderRouteMap) {
    navRoutes.forEach((route) => {
      if (orderRouteMap[route.link]) {
        route.order = orderRouteMap[route.link];
      }
    });

    return navRoutes;
  }

  initNavRoutes() {
    const jobs = UNAUTHENTICATED_NAV_ROUTES.findIndex(
      (route) => route.link === "/jobs"
    );
    if (jobs > -1 && this.config.forwardJobSearchPage) {
      UNAUTHENTICATED_NAV_ROUTES[jobs].externalLink =
        this.config.forwardJobSearchPage;
    }
    (this.unauthenticatedRoutes = [
      ...this.setupNavRoutesOrder(
        UNAUTHENTICATED_NAV_ROUTES,
        this.routesWithOrder
      ),
      ...this.dynamicRoutes,
    ]
      .filter((route) => route.link !== "/" || this.config.enableHomePage)
      .filter(
        (route) => route.link !== "/jobs" || !this.config.disableJobSearchPage
      )
      .filter(
        (route) =>
          route.link !== "/register" || !this.config.disableRegisterPage
      )
      .filter(
        (route) =>
          route.link !== "/suggestions" || this.config.enableSuggestions
      )),
      (this.authenticatedRoutes = [
        ...this.setupNavRoutesOrder(
          AUTHENTICATED_NAV_ROUTES,
          this.routesWithOrder
        ),
        ...this.dynamicRoutes,
      ]
        .filter(
          (route) => route.link !== "/jobs" || !this.config.disableJobSearchPage
        )
        .filter(
          (route) =>
            route.link !== "/register" || !this.config.disableRegisterPage
        )
        .sort((a, b) => a.order - b.order));
  }

  register(): void {
    this.router.navigate(["/register"]);
  }

  jobs(): void {
    this.router.navigate(["/jobs"]);
  }

  login(): void {
    this.router.navigate(["/login"]);
  }

  logout(): void {
    this.router.navigate(["/logout"], { skipLocationChange: true });
  }

  dashboard(): void {
    this.router.navigate(["/dashboard"]);
  }

  vacancies(): void {
    this.router.navigate(["/jobs"]);
  }

  toggleBurgerItems(): void {
    this.toggled = !this.toggled;
  }

  inIframe(): boolean {
    try {
      this.isInIframe = window.self !== window.top;
    } catch (e) {
      this.isInIframe = true;
    }

    return this.isInIframe;
  }

  blockIframe(): void {
    if (this.inIframe()) {
      this.router.navigate(["/405"]);
    }
  }

  exclusiveIframe(): void {
    if (!this.inIframe()) {
      this.router.navigate(["/405"]);
    }
  }

  ngOnInit() {
    this.portalServiceSubscription = this.portalService
      .getComponentData("footer")
      .subscribe((data) => {
        this.config = data.configuration;
        this.hasGtmEnabled = data.configuration.googleTagManagerId;
        this.component = data.component;
        this.portalName = this.portalService.getPortalName();
        this.getText = this.utils.getText(this.component);
        this.footerTextExists = this.getText("FOOTER_TEXT") !== "FOOTER_TEXT";
        this.initNavRoutes();
      });
  }

  ngOnDestroy() {
    this.portalServiceSubscription.unsubscribe();
  }
}
