import { Component, Input, OnDestroy, OnInit } from '@angular/core';
import { faExclamationTriangle } from '@fortawesome/free-solid-svg-icons';
import { Store } from '@ngxs/store';
import { actionsComponentDef } from '@career/actions/component-definition';
import { EDialogType } from '@career/actions/models/action-dialog-types';
import { IAction, OpportunityAction } from '@career/actions/models/action.model';
import { ActionsDialogService } from '@career/actions/services/actions-dialog.service';
import { ActionsService } from '@career/actions/services/actions-service.service';
import { ActionsState } from '@career/actions/store/states/actions.state';
import { TemplateComponent, TemplateConfiguration } from '@career/core/models/component.model';
import { PortalService } from '@career/core/services/portal.service';
import { Observable, Subscription } from 'rxjs';
import { filter } from 'rxjs/operators';

@Component({
  selector: 'app-action-required-warning',
  template: `
  <app-base-template [component]="component" [componentDef]="componentDef" [scope]="this" *ngIf="component"></app-base-template>
  `
})
export class ActionRequiredWarningComponent implements OnInit {

  @Input() user;
  @Input() opportunity;
  faExclamationTriangle = faExclamationTriangle;
  @Input() incompleteActions: Observable<IAction[]>;
  @Input() completeActions: Observable<IAction[]>;
  portalServiceSubscription: Subscription;
  config: TemplateConfiguration;
  component: TemplateComponent;
  componentDef = actionsComponentDef['action-required-warning'];
  
  constructor(private actionsDialogSvc: ActionsDialogService, private portalService: PortalService) { }
  openDialog() {
    this.actionsDialogSvc.open(this.opportunity && this.opportunity._id);
  }

  filterOpp(actions: IAction[], scope: this) {
    if( !scope.opportunity ) {
      return actions;
    }
    return (actions || []).filter((action) => action instanceof OpportunityAction && action.opportunity._id === scope.opportunity._id);
  }

  ngOnInit(): void {
    this.portalServiceSubscription = this.portalService.getComponentData("action-required-warning").subscribe(data => {
      this.config = data.configuration;
      this.component = data.component;
    });
  }

}
