import { DOCUMENT } from '@angular/common';
import { AfterViewInit, Component, ElementRef, Inject, Input, OnChanges, OnInit, SimpleChanges, ViewChild } from '@angular/core';
import { UntypedFormGroup } from '@angular/forms';
import { TemplateComponent, TemplateConfiguration } from '@career/core/models/component.model';
import { PortalService } from '@career/core/services/portal.service';
import { formComponentDef } from '@career/pocket-forms/component-definition';
import { FieldConfig } from '@career/pocket-forms/models/field';
import { Subscription } from 'rxjs';
import { v4 as uuidv4 } from 'uuid';

@Component({
  selector: 'app-error-form-summary',
  template: `
  <app-base-template [component]="component" [componentDef]="componentDef" [scope]="this" *ngIf="component"></app-base-template>
  `
})
export class ErrorFormSummaryComponent implements OnChanges {
  component: TemplateComponent;
  config: TemplateConfiguration;
  @Input() form: UntypedFormGroup;
  @Input() fields: FieldConfig[];
  statusChanges: Subscription;
  portalServiceSubscription: Subscription;
  @Input() errors: {id: string, label: string, message: string}[] = [];
  id: string;
  componentDef = formComponentDef['error-form-summary'];
  constructor(@Inject(DOCUMENT) private document: Document, private portalService: PortalService) { }

  scrollIntoView(element: Element) {
    if( !element ) {
      return;
    }
    element.scrollIntoView({behavior: 'smooth', block: 'start'});
  }

  focus(id) {
    const elm = this.document.getElementById(id);
    if( !elm ) {
      return;
    }
    this.scrollIntoView(elm);
    elm.focus();
  }

  setErrors() {
    this.errors = [];
    for(const field of this.fields) {
      const control = this.form.get(field.name);
      if( !control ) {
        continue;
      }

      (field.validations || []).forEach(validation => {
        if( this.form.get(field.name).hasError(validation.name) ) {
          this.errors.push({id: field.name, label: field.label, message: validation.message});
        }
      })
    }
  }

  ngOnChanges(changes: SimpleChanges): void {
      if( Object.values(changes)[0].isFirstChange() ) {
        this.portalServiceSubscription = this.portalService.getComponentData("error-form-summary").subscribe(data => {
          this.config = data.configuration;
          this.component = data.component;
          this.init();
        });
      } else {
        this.init();
      }
  }

  init() {
    this.id = uuidv4();
    if( this.statusChanges ) {
      this.statusChanges.unsubscribe();
    }
    if( !this.form ) {
      return;
    }
    this.statusChanges = this.form.statusChanges.subscribe((change) => {
      this.setErrors();
      if( this.errors.length > 0 ) {
        this.scrollIntoView(this.document.getElementById(this.id));
      }
    });
  }
}
