import { DOCUMENT } from "@angular/common";
import {
  ElementRef,
  Input,
  Directive,
  OnInit,
  HostListener,
  Inject,
} from "@angular/core";

import { DialogService } from "@career/core/services/dialog.service";
import { UserService } from "../services/user.service";

@Directive({
  selector: "[appTextEditContainer]",
})
export class TextEditContainerDirective implements OnInit {
  @Input() appTextEditContainer: string;
  @Input() body = "";
  @Input() title = "";
  @Input() name = "";
  @Input() isStaticTemplate = false;
  isAdmin = false;
  isEditMode = false;

  constructor(
    private el: ElementRef,
    private dialogSvc: DialogService,
    private userSvc: UserService,
    @Inject(DOCUMENT) private document: Document
  ) {}

  @HostListener("click", ["$event"])
  onClick(e: PointerEvent) {
    if (!this.isAdmin) {
      return;
    }
    this.dialogSvc.editSectionContentDialog(
      this.appTextEditContainer,
      this.title,
      this.body,
      this.name,
      e,
      this.el,
      this.isStaticTemplate
    );
  }

  ngOnInit(): void {
    this.userSvc.isAdmin().subscribe((response) => {
      this.isAdmin = response;
      if (this.el.nativeElement.classList.contains("_editable")) {
        this.el.nativeElement.classList.remove("_editable");
        const spanElem = this.el.nativeElement.querySelector(
          "._editable__heading"
        );
        this.el.nativeElement.removeChild(spanElem);
      }
      if (this.isAdmin) {
        this.renderBodyBorder();
      }
    });
  }

  renderBodyBorder(): void {
    this.el.nativeElement.classList.add("_editable");
    const caption = this.document.createElement("span");
    caption.setAttribute("class", "_editable__heading");
    caption.innerHTML = this.name;
    this.el.nativeElement.appendChild(caption);
  }
}
