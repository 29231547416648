import { Component, OnInit, Input } from '@angular/core';

@Component({
  selector: 'app-default-reset-password',
  styleUrls: ['./default-reset-password.component.scss'],
  templateUrl: './default-reset-password.component.html'
})
export class DefaultResetPasswordComponent implements OnInit {

  @Input() scope;
  constructor() { }

  ngOnInit(): void {
  }

}
