import { NgModule } from "@angular/core";
import { CommonModule } from "@angular/common";
import { FormsModule } from "@angular/forms";
import { DefaultTemplateComponent } from "./templates/default/default-template.component";
import { StaticPageComponent } from "./static-page/static-page.component";
import { CoreModule } from "@career/core/core.module";
import { ExternalUrlComponent } from "./external-url/external-url.component";
import { SocialTemplateComponent } from "./templates/social/social-template.component";
import { AngularEditorModule } from "@kolkov/angular-editor";
import { PageConfigurationModule } from "@career/page-configuration/page-configuration.module";
import { MatIconModule } from '@angular/material/icon';
import { MatLegacyButtonModule as MatButtonModule } from '@angular/material/legacy-button';
import { RouterModule } from "@angular/router";

@NgModule({
  declarations: [
    DefaultTemplateComponent,
    SocialTemplateComponent,
    StaticPageComponent,
    ExternalUrlComponent,
  ],
  imports: [
    CommonModule,
    FormsModule,
    RouterModule,
    MatButtonModule,
    MatIconModule,
    PageConfigurationModule,
    CoreModule,
    AngularEditorModule,
  ],
})
export class StaticPagesModule {}
