import { Component, OnInit, Input, Output, EventEmitter } from '@angular/core';

@Component({
  selector: 'app-data-pills',
  templateUrl: './data-pills.component.html',
  styleUrls: ['./data-pills.component.scss']
})
export class DataPillsComponent implements OnInit {

  @Input() pills;
  @Input() invertBg;
  @Input() asLink = false;
  @Input() asLabel = false;
  @Input() isImage = false;
  @Input() isIconApplicable = true;

  @Output() linkClicked = new EventEmitter<any>();

  constructor() { }

  ngOnInit() {
    
  }

  onLinkClicked(id?: string) {
    this.linkClicked.next(id);
  }

}
