import { Component, OnDestroy, OnInit } from "@angular/core";
import { Router } from "@angular/router";
import { coreComponentDef } from "../../component-definition";
import { PortalService } from "@career/core/services/portal.service";
import { INavRoute } from "./constants/nav.constant";
import { Store } from "@ngxs/store";
import { ActionsService } from "@career/actions/services/actions-service.service";
import {
  TemplateComponent,
  TemplateConfiguration,
} from "@career/core/models/component.model";
import { AuthState } from "@career/authentication/store/states/auth.state";
import { NavService } from "@career/core/services/nav.service";
import { UploadImageIcon } from "@career/core/models/update-image-icon.enum";
import { CoreState } from "@career/core/store/states/core.state";
import { Observable, Subscription } from "rxjs";

@Component({
  selector: "app-nav",
  template: `
    <app-base-template
      [component]="component"
      [componentDef]="componentDef"
      [scope]="this"
      *ngIf="component"
    ></app-base-template>
  `,
})
export class NavComponent implements OnInit, OnDestroy {
  config: TemplateConfiguration;
  loggedIn: boolean;
  isInIframe: boolean;
  toggled: boolean = false;
  component: TemplateComponent;
  portalServiceSubscription: Subscription;
  routes$: Observable<any>;
  componentDef = coreComponentDef["nav"];
  portalName: string;
  unauthenticatedRoutes: INavRoute[];
  authenticatedRoutes: INavRoute[];
  incompleteActions$ = this.actionsService.getIncompleteActions();
  userChanged$ = this.store.select(AuthState.token);
  uploadImageIcon = UploadImageIcon;
  isPreviewMode$ = this.portalService.isPreviewMode();

  constructor(
    private router: Router,
    private navService: NavService,
    private store: Store,
    private portalService: PortalService,
    private actionsService: ActionsService
  ) {}

  register(): void {
    this.router.navigate(["/register"]);
  }

  jobs(): void {
    this.router.navigate(["/jobs"]);
  }

  login(): void {
    this.router.navigate(["/login"]);
  }

  logout(): void {
      this.router.navigate(["/logout"], { skipLocationChange: true });
  }

  dashboard(): void {
    this.router.navigate(["/dashboard"]);
  }

  vacancies(): void {
    this.router.navigate(["/jobs"]);
  }

  toggleBurgerItems(): void {
    this.toggled = !this.toggled;
  }

  exitPreview(): void {
    this.portalService.exitPreview();
  }

  inIframe(): boolean {
    try {
      this.isInIframe = window.self !== window.top;
    } catch (e) {
      this.isInIframe = true;
    }

    return this.isInIframe;
  }

  blockIframe(): void {
    if (this.inIframe()) {
      this.router.navigate(["/405"]);
    }
  }

  exclusiveIframe(): void {
    if (!this.inIframe()) {
      this.router.navigate(["/405"]);
    }
  }

  ngOnInit() {
    this.portalServiceSubscription = this.portalService
      .getComponentData("nav")
      .subscribe((data) => {
        this.config = data.configuration;
        this.component = data.component;
        this.portalName = this.portalService.getPortalName();
        switch (this.config.iFrameUsage) {
          case "EXCLUSIVE":
            this.exclusiveIframe();
            break;
          case "ENABLED":
            this.inIframe();
            break;
          default:
            this.blockIframe();
        }

        this.navService.setupRoutes(this.config);
      });

      this.routes$ = this.store.select(CoreState.routes);
  }

  ngOnDestroy() {
    this.portalServiceSubscription.unsubscribe();
  }
}
