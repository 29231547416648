export const DefaultStaticTemplateRes = {
  assets: {
    image:
      "https://prweb-assets-prod.s3.amazonaws.com/5deaad68c382972df89696c3/ERM Dashboard.jpg",
  },
  textMap: {
    routeDisplayName: "Recruitment Services",
    richText: "<p>Rich Test</p>",
    pageTitle: "Sample Page Title",
  },
  adminOnly: {
    staticPage: true,
    hideNavigation: false
  },
};
