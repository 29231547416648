import { Component, OnInit, Input, SimpleChanges } from '@angular/core';

@Component({
  selector: 'app-progress-bar',
  template: `
    <div class="progress-bar">
      <div class="progress-bar__internal progress-bar__internal--{{scoreType}}" [ngStyle]="{'width': progress + '%'}" [ngClass]="{'progress-bar__min-width': showNum}">
        <span *ngIf="showNum" class="progress-bar__score">{{progress}}%</span>
      </div>
    </div>
  `,
  styleUrls: ['./progress-bar.component.scss']
})
export class ProgressBarComponent implements OnInit {

  @Input() progress: number;
  @Input() total: number;
  @Input() showNum: boolean;
  @Input() trackChanges: boolean;
  @Input() scoreType: string;

  constructor() { }

  ngOnChanges(changes: SimpleChanges) {
    if(this.trackChanges && !changes["progress"].isFirstChange()) {
      this.progress = Math.floor((this.progress / this.total) * 100);
    }
  }

  ngOnInit() {
    //if we don't have progress, set it to 0.
    if(!this.progress) {
      this.progress = 0;
    }
    //if we don't have a total aka no requirement, it's 100%.
    if(this.total === 0) {
      this.total = this.progress;
    } else if(!this.total) {
      this.total = 100;
    }
    //if the progress is greater than the total, it's also 100%.
    if(this.progress > this.total) { 
      this.progress = 100;
      this.total = 100;
    }
    this.progress = Math.floor((this.progress / this.total) * 100);
    /* < 70 was bad, < 85 was ok*/
    if(!this.scoreType) {
      if(this.progress < 50) {
        this.scoreType = 'bad';
      } else if(this.progress < 75) {
        this.scoreType = 'ok';
      } else {
        this.scoreType = 'good';
      }
    }
  }

}
