import { Component, OnInit, Input, ViewChild, ViewContainerRef, OnChanges, SimpleChanges, ComponentRef } from '@angular/core';
import { Router } from '@angular/router';
import { PortalService } from '@career/core/services/portal.service';
import { TemplateService } from '@career/core/services/template.service';
import { UtilsService } from '@career/core/services/utils.service';

@Component({
  selector: 'app-base-template',
  templateUrl: './base-template.component.html'
})
export class BaseTemplateComponent implements OnChanges {

  @Input() component;
  @Input() componentDef;
  @Input() customGetTextMethod;
  @Input() scope;
  @ViewChild('chosenTemplate', {read: ViewContainerRef, static: true}) template: ViewContainerRef;
  constructor(private router: Router, private templateService: TemplateService, 
    private utils: UtilsService, private portalService: PortalService) { }

  ngOnChanges(simpleChanges: SimpleChanges): void {
    try {
      if( !simpleChanges.component ) {
        return;
      }

      if( !simpleChanges.component.isFirstChange() ) {
        this.template.remove();
      }
      // if(!this.component || !this.component.name) throw new Error('Invalid component: ' + this.component);
      //use getText instance if it already exists, otherwise load textMap here to reduce boiler plate code
      this.scope.getText = this.scope.getText || this.utils.getText(this.component);
      if(this.customGetTextMethod) {
        this.scope.getText = this.customGetTextMethod(this.scope.getText, this.scope);
      }

      this.scope.hasText = (key) => this.scope.getText(key) !== key;
      const instance: ComponentRef<any>['instance'] = this.templateService.loadTemplateComponent(this.portalService.getPortalTemplate(), this.componentDef, this.template);
      instance.scope = this.scope;
    } catch(err) {
      console.error(err);
      this.router.navigate(['/404']);  
    }
  }

}