import { HttpClient } from '@angular/common/http';
import { Injectable } from '@angular/core';
import { PortalService } from '@career/core/services/portal.service';
import { QuestionnaireStatuses, QuestionnaireTypes } from '@career/questionnaires/types/question.type';
import { Observable, of } from 'rxjs';

@Injectable({
  providedIn: 'root'
})
export class ConsentService {

  constructor(private http: HttpClient, private portalService: PortalService) { }

  getConsents(): Observable<any> {
    return this.http.get(`/api/portal/${this.portalService.getPortalId()}/questionnaire/consents`);
  }
}
