import { Component, OnInit } from "@angular/core";
import { UntypedFormGroup } from "@angular/forms";
import { FieldConfig } from "@career/pocket-forms/models/field";

@Component({
  selector: "app-button",
  template: `
    <div [formGroup]="group" disabledDiv>
      <ng-container [ngSwitch]="field.appearance">
        <button
          *ngSwitchDefault
          class="button"
          (click)="field.action && field.action()"
          [style]="field.style"
          [type]="field.action ? 'button' : 'submit'"
          mat-flat-button
          [color]="field.color"
        >
          {{ field.label }}
        </button>
        <button
          *ngSwitchCase="'mat-stroked-button'"
          (click)="field.action && field.action()"
          [style]="field.style"
          class="button"
          [type]="field.action ? 'button' : 'submit'"
          mat-stroked-button
          [color]="field.color"
        >
          {{ field.label }}
        </button>
      </ng-container>
    </div>
  `,
  styleUrls: ["./button.component.scss"],
})
export class ButtonComponent implements OnInit {
  field: FieldConfig;
  group: UntypedFormGroup;
  constructor() {}
  ngOnInit() {}
}