<app-error-summary [error]="scope.error" *ngIf="scope.error"></app-error-summary>
<div [ngClass]="{'disabled--loading': scope.loading}">
    <div class="files" *ngIf="(scope.attachments$ | async) as attachments">
        <p [innerText]="scope.getText | transform:'CLICK_BELOW_TO_DOWNLOAD_FILES'" *ngIf="attachments.length"></p>
        <mat-chip-list #chipList disabledDiv [attr.aria-label]="scope.getText | transform:'UPLOADED_FILE_LIST'">
            <mat-chip class="chip" *ngFor="let file of attachments" (click)="scope.selected(file)"
                (removed)="scope.remove(file)">
                {{file.fileName}}
                | {{scope.getText | transform:'UPLOADED_ON_DATE':{date: scope.getDate(file.uploadedDate, scope)} }}
                <button matChipRemove *ngIf="!file.questionnaire">
                    <fa-icon [icon]="faTimes"></fa-icon>
                </button>
            </mat-chip>
        </mat-chip-list>
    </div>
    <div>
        <p [innerText]="scope.getText | transform:'UPLOAD_A_NEW_FILE'"></p>
        <app-upload-file resume="false" id="new-file-upload" (uploadEvent)="scope.uploadFile($event)"></app-upload-file>
    </div>
</div>