import { Component, OnDestroy, OnInit } from '@angular/core';
import { ActionsService } from '@career/actions/services/actions-service.service';
import { AuthState } from '@career/authentication/store/states/auth.state';
import { coreComponentDef } from '@career/core/component-definition';
import { TemplateComponent, TemplateConfiguration } from '@career/core/models/component.model';
import { NavService } from '@career/core/services/nav.service';
import { PortalService } from '@career/core/services/portal.service';
import { CoreState } from '@career/core/store/states/core.state';
import { Store } from '@ngxs/store';
import { Observable, Subscription } from 'rxjs';
import { INavRoute } from '../nav/constants/nav.constant';

@Component({
  selector: 'app-mobile-nav',
  template:`
  <app-base-template [component]="component" [componentDef]="componentDef" [scope]="this" *ngIf="component"></app-base-template>
  `
})
export class MobileNavComponent implements OnInit, OnDestroy {
  component: TemplateComponent;
  config: TemplateConfiguration;
  componentDef = coreComponentDef['mobile-nav'];
  portalName: string;
  portalServiceSubscription: Subscription;
  routes$: Observable<any>;
  unauthenticatedRoutes: INavRoute[];
  authenticatedRoutes: INavRoute[];
  incompleteActions$ = this.actionsService.getIncompleteActions();
  userChanged$ = this.store.select(AuthState.token);
  constructor(private portalService: PortalService, private actionsService: ActionsService, private store: Store, private navService: NavService) { }

  ngOnInit(): void {
    this.portalServiceSubscription = this.portalService.getComponentData('nav').subscribe(data => {
      this.config = data.configuration;
      this.component = data.component;
      this.portalName = this.portalService.getPortalName();
    });

    this.routes$ = this.store.select(CoreState.routes);
  }

  ngOnDestroy(): void {
    this.portalServiceSubscription.unsubscribe();
  }
}
