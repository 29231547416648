import { LINKS } from "@career/core/constants/links.constant";
import { CandidateService } from "@career/core/services/candidate.service";
import { QuestionnaireService } from "@career/core/services/questionnaire.service";
import { UserService } from "@career/core/services/user.service";
import { IQuestionnaire } from "@career/questionnaires/models/question";
import { IUser } from "@career/user/models/user.model";
import { Observable, from } from "rxjs";
import { switchMap, concatMap, take, map, filter, distinct, mergeMap, tap } from "rxjs/operators";
import { ActionProvider } from "../action-data-source.model";
import { ActionTarget, EActionTargetType } from "../action-target.model";
import { IAction, EActionType, OpportunityActionBuilder, OpportunityAction, EActionStatus } from "../action.model";

export class IncompleteQuestionnaireProvider extends ActionProvider {
    constructor(private candidateService: CandidateService, private questSvc: QuestionnaireService, private userSvc: UserService) {
        super();
    }


    setId(action: IAction): string {
        return `questionnaire:${(action.object as IQuestionnaire)._id}:${(action as OpportunityAction).opportunity._id}`;
    }

    populateTarget(action: IAction) {
        if( action instanceof OpportunityAction ) {
            action.target = new ActionTarget();
            action.target.name = (action.object as IQuestionnaire).name;
            switch((action.object as IQuestionnaire).source) { 
                case "INTERNAL":
                    action.target.type = EActionTargetType.LINK;
                    action.target.value = `${LINKS.QUESTIONNAIRE_URL}/${(action.object as IQuestionnaire)._id}/${action.opportunity._id}`;
                    break;
                case "SOVA_SJT":
                    action.target.type = EActionTargetType.EXTERNAL_LINK;
                    action.target.value = action.externalDetails && action.externalDetails['SOVA_URL'];
                    break;
                case "SOVA_VIDEO":
                    action.target.type = EActionTargetType.EXTERNAL_LINK;
                    action.target.value = action.externalDetails && action.externalDetails['SOVA_VIDEO_URL'];
                    break;
            }
        }
        return action;
    }

    private transformToAction(questionnaire: IQuestionnaire, baseAction): IAction {
        const action = new OpportunityActionBuilder();

        const mutated = action
        .from(baseAction)
        .setObject(questionnaire)
        .setStatus(EActionStatus.INCOMPLETE)
        .setTimestamp(new Date())
        .setType(EActionType.QUESTIONNAIRES)
        .build();
        this.populateTarget(mutated);
        return mutated;
    }
    
    fetch(): Observable<IAction> {
        const baseAction = new OpportunityActionBuilder();
        return this.userSvc.getUser().pipe(
            take(1),
            filter((user) => user && user._id),
            switchMap((user: IUser) => baseAction.setUser(user) && this.candidateService.getApplied(user._id)),
            filter((applications) => applications && applications.matches),
            switchMap((applications) => from(applications.matches)),
            concatMap((match: any) => baseAction.setOpportunity(match.opportunity) && baseAction.setExternalDetails(match.externalDetails) && this.questSvc.findUnfilledQuestionnaires(match.profile._id, match.opportunity._id)),
            mergeMap((questionnaires: IQuestionnaire[]) => from(questionnaires)),
            map((questionnaire: IQuestionnaire) => this.transformToAction(questionnaire, baseAction.build())),
            filter((action) => !!(action.target || {}).value)
        );
    }
}
