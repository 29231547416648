
export enum EActionTargetType {
    LINK, API_CALL, VIEW_ONLY, EXTERNAL_LINK, PAGE_ITEM
}

export interface IActionTarget {
    type: EActionTargetType;
    value: Object;
    name: string;
}

export class ActionTarget implements IActionTarget {
    private _type: EActionTargetType;
    private _targetValue: Object;
    private _name: string;

    get name() {
        return this._name;
    }

    set name(name: string) {
        this._name = name;
    }

    get type() {
        return this._type;
    }

    set type(type: EActionTargetType) {
        this._type = type;
    }

    get value() {
        return this._targetValue;
    }

    set value(value: Object) {
        this._targetValue = value;
    }
}
