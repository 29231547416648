import { CandidateService } from "@career/core/services/candidate.service";
import { QuestionnaireService } from "@career/core/services/questionnaire.service";
import { UserService } from "@career/core/services/user.service";
import { Observable, of } from "rxjs";
import { ActionDataSourceService } from "../../services/action-data-source.service";
import { ActionProvider } from "../action-data-source.model";
import { ActionStrategy } from "../action-strategy.model";
import { IAction, EActionType, OpportunityAction } from "../action.model";
import { IncompleteQuestionnaireProvider } from "./incomplete-questionnaire-provider";


export class IncompleteQuestionnaireActionStrategy extends ActionStrategy {
    constructor(protected dataSourceService: ActionDataSourceService) {
        super(dataSourceService);
    }

    getActionProvider(): ActionProvider {
      return new IncompleteQuestionnaireProvider(
        this.dataSourceService.get(CandidateService),
        this.dataSourceService.get(QuestionnaireService),
        this.dataSourceService.get(UserService)
      );
    }

    getType(): EActionType {
      return EActionType.QUESTIONNAIRES;
    }

    createActions(): Observable<IAction> {
        return this.transformActions(this, [
            this.getIncompleteQuestionnaireAction
        ]);
    }
  
    private getIncompleteQuestionnaireAction(action: OpportunityAction): Observable<IAction> {
      return of(action);
    }
}

