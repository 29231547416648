import { CandidateService } from "@career/core/services/candidate.service";
import { PortalService } from "@career/core/services/portal.service";
import { UserService } from "@career/core/services/user.service";
import { Observable, of } from "rxjs";
import { filter, map, switchMap } from "rxjs/operators";
import { ActionDataSourceService } from "../../services/action-data-source.service";
import { ActionProvider } from "../action-data-source.model";
import { ActionStrategy } from "../action-strategy.model";
import { ActionTarget, EActionTargetType } from "../action-target.model";
import { IAction, EActionType, ActionBuilder, EActionStatus } from "../action.model";


export class SkillsActionStrategy extends ActionStrategy {
    constructor(protected dataSourceService: ActionDataSourceService) {
        super(dataSourceService);
    }

    getActionProvider(): ActionProvider {
      return new class extends ActionProvider {
        constructor(private candidateService: CandidateService, private portalService: PortalService, private userSvc: UserService) {
          super();
        }


        setId(action: IAction): string {
          return 'skills';
        }

        populateTarget(action: IAction) {
            action.target = new ActionTarget();
            action.target.name = "MY_SKILLS";
            action.target.type = EActionTargetType.PAGE_ITEM;
            action.target.value = "skillsSection";
            return action;
        }

        fetch(): Observable<IAction> {
          const actionBuilder = new ActionBuilder();
          return this.userSvc.getUser().pipe(
            filter(user => user && user._id),
            map(user => actionBuilder.setUser(user) && user),
            switchMap(() => this.portalService.getPortalConfiguration()),
            switchMap(config => {
              if( !(config.requireSkills && config.enableSkills) ) {
                return of(null);
              }
              return this.candidateService.getSkillsList(actionBuilder.build().user.authorization.agency._id);
            }),
            map(skills => {
              if( !skills ) {
                return null;
              }     
              
              const user = actionBuilder.build().user;
              return actionBuilder.setTimestamp(new Date())
              .setStatus((user.skills || []).length > 0?EActionStatus.COMPLETE:EActionStatus.INCOMPLETE)
              .setObject(skills)
              .setType(EActionType.PROFILE)
              .build();
            })
          )
        }
      }(
        this.dataSourceService.get(CandidateService),
        this.dataSourceService.get(PortalService),
        this.dataSourceService.get(UserService)
      );
    }

    getType(): EActionType {
      return EActionType.PROFILE;
    }

    createActions(): Observable<IAction> {
        return this.transformActions(this, []);
    }
}

