import { AVAILABLE_CONSENT_KEYS } from "../constants/consents.constant";
import { LOCATION_FIELD_KEYS } from "../constants/location.constant";

type UserDTOOperation = "CREATE"|"UPDATE";
export class UserDTOFactory {
    private static createConsentsDTO(obj) {
        return Object.keys(obj).filter(key => AVAILABLE_CONSENT_KEYS.includes(key))
        .filter(key => obj[key])
        .reduce((acc, key) => {
          acc[key] = obj[key];
          return acc;
        }, {});
      }
    
      private static createLocationDTO(obj) {
        return Object.keys(obj).filter(key => LOCATION_FIELD_KEYS.includes(key))
        .filter(key => obj[key])
        .reduce((acc, key) => {
          acc[key] = obj[key];
          return acc;
        }, {});
      }

      private static setCommuteDistance(dto, obj, operation: UserDTOOperation) {
        if( operation === "UPDATE" ) {
          if( typeof obj.commuteDistance !== 'undefined' ) {
            dto.location = {
              ...(dto.location || {}),
              commuteDistance: obj.commuteDistance
            }
          }
          delete dto.commuteDistance;
        } else if( operation === "CREATE" ) {
          if( typeof obj.commuteDistance !== 'undefined' ) {
            dto.commuteDistance = obj.commuteDistance
          }
        }
      }
    
      static create(obj, operation: UserDTOOperation) {
        const dto = {
          ...obj,
          phones: [{number: obj.phones, location: 'MOBILE'}].filter(p => p.number),
          prefersText: obj.prefertext
        };

        if( operation === "CREATE" ) {
            dto.consents = this.createConsentsDTO(obj);
            dto.address = this.createLocationDTO(obj);
        } else if( operation === "UPDATE" ) {
            dto.addresses = [this.createLocationDTO(obj)];
            if( dto.firstName ) {
              dto.name = {
                ...dto.name || {},
                firstName: dto.firstName
              };
              delete dto.firstName;
            }
    
            if( dto.lastName ) {
              dto.name = {
                ...dto.name || {},
                lastName: dto.lastName
              };
              delete dto.lastName;
            }
    
            if( dto.middleName ) {
              dto.name = {
                ...dto.name || {},
                middleName: dto.middleName
              }
              delete dto.middleName;
            }
        }
    
        if( dto.phones?.length < 1 ) {
            delete dto.phones;
        }

        this.setCommuteDistance(dto, obj, operation);

        [...AVAILABLE_CONSENT_KEYS, ...LOCATION_FIELD_KEYS, 'confirmpassword', 'prefertext'].forEach(key => {
          if( typeof dto[key] !== 'undefined' ) {
            delete dto[key];
          }
        });
        return dto;
      }
}
