import { Component, Input, OnInit, ViewChild } from '@angular/core';
import { VerifyPhoneComponent } from '@career/user/components/verify-phone/verify-phone.component';
import { NgOtpInputComponent } from 'ng-otp-input';

@Component({
  selector: 'app-default-verify-phone',
  templateUrl: './default-verify-phone.component.html',
  styleUrls: ['./default-verify-phone.component.scss']
})
export class DefaultVerifyPhoneComponent implements OnInit {

  @Input() scope: VerifyPhoneComponent;
  @ViewChild(NgOtpInputComponent, { static: false}) ngOtpInput: NgOtpInputComponent;
  constructor() { }

  onOtpChange(pin) {
    if( pin.length === 6 ) {
      this.ngOtpInput.otpForm.disable();
      this.scope.verify(pin).subscribe(_ => {
        this.ngOtpInput.otpForm.enable();
      });
    }
  }

  ngOnInit(): void {
  }

}
