import { PageNotFoundComponent } from "./core/components/page-not-found/page-not-found.component";
import { RegisterComponent } from "./authentication/components/register/register.component";
import { ActivateComponent } from "./authentication/components/activate/activate.component";
import { ForgotPasswordComponent } from "./authentication/components/forgot-password/forgot-password.component";
import { LoginComponent } from "./authentication/components/login/login.component";
import { OpportunitiesComponent } from "./opportunity-list/components/opportunities/opportunities.component";
import { ApplyComponent } from "./core/components/apply/apply.component";
import { AuthGuard } from "./authentication/services/authentication-guard.service";
import { LogoutComponent } from "./core/logout/logout.component";
import { NotAuthGuard } from "./authentication/services/not-authenticated-guard.service";
import { PageNotAllowedComponent } from "./core/components/page-not-allowed/page-not-allowed.component";
import { ResetPasswordComponent } from "./authentication/components/reset-password/reset-password.component";
import { Route, Routes } from "@angular/router";
import { UnsubscribeComponent } from "./core/components/unsubscribe/unsubscribe.component";
import { HomeComponent } from "./home/components/home/home.component";
import { SuggestionsComponent } from "./suggestions/components/suggestions/suggestions.component";
import { PageConfigurationComponent } from "./page-configuration/page-configuration/page-configuration.component";
import { NavComponent } from "./core/components/nav/nav.component";
import { FooterComponent } from "./core/components/footer/footer.component";
import { AdminLoginComponent } from "./authentication/components/admin-login/admin-login.component";
import { DashboardComponent } from "./user/components/dashboard/dashboard.component";
import { AdminAuthGaurd } from "./authentication/services/admin-authentication-guard.service";
import { OpportunityComponent } from "./opportunity-detail/components/opportunity/opportunity.component";
import { ConfigGuard } from "./authentication/services/config-guard.service";
import { NotAdminAuthGaurd } from "./authentication/services/not-admin-authentication-guard.service";
import { StaticPageComponent } from "./static-pages/static-page/static-page.component";

let _needsRefresh = false;
/*used to map component names to routes*/
const SORTABLE_ROUTES_MAP = {
  "opportunity-list": "jobs",
  home: "",
  register: "register",
  login: "login",
  dashboard: "dashboard",
};

export const routerActions = {
  STATIC_PAGE_ROUTE_ORDER : 99,
  needsRefresh() {
    return _needsRefresh;
  },
  getRoutes() {
    return routes;
  },
  setRouteOrder(components) {
    Object.keys(SORTABLE_ROUTES_MAP).forEach((key) => {
      if (components[key] && components[key].order) {
        _needsRefresh = true;
        routes[
          routes.findIndex((x) => x.path === SORTABLE_ROUTES_MAP[key])
        ].data = { order: components[key].order };
      }
    });
    return this;
  },
  getSortableRouteOrders() {
    const obj = {};
    routes
      .filter((route) => route.data && route.data.order)
      .forEach((x) => {
        return (obj["/" + x.path] = x.data.order);
      });

    return obj;
  },
  addToRoutes(newRoutes: Route[]) {
    _needsRefresh = true;
    dynamicRoutes = newRoutes;
    routes = [routes[0], ...newRoutes, ...routes.slice(1)];
    return this;
  },
  getDynamicRoutes() {
    return dynamicRoutes;
  },
  getSortableRoutePath(componentName: string) {
    return SORTABLE_ROUTES_MAP[componentName];
  },
  addStaticPageRoute(route:any) {
    staticPageRoutes.push(route);
  },
  getStaticPageRoutes(): any[] {
    return [...staticPageRoutes]
  },
  resetStaticPageRoutes() {
    staticPageRoutes = [];
  }
};

let dynamicRoutes = [];
let staticPageRoutes = [];
//{path: '', redirectTo: '/jobs', pathMatch: 'full'},
let routes: Routes = [
  { 
    path: "", 
    component: HomeComponent, 
    canActivate: [NotAdminAuthGaurd, ConfigGuard.forConfigs(['enableHomePage'], '/jobs')] 
  },
  { 
    path: "jobs", 
    component: OpportunitiesComponent, 
    canActivate: [NotAdminAuthGaurd, ConfigGuard.forConfigs(['disableJobSearchPage'], '/login', true)] 
  },
  {
    path: "suggestions",
    component: SuggestionsComponent,
    canActivate: [NotAdminAuthGaurd],
  },
  {
    path: "details/:id",
    loadChildren: () =>
      import("../app/opportunity-detail/opportunity-detail.module").then(
        (m) => m.OpportunityDetailModule
      ),
  },
  {
    path: "dashboard",
    loadChildren: () =>
      import("../app/user/user.module").then((m) => m.UserModule),
    canActivate: [AuthGuard, NotAdminAuthGaurd],
  },
  {
    path: "dashboard/preview",
    loadChildren: () =>
      import("../app/user/user.module").then((m) => m.UserModule),
  },
  {
    path: "questionnaire/:id",
    loadChildren: () =>
      import("../app/questionnaires/questionnaires.module").then(
        (m) => m.QuestionnairesModule
      ),
    canActivate: [AuthGuard, NotAdminAuthGaurd],
  },
  {
    path: "register",
    component: RegisterComponent,
    canActivate: [NotAuthGuard, ConfigGuard.forConfigs(['disableRegisterPage'], '/login', true)],
  },
  {
    path: "register/:optionalId",
    component: RegisterComponent,
    canActivate: [NotAuthGuard],
  },
  { path: "apply/:id", component: ApplyComponent },
  { path: "activate", component: ActivateComponent },
  { path: "login", component: LoginComponent, canActivate: [NotAuthGuard] },
  {
    path: "admin/login",
    component: AdminLoginComponent,
    canActivate: [NotAuthGuard],
  },
  { path: "login/:id", component: LoginComponent, canActivate: [NotAuthGuard] },
  { path: "logout", component: LogoutComponent },
  { path: "unsubscribe/:token", component: UnsubscribeComponent },
  {
    path: "unsubscribe",
    component: UnsubscribeComponent,
    canActivate: [AuthGuard],
  },
  { path: "forgotPassword", component: ForgotPasswordComponent },
  { path: "resetPassword", component: ResetPasswordComponent },
  {
    path: "admin/pageConfiguration",
    component: PageConfigurationComponent,
    canActivate: [AdminAuthGaurd],
    children: [
      {
        path: "",
        redirectTo: "/admin/pageConfiguration/home",
        pathMatch: "full"
      },
      {
        path: "home",
        component: HomeComponent
      },
      {
        path: "resetPassword",
        component: ResetPasswordComponent,
      },
      {
        path: "forgotPassword",
        component: ForgotPasswordComponent,
      },
      {
        path: "jobs",
        component: OpportunitiesComponent,
      },
      {
        path: "detail/:id",
        loadChildren: () =>
          import("../app/opportunity-detail/opportunity-detail.module").then(
            (m) => m.OpportunityDetailModule
          ),
      },
      {
        path: "questionnaire/:id",
        loadChildren: () =>
          import("../app/questionnaires/questionnaires.module").then(
            (m) => m.QuestionnairesModule
          ),
      },
      {
        path: "register",
        component: RegisterComponent,
      },
      {
        path: "login",
        component: LoginComponent,
      },
      {
        path: "nav",
        component: NavComponent,
      },
      {
        path: "footer",
        component: FooterComponent,
      },
      {
        path: "dashboard",
        component: DashboardComponent,
      },
      {
        path:"static/:id",
        component: StaticPageComponent
      }
    ],
  },
  { path: "404", component: PageNotFoundComponent },
  { path: "405", component: PageNotAllowedComponent },
  { path: "**", redirectTo: "/404" },
];
