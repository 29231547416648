import { Pipe, PipeTransform } from '@angular/core';
import { UntypedFormGroup } from '@angular/forms';
import { FieldConfig, Validator } from './field';
@Pipe({
  name: 'hasValidationErrors',
  pure: false
})
export class HasValidationErrorsPipe implements PipeTransform {
  transform(group: UntypedFormGroup, field: FieldConfig): boolean {
      if( group.untouched ) {
        return false;
      }
      return (field.validations || []).some(v => group.get(field.name).hasError(v.name));
   }
}

@Pipe({
  name: 'getValidationErrors',
  pure: false
})
export class GetValidationErrorsPipe implements PipeTransform {
  transform(group: UntypedFormGroup, field: FieldConfig): any[] {
      if( group.untouched ) {
        return [];
      }
      return (field.validations || []).filter(v => group.get(field.name).hasError(v.name));
   }
}

@Pipe({
  name: 'isRequired',
  pure: true
})
export class IsFieldRequiredPipe implements PipeTransform {
  transform(group: UntypedFormGroup, field: FieldConfig): boolean {
      return (field.validations || []).some(v => v.name === "required");
   }
}