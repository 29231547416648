import { Component, OnInit, Input, Output, OnDestroy } from "@angular/core";
import { EventEmitter } from "@angular/core";
import { coreComponentDef } from "@career/core/component-definition";
import {
  TemplateComponent,
  TemplateConfiguration,
} from "@career/core/models/component.model";
import { PortalService } from "@career/core/services/portal.service";
import { UserService } from "@career/core/services/user.service";
import { Observable, Subscription } from "rxjs";

@Component({
  selector: "app-upload-file",
  template: `
    <app-base-template
      [component]="component"
      [componentDef]="componentDef"
      [scope]="this"
      *ngIf="component"
    ></app-base-template>
  `,
})
export class UploadFileComponent implements OnInit, OnDestroy {
  @Output() uploadEvent = new EventEmitter<Event>();
  @Input() resume: boolean;
  @Input() noStyle;
  @Input() id: string;
  portalServiceSubscription: Subscription;
  component: TemplateComponent;
  config: TemplateConfiguration;
  componentDef = coreComponentDef["upload-file"];

  constructor(private portalService: PortalService) {}

  ngOnInit() {
    this.portalServiceSubscription = this.portalService
      .getComponentData("upload-file")
      .subscribe((data) => {
        this.component = data.component;
        this.config = data.configuration;
      });
  }

  upload($event: Event) {
    this.uploadEvent.emit($event);
    ($event.target as any).value = "";
  }

  ngOnDestroy(): void {
    this.portalServiceSubscription.unsubscribe();
  }
}
