<div class="dashboard">
    <app-embedded-toolbar (onConfigure)="scope.openConfiguration()" [configurationName]="scope.translations.DASHBOARD_CONFIG"></app-embedded-toolbar>
    <div class="data-with-image data-with-image--dashboard data-with-image--min-height">
        <div class="data-with-image__img-container data-with-image__img-container--overflow"
            appImgEditContainer="dashboard" name="Main Image">
            <div>
                <img class="data-with-image__img" [src]="scope.component.assets.image"
                    [alt]="scope.getText | transform:'LOGIN'">
            </div>
        </div>
        <div class="data-with-image__text-container data-with-image__text-container--overflow">
            <app-error-summary [error]="scope.error" *ngIf="scope.error"></app-error-summary>
            <h1 class="data-with-image__heading data-with-image__heading--bold"
                [innerText]="scope.getText | transform:'DASHBOARD'"></h1>
            <app-panels [suggestedJobsOpen]="scope.suggestedJobsOpen"></app-panels>
        </div>
    </div>
</div>