import { Component, Input, OnInit } from '@angular/core';

@Component({
  selector: 'default-app-action-required-warning',
  templateUrl: './default-action-required-warning.component.html',
  styleUrls: ['./default-action-required-warning.component.scss']
})
export class DefaultActionRequiredWarningComponent implements OnInit {

  @Input() scope;
  constructor() { }

  ngOnInit(): void {

  }

}
