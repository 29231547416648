import { Component, OnInit, Input } from "@angular/core";
import { Pill } from "@career/core/models/pill.model";
import { Router } from "@angular/router";
import { Match } from "@career/core/models/match.model";
import { Opportunity } from "@career/opportunity-detail/models/opportunity.model";

@Component({
  selector: "app-data-card",
  templateUrl: "./data-card.component.html",
  styleUrls: ["./data-card.component.scss"],
})
export class DataCardComponent implements OnInit {
  @Input() match: Match;
  @Input() banner: string;
  @Input() opp: Opportunity;
  @Input() shortVersion: boolean;
  @Input() token: string;
  @Input() isAdmin: boolean;
  pills: Pill[];
  constructor(private router: Router) {}

  ngOnInit() {
    if (!this.opp && !this.match) {
      throw new Error("No Opportunity Provided");
    }
    if (this.match) {
      this.opp = this.match.opportunity;
      this.pills = [...this.opp.display.pills, ...this.match.display.pills];
    } else {
      this.pills = this.opp.display.pills;
    }
  }

  apply(id) {
    if (!this.isAdmin) {
      this.router.navigate(["/apply/" + id], { skipLocationChange: true });
    }
  }
}
