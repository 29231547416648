import { Component, OnInit } from "@angular/core";
import { UntypedFormGroup } from "@angular/forms";
import { FieldConfig } from "@career/pocket-forms/models/field";
@Component({
  selector: "app-radiobutton",
  template: `
<div class="input-wrapper" [ngClass]="{'_error-handler': (group | hasValidationErrors:field)}" [formGroup]="group">
  <ng-container *ngFor="let validation of (group | getValidationErrors:field)">
    <p *ngIf="group.get(field.name).hasError(validation.name)" class="_error-handler-message">{{validation.message}}</p>
  </ng-container>
  <label [for]="field.name">{{field.label}}</label>
  <div>
    <input type="radio" *ngFor="let item of field.options" [value]="item" [formControlName]="field.name" [name]="field.name">
  </div>
</div>
`,
  styles: []
})
export class RadiobuttonComponent implements OnInit {
  field: FieldConfig;
  group: UntypedFormGroup;
  constructor() {}
  ngOnInit() {}
}