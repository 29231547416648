import {
  ComponentFactoryResolver,
  Directive,
  ElementRef,
  HostListener,
  Input,
  OnInit,
  Renderer2,
  ViewContainerRef,
} from "@angular/core";
import { MatIcon } from "@angular/material/icon";
import {
  ImageBucketTypes,
  UploadImageIcon,
} from "../models/update-image-icon.enum";
import { DialogService } from "@career/core/services/dialog.service";
import { UserService } from "../services/user.service";

@Directive({
  selector: "[appImgEditContainer]",
})
export class ImgEditContainerDirective implements OnInit {
  @Input() appImgEditContainer;
  @Input() path = UploadImageIcon.image;
  @Input() name: string;
  pageName = `${Math.random()}-update-icon`;
  isAdmin = false;

  constructor(
    private el: ElementRef,
    private dialogSvc: DialogService,
    private userSvc: UserService,
  ) {}

  ngOnInit(): void {
    this.userSvc.isAdmin().subscribe((response) => {
      this.isAdmin = response;
      if (this.el.nativeElement.classList.contains("_editable")) {
        this.el.nativeElement.classList.remove("_editable");
      }
      if (this.isAdmin) {
        this.el.nativeElement.classList.add("_editable");
      }
    });
  }

  @HostListener("click", ["$event"])
  onClick(e: PointerEvent) {
    if (!this.isAdmin) {
      return;
    }
    this.dialogSvc.uploadImageDialog(
      this.appImgEditContainer,
      this.path,
      this.path === UploadImageIcon.logo
        ? UploadImageIcon.logo.toUpperCase()
        : UploadImageIcon.image.toUpperCase(),
      this.name,
      e,
      this.el
    );
  }
}
