import { Component, OnDestroy, OnInit } from "@angular/core";
import { UntypedFormControl } from "@angular/forms";
import { MatLegacyDialog as MatDialog } from "@angular/material/legacy-dialog";
import { Title } from "@angular/platform-browser";
import { Router } from "@angular/router";
import {
  TemplateComponent,
  TemplateConfiguration,
} from "@career/core/models/component.model";
import { PocketLocation } from "@career/core/models/location.model";
import { UploadImageIcon } from "@career/core/models/update-image-icon.enum";
import { JobService } from "@career/core/services/job.service";
import { LocationService } from "@career/core/services/location.service";
import { PortalService } from "@career/core/services/portal.service";
import { UserService } from "@career/core/services/user.service";
import { UtilsService } from "@career/core/services/utils.service";
import { homeComponentDef } from "@career/home/component-definition";
import { Translations } from "@career/page-configuration/configs/page-config.config";
import { PageConfigTypes } from "@career/page-configuration/models/page-config.model";
import { ConfigurableComponent } from "@career/page-configuration/Shared/components/configurable.component";
import { EditConfigurationsComponent } from "@career/page-configuration/Shared/components/edit-configurations/edit-configurations.component";
import { LayerService } from "kleos-ui";
import { Observable, Subscription } from "rxjs";

@Component({
  selector: "app-home",
  template: `
    <app-base-template
      [component]="component"
      [componentDef]="componentDef"
      [scope]="this"
      *ngIf="component"
    ></app-base-template>
  `,
})
export class HomeComponent
  extends ConfigurableComponent
  implements OnInit, OnDestroy
{
  portalServiceSubscription: Subscription;
  locAutocompleteSubscription: Subscription;
  locations: PocketLocation[];
  selectedLocation: UntypedFormControl = new UntypedFormControl();
  jobText: UntypedFormControl = new UntypedFormControl();
  config: TemplateConfiguration;
  component: TemplateComponent;
  getText: Function;
  componentDef = homeComponentDef["home"];
  uploadImageIcon = UploadImageIcon;
  createLocationString = this.utils.createLocationString;
  subscriptions: Subscription[] = [];
  translations = Translations;

  constructor(
    private titleService: Title,
    private portalService: PortalService,
    private router: Router,
    private jobService: JobService,
    private utils: UtilsService,
    private locationService: LocationService,
    layerService: LayerService
  ) {
    super(layerService);
  }

  ngOnInit(): void {
    this.portalServiceSubscription = this.portalService
      .getComponentData("home")
      .subscribe((data) => {
        this.config = data.configuration;
        this.component = data.component;
        this.getText = this.utils.getText(this.component);
        this.titleService.setTitle(
          `${this.config.seoTitle || this.getText("HOME")} | ${this.portalService.getPortalName()}`
        );
        this.jobService.getCachedSearchFilters().subscribe((cached) => {
          const body = (cached && cached["body"]) || {};
          if (body.location && typeof body.location === "object") {
            this.selectedLocation.setValue(body.location);
          }
          this.jobText.setValue(body.keywords || "");
        });

        this.locAutocompleteSubscription = this.locationService
          .createLocationAutocomplete(this.selectedLocation)
          .subscribe((data: any) => {
            if (data) {
              this.locations = data.locations;
            }
          });
      });
  }

  search() {
    const subs = this.jobService
      .setCachedSearchFilters({
        keywords: this.jobText.value,
        location: this.selectedLocation.value,
      })
      .subscribe(() => {
        this.router.navigate(["/jobs"], {
          queryParams: {
            search: this.jobText.value,
            location: this.createLocationString(this.selectedLocation.value),
          },
        });
      });
    this.subscriptions.push(subs);
  }

  getConfigurationType(): keyof typeof PageConfigTypes {
    return PageConfigTypes.HOME;
  }

  getTemplateConfiguration(): TemplateConfiguration {
    return { ...this.config };
  }

  ngOnDestroy(): void {
    if (this.locAutocompleteSubscription) {
      this.locAutocompleteSubscription.unsubscribe();
    }
    this.subscriptions.forEach((sub) => sub.unsubscribe());
    this.portalServiceSubscription.unsubscribe();
  }
}
