import { Component, OnDestroy, OnInit } from "@angular/core";
import { ActivatedRoute, Router } from "@angular/router";
import { Subscription } from "rxjs";

@Component({
  selector: "app-external-url",
  templateUrl: "./external-url.component.html",
  styleUrls: ["./external-url.component.scss"],
})
export class ExternalUrlComponent implements OnInit, OnDestroy {
  routeDataSubscription: Subscription;
  constructor(private route: ActivatedRoute, private router: Router) {}

  ngOnInit(): void {
    this.routeDataSubscription = this.route.data.subscribe((data) => {
      if (!data.externalUrl) {
        this.router.navigate(["/404"]);
        return;
      }

      window.location.replace(data.externalUrl);
    });
  }

  ngOnDestroy() {
    this.routeDataSubscription.unsubscribe();
  }
}
