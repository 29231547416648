<div class="wrapper">
  <h1 class="title">{{name | titlecase}}</h1>
  <app-error-summary [error]="error" *ngIf="error"></app-error-summary>
  <ng-container *ngIf="titlePath">
    <mat-form-field appearance="outline" class="form__field header__form-title">
      <input matInput [(ngModel)]="titleHtmlContent">
    </mat-form-field>
  </ng-container>

  <ng-container *ngIf="bodyPath">
    <div mat-dialog-content class="angular-editor-container">
      <!-- <quill-editor [(ngModel)]="bodyHtmlContent" [modules]="modules"></quill-editor> -->
      <angular-editor [(ngModel)]="bodyHtmlContent" [config]="editorConfig"></angular-editor>
    </div>
  </ng-container>
  <div style="margin-top: 10px;">
    <button mat-button color="primary" (click)="onSave()">Update Text</button>
  </div>
</div>