<div class="wrapper">
    <h1 class="title">Page Name</h1>
    <app-error-summary [error]="error" *ngIf="error"></app-error-summary>
    <mat-form-field appearance="outline" class="form__field header__form-title">
        <input matInput [(ngModel)]="templateName">
    </mat-form-field>
    <mat-checkbox [(ngModel)]="hideNavigation">Hide from navigation?</mat-checkbox>
    <div style="margin-top: 10px;">
        <button mat-button color="primary" (click)="onSave()">{{existing ? 'Update Page' : 'Create New Page'}}</button>
    </div>
</div>