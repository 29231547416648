import { DOCUMENT } from "@angular/common";
import {
  ChangeDetectorRef,
  Component,
  Inject,
  OnDestroy,
  OnInit
} from "@angular/core";
import { Validators } from "@angular/forms";
import {
  TemplateComponent,
  TemplateConfiguration,
} from "@career/core/models/component.model";
import { ErrorSummary } from "@career/core/models/error.model";
import { UserDTOFactory } from "@career/core/models/user-dto.model";
import { CandidateService } from "@career/core/services/candidate.service";
import { LocaleService } from "@career/core/services/locale.service";
import { MatchService } from "@career/core/services/match.service";
import { PortalService } from "@career/core/services/portal.service";
import { UserService } from "@career/core/services/user.service";
import { UtilsService } from "@career/core/services/utils.service";
import { FieldConfig } from "@career/pocket-forms/models/field";
import { userComponentDef } from "@career/user/component-definition";
import { UserState } from "@career/user/store/states/user.state";
import { Store } from "@ngxs/store";
import { Observable, Subscription, of } from "rxjs";
import { map, switchMap } from "rxjs/operators";

@Component({
  selector: "app-manage-profile",
  template: `
    <app-base-template
      #top
      [component]="component"
      [componentDef]="componentDef"
      [scope]="this"
      *ngIf="component"
    ></app-base-template>
  `,
})
export class ManageProfileComponent implements OnInit, OnDestroy {
  component: TemplateComponent;
  config: TemplateConfiguration;
  getText: Function;
  error: ErrorSummary;
  loading: boolean;
  updated: boolean;
  portalServiceSubscription: Subscription;
  componentDef = userComponentDef["manage-profile"];
  fieldConfig$: Observable<FieldConfig[]>;
  constructor(
    private candidateService: CandidateService,
    private changeDetectorRef: ChangeDetectorRef,
    @Inject(DOCUMENT) private document: Document,
    private store: Store,
    private utils: UtilsService,
    private localeService: LocaleService,
    private matchService: MatchService,
    private portalService: PortalService,
    private userSvc: UserService
  ) {}
  submitForm($event) {
    this.loading = true;
    this.updated = false;
    const user = this.store.selectSnapshot(UserState.user);
    if (!(user || {})._id) {
      this.error = new ErrorSummary(
        this.getText("YOUR_SESSION_MAY_HAVE_EXPIRED"),
        `Unable to find user`
      );
      this.loading = false;
      return;
    }
    const dto = UserDTOFactory.create($event, "UPDATE");
    const updatedPhones = !(user.phones || []).some(p => p.number === $event.phones);
    this.candidateService.updateProfile(dto, user._id)
    .pipe(
      switchMap(updated => {
        //meaning able to send texts
        if( updatedPhones && this.portalService.isPreferencesEnabled() ) {
          return this.candidateService.resendMobileVerificationPin(updated._id);
        }
        return of(updated);
      })
    )
    .subscribe(
      () => {
        this.loading = false;
        this.updated = true;
        const elm = this.document.getElementById("panels");
        if (elm) {
          elm.scrollIntoView({ behavior: "smooth", block: "start" });
        }
        this.changeDetectorRef.markForCheck();
      },
      (err) => {
        this.loading = false;
        this.error = new ErrorSummary(
          this.getText("ISSUE_UPDATING_PROFILE"),
          `Error Code: ${err.status}`
        );
      }
    );
  }

  setupFieldConfig() {
    this.fieldConfig$ = this.userSvc.getUser().pipe(
      map((user) => {
        return [
          {
            type: "input",
            label: this.getText("FIRST_NAME"),
            inputType: "text",
            name: "firstName",
            autocomplete: "given-name",
            value: user.name?.firstName || "",
            validations: [
              {
                name: "required",
                validator: Validators.required,
                message: this.getText("FIELD_REQUIRED", {
                  fieldName: this.getText("FIRST_NAME"),
                }),
              },
              {
                name: "pattern",
                validator: Validators.pattern("^[\\-a-zA-ZÀ-ž ]+$"),
                message: this.getText("PLEASE_ENTER_TEXT_ONLY"),
              },
            ],
          },
          {
            type: "input",
            label: this.getText("MIDDLE_NAME"),
            inputType: "text",
            name: "middleName",
            value: user.name?.middleName || "",
            autocomplete: "middle-name",
            validations: [
              {
                name: "pattern",
                validator: Validators.pattern("^[\\-a-zA-ZÀ-ž ]+$"),
                message: this.getText("PLEASE_ENTER_TEXT_ONLY"),
              },
            ],
          },
          {
            type: "input",
            label: this.getText("LAST_NAME"),
            inputType: "text",
            name: "lastName",
            value: user.name?.lastName || "",
            autocomplete: "family-name",
            validations: [
              {
                name: "required",
                validator: Validators.required,
                message: this.getText("FIELD_REQUIRED", {
                  fieldName: this.getText("LAST_NAME"),
                }),
              },
              {
                name: "pattern",
                validator: Validators.pattern("^[\\-a-zA-ZÀ-ž ]+$"),
                message: this.getText("PLEASE_ENTER_TEXT_ONLY"),
              },
            ],
          },
          //TODO: Replace with new intl. phone number input
          {
            type: "input",
            label: this.getText("PHONE_NUMBER"),
            inputType: "tel",
            name: "phones",
            value:
              (user.phones &&
                user.phones.length > 0 &&
                user.phones[0].number) ||
              "",
            autocomplete: "tel",
            validations: [
              this.config.requirePhoneNumber
                ? {
                    name: "required",
                    validator: Validators.required,
                    message: this.getText("FIELD_REQUIRED", {
                      fieldName: this.getText("PHONE_NUMBER"),
                    }),
                  }
                : null,
              {
                name: "validPhone",
                validator: this.utils.regexValidator(
                  new RegExp("^\\+((?:9[679]|8[035789]|6[789]|5[90]|42|3[578]|2[1-689])|9[0-58]|8[1246]|6[0-6]|5[1-8]|4[013-9]|3[0-469]|2[70]|7|1)(?:\\W*\\d){0,13}\\d$"),
                  { validPhone: true }
                ),
                message: this.getText("PLEASE_ENTER_A_VALID_PHONE_NUMBER"),
              },
            ].filter((v) => !!v),
          },
          {
            type: "input",
            label: this.getText("ADDRESS1"),
            inputType: "text",
            name: "address1",
            value:
              (user.addresses &&
                user.addresses.length > 0 &&
                user.addresses[0]?.address1) ||
              "",
            showIf: !!this.config.collectFullAddress,
            validations: [
              {
                name: "required",
                validator: Validators.required,
                message: this.getText("FIELD_REQUIRED", {
                  fieldName: this.getText("ADDRESS1"),
                }),
              },
            ],
          },
          {
            type: "input",
            label: this.getText("ADDRESS2"),
            inputType: "text",
            name: "address2",
            showIf: !!this.config.collectFullAddress,
            value:
              user.addresses &&
              user.addresses.length &&
              user.addresses[0]?.address2
                ? user.addresses[0]?.address2
                : "",
          },
          {
            type: "input",
            label: this.getText("STATE"),
            inputType: "text",
            name: "stateName",
            value: user.addresses[0]?.stateName || "",
            showIf: this.localeService.matchesLocale("en-US", "de-DE"),
            validations: [
              {
                name: "required",
                validator: Validators.required,
                message: this.getText("FIELD_REQUIRED", {
                  fieldName: this.getText("STATE"),
                }),
              },
            ],
          },
          {
            type: "input",
            label: this.getText("COUNTY"),
            inputType: "text",
            name: "county",
            value: user.addresses[0]?.county || "",
            showIf: !this.localeService.matchesLocale("en-US", "de-DE"),
            validations: [
              {
                name: "required",
                validator: Validators.required,
                message: this.getText("FIELD_REQUIRED", {
                  fieldName: this.getText("COUNTY"),
                }),
              },
            ],
          },
          {
            type: "input",
            label: this.getText("CITY"),
            value: user.addresses[0]?.city || "",
            inputType: "text",
            name: "city",
            validations: [
              {
                name: "required",
                validator: Validators.required,
                message: this.getText("FIELD_REQUIRED", {
                  fieldName: this.getText("CITY"),
                }),
              },
            ],
          },
          {
            type: "input",
            label: this.getText("POSTAL_CODE"),
            inputType: "text",
            value: user.addresses[0]?.postalCode || "",
            name: "postalCode",
            validations: [
              {
                name: "required",
                validator: Validators.required,
                message: this.getText("FIELD_REQUIRED", {
                  fieldName: this.getText("POSTAL_CODE"),
                }),
              },
            ],
          },
          {
            type: "select",
            label: this.getText("COUNTRY"),
            name: "country",
            value: user.addresses[0]?.country || "",
            options: this.portalService.getCountryList(),
            validations: [
              {
                name: "required",
                validator: Validators.required,
                message: this.getText("FIELD_REQUIRED", {
                  fieldName: this.getText("COUNTRY"),
                }),
              },
            ],
          },
          {
                type: "input",
                label: this.getText("MAX_COMMUTE_DISTANCE", {
                  units: this.getText("MILES")
                }),
                inputType: "number",
                max: 500,
                min: 0,
                name: "commuteDistance",
                showIf: !this.config.hideMaxCommuteDistance,
                value: user.location?.commuteDistance,
                validations: [
                  {
                    name: "max",
                    validator: Validators.max(500),
                    message: this.getText("MAXIMUM_DISTANCE", {
                      units: this.getText("MILES"),
                      distance: 500,
                    }),
                  },
                ],
              },
          {
            type: "input",
            label: this.getText("EMAIL"),
            inputType: "email",
            autocomplete: "email",
            name: "email",
            value: user.email,
            validations: [
              {
                name: "required",
                validator: Validators.required,
                message: this.getText("FIELD_REQUIRED", {
                  fieldName: this.getText("EMAIL"),
                }),
              },
              {
                name: "pattern",
                validator: Validators.pattern(
                  "^[a-zA-Z0-9._%+-]+@[a-zA-Z0-9.-]+.[a-zA-Z]{2,4}$"
                ),
                message: this.getText("INVALID_EMAIL"),
              },
            ],
          },
          {
            type: "button",
            color: "primary",
            label: this.getText("UPDATE_MY_PROFILE"),
          },
        ];
      })
    );
  }

  ngOnInit(): void {
    this.portalServiceSubscription = this.portalService
      .getComponentData("manage-profile")
      .subscribe((data) => {
        this.component = data.component;
        this.config = data.configuration;
        this.setupFieldConfig();
      });
  }

  ngOnDestroy(): void {
    this.portalServiceSubscription.unsubscribe();
  }
}
