import { Injectable } from '@angular/core';
import { UntypedFormControl } from '@angular/forms';
import { Observable, of } from 'rxjs';
import { debounceTime, switchMap } from 'rxjs/operators';
import { PocketLocations } from '../models/location.model';
import { ListService } from './list.service';
import { LocaleService } from './locale.service';

@Injectable({
  providedIn: 'root'
})
export class LocationService {

  constructor(private localeService: LocaleService, private listService: ListService) { }

  searchLocation(value): Observable<PocketLocations> {
    if( this.localeService.isUSLocale() ) {
      return this.listService.searchUSLocation(value);
    }
    return this.listService.searchLocation(value);
  }

  createLocationAutocomplete(field: UntypedFormControl): Observable<PocketLocations> {
    return field.valueChanges.pipe(
      debounceTime(400), 
      switchMap(value => {
        if(value && typeof value === 'string') { 
          return this.searchLocation(value);
        }
        return of(null);
      })) as Observable<PocketLocations>;
  }
}
