import { NgModule } from "@angular/core";
import { CommonModule } from "@angular/common";
import { HttpClientModule } from "@angular/common/http";
import { FilterPipe } from "./pipes/filter.pipe";
import { PageNotFoundComponent } from "./components/page-not-found/page-not-found.component";
import { NavComponent } from "./components/nav/nav.component";
import { NgxsModule } from "@ngxs/store";
import { CoreState } from "./store/states/core.state";
import { ApplyComponent } from "./components/apply/apply.component";
import { LogoutComponent } from "./logout/logout.component";
import { LoadingComponent } from "./loading/loading.component";
import { PageNotAllowedComponent } from "./components/page-not-allowed/page-not-allowed.component";
import { DefaultNavComponent } from "./components/nav/templates/default/default-nav.component";
import { BaseTemplateComponent } from "./components/base-template/base-template.component";
import { FontAwesomeModule } from "@fortawesome/angular-fontawesome";
import { MatLegacyMenuModule as MatMenuModule } from "@angular/material/legacy-menu";
import { RouterModule } from "@angular/router";
import { TransformPipe } from "./pipes/transform.pipe";
import { ConfirmationDialogComponent } from "./components/confirmation-dialog/confirmation-dialog.component";
import { MatLegacyButtonModule as MatButtonModule } from "@angular/material/legacy-button";
import { MatLegacyDialogModule as MatDialogModule } from "@angular/material/legacy-dialog";
import { MatLegacyTooltipModule as MatTooltipModule } from "@angular/material/legacy-tooltip";
import { UnsubscribeComponent } from "./components/unsubscribe/unsubscribe.component";
import { DefaultUnsubscribeComponent } from "./components/unsubscribe/templates/default-unsubscribe/default-unsubscribe.component";
import { MatLegacyRadioModule as MatRadioModule } from "@angular/material/legacy-radio";
import { FormsModule, ReactiveFormsModule } from "@angular/forms";
import { MatLegacySelectModule as MatSelectModule } from "@angular/material/legacy-select";
import { MatLegacyAutocompleteModule as MatAutocompleteModule } from "@angular/material/legacy-autocomplete";
import { FooterComponent } from "./components/footer/footer.component";
import { DefaultErrorSummaryComponent } from "./components/error-summary/templates/default-error-summary/default-error-summary.component";
import { ErrorSummaryComponent } from "./components/error-summary/error-summary.component";
import { UploadFileComponent } from "./components/upload-file/upload-file.component";
import { ProgressBarComponent } from "./components/progress-bar/progress-bar.component";
import { DataCardComponent } from "./components/data-card/data-card.component";
import { DataPillsComponent } from "./components/data-pills/data-pills.component";
import { MatLegacyInputModule as MatInputModule } from "@angular/material/legacy-input";
import { DragAndDropDirective } from "./directives/drag-and-drop.directive";
import { MatBadgeModule } from "@angular/material/badge";
import { MatSidenavModule } from "@angular/material/sidenav";
import { MatLegacyListModule as MatListModule } from "@angular/material/legacy-list";
import { DefaultUploadFileComponent } from "./components/upload-file/templates/default-upload-file/default-upload-file.component";
import { MobileNavComponent } from "./components/mobile-nav/mobile-nav.component";
import { DefaultMobileNavComponent } from "./components/mobile-nav/templates/default-mobile-nav/default-mobile-nav.component";
import { SocialNavComponent } from "./components/nav/templates/social/social-nav.component";
import { DefaultFooterComponent } from "./components/footer/templates/default/default-footer.component";
import { ImgEditContainerDirective } from "./directives/img-edit-container.directive";
import { DisableClickEvent } from "./directives/disable-click-event.directive";
import { TextEditContainerDirective } from './directives/text-edit-container.directive';
import { DisabledDivDirective } from "./directives/disabled-div.directive";
import { MatIconModule } from "@angular/material/icon";
import { MatExpansionModule } from "@angular/material/expansion";
@NgModule({
  declarations: [
    FilterPipe,
    DragAndDropDirective,
    DataCardComponent,
    DataPillsComponent,
    ProgressBarComponent,
    UploadFileComponent,
    PageNotFoundComponent,
    NavComponent,
    ApplyComponent,
    LogoutComponent,
    LoadingComponent,
    PageNotAllowedComponent,
    DefaultNavComponent,
    BaseTemplateComponent,
    TransformPipe,
    ConfirmationDialogComponent,
    UnsubscribeComponent,
    DefaultUnsubscribeComponent,
    FooterComponent,
    DefaultFooterComponent,
    ErrorSummaryComponent,
    DefaultErrorSummaryComponent,
    DefaultUploadFileComponent,
    MobileNavComponent,
    DefaultMobileNavComponent,
    SocialNavComponent,
    ImgEditContainerDirective,
    DisabledDivDirective,
    DisableClickEvent,
    TextEditContainerDirective
  ],
  imports: [
    NgxsModule.forFeature([CoreState]),
    CommonModule,
    RouterModule,
    HttpClientModule,
    MatExpansionModule,
    FontAwesomeModule,
    MatButtonModule,
    MatIconModule,
    MatDialogModule,
    MatTooltipModule,
    MatSidenavModule,
    MatListModule,
    MatInputModule,
    MatBadgeModule,
    FormsModule,
    ReactiveFormsModule,
    MatAutocompleteModule,
    MatSelectModule,
    MatRadioModule,
    MatMenuModule
  ],
  exports: [
    FilterPipe,
    TransformPipe,
    DataCardComponent,
    DataPillsComponent,
    PageNotFoundComponent,
    ErrorSummaryComponent,
    NavComponent,
    FooterComponent,
    MobileNavComponent,
    LoadingComponent,
    ProgressBarComponent,
    UploadFileComponent,
    BaseTemplateComponent,
    ImgEditContainerDirective,
    DisabledDivDirective,
    DisableClickEvent,
    TextEditContainerDirective
  ]
})
export class CoreModule {}
