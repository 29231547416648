import { Component, OnInit } from '@angular/core';
import { Router } from '@angular/router';
import { UserAuthService } from '@career/core/services/user-auth.service';

@Component({
  selector: 'app-logout',
  templateUrl: './logout.component.html'
})
export class LogoutComponent implements OnInit {

  constructor(private userService: UserAuthService, private router: Router) { }

  ngOnInit() {
    if(this.userService.getToken()) {
      this.userService.logout().subscribe(() => {
        this.router.navigate(['/jobs']);
      })
    } else {
      this.router.navigate(['/']);
    }
  }

}
