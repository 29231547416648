<div class="data-with-image data-with-image--min-height">
    <div class="data-with-image__img-container" appImgEditContainer="forgot-password" name="Main Image">
        <div>
            <img class="data-with-image__img" [src]="scope.component.assets.image"
                [alt]="scope.getText | transform:'FORGOT_PASSWORD?'">
        </div>
    </div>
    <div class="data-with-image__text-container">
        <app-error-summary [error]="scope.error" *ngIf="scope.error"></app-error-summary>
        <ng-container *ngIf="!scope.success">
            <h1 [innerText]="scope.getText | transform:'FORGOT_PASSWORD?'"
                class="data-with-image__heading data-with-image__heading--bold"></h1>
            <p class="data-with-image__text" [innerText]="scope.getText | transform:'FORGOT_PASSWORD_DESC'"></p>
            <dynamic-form (submit)="scope.submitForm($event)" [groupValidators]="scope.groupValidators"
                [fields]="scope.forgotPasswordConfig" [group]></dynamic-form>
        </ng-container>
        <ng-container *ngIf="scope.success">
            <h1 [innerText]="scope.getText | transform:'SUCCESS'"
                class="data-with-image__heading data-with-image__heading--bold"></h1>
            <p class="data-with-image__text" [innerText]="scope.getText | transform:'CHECK_EMAIL_TO_RESET'"></p>
        </ng-container>
    </div>
</div>