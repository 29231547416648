import { Component, OnInit, Input, ViewChild, TemplateRef } from '@angular/core';
import { faPencilAlt, faTimes, faDownload} from '@fortawesome/free-solid-svg-icons';
@Component({
  selector: 'app-default-dashboard',
  styleUrls: ['./default-dashboard.component.scss'],
  templateUrl: './default-dashboard.component.html'
})
export class DefaultDashboardComponent implements OnInit {

  @Input() scope;
  constructor() { }

  ngOnInit(): void {
  }

}
