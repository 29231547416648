<app-embedded-toolbar (onConfigure)="scope.openConfiguration()" [configurationName]="scope.translations.DASHBOARD_CONFIG"></app-embedded-toolbar>
<div class="data-hero data-hero--min-height">
    <div class="header" appImgEditContainer="dashboard" name="Main Image">
        <div class="header__img-container">
            <img [src]="scope.component.assets.image" class="header__img" [alt]="scope.getText | transform:'DASHBOARD'">
        </div>
        <div class="header__overlay header__overlay--top">
            <div class="header__title-container">
                <div class="header__title-row header__title-row--slim">
                    <h1 class="header__title" [innerText]="scope.getText | transform:'DASHBOARD'"></h1>
                </div>
            </div>
        </div>
    </div>
    <div class="data-hero__text-container">
        <app-error-summary [error]="scope.error" *ngIf="scope.error"></app-error-summary>
        <app-panels [suggestedJobsOpen]="scope.suggestedJobsOpen"></app-panels>
    </div>
</div>
