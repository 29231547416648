import { Component, Input, OnInit, ViewEncapsulation } from '@angular/core';
import { inOutAnimation } from '@career/core/animations/fade-in-out';
import { LocaleService } from '@career/core/services/locale.service';

@Component({
  selector: 'app-social-register',
  templateUrl: './social-register.component.html',
  styleUrls: ['./social-register.component.scss'],
  animations: [inOutAnimation],
  encapsulation: ViewEncapsulation.None
})
export class SocialRegisterComponent implements OnInit {

  @Input() scope;
  resumeOrCvText = this.localeService.getResumeOrCVText();
  constructor(private localeService: LocaleService) { }

  ngOnInit(): void {
  }

}
