export const ConfigMessages = {
  headers: {
    GLOBAL: "Global Configurations",
    REGISTER: "Register Configurations",
    JOBS: "Jobs Configurations",
    DASHBOARD: "Dashboard Configurations",
    HOME: "Home Configurations",
    STATIC_PAGE: "Static Page Configurations"
  },
};

export const Translations = {
  JOBS_CONFIG: "Jobs Config",
  REGISTER_CONFIG: "Register Config",
  DASHBOARD_CONFIG: "Dashboard Config",
  GLOBAL_CONFIG: "Global Config",
  HOME_CONFIG: "Home Config",
  STATIC_PAGE_CONFIG: "Static Page Config",
  THEME: "Theme",
  FONT_FAMILY: "Font Family",
  TEMPLATE: "Template",
  NO_ACCESS_HOME_PAGE: "Portal doesn't have the home page configuration",
  FORWARD_PAGE_MSG: "Organization has an external job search page URL:",
  NO_ACCESS_JOB_SEARCH_PAGE:
    "Portal doesn't have the job search page configuration",
  NO_ACCESS_REGISTER_PAGE:
    "Portal doesn't have the register page configuration",
  ADMIN_QUESTIONS_HEADER: "Questionnaire",
  FAVICONS: "Favicons",
};

export const SliderConfigs = {
  GLOBAL: [
    {
      type: "slider",
      key: "smoothScrolling",
      question: "Enable smooth scrolling effect?",
    },
    {
      type: "slider",
      key: "disablePortalVerification",
      question: "Disable verification email?",
    },
    {
      type: "slider",
      key: "enableReviewDetails",
      question:
        "Enable moving candidate to 'Pending Review' upon registration?",
    },
    {
      type: "slider",
      key: "disableJobSearchPage",
      question: "Disable job search page?",
    },
    {
      type: "slider",
      key: "disableRegisterPage",
      question: "Disable register page?",
    },
    {
      type: "input",
      key: "agencyWebsite",
      question: "Does organization have an external homepage?",
    },
    {
      type: "input",
      key: "forwardJobSearchPage",
      question: "Does organization have an external job search page URL?",
    },
    {
      type: "select",
      key: "questionnaire",
      question: "Provide an optional registration questionnaire",
    },
    {
      type: "input",
      key: "googleTagManagerId",
      question: "Google Tag Manager ID"
    },
    {
      type: "input",
      key: "logoSize",
      question: "Max Navigation Logo Width (in px)"
    },
    {
      type: "input",
      key: "footerSize",
      question: "Max Footer Logo Width (in px)"
    },
    {
      type: "slider",
      key: "shortLocations",
      question: "Use Shortened Locations (e.g. New York, NY, USA)"
    }
  ],
  REGISTER: [
    {
      type: "slider",
      key: "collectFullAddress",
      question: "Does organization want to collect full candidate address?",
    },
    {
      type: "slider",
      key: "disableResumeRegistration",
      question:
        "Does organization want to disable collecting candidate resumes?",
    },
    {
      type: "slider",
      key: "requirePhoneNumber",
      question: "Does organization want to collect phone number ?",
    },
  ],
  DASHBOARD: [
    {
      type: "slider",
      key: "enableAvailability",
      question: "Enable candidate availability on the dashboard?",
    },
    {
      type: "slider",
      key: "enableSkills",
      question: "Enable candidate skills display on the dashboard?",
    },
    {
      type: "slider",
      key: "hideAttachedFiles",
      question: "Hide attached files for candidates?"
    },
    {
      type: "slider",
      key: "enableSuggestions",
      question: "Does organization want to enable candidate suggested jobs?",
    },
    {
      type: "slider",
      key: "requireAvailability",
      question: "Enable warning if candidate doesn't provide availability?",
    },
    {
      type: "slider",
      key: "requireResume",
      question: "Enable warning if candidate doesn't provide resume?",
    },
    {
      type: "slider",
      key: "requireSkills",
      question: "Enable warning if candidate skills are not provided?",
    },
  ],
  JOBS: [
    {
      type: "slider",
      key: "hideClient",
      question: "Hide client name from jobs / applications?",
    },
    {
      type: "slider",
      key: "showOpportunityFiles",
      question: "Display attached files for download on opportunity?",
    },
  ],
  HOME: [
    {
      type: "slider",
      key: "enableHomePage",
      question: "Will this portal have a configured homepage?",
    },
  ]
};

export const FontFamilyConfig = [
  {
    name: "Noto Sans",
    value: "noto-sans",
  },
  {
    name: "Montserrat",
    value: "montserrat",
  },
  {
    name: "Inter",
    value: "inter",
  },
];


