import { Injector } from "@angular/core";
import { combineLatest, concat, forkJoin, from, Observable, of } from "rxjs";
import { concatAll, concatMap, filter, map, mergeAll, mergeMap } from "rxjs/operators";
import { ActionDataSourceService } from "../services/action-data-source.service";
import { ActionProvider } from "./action-data-source.model";
import { EActionType, EDataSource, IAction } from "./action.model";

export interface IActionStrategy {
    createActions(): Observable<IAction>;
    getType(): EActionType;
}

export abstract class ActionStrategy implements IActionStrategy {
    paramMap: Map<string, any> = new Map<string, any>();
    abstract createActions(): Observable<IAction>;
    abstract getType(): EActionType;
    abstract getActionProvider(): ActionProvider;
    requiredParams: string[];

    constructor(protected dataSourceService: ActionDataSourceService) {}
    
    transformActions(context, transformations: Function[]): Observable<IAction> {
        const provider = this.getActionProvider();
        return provider.fetch().pipe(
            filter((action) => !!action),
            concatMap((action) => {
                if( transformations.length < 1 ) {
                    return of([action]);
                }
                return transformations.map(transformation => transformation.call(context, action) as Observable<IAction>)
            }),
            mergeAll(),
            map(data => {
                data.id = [data.type.toString(), provider.setId(data)].map(s => s.trim()).join(":");
                if( provider.populateTarget ) {
                    return provider.populateTarget(data);
                }
                return data;
            })
        )
    }
}