import { Component, Input, OnInit } from '@angular/core';

@Component({
  selector: 'app-default-dashboard-suggested-jobs',
  templateUrl: './default-dashboard-suggested-jobs.component.html',
  styleUrls: ['./default-dashboard-suggested-jobs.component.scss']
})
export class DefaultDashboardSuggestedJobsComponent implements OnInit {

  @Input() scope;
  constructor() { }

  ngOnInit(): void {
  }

}
