import { ElementRef, Injectable } from "@angular/core";
import { CreateNewTemplateComponent } from "@career/page-configuration/Shared/components/create-new-template/create-new-template.component";
import { SectionTextEditDialogComponent } from "@career/page-configuration/Shared/components/section-text-edit-dialog/section-text-edit-dialog.component";
import { UploadImageDialogComponent } from "@career/page-configuration/Shared/components/upload-image-dialog/upload-image-dialog.component";
import { LayerOverlayRef, LayerService } from "kleos-ui";
import { take } from "rxjs/operators";
import { UploadImageIcon } from "../models/update-image-icon.enum";
import { PortalService } from "./portal.service";

@Injectable({
  providedIn: "root",
})
export class DialogService {
  constructor(
    private layerService: LayerService,
    private portalService: PortalService
  ) {}

  private highlight(layerRef: LayerOverlayRef, elm: ElementRef) {
    if (!elm || !layerRef) {
      return;
    }
    elm.nativeElement.classList.add("_editable--selected");
    layerRef.beforeClose().subscribe((_) => {
      elm.nativeElement.classList.remove("_editable--selected");
    });
  }

  openCreateNewTemplate(component: any, event?: PointerEvent, data?: any) {
    const layerRef = this.layerService.open({
      component: component,
      position:
        event && event.clientX > window.innerWidth / 2 ? "left" : "right",
      data: data || {},
    });
  }

  editSectionContentDialog(
    componentName: string,
    titlePath: string,
    bodyPath: string,
    name: string,
    event?: PointerEvent,
    elementRef?: ElementRef,
    isStaticTemplate = false
  ) {
    let dialogPayload = {
      titlePath: titlePath,
      bodyPath: bodyPath,
      name: name,
      component: componentName,
      bodyHtmlContent: null,
      titleHtmlContent: null,
      isStaticTemplate: isStaticTemplate,
    };
    this.portalService
      .getComponentData(componentName)
      .pipe(take(1))
      .subscribe((componentObj) => {
        dialogPayload = {
          ...dialogPayload,
          bodyHtmlContent: componentObj.component.textMap[bodyPath],
          titleHtmlContent: componentObj.component.textMap[titlePath],
        };
      });
    const layerRef = this.layerService.open({
      component: SectionTextEditDialogComponent,
      position:
        event && event.clientX > window.innerWidth / 2 ? "left" : "right",
      data: dialogPayload,
    });
    this.highlight(layerRef, elementRef);
  }

  uploadImageDialog(
    componentName: string,
    path: UploadImageIcon,
    type: string,
    name: string,
    event?: PointerEvent,
    elementRef?: ElementRef
  ) {
    const layerRef = this.layerService.open({
      component: UploadImageDialogComponent,
      position:
        event && event.clientX > window.innerWidth / 2 ? "left" : "right",
      data: {
        component: componentName,
        path: path,
        type: type,
        name: name,
      },
    });
    this.highlight(layerRef, elementRef);
  }

  uploadFaviconDialog(): void {
    this.layerService.open({
      component: UploadImageDialogComponent,
      data: { type: UploadImageIcon.favicon.toUpperCase() },
    });
  }
}
