import { Component, Input, OnInit } from '@angular/core';
import { faStopCircle, faTimesCircle } from '@fortawesome/free-solid-svg-icons';

@Component({
  selector: 'app-default-manage-skills',
  templateUrl: './default-manage-skills.component.html',
  styleUrls: ['./default-manage-skills.component.scss']
})
export class DefaultManageSkillsComponent implements OnInit {
  @Input() scope;
  faTimesCircle = faTimesCircle;
  constructor() { }

  ngOnInit(): void {
  }

}
