import { Injectable } from '@angular/core';
import { HttpInterceptor, HttpRequest, HttpHandler, HttpEvent } from '@angular/common/http';
import { Observable } from 'rxjs';
import { Store } from '@ngxs/store';
import { AuthState } from '../store/states/auth.state';
import { CoreState } from '@career/core/store/states/core.state';


@Injectable()
export class AuthInterceptor implements HttpInterceptor {
    constructor(private store: Store) { }

    intercept(request: HttpRequest<any>, next: HttpHandler): Observable<HttpEvent<any>> {
        // add authorization header with basic auth credentials if available
        const token =  this.store.selectSnapshot(AuthState.token);
        if (token) {
            request = request.clone({
                setHeaders: { 
                    'x-session-id': token
                }
            });
        } else {
            const auditToken = this.store.selectSnapshot(CoreState.getAuditToken);
            if( auditToken ) {
                request = request.clone({
                    setHeaders: { 
                        'x-audit-token': auditToken
                    }
                });
            }
        }

        return next.handle(request);
    }
}