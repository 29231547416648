export enum PageConfigTypes {
  GLOBAL = "GLOBAL",
  REGISTER = "REGISTER",
  DASHBOARD = "DASHBOARD",
  JOBS = "JOBS",
  HOME = "HOME",
  STATIC_PAGE = 'STATIC_PAGE'
}

export interface GlobalConfig {
  collectFullAddress?: boolean;
  disablePortalVerification?: boolean;
  disableResumeRegistration?: boolean;
  enableAvailability?: boolean;
  enableHomePage?: boolean;
  enableReviewDetails?: boolean;
  enableSkills?: boolean;
  enableSuggestions?: boolean;
  hideClient?: boolean;
  requireAvailability?: boolean;
  requireResume?: boolean;
  requireSkills?: boolean;
  googleTagManagerId?: string;
  logoSize?: string;
  footerSize?: string;
}

export interface UpdateConfigRes {
  template: { _id: string };
  theme: { _id: string };
  fontFamily: string;
  configuration: GlobalConfig;
}
export interface ConfigQuestion {
  type: string;
  key: string;
  question: string;
}

export interface ThemeAndTemplateResponse {
  themes: ThemeResponse;
  templates: TemplateResponse;
}

export interface TemplateResponse {
  portaltemplates: Theme[];
  total: number;
}

export interface ThemeResponse {
  portalthemes: Theme[];
  total: number;
}

export interface Theme {
  colors?: ThemeColors;
  _id: string;
  name: string;
  value?: string;
}

export interface SelectOption {
  name: string;
  id?: string;
  value?: string;
}

export interface ThemeColors {
  accentColor: string;
  primaryColor: string;
  warnColor: string;
}
