<div class="admin__login">
  <div class="admin__login__img-container">
    <img [src]="'https://prweb-assets-prod.s3.amazonaws.com/images/prlogo.svg'" alt="prlogo" class="img" width="300" />
  </div>
  <div class="admin__login__text-container">
    <app-error-summary [error]="error" *ngIf="error"></app-error-summary>
    <ng-container *ngIf="showLoginContainer">
      <dynamic-form (submit)="onLogin($event)" [fields]="loginConfig"></dynamic-form>
    </ng-container>
    <ng-container *ngIf="mfaEnabled">
      <dynamic-form (submit)="onMfaLogin($event)" [fields]="mfaLoginConfig"></dynamic-form>
    </ng-container>
  </div>
</div>