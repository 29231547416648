import { Injectable } from "@angular/core";
import { HttpClient, HttpResponse } from "@angular/common/http";
import { Store } from "@ngxs/store";
import { from, Observable, of, throwError } from "rxjs";
import { map, catchError, switchMap, share } from "rxjs/operators";
import { UserAction } from "@career/user/store/actions/user.actions";
import { PortalService } from "./portal.service";
import { UserState } from "@career/user/store/states/user.state";
import { CoreState } from "../store/states/core.state";
import { UserService } from "./user.service";

@Injectable({
  providedIn: "root",
})
export class CandidateService {
  _user$: Observable<any>;
  _applied$: Observable<any>;
  constructor(
    protected http: HttpClient,
    protected portalService: PortalService,
    private store: Store,
    private userService: UserService
  ) {}

  updateResume(resume: File, id: string): Observable<any> {
    let url: string = `/api/candidate/${id}?action=updateresume&force=true&portal=true&ignoreids=true`;
    const formData: FormData = new FormData();
    formData.append("file", resume);
    return this.http.post(url, formData).pipe(
      map((data) => {
        this.store.dispatch(
          new UserAction.Update({ user: data, context: "PROFILE" })
        );
        return data;
      })
    );
  }

  verifyMobileNumber(pin: string, id: string): Observable<number> {
    return this.http.post(`/api/candidate/${id}/validatephone`, pin, {observe: 'response'}).pipe(
      map(resp => {
        this.store.dispatch(
          new UserAction.Update({ user: resp.body, context: "PROFILE" })
        );
        return resp.status;
      })
    )
  }

  resendMobileVerificationPin(id: string): Observable<any> {
    return this.http.get(`/api/candidate/${id}/validatephone`, {observe: 'response'});
  }

  updateProfile(obj, id): Observable<any> {
    let url: string = `/api/candidate/${id}`;
    return this.http.patch(url, obj).pipe(
      map((data) => {
        this.store.dispatch(
          new UserAction.Update({ user: data, context: "PROFILE" })
        );
        return data;
      })
    );
  }

  updatePreferences(obj, id): Observable<any> {
    let url: string = `/api/candidate/${id}/preferences`;
    return this.http.post(url, obj).pipe(
      map((data) => {
        this.store.dispatch(
          new UserAction.Update({ user: data, context: "PROFILE" })
        );
        return data;
      })
    );
  }

  isCandidateApplied(candidateId: string, oppId: string): Observable<any> {
    return this.getApplied(candidateId).pipe(
      map((data) => {
        if (data && data.matches) {
          for (let i = 0; i < data.matches.length; i++) {
            if (data.matches[i].opportunity._id === oppId) {
              return true;
            }
          }
        }
        return null;
      })
    );
  }

  uploadResume(resume: File): Observable<any> {
    const existingUser = this.store.selectSnapshot((state) => state.user);

    if (
      existingUser &&
      existingUser.user &&
      (existingUser.user.latestResume || {}).name
    ) {
      return of(existingUser);
    }

    const formData: FormData = new FormData();
    formData.append("file", resume);
    return this.http
      .post(
        `/api/portal/${this.portalService.getPortalId()}/import/resume`,
        formData
      )
      .pipe(
        map((data) => {
          return data;
        })
      );
  }

  setAvailability(availability, candidateId): Observable<any> {
    return this.http
      .patch(`/api/candidate/${candidateId}`, { availableDates: availability })
      .pipe(
        map((data) => {
          this.store.dispatch(
            new UserAction.Update({ user: data, context: "PROFILE" })
          );
          return data;
        })
      );
  }

  setSkills(skills, candidateId): Observable<any> {
    return this.http
      .patch(`/api/candidate/${candidateId}`, { skills: skills })
      .pipe(
        map((data) => {
          this.store.dispatch(
            new UserAction.Update({ user: data, context: "PROFILE" })
          );
          return data;
        })
      );
  }

  getSkillsList(agencyId: string): Observable<any[]> {
    return (
      this.http.get(
        `/api/agency/labels?portalVisibility=VISIBLE&type=PROFILE&agency=${agencyId}`
      ) as Observable<any[]>
    ).pipe(
      map((skills) => {
        if (skills?.length) {
          skills.sort((a, b) => a.value.localeCompare(b.value));
        }
        return skills;
      })
    );
  }

  deleteUser(): Observable<any> {
    return this.http.delete("/api/portal/deleteUser").pipe(
      map(() => {
        this.redirectToHome();
      })
    );
  }
  redirectToHome() {
    window.location.href = "/";
  }

  getSuggested(candidateId: string, location?: string): Observable<any> {
    let url = `/api/portal/${this.portalService.getPortalId()}/suggestions?candidate=${candidateId}`;
    if (location) url += "&location=" + encodeURIComponent(location);
    if (this.store.selectSnapshot((state) => state.auth.token)) {
      url += `&excludeStatusHistory=${encodeURIComponent('PREAPPLIED,APPLIED')}&status=`;
    }
    url += `&populate=${encodeURIComponent(
      "opportunity|opportunity.department:name"
    )}`;
    return this.http.get(url).pipe(
      map((data: any) => {
        if (data && data.matches.length > 0) {
          this.store.dispatch(new UserAction.GetSuggested(data.matches));
        }
        return data;
      }),
      catchError((err: any) => {
        return throwError(err);
      })
    );
  }

  apply(id): Observable<any> {
    return this.userService.getUser().pipe(
      switchMap((user) => {
        if (user && user._id) {
          return this.http.get(
            `/api/portal/${this.portalService.getPortalId()}/apply/${id}?profile=${
              user._id
            }&src=${this.store.selectSnapshot(CoreState.getSource)}`
          );
        }
        return of(null);
      }),
      map((data) => {
        if (data) {
          this.clearApplied();
          return data;
        }
        throw new Error("404");
      })
    );
  }

  register(obj, id?, applyId?): Observable<any> {
    let url = `/api/portal/${this.portalService.getPortalId()}/register`;
    let params = [];
    if (id) {
      params.push(["profile", id]);
    }
    if (applyId) {
      params.push(["applyTo", applyId]);
    }
    params.push(["src", this.store.selectSnapshot(CoreState.getSource)]);
    if (params.length > 0) {
      url += "?" + params.map((param) => param.join("=")).join("&");
    }
    return this.http.post(url, obj);
  }

  getApplied(candidateId: string): Observable<any> {
    if (!this._applied$) {
      this._applied$ = this.store.selectOnce(UserState.applied).pipe(
        switchMap((data) => {
          return data
            ? of(data)
            : this.http.get(
                `/api/portal/${this.portalService.getPortalId()}/matches?candidate=${candidateId}&statusHistory=${encodeURIComponent('PREAPPLIED,APPLIED')}&status=&populate=${encodeURIComponent(
                  "opportunity:name,location|opportunity.client:name|status"
                )}`
              );
        }),
        map(
          (data: any) => {
            this.store.dispatch(new UserAction.GetApplied(data));
            return data;
          }
        ),
        share(),
        catchError((err: any) => {
          return throwError(err);
        })
      );
    }
    return this._applied$;
  }

  clearApplied(): Observable<any> {
    this._applied$ = null;
    return this.store.dispatch(new UserAction.ClearApplied());
  }

  getCandidateConsents(candidateId: string): Observable<any> {
    return this.http.get(
      `/api/portal/${this.portalService.getPortalId()}/questionnaire/${candidateId}/consents`
    );
  }

  updateCandidateConsents(
    consents: { [k: string]: boolean },
    candidateId
  ): Observable<any> {
    return this.http.put(
      `/api/portal/${this.portalService.getPortalId()}/questionnaire/${candidateId}/consents`,
      consents
    ).pipe(
      map((data) => {
        this.store.dispatch(
          new UserAction.Update({ user: data, context: "PROFILE" })
        );
        return data;
      })
    )
  }
}
