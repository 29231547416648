import { Injectable } from '@angular/core';
import { CoreModule } from '../core.module';
import { AbstractControl, UntypedFormBuilder, FormControl, FormGroup, ValidationErrors, ValidatorFn, Validators } from '@angular/forms';
import { MatLegacySnackBar as MatSnackBar } from '@angular/material/legacy-snack-bar';
import { PocketLocations } from '../models/location.model';
import { FieldConfig } from '@career/pocket-forms/models/field';
import { LocaleService } from './locale.service';

export interface GetTextMethod {
  (key, replacement?): string;
}
@Injectable({
  providedIn: 'root'
})
export class UtilsService {

  constructor(private fb: UntypedFormBuilder, private localeService: LocaleService, private snackBar: MatSnackBar) { }

  createLocationString(args, shortLocation?: boolean) {
    if( shortLocation ) {
      return `${args.city || args.county}, ${args.state || args.stateName}, ${args.country}`;
    }
    if(args) {
      return Object.keys({address1: args.address1, city: args.city, state: args.stateName || args.state, county: args.county, country: args.country, postalCode: args.postalCode})
      .filter(key => args[key]).map(validKey => args[validKey]).join(', ');
    }
  }

  confirmFieldValidator = (fieldName, confirmFieldName, errorKey) => {
    return (group: AbstractControl):  ValidationErrors | null => { 
      const pass = group.get(fieldName)?.value;
      const confirmed = group.get(confirmFieldName)?.value
      if( pass && confirmed ) {
        if( pass !== confirmed ) {
          group.get(confirmFieldName).setErrors({[errorKey]: true});
        }
      }
      return null;
    };
  }

  parseCandidate(candidate) {
    if(candidate.location && candidate.location.locations && candidate.location.locations[0]) {
      let address = candidate.location.locations[0];
      if(address.city) {
        address.city = address.city.charAt(0).toUpperCase() + address.city.substring(1);
      }
    }

    if(candidate.name) {
      candidate.firstName = candidate.name.firstName ? candidate.name.firstName : '';
      candidate.lastName = candidate.name.lastName ? candidate.name.lastName : '';
    }
    // if(candidate.phones && candidate.phones.length > 0 ) {
    //   candidate.phones = candidate.phones[0].number;
    // }
    return candidate;
  }

  createConsentQuestionnaireFields(questionnaire, getText: Function, honorRequired=true, profileConsents=null): FieldConfig[] {
    return questionnaire && (questionnaire.sections || []).reduce((acc, section) => {
      section.questions.forEach(q => {
        const foundResponse =  profileConsents && profileConsents.find(x => x.name === q.key);
        acc.push({
          type: "consent",
          label: section.name,
          name: q.key,
          value: (foundResponse && foundResponse.accepted) || null,
          extras: {
            acceptanceText: getText('I_HAVE_READ_AND_AGREE_TO_THE_TERMS'),
            viewText: getText('VIEW_CONSENT', {consentName: section.name}),
            introduction: getText('CONSENT_INTRODUCTION', {consentName: section.name, date: (foundResponse && this.localeService.getLocalDateStr(foundResponse.timestamp)) || 'unavailable'}),
            dialogConfirmation: getText('GO_BACK'),
            consent: q.question,
            profileResponse: foundResponse
          },
          validations: (!honorRequired || !q.required)?null:[
            {
              name: "required",
              validator: Validators.requiredTrue,
              message: getText("FIELD_REQUIRED", {fieldName: section.name})
            }
          ]
        });
      });
      return acc;
    }, []);
  }

  regexValidator(regex: RegExp, error: ValidationErrors): ValidatorFn {
    return (control: AbstractControl): {[key: string]: any} => {
      if (!control.value) {
        return null;
      }
      const valid = regex.test(control.value);
      return valid ? null : error;
    };
  }

  openSnackbar(text, duration?) {
    this.snackBar.open(text, null, {duration: duration || 18000, politeness: 'assertive'});
  }

  getText(component) {
    let textMap;
    if(component.textMap) {
      textMap = component.textMap;
    } else {
      throw new Error('Text map not found');
    }

    return function(key, replacement?): string {
        //e.g. 'CANDIDATE', {myReplacement: xyz}
        if(replacement && textMap[key]) {
          let toReplace = textMap[key].match(/[^{}]+(?=})/g);
          if(toReplace && toReplace.length > 0) { 
            let retStr = textMap[key];
            toReplace.forEach(r => {
              if(r in replacement) {
                retStr = retStr.replace('{{'+r+'}}', replacement[r]);
              }
            });
            return retStr;
          }
        }
        return textMap[key] || key;
    }
  }
}
