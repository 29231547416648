<div class="data-hero data-hero--min-height">
    <div class="header" appImgEditContainer="reset-password" name="Main Image">
        <div class="header__img-container">
            <img [src]="scope.component.assets.image" class="header__img" [alt]="scope.getText | transform:'RESET_YOUR_PASSWORD'">
        </div>
        <div class="header__overlay header__overlay--top">
            <div class="header__title-container">
                <div class="header__title-row header__title-row--slim">
                    <h1 class="header__title" [innerText]="scope.getText | transform:'RESET_YOUR_PASSWORD'"></h1>
                </div>
            </div>
        </div>
    </div>
    <div class="data-hero__text-container">
        <app-error-summary [error]="scope.error" *ngIf="scope.error"></app-error-summary>
        <div [ngClass]="{'disabled--loading': scope.loading}">
            <div class="data-hero__heading-container">
                <p class="data-hero__text" [innerText]="scope.getText | transform:'ENTER_NEW_PASSWORD_BELOW'"></p>
            </div>
            <dynamic-form (submit)="scope.submitForm($event)" [groupValidators]="scope.groupValidators" [fields]="scope.resetPasswordConfig"></dynamic-form>
        </div>
    </div>
</div>