import { Component, OnInit, Input } from '@angular/core';
import { faEllipsisV } from '@fortawesome/free-solid-svg-icons';

@Component({
  selector: 'app-default-nav',
  templateUrl: './default-nav.component.html',
  styleUrls: ['./default-nav.component.scss']
})
export class DefaultNavComponent implements OnInit {

  @Input() scope;
  faEllipsisV = faEllipsisV;
  constructor() { }

  ngOnInit(): void {
  }

}
