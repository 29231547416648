import { Component, ElementRef, Input, OnChanges, OnInit, SimpleChanges, ViewChild } from '@angular/core';

@Component({
  selector: 'app-default-error-summary',
  template: `
    <div class="error-summary" aria-labelledby="error-summary-title" role="alert" tabindex="-1" aria-live="assertive" aria-atomic="false" #error *ngIf="!scope.forceClosed">
      <ng-container *ngIf="scope.error">
        <ng-container *ngIf="!scope.hideTitle">
        <h2 class="error-summary__title" id="error-summary-title" [innerText]="scope.title" *ngIf="scope.title"></h2>
        <h2 class="error-summary__title" id="error-summary-title" [innerText]="scope.getText | transform:'THERE_WAS_A_PROBLEM'" *ngIf="!scope.title"></h2>
        </ng-container>
        <p class="error-summary__message" [innerHTML]="scope.error.message"></p>
        <ul class="error-summary__list error-summary__list--thin">
          <li class="error-summary__list-item">
              <a class="error-summary__link" (click)="close()" tabindex="0" role="link">
                <strong *ngIf="!scope.confirmationText">{{scope.getText | transform:'DID_THIS_RESOLVE_YOUR_ISSUE'}}</strong>
                <strong *ngIf="scope.confirmationText">{{scope.confirmationText}}</strong>
              </a>
          </li>
          <li class="error-summary__list-item" *ngIf="scope.error.moreDetails">
              <a class="error-summary__link" (click)="showMoreDetails()" tabindex="0" role="link">
                <strong>{{scope.getText | transform:'PLEASE_PROVIDE_MORE_DETAIL'}}</strong>
              </a>
              <p class="error-summary__message error-summary__message--more-details" *ngIf="moreDetails">
                {{scope.error.moreDetails}}
              </p>
          </li>
        </ul>
      </ng-container>
    </div>
  `
})
export class DefaultErrorSummaryComponent {
  @Input() scope;
  @ViewChild("error") error: ElementRef;
  moreDetails: boolean = false;
  constructor() { }

  ngOnChanges(changes: SimpleChanges): void {
    if( changes.error ) {
      this.error.nativeElement.scrollIntoView();
    }
  }

  showMoreDetails() {
    this.moreDetails = !this.moreDetails;
  }

  close() {
    this.scope.forceClosed = true;
  }
}
