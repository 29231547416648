import { Component, OnInit } from "@angular/core";
import { MatLegacyDialog as MatDialog } from "@angular/material/legacy-dialog";
import { Router } from "@angular/router";
import { setupRoutes } from "@career/app.module";
import { AttachmentService } from "@career/core/services/attachment.service";
import { ContentService } from "@career/core/services/content.service";
import { DialogService } from "@career/core/services/dialog.service";
import { PortalService } from "@career/core/services/portal.service";
import { ConfirmDialogComponent } from "@career/page-configuration/Shared/components/confirm-dialog/confirm-dialog.component";
import { CreateNewTemplateComponent } from "@career/page-configuration/Shared/components/create-new-template/create-new-template.component";
import { routerActions } from "@career/routes";
import { Observable, Subject } from "rxjs";
import { map, take } from "rxjs/operators";

@Component({
  selector: "app-page-config-side-nav",
  templateUrl: "./page-config-side-nav.component.html",
  styleUrls: ["./page-config-side-nav.component.scss"],
})
export class PageConfigSideNavComponent implements OnInit {
  opensubMenu = false;
  openJobSubMenu = false;
  staticPageRoutes = [];
  components$: Observable<{[k: string]: any}>;

  constructor(
    private dialogSvc: DialogService,
    private portalSvc: PortalService,
    private contentSvc: ContentService,
    private router: Router,
    private dialog: MatDialog
  ) {}

  ngOnInit(): void {
    this.components$ = this.portalSvc.getPortalObj().pipe(
      map(data => data.components)
    );
    this.staticPageRoutes = routerActions.getStaticPageRoutes();
    this.checkForRoutesUpdate();
  }

  checkForRoutesUpdate() {
    this.portalSvc.routesUpdated().subscribe(() => {
      this.staticPageRoutes = routerActions.getStaticPageRoutes();
    });
  }

  onFaviconClick(): void {
    this.dialogSvc.uploadFaviconDialog();
  }

  onDelete(route: any): void {
    const dialgRef = this.dialog.open(ConfirmDialogComponent, {
      minWidth: "500px",
      maxWidth: "600px",
      disableClose: true,
      data: {
        cancelButtonText: "Cancel",
        message: `Are you sure you want to delete custom template ${route.data.displayName} ?`,
      },
    });
    dialgRef.afterClosed().subscribe((isSuccess) => {
      if (isSuccess) {
        this.delete(route.path);
      }
    });
  }

  delete(pathName: string): void {
    this.contentSvc.deleteCustomTemplate(pathName).subscribe((res) => {
      this.updateRoutes();
    });
  }

  updateRoutes() {
    this.portalSvc
      .getPortalObj()
      .pipe(take(1))
      .subscribe((data) => {
        setupRoutes(data);
        this.portalSvc.toggleRoutesUpdated();
        this.router.navigate(["/admin/pageConfiguration/home"]);
      });
  }

  navigateTo(path: string): void {
    this.router.navigate(["/admin/pageConfiguration/static/", path]);
  }

  onCreateClick(event: any): void {
    this.dialogSvc.openCreateNewTemplate(CreateNewTemplateComponent,event);
  }
}
