export enum UploadImageIcon {
  image = "image",
  logo = "logo",
  favicon = "favicon",
  section1 = "section1",
  section2 = "section2",
}

export enum ImageBucketTypes {
  IMAGE = "images",
  LOGO = "logos",
  FAVICON = "favicons",
}

export const ImageMaxWidthRestrictions = {
  IMAGE: {
    min: 226,
    max: Infinity,
  },
  LOGO: {
    min: 33,
    max: 225,
  },
  FAVICON: {
    min: 16,
    max: 32,
  },
};
