<div class="error-summary verify-phone-summary" aria-labelledby="error-summary-title" role="alert" tabindex="-1" aria-live="assertive"
    aria-atomic="false" *ngIf="(scope.show$ | async)">
    <h2 class="error-summary__title" id="error-summary-title">
        {{scope.getText | transform:'PLEASE_VERIFY_YOUR_PHONE_NUMBER'}}
    </h2>
    <div [ngClass]="{'disabled--loading': scope.loading$ | async}">
        <div class="banner" *ngIf="scope.updated">
            <h2 class="banner__text" [innerText]="scope.getText | transform:'NEW_PIN_SENT'"></h2>
        </div>
        <app-error-summary [error]="scope.error" [hideTitle]="true" *ngIf="scope.error"></app-error-summary>
        <p class="error-summary__message">{{scope.getText | transform:'VERIFY_PHONE_NUMBER_INSTRUCTIONS'}}</p>
        <div class="verify-inputs">
            <ng-otp-input  (onInputChange)="onOtpChange($event)" [config]="{length:6}"></ng-otp-input>
        </div>
        <div class="verify-resend">
            <button mat-button class="resend" (click)="scope.requestPin()">{{scope.getText | transform:'RESEND'}}</button>
        </div>
    </div>
</div>