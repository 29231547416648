import { NgModule } from '@angular/core';
import { CommonModule } from '@angular/common';
import { OpportunitiesComponent } from './components/opportunities/opportunities.component';
import { FormsModule, ReactiveFormsModule } from '@angular/forms';
import { CoreModule } from '../core/core.module';
import { NgxsModule } from '@ngxs/store';
import { OpportunitiesState } from './store/states/opportunities.state';
import { OpportunityListRoutingModule } from './opportunity-list-routing.module';
import { PortalModule } from '@angular/cdk/portal';
import { MatLegacyAutocompleteModule as MatAutocompleteModule } from '@angular/material/legacy-autocomplete';
import { MatLegacySelectModule as MatSelectModule } from '@angular/material/legacy-select';
import { MatLegacyPaginatorModule as MatPaginatorModule } from '@angular/material/legacy-paginator';
import { PocketFormsModule } from '@career/pocket-forms/pocket-forms.module';
import { MatLegacyFormFieldModule as MatFormFieldModule } from '@angular/material/legacy-form-field';
import { MatLegacyInputModule as MatInputModule } from '@angular/material/legacy-input';
import { MatLegacySliderModule as MatSliderModule } from '@angular/material/legacy-slider';
import { RouterModule } from '@angular/router';
import { MatLegacyButtonModule as MatButtonModule } from '@angular/material/legacy-button';
import { DefaultOpportunitiesComponent } from './components/opportunities/templates/default/default-opportunities.component';
import { SocialOpportunitiesComponent } from './components/opportunities/templates/social/social-opportunities.component';
import { MatIconModule } from '@angular/material/icon';
import { MatLegacyDialogModule } from '@angular/material/legacy-dialog';

@NgModule({
  declarations: [OpportunitiesComponent, DefaultOpportunitiesComponent, SocialOpportunitiesComponent],
  imports: [
    CommonModule,
    NgxsModule.forFeature([OpportunitiesState]),
    OpportunityListRoutingModule,
    PocketFormsModule,
    MatInputModule,
    MatAutocompleteModule,
    MatPaginatorModule,
    RouterModule,
    MatSliderModule,
    MatSelectModule,
    MatButtonModule,
    MatIconModule,
    MatFormFieldModule,
    FormsModule,
    PortalModule,
    ReactiveFormsModule,
    CoreModule,
    MatLegacyDialogModule
  ]
})
export class OpportunityListModule { }