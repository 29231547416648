import { EDialogType } from "@career/actions/models/action-dialog-types";
import { EActionType, IAction } from "@career/actions/models/action.model";

export namespace ActionsAction {
    
    export class Add {
        static readonly type = '[Actions] Add';
        constructor(public payload: IAction[]) {}
    }
    export class Load {
        static readonly type = '[Actions] Load';
        constructor(public type: EActionType|null = null) {}
    }

    export class Clear {
        static readonly type = '[Actions] Clear';
        constructor() {}
    }
    export class SetDialogOpened {
        static readonly type = '[Actions] Set Dialog Opened';
        constructor(public type: EDialogType) {}
    }
    export class SetDialogClosed {
        static readonly type = '[Actions] Set Dialog Closed';
        constructor() {}
    }
    export class SetComplete {
        static readonly type = '[Actions] Set Complete Actions';
        constructor(public payload: {complete: IAction[]}) {}
    }

    export class SetIncomplete {
        static readonly type = '[Actions] Set Incomplete Actions';
        constructor(public payload: {incomplete: IAction[]}) {}
    }
}