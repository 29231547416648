export namespace CoreAction {
  export class Bootstrap {
    static readonly type = "[Core] Bootstrapping Agency";
    constructor(
      public payload: { url?: string; isAdmin?: any; payload?: any }
    ) {}
  }

  export class AdminLogout {
    static readonly type = "[Core] Admin Logout";
    constructor() {}
  }

  export class SetAdminImages {
    static readonly type = "[Core] Update Images";
    constructor(public payload: { type: string; images: string[] }) {}
  }

  export class UpdateConfiguration {
    static readonly type = "[Core] Update Configuration";
    constructor(public config: any) {}
  }

  export class UpdateComponent {
    static readonly type = "[Core] Update component";
    constructor(public component: any) {}
  }

  export class SetRoutes {
    static readonly type = "[Core] Set routes";
    constructor(public routes: any) {}
  }

  export class DeleteComponent {
    static readonly type = "[Core] Delete component";
    constructor(public component: any) {}
  }
  export class SetSource {
    static readonly type = "[Core] Set Source";
    constructor(public payload: { source: string }) {}
  }

  export class ClearSource {
    static readonly type = "[Core] Clear Source";
    constructor() {}
  }

  export class SetAuditToken {
    static readonly type = "[Core] Set Audit Token";
    constructor() {}
  }

  export class ClearAuditToken {
    static readonly type = "[Core] Clear Audit Token";
    constructor() {}
  }
}
