import { Component, OnInit, Input } from '@angular/core';
import { Validators } from '@angular/forms';
import { faArrowDown } from '@fortawesome/free-solid-svg-icons';
import { FieldConfig } from '@career/pocket-forms/models/field';
import { LocaleService } from '@career/core/services/locale.service';

@Component({
  selector: 'app-default-opportunities',
  templateUrl: './default-opportunities.component.html',
  styleUrls: ['./default-opportunities.component.scss']
})
export class DefaultOpportunitiesComponent implements OnInit {

  @Input() scope;
  faArrowDown = faArrowDown;
  filtersConfig: FieldConfig[];
  resumeOrCvText = this.localeService.getResumeOrCVText();
  constructor(private localeService: LocaleService) { }

  ngOnInit() {
    
  }

}
