import { Injectable } from "@angular/core";
import { HttpClient, HttpResponse } from "@angular/common/http";
import { Store } from "@ngxs/store";
import { Observable, throwError, of, forkJoin, BehaviorSubject } from "rxjs";
import { CoreAction } from "../store/actions/core.actions";
import { CoreState } from "../store/states/core.state";
import { map, take, switchMap, catchError, tap } from "rxjs/operators";
import { environment } from "projects/pocket-career/src/environments/environment";
import { ThemingService } from "./theming.service";
import {
  PocketPortal,
  PocketPortalComponent,
} from "../models/pocket-portal.model";
import { TemplateConfiguration } from "../models/component.model";
import { FontService } from "./font.service";
import { DateAdapter } from "@angular/material/core";
import {
  TemplateResponse,
  ThemeAndTemplateResponse,
  ThemeResponse,
  UpdateConfigRes,
} from "@career/page-configuration/models/page-config.model";
import { UserState } from "@career/user/store/states/user.state";
import { Router } from "@angular/router";

@Injectable({
  providedIn: "root",
})
export class PortalService {
  private _isPreviewMode$: BehaviorSubject<boolean> =
    new BehaviorSubject<boolean>(false);
  private _routesUpdated$: BehaviorSubject<boolean> =
    new BehaviorSubject<boolean>(false);

  isAdmin = false;
  constructor(
    protected http: HttpClient,
    private dateAdapter: DateAdapter<any>,
    private fontService: FontService,
    private store: Store,
    private router: Router,
    private themingService: ThemingService
  ) {}

  isPreviewMode(): Observable<boolean> {
    return this._isPreviewMode$.asObservable();
  }

  togglePreviewMode(): void {
    this._isPreviewMode$.pipe(take(1)).subscribe((value) => {
      this._isPreviewMode$.next(!value);
    });
  }

  toggleRoutesUpdated(): void {
    this._routesUpdated$.next(true);
  }

  routesUpdated(): Observable<boolean> {
    return this._routesUpdated$.asObservable();
  }

  getPortalId(): string {
    return this.store.selectSnapshot(CoreState.getPortalId);
  }

  getPortalObj(): Observable<PocketPortal> {
    return this.store.select(CoreState.getPortalObj);
  }

  getPortalTemplate(): string {
    return this.store.selectSnapshot(CoreState.getPortalTemplate);
  }

  getPortalLocalization(): string {
    return this.store.selectSnapshot(CoreState.getPortalTemplate);
  }

  getPortalName(): string {
    return this.store.selectSnapshot(CoreState.getPortalName);
  }

  isPreferencesEnabled(): boolean {
    return this.store.selectSnapshot(CoreState.isPreferencesEnabled);
  }

  public getURLParam(): string {
    let url = window.location.hostname;
    return environment["testAccount"] || url.split(".")[0];
  }

  exitPreview(): void {
    this.store.dispatch(new CoreAction.Bootstrap(null)).subscribe((state) => {
      window.location.href = window.location.href.split("?")[0];
    });
  }

  initializeLocale(locale) {
    this.dateAdapter.setLocale(locale);
  }

  findPortalFromURL(): Observable<PocketPortal> {
    const isAdminRole = this.store.selectSnapshot(UserState.isAdminRole);
    return this.store.select(CoreState.getPortalObj).pipe(
      take(1),
      switchMap((cached) => {
        if (cached && !isAdminRole) {
          return this.getPortalObjByTimestamp(cached.lastUpdated, cached);
        } else if (isAdminRole) {
          return this.getAdminPreviewData(
            false,
            {}
          ) as Observable<PocketPortal>;
        }
        return this.getLatestPortalObject();
      })
    );
  }

  getLatestPortalObject(): Observable<PocketPortal> {
    const id = this.getURLParam();
    const url = `/api/agency/portal/${encodeURIComponent(id)}/components`;
    return this.http.get(url).pipe(
      tap((res) => {
        sessionStorage.setItem("core.portal", JSON.stringify(res));
        return res;
      }),
      switchMap(
        (data: any) =>
          this.initializeApp(data, false) as Observable<PocketPortal>
      )
    );
  }

  getPortalObjByTimestamp(timestamp: string, cached: any): Observable<any> {
    const id = this.getURLParam();
    let url = `/api/agency/portal/${encodeURIComponent(
      id
    )}/updated?timestamp=${timestamp}`;
    return this.http.get(url, { observe: "response" }).pipe(
      switchMap((data: HttpResponse<any>) => {
        if (data.status === 200) {
          return this.initializeApp(cached, false);
        }
        if (data.status === 205) return this.getLatestPortalObject();
      })
    );
  }

  getAdminPreviewData(isFromLoginPage: boolean, loginRes: any): any {
    const id = this.getURLParam();
    let url = `/api/agency/portal/${encodeURIComponent(
      id
    )}/components?preview=true`;
    return this.http
      .get(url)
      .pipe(
        switchMap((data: any) =>
          this.initializeApp(data, true, isFromLoginPage, loginRes)
        )
      );
  }

  initializeApp(
    data: any,
    isAdmin: boolean,
    isFromLoginPage?: boolean,
    loginRes?: any
  ): any {
    if (data) {
      return this.fontService.setFont(data.fontFamily).pipe(
        switchMap((_) => {
          return this.themingService.initialize(data.theme.colors);
        }),
        map((_) => {
          this.initializeLocale(data.localization);
          const bootstrapData = { isAdmin: isAdmin, payload: data };
          this.store.dispatch(new CoreAction.Bootstrap(bootstrapData));
          return isAdmin && isFromLoginPage ? loginRes : data;
        }),
        catchError((err: any) => {
          return throwError(err);
        })
      );
    } else {
      throw throwError(
        new Error("404 param route does not match agency route")
      );
    }
  }

  getPortalConfiguration(): Observable<TemplateConfiguration> {
    return this.getPortalObj().pipe(
      take(1),
      map((portalObj) => {
        return portalObj.configuration;
      })
    );
  }

  getComponentData(
    name,
    failSilently?: boolean
  ): Observable<PocketPortalComponent> {
    return this.getPortalObj().pipe(
      switchMap((data) => {
        if (!data.components[name]) {
          if (!failSilently) {
            this.router.navigate(["/404"]);
            return throwError(
              new Error(
                "Component does not exist. Please make sure it is added to your portal."
              )
            );
          }

          return of(null);
        }

        const obj = {
          component: data.components[name],
          configuration: data.configuration,
        };
        return of(obj);
      })
    );
  }

  getThemeAndTemplatesList(): Observable<ThemeAndTemplateResponse> {
    return forkJoin([this.getThemesList(), this.getTemplatesList()]).pipe(
      map((res) => {
        return {
          themes: res[0],
          templates: res[1],
        };
      })
    );
  }

  getTemplatesList(): Observable<TemplateResponse> {
    return this.http
      .get<any>(
        `/api/agency/portal_templates/search?language=en&localization=en-GB`
      )
      .pipe(
        catchError((_) => {
          return of({
            portaltemplates: [],
            total: 0,
          } as TemplateResponse);
        })
      );
  }

  getThemesList(): Observable<ThemeResponse> {
    return this.http.get<any>(`/api/agency/portal_themes/search`).pipe(
      catchError((_) => {
        return of({
          portalthemes: [],
          total: 0,
        } as ThemeResponse);
      })
    );
  }

  getCountryList(): Observable<any> {
    return this.http.get(`/api/portal/geo/countries?locale=${this.getPortalLocalization()}`).pipe(
      map((data: any[]) =>
        data
          .map((d) => {
            const key = Object.keys(d)[0];
            return { value: key, key: d[key] };
          })
          .sort((a, b) => a.key.localeCompare(b.key))
      )
    );
  }
 
  patchComponent(
    body: any,
    componentName: string
  ): Observable<PocketPortal["components"]> {
    const id = this.getURLParam();
    return this.http
      .patch<PocketPortal["components"]>(
        `/api/agency/portal/${id}/component?key=${componentName}`,
        body
      )
      .pipe(
        map((components) => {
          this.store.dispatch(
            new CoreAction.UpdateComponent({
              key: componentName,
              value: components[componentName],
            })
          );
          return components;
        })
      );
  }
  
  updateConfigurations(payload: any): Observable<UpdateConfigRes> {
    const id = this.getURLParam();
    return this.http
      .patch<UpdateConfigRes>(`/api/agency/portal/${id}/config`, payload)
      .pipe(
        map((config) => {
          this.store.dispatch(new CoreAction.UpdateConfiguration(config));
          return config;
        })
      );
  }


  publishPortal(): Observable<any> {
    const id = this.getURLParam();
    return this.http.get(`/api/agency/portal/${id}/publish`);
  }
}
