<ng-container *ngIf="!scope.config.disableJobSearchPage; else showNoAccessMsg">
  <ng-container *ngIf="!scope.forwardJobSearchPageExists ; else showForwardPageMsg">
    <div class="banner" *ngIf="scope.config.enableSuggestions && !scope.isAdmin">
      <ng-container *ngIf="!scope.hasSuggestions">
        <i class="icon-cloud banner__upload-cloud"></i>
        <a [routerLink]="['/suggestions']" class="banner__link"
          [innerText]="scope.getText | transform:'CLICK_TO_UPLOAD_YOUR_RESUME':{resumeOrCv: resumeOrCvText}"></a>
        <span class="banner__caption">{{scope.getText | transform:'LET_THE_RIGHT_JOBS_FIND_YOU'}}</span>
      </ng-container>
      <ng-container *ngIf="scope.hasSuggestions">
        <div class="banner__upload">
          <i class="icon-cloud banner__upload-cloud"></i>
          <a [routerLink]="['/suggestions']" class="banner__link"
            [innerText]="scope.getText | transform:'VIEW_MY_SUGGESTIONS'"></a>
        </div>
      </ng-container>
    </div>
    <div class="opportunities" disabledDiv>
      <div class="filters-container">
        <form class="filters" (submit)="scope.search()" [ngClass]="{'disabled': scope.loading}">
          <div class="filters__text-filters">
            <div class="connected-field">
              <div class="connected-field__wrapper">
                <div class="connected-field__field">
                  <mat-form-field appearance="none">
                    <label class="connected-field__label" [innerText]="scope.getText | transform:'FIND_JOBS'"></label>
                    <input matInput [placeholder]="scope.getText | transform:'FIND_JOBS_PLACEHOLDER'"
                      [(ngModel)]='scope.params.keywords' name="jobName">
                  </mat-form-field>
                </div>
                <div class="connected-field__field">
                  <mat-form-field appearance="none">
                    <label class="connected-field__label"
                      [innerText]="scope.getText | transform:'NEAR_LOCATION'"></label>
                    <input matInput [placeholder]="scope.getText | transform:'NEAR_LOCATION_PLACEHOLDER'"
                      [matAutocomplete]="auto" [formControl]="scope.params.location">
                  </mat-form-field>
                  <mat-autocomplete #auto="matAutocomplete" [displayWith]="scope.createLocationString">
                    <mat-option *ngFor="let location of scope.locations" [value]="location">
                      {{scope.createLocationString | transform:location}}
                    </mat-option>
                  </mat-autocomplete>
                </div>
              </div>
              <div class="connected-field__button-wrapper">
                <button class="connected-field__button" mat-button type="submit" mat-flat-button color="primary"
                  [innerText]="scope.getText | transform:'SEARCH'"></button>
              </div>
            </div>
          </div>
          <div class="filters__dropdowns">
            <div class="filters__dropdown-container">
              <div class="filters__dropdown-wrapper">
                <div class="filter__dropdown">
                  <label for="sortBy" class="form__label" [innerText]="scope.getText | transform:'SORT_BY'"></label>
                  <mat-form-field appearance="outline" class="filters__dropdown">
                    <mat-select (selectionChange)="scope.search()" [(ngModel)]="scope.params.sort" name="sortBy">
                      <mat-option value="lastUpdated:-1">{{scope.getText | transform:'LAST_UPDATED'}}</mat-option>
                      <mat-option value="createdDate:-1">{{scope.getText | transform:'CREATED_DATE'}}</mat-option>
                      <mat-option value="name">{{scope.getText | transform:'NAME'}}</mat-option>
                      <mat-option value="compensation">{{scope.getText | transform:'COMPENSATION'}}</mat-option>
                    </mat-select>
                  </mat-form-field>
                </div>
              </div>
              <div class="filters__dropdown-wrapper">
                <div class="filter__dropdown">
                  <label class="form__label" for="careerLevel"
                    [innerText]="scope.getText | transform:'CAREER_LEVEL'"></label>
                  <mat-form-field appearance="outline" class="filters__dropdown">
                    <mat-select (selectionChange)="scope.search()" [(ngModel)]="scope.params.careerLevel"
                      name="careerLevel">
                      <mat-option value="">{{scope.getText | transform:'ANY'}}</mat-option>
                      <mat-option value="STUDENT">{{scope.getText | transform:'STUDENT'}}</mat-option>
                      <mat-option value="ENTRYLEVEL">{{scope.getText | transform:'ENTRY_LEVEL'}}</mat-option>
                      <mat-option value="MIDCAREER">{{scope.getText | transform:'MID_CAREER'}}</mat-option>
                      <mat-option value="EXPERIENCED">{{scope.getText | transform:'EXPERIENCED_NON_MANAGER'}}</mat-option>
                      <mat-option value="MANAGER">{{scope.getText | transform:'MANAGER'}}</mat-option>
                      <mat-option value="EXECUTIVE">{{scope.getText | transform:'EXECUTIVE'}}</mat-option>
                      <mat-option value="SENIOREXECUTIVE">{{scope.getText | transform:'SENIOR_EXECUTIVE'}}</mat-option>
                    </mat-select>
                  </mat-form-field>
                </div>
              </div>
              <div class="filters__dropdown-wrapper">
                <div class="filter__dropdown">
                  <label class="form__label" for="compensation"
                    [innerText]="scope.getText | transform:'SALARY'"></label>
                  <mat-form-field appearance="outline" class="filters__dropdown">
                    <mat-select (selectionChange)="scope.search()" [(ngModel)]="scope.params.compensation"
                      name="compensation">
                      <mat-option value="">{{scope.getText | transform:'ANY'}}</mat-option>
                      <mat-option value="20000">20,000+</mat-option>
                      <mat-option value="40000">40,000+</mat-option>
                      <mat-option value="60000">60,000+</mat-option>
                      <mat-option value="80000">80,000+</mat-option>
                      <mat-option value="100000">100,000+</mat-option>
                      <mat-option value="120000">120,000+</mat-option>
                      <mat-option value="140000">140,000+</mat-option>
                      <mat-option value="160000">160,000+</mat-option>
                      <mat-option value="180000">180,000+</mat-option>
                      <mat-option value="200000">200,000+</mat-option>
                    </mat-select>
                  </mat-form-field>
                </div>
              </div>
            </div>
          </div>
        </form>
      </div>
      <div class="job-opp-list-container">
        <div class="job-opp-list">
          <div *ngIf="scope.opps" class="results-title">{{scope.getText |
            transform:'PAGE_OF_JOBS':{page:scope.pagination.page+1,total:scope.pagination.total} }}</div>
          <app-data-card [isAdmin]="scope.isAdmin" *ngFor="let opp of scope.opps" [opp]="opp"
            [match]="scope.suggestions[opp._id]"></app-data-card>
          <div class="none-found" *ngIf="scope.noneFound">
            <div class="none-found__text">
              <div class="none-found__title">
                <i class="icon-search none-found__icon"></i>
                {{scope.getText | transform:'NO_RESULTS_FOUND'}}
              </div>
            </div>
          </div>
          <mat-paginator class="paginator" [length]="scope.pagination.total" [pageIndex]="scope.pagination.page"
            [hidePageSize]="true" [pageSize]="10" (page)="scope.changePage($event)">
          </mat-paginator>
        </div>
      </div>

    </div>
  </ng-container>
</ng-container>

<ng-template #showForwardPageMsg>
  <div class="forward-page-msg">
    <mat-icon>settings</mat-icon>
    <h1>
      {{scope.translations.FORWARD_PAGE_MSG}} : <a [href]="scope.config.forwardJobSearchPage"
        target="_blank">{{scope.config.forwardJobSearchPage}}</a>
    </h1>
  </div>
</ng-template>
<ng-template #showNoAccessMsg>
  <div class="forward-page-msg">
    <mat-icon>settings</mat-icon>
    <h1>
      {{scope.translations.NO_ACCESS_JOB_SEARCH_PAGE}}
    </h1>
  </div>
</ng-template>