import { Component, OnDestroy, OnInit } from '@angular/core';
import { ActivatedRoute, Router } from '@angular/router';
import { coreComponentDef } from '@career/core/component-definition';
import { TemplateComponent, TemplateConfiguration } from '@career/core/models/component.model';
import { PortalService } from '@career/core/services/portal.service';
import { UserAuthService } from '@career/core/services/user-auth.service';
import { UtilsService } from '@career/core/services/utils.service';
import { Subscription } from 'rxjs';
import { EUnsubscribeTextMap } from './constants/text-map';

@Component({
  selector: 'app-unsubscribe',
  template: `
  <app-base-template [component]="component" [componentDef]="componentDef" [scope]="this" *ngIf="component"></app-base-template>
  `,
  styleUrls: ['./unsubscribe.component.scss']
})
export class UnsubscribeComponent implements OnInit, OnDestroy {

token: string = this.route.snapshot.paramMap.get('token');
getText: Function;
unsubscribeOptions;
chosenOption: string;
whenToContact: string = "3";
error: boolean = false;
submitted: boolean = false;
loading: boolean = false;
unsubscribeText: string;
component: TemplateComponent;
config: TemplateConfiguration;
componentDef = coreComponentDef['unsubscribe'];

portalSubscription: Subscription;

  constructor(private route: ActivatedRoute, private router: Router, private userService: UserAuthService, private utils: UtilsService, private portalService: PortalService) { }

  ngOnInit() {
    this.portalSubscription = this.portalService.getComponentData("unsubscribe").subscribe((data) => {
      this.component = data.component;
      this.config = data.configuration;
      this.getText = this.utils.getText(this.component);

      if(this.token) { 
        this.unsubscribeText = this.getText(EUnsubscribeTextMap.UNSUBSCRIBE);
      } else {
        this.unsubscribeText = this.getText(EUnsubscribeTextMap.DEACTIVATE_ACCOUNT);
      }
      if(!this.token && !this.userService.getToken()) {
        this.router.navigate(['/login', {forwardUrl: '/unsubscribe'}]);
        this.utils.openSnackbar(this.getText(EUnsubscribeTextMap.LOGIN_TO_DEACTIVATE_ACCOUNT), true);
        return;
      }
  
      this.unsubscribeOptions = [
        {key: "other", text: this.getText(EUnsubscribeTextMap.NOT_INTERESTED)},
        {key: "notlooking", text: this.getText(EUnsubscribeTextMap.NOT_LOOKING)},
        {key: "notrelevant", text: this.getText(EUnsubscribeTextMap.NOT_RELEVANT)},
        {key: "annoying", text: this.getText(EUnsubscribeTextMap.EMAILS_WERE_ANNOYING)}
      ];
    });
  }

  showMsg() {
    this.loading = false;
    this.submitted = true;
  }

  unsubscribeErr() {
    this.error = true;
    this.showMsg();
  }

  unsubscribe() {
    if(this.loading) return;
    this.loading = true;
    let duration = null, reason = null;
    //unsubscribe or throw error to contact support for manual unsubscription
    if(this.chosenOption && this.chosenOption === 'notlooking' && this.whenToContact !== 'never') {
      duration = this.whenToContact;
    }
    if(this.chosenOption) {
      reason = this.unsubscribeOptions.find(x => x.key === this.chosenOption).text || null;
    }

    
    this.userService.unsubscribe(this.token || null, reason, duration).subscribe(data => {
      //success
      this.showMsg();
    },
    (err) => {
      this.unsubscribeErr();
    })
  }

  ngOnDestroy() {
    this.portalSubscription.unsubscribe();
  }

}
