import {
  ChangeDetectionStrategy,
  Component,
  OnDestroy,
  OnInit,
  ApplicationRef,
} from "@angular/core";
import { ActivatedRoute } from "@angular/router";
import { Observable, Subscription, BehaviorSubject } from "rxjs";
import { userComponentDef } from "../../component-definition";
import { UtilsService } from "@career/core/services/utils.service";
import { PortalService } from "@career/core/services/portal.service";
import {
  TemplateComponent,
  TemplateConfiguration,
} from "@career/core/models/component.model";
import { Title } from "@angular/platform-browser";
import { MatLegacyDialog as MatDialog } from "@angular/material/legacy-dialog";
import { PageConfigTypes } from "@career/page-configuration/models/page-config.model";
import { Translations } from "@career/page-configuration/configs/page-config.config";
import { UserService } from "@career/core/services/user.service";
import { ConfigurableComponent } from "@career/page-configuration/Shared/components/configurable.component";
import { LayerService } from "kleos-ui";
@Component({
  selector: "app-dashboard",
  template: `
    <app-base-template
      [component]="component"
      [componentDef]="componentDef"
      [scope]="this"
      *ngIf="component"
    ></app-base-template>
  `,
  changeDetection: ChangeDetectionStrategy.OnPush,
})
export class DashboardComponent
  extends ConfigurableComponent
  implements OnInit, OnDestroy
{
  subscriptions: Subscription[] = [];
  config: TemplateConfiguration;
  component: TemplateComponent;
  getText: Function;
  componentDef = userComponentDef["dashboard"];
  portalServiceSubscription: Subscription;
  suggestedJobsOpen: boolean = false;
  translations = Translations;

  constructor(
    private titleService: Title,
    private portalService: PortalService,
    layerService: LayerService,
    private route: ActivatedRoute,
    private utils: UtilsService
  ) {
    super(layerService);
  }

  ngOnInit() {
    this.portalServiceSubscription = this.portalService
      .getComponentData("dashboard")
      .subscribe((data) => {
        this.config = data.configuration;
        this.component = data.component;
        this.getText = this.utils.getText(this.component);
        this.titleService.setTitle(
          `${this.getText("DASHBOARD")} | ${this.portalService.getPortalName()}`
        );
        if (this.route.snapshot.queryParams.suggestions) {
          this.suggestedJobsOpen = true;
        }
      });
  }
  getConfigurationType(): keyof typeof PageConfigTypes {
    return PageConfigTypes.DASHBOARD;
  }

  getTemplateConfiguration(): TemplateConfiguration {
    return { ...this.config };
  }

  ngOnDestroy() {
    this.portalServiceSubscription.unsubscribe();
  }
}
