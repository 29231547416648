import { Injectable } from "@angular/core";
import {
  HttpClient,
  HttpErrorResponse,
  HttpHeaders,
} from "@angular/common/http";
import { Store } from "@ngxs/store";
import { Observable, throwError, of } from "rxjs";
import { map, catchError, concatMap, switchMap } from "rxjs/operators";
import { AuthAction } from "../../authentication/store/actions/auth.actions";
import { CoreAction } from "@career/core/store/actions/core.actions";
import { UserAction } from "@career/user/store/actions/user.actions";
import { PortalService } from "./portal.service";
import {
  LoginRequest,
  LoginResponse,
} from "@career/authentication/models/auth.model";

@Injectable({
  providedIn: "root",
})
export class AuthenticationService {
  constructor(
    private http: HttpClient,
    private portalService: PortalService,
    private store: Store
  ) {}

  activate(token: string): Observable<any> {
    return this.http.get(
      `/api/portal/${this.portalService.getPortalId()}/activate?key=${token}`,
      {
        responseType: "text",
      }
    );
  }

  forgotPassword(email: string): Observable<any> {
    return this.http.post(
      "/api/account/resetPassword/init?email=" +
        encodeURIComponent(email) +
        "&portal=" +
        this.portalService.getPortalId(),
      {}
    );
  }

  resetPassword(token: string, password: string): Observable<any> {
    return this.http.post(
      `/api/account/resetPassword/finish?portal=${this.portalService.getPortalId()}`,
      { resetToken: token, password: password }
    );
  }

  login(userObj: LoginRequest, isAdmin = false): Observable<LoginResponse> {
    const data = {
      username: userObj.email,
      password: userObj.password,
    };
    if (userObj.mfacode) {
      data["code"] = userObj.mfacode;
      data["deviceType"] = this.getDeviceType(window.navigator.userAgent);
    }

    const body = [];
    for (const x in data) {
      body.push(x + "=" + encodeURIComponent(data[x]));
    }
    const transformed = body.join("&");
    const headers = new HttpHeaders({
      ["Accept"]: "application/json",
      ["Content-Type"]: "application/x-www-form-urlencoded",
    });

    let loginUrl = `/api/users/authenticate?portal=${this.portalService.getPortalId()}`;
    return this.http
      .post(loginUrl, transformed, {
        observe: "response",
        headers: headers,
      })
      .pipe(
        map((data: any) => {
          if (data.headers) {
            let redirect = data.headers.get("X-RecruiterRedirect");
            if (redirect && redirect.length > 0) {
              window.location.href = redirect + "/#!/signin?fromPortal=true";
              throw new Error("Logged in with recruiter account");
            }
          }
          return data;
        }),
        switchMap((loginResponse) => {
          let enforceMFA = false;
          if (isAdmin) {
            const agency =
              loginResponse.data &&
              (loginResponse.data.authorization || {})?.agency;
            enforceMFA =
              agency && agency.configuration && agency.configuration.enforceMFA;
          }
          return of({
            enforceMFA: enforceMFA,
            loginRes: loginResponse,
          });
        }),
        map((loginRes) => {
          const result = loginRes["loginRes"];
          this.store.dispatch([
            new AuthAction.Login({
              token: result["headers"].get("x-session-id"),
            }),
            new UserAction.Setup(result["body"]),
            new CoreAction.ClearAuditToken(),
          ]);
          return result;
        }),
        concatMap((data) => this.updateAdminData(data, isAdmin))
      );
  }

  getDeviceType(userAgent: string): any {
    if (this.isMobilePlatform(userAgent)) {
      return "mobile";
    }
    return "desktop";
  }

  isMobilePlatform(useragent: string) {
    return (
      useragent.match(/Android/i) ||
      useragent.match(/BlackBerry/i) ||
      useragent.match(/iPhone|iPad|iPod/i) ||
      useragent.match(/Opera Mini/i) ||
      useragent.match(/IEMobile/i) ||
      useragent.match(/WPDesktop/i)
    );
  }

  updateAdminData(loginRes: any, isAdmin: boolean): any {
    return isAdmin
      ? this.portalService.getAdminPreviewData(true, loginRes)
      : of(loginRes);
  }

  getAgency(id): Observable<any> {
    return this.http.get(`/api/agency/brand/${id}`).pipe(
      map((data: any) => {
        // save the data in store.
        this.store.dispatch(new CoreAction.Bootstrap(data)).subscribe(
          () => {
            return data;
          },
          (err) => {
            // something went wrong (doesn't exist)
          }
        );
      }),
      catchError((err: any) => {
        return throwError(err);
      })
    );
  }
}
