import { Injectable } from '@angular/core';
import { Store } from '@ngxs/store';
import { CoreState } from '../store/states/core.state';

@Injectable({
  providedIn: 'root'
})
export class LocaleService {

  constructor(private store: Store) { }
  getLocale(): string {
    return this.store.selectSnapshot(CoreState.getLocale);
  }

  getHourNotation(): 12|24 {
    return new Intl.DateTimeFormat(this.getLocale(), {hour: 'numeric'}).resolvedOptions()['hourCycle'] === 'h12'?12:24;
  }

  getResumeOrCVText(lowercase=false) {
  if( this.isUSLocale() ) {
      return lowercase?'resume':'Resume';
    }
    return lowercase?'cv':'CV';
  }

  matchesLocale(...locales) {
    return locales.some(s => s === this.getLocale());
  }

  isUSLocale() {
    return this.getLocale() === 'en-US';
  }

  getLocalDateStr(date=null, locale=null, dateStyle?, timeStyle?): string {
    return new Intl.DateTimeFormat(locale || this.getLocale(), {dateStyle: dateStyle, timeStyle: timeStyle} as any).format(date || new Date());
  }

  parseTime(time, format, step) {
    let hour, minute, stepMinute,
      defaultFormat = 'g:ia',
      pm = time.match(/p/i) !== null,
      num = time.replace(/[^0-9]/g, '');
    
    // Parse for hour and minute
    switch(num.length) {
      case 4:
        hour = parseInt(num[0] + num[1], 10);
        minute = parseInt(num[2] + num[3], 10);
        break;
      case 3:
        hour = parseInt(num[0], 10);
        minute = parseInt(num[1] + num[2], 10);
        break;
      case 2:
      case 1:
        hour = parseInt(num[0] + (num[1] || ''), 10);
        minute = 0;
        break;
      default:
        return '';
    }
    
    // Make sure hour is in 24 hour format
    if( pm === true && hour > 0 && hour < 12 ) hour += 12;
    
    // Force pm for hours between 13:00 and 23:00
    if( hour >= 13 && hour <= 23 ) pm = true;
    
    // Handle step
    if( step ) {
      // Step to the nearest hour requires 60, not 0
      if( step === 0 ) step = 60;
      // Round to nearest step
      stepMinute = (Math.round(minute / step) * step) % 60;
      // Do we need to round the hour up?
      if( stepMinute === 0 && minute >= 30 ) {
        hour++;
        // Do we need to switch am/pm?
        if( hour === 12 || hour === 24 ) pm = !pm;
      }
      minute = stepMinute;
    }
    
    // Keep within range
    if( hour <= 0 || hour >= 24 ) hour = 0;
    if( minute < 0 || minute > 59 ) minute = 0;
  
    // Format output
    return (format || defaultFormat)
      // 12 hour without leading 0
          .replace(/g/g, hour === 0 ? '12' : 'g')
      .replace(/g/g, hour > 12 ? hour - 12 : hour)
      // 24 hour without leading 0
      .replace(/G/g, hour)
      // 12 hour with leading 0
      .replace(/h/g, hour.toString().length > 1 ? (hour > 12 ? hour - 12 : hour) : '0' + (hour > 12 ? hour - 12 : hour))
      // 24 hour with leading 0
      .replace(/H/g, hour.toString().length > 1 ? hour : '0' + hour)
      // minutes with leading zero
      .replace(/i/g, minute.toString().length > 1 ? minute : '0' + minute)
      // simulate seconds
      .replace(/s/g, '00')
      // lowercase am/pm
      .replace(/a/g, pm ? 'pm' : 'am')
      // lowercase am/pm
      .replace(/A/g, pm ? 'PM' : 'AM');
    
  }
}
