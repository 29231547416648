import { Component, Input, OnInit } from '@angular/core';
import { faTimesCircle } from '@fortawesome/free-solid-svg-icons';

@Component({
  selector: 'app-default-manage-files',
  templateUrl: './default-manage-files.component.html',
  styleUrls: ['./default-manage-files.component.scss']
})
export class DefaultManageFilesComponent implements OnInit {

  @Input() scope;
  faTimes = faTimesCircle;
  constructor() { }

  ngOnInit(): void {
  }

}
