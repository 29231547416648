import { Directive, ElementRef, OnInit, Renderer2 } from "@angular/core";
import { UserService } from "../services/user.service";

@Directive({
  selector: "[disabledDiv]",
})
export class DisabledDivDirective implements OnInit {
  constructor(
    private renderer: Renderer2,
    private userSvc: UserService,
    private hostElem: ElementRef
  ) {}

  ngOnInit(): void {
    this.userSvc.isAdmin().subscribe((isAdmin) => {
      if (this.hostElem.nativeElement.classList.contains("disabled_div")) {
        this.hostElem.nativeElement.classList.remove("disabled_div");
      }
      if (isAdmin) {
        this.renderer.addClass(this.hostElem.nativeElement, "disabled_div");
      }
    });
  }
}
