import { Injectable } from "@angular/core";
import { CanActivate, Router } from "@angular/router";
import { TemplateConfiguration } from "@career/core/models/component.model";
import { PortalService } from "@career/core/services/portal.service";
import { UserService } from "@career/core/services/user.service";
import { Observable, of } from "rxjs";
import { map, switchMap } from "rxjs/operators";

export class ConfigGuard {
    static forConfigs(configNames: string[], forwardPage: string, reverseCondition?: boolean) {
  
      @Injectable({
        providedIn: 'root'
      })
      class ConfigCheck implements CanActivate {
        constructor(private userSvc: UserService, private router: Router, private portalService: PortalService) { }
          canActivate(): Observable<boolean> {
            return this.userSvc.isAdmin().pipe(
                switchMap(isAdmin => {
                    if( !isAdmin ) {
                        return this.portalService.getPortalConfiguration();
                    }
                    return of(false);
                }),
                map((config: TemplateConfiguration) => {
                    return !config || configNames.every(name => reverseCondition?!Boolean(config[name]):Boolean(config[name]));
                }),
                map(result => {
                  if( result ) {
                    return true;
                  }
                  this.router.navigate([forwardPage]);
                  return false;
                })
            )
          }
        }
        return ConfigCheck;
      }
  
  }