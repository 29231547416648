import { Opportunity } from 'projects/pocket-career/src/app/opportunity-detail/models/opportunity.model';

export namespace OpportunitiesAction {
    export class List {
        static readonly type = '[Opportunities] Get List';
        constructor(public payload: Array<Opportunity>) {}
    }

    export class Filters {
        static readonly type = '[Opportunities] Filters';
        constructor(public payload: any) {};
    }
}