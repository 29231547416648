<div class="action-required-warning" *ngIf="scope">
    <ng-container *ngIf="(scope.filterOpp | transform:(scope.incompleteActions | async):scope) as incompleteActions">
        <button class="button" mat-stroked-button color="warn" (click)="scope.openDialog()" *ngIf="incompleteActions && incompleteActions.length > 0;else completeActions">
            <ng-container *ngIf="incompleteActions.length < 2">
                {{scope.getText | transform:'ACTION_REQUIRED_MESSAGE'}}
                <i class="icon-link action__icon"></i>
            </ng-container>
            <ng-container *ngIf="incompleteActions.length > 1">
                {{scope.getText | transform:'MULTIPLE_ACTIONS_REQUIRED_MESSAGE':{numberOfActions: incompleteActions.length} }}
                <i class="icon-link action__icon"></i>
            </ng-container>
        </button>    
    </ng-container>
</div>

<ng-template #completeActions>
    <ng-container *ngIf="(scope.filterOpp | transform:(scope.completeActions | async):scope) as completeActions">
        <button class="button" mat-stroked-button (click)="scope.openDialog()" *ngIf="completeActions && completeActions.length > 0">
            {{scope.getText | transform:'NO_ACTIONS_REQUIRED_MESSAGE':{numberOfActions: completeActions.length} }}
        </button>
    </ng-container>
</ng-template>