import { Component, OnDestroy, OnInit } from '@angular/core';
import { Title } from '@angular/platform-browser';
import { Router } from '@angular/router';
import { AuthState } from '@career/authentication/store/states/auth.state';
import { VALID_RESUME_UPLOAD_TYPES } from '@career/core/constants/file.constant';
import { TemplateComponent, TemplateConfiguration } from '@career/core/models/component.model';
import { ErrorSummary } from '@career/core/models/error.model';
import { Match } from '@career/core/models/match.model';
import { CandidateService } from '@career/core/services/candidate.service';
import { MatchService } from '@career/core/services/match.service';
import { PortalService } from '@career/core/services/portal.service';
import { UserAuthService } from '@career/core/services/user-auth.service';
import { UtilsService } from '@career/core/services/utils.service';
import { suggestionsComponentDef } from '@career/suggestions/component-definition';
import { UserAction } from '@career/user/store/actions/user.actions';
import { UserState } from '@career/user/store/states/user.state';
import { Store } from '@ngxs/store';
import { Observable, of, Subscription } from 'rxjs';
import { catchError, map, switchMap } from 'rxjs/operators';

@Component({
  selector: 'app-suggestions',
  template: `
  <app-base-template [component]="component" [componentDef]="componentDef" [scope]="this" *ngIf="component"></app-base-template>
  `
})
export class SuggestionsComponent implements OnInit, OnDestroy {

  portalServiceSubscription: Subscription;
  component: TemplateComponent;
  config: TemplateConfiguration;
  componentDef = suggestionsComponentDef['suggestions'];
  getText: Function;
  status: "PENDING"|"UPLOADING"|"COMPLETED" = "PENDING";
  uploadStatus: string;
  error: ErrorSummary;
  suggestions: Match[];
  constructor(private titleService: Title, private router: Router, private userAuthService: UserAuthService, private matchService: MatchService, private store: Store, private candidateService: CandidateService,
    private utils: UtilsService, private portalService: PortalService) { }

  createMatches(matches: Match[]) {
    return matches && matches.map(m => this.matchService.convertToMatch(m, {'TOP_MATCH': this.getText('TOP_MATCH')}, this.config));
  }

  onUpload(event) {
    this.error = null;
    let file;
    if(event.target) {
      file = event.target.files[0] || null;
    } else {
      file = event[0];
    }
    if( VALID_RESUME_UPLOAD_TYPES.includes(file.type) ) {
      this.status = "UPLOADING";
      this.uploadStatus = this.getText('UPLOADING_FILE', {name: file.name});
      this.candidateService.uploadResume(file)
      .pipe(
        switchMap(data => {
          this.uploadStatus = this.getText('GENERATING_SUGGESTIONS');
          //WE_FOUND_NO_MATCHING_RESULTS
          //WE_FOUND_ONE_RESULT
          //WE_FOUND_MULTIPLE_RESULTS
          //EXPLORE_JOBS
          //SEE_MY_RESULTS
          return this.candidateService.getSuggested(data._id);
        }),
        catchError((err) => {
          this.status = "PENDING";
          this.error = new ErrorSummary(this.getText("UNKNOWN_ISSUE"), `Error Code: ${err.status || 'Unknown'}`);
          return of(null);
        })
      ).subscribe(data => {
        this.suggestions = this.createMatches(data && data.matches);
        this.status = "COMPLETED";
      });
    } else {
      //wrong file type
      this.error = new ErrorSummary(this.getText("INVALID_FILE_TYPE"));
    }
  }

  restart() {
    this.store.dispatch(new UserAction.ClearSuggested());
    this.status = "PENDING";
  }

  setDefaultSuggestions() {
    this.store.selectOnce(UserState.suggested).pipe(
      map(data => {
        return this.createMatches(data);
      })
    ).subscribe(suggestions => {
      this.suggestions = suggestions;
      if( this.suggestions != null ) {
        this.status = "COMPLETED";
      }
    })
  }

  ngOnInit(): void {
    this.portalServiceSubscription = this.portalService.getComponentData('suggestions').subscribe((data) => {
      if( this.store.selectSnapshot(AuthState.token) ) {
        this.router.navigate(['/dashboard'], {queryParams: {suggestions: true}});
        return;
      }

      if( !(data.configuration || {}).enableSuggestions || this.userAuthService.getToken() ) {
        this.router.navigate(['/404']);
      }
      this.component = data.component;
      this.config = data.configuration;
      this.getText = this.utils.getText(this.component);
      this.titleService.setTitle(`${this.getText('SUGGESTIONS')} | ${this.portalService.getPortalName()}`);
      this.setDefaultSuggestions();
    });
  }

  ngOnDestroy(): void {
      this.portalServiceSubscription.unsubscribe();
  }

}
