import { Injectable } from "@angular/core";
import { HttpClient } from "@angular/common/http";
import { Observable } from "rxjs";
import {
  IQuestionnaire,
  QuestionnaireSection,
} from "@career/questionnaires/models/question";
import { map } from "rxjs/operators";
import { PortalService } from "./portal.service";
import { Router } from "@angular/router";
import { LINKS } from "../constants/links.constant";
import { CandidateQuestionnaireDTO } from "@career/questionnaires/models/candidate-questionnaire-dto.model";

@Injectable({
  providedIn: "root",
})
export class QuestionnaireService {
  constructor(
    private http: HttpClient,
    private portalService: PortalService,
    private router: Router
  ) {}

  getQuestionnaire(id): Observable<any> {
    return this.http
      .get(
        `/api/portal/${this.portalService.getPortalId()}/questionnaire/${id}`
      )
      .pipe(
        map((data: { sections: QuestionnaireSection[] }) => {
          if (data && data.sections) {
            return data;
          }
          throw new Error("Invalid questionnaire returned");
        })
      );
  }

  findResponse(candidateId: string, questionnaireId: string): Observable<any> {
    return this.http.get(
      `/api/user_questionnaire/${candidateId}?questionnaire=${questionnaireId}`
    );
  }

  findUnfilledQuestionnaires(
    candidateId: string,
    oppId: string
  ): Observable<IQuestionnaire[]> {
    return this.http.get(
      `/api/user_questionnaire/${candidateId}/incomplete?opportunity=${oppId}&sources=INTERNAL,SOVA_SJT,SOVA_VIDEO`
    ) as Observable<IQuestionnaire[]>;
  }

  findFilledQuestionnaires(
    candidateId: string,
    oppId: string
  ): Observable<IQuestionnaire[]> {
    return this.http.get(
      `/api/user_questionnaire/${candidateId}/filled?opportunity=${oppId}`
    ) as Observable<IQuestionnaire[]>;
  }

  completeUserQuestionnaire(
    candidateId: string,
    responseId: string,
    obj: CandidateQuestionnaireDTO,
    oppId?: string
  ) {
    let url = `/api/user_questionnaire/${candidateId}/complete?response=${responseId}`;
    if (oppId) {
      url += "&opportunity=" + oppId;
    }

    return this.http.post(url, obj);
  }

  initUserQuestionnaire(
    candidateId: string,
    questionnaireId: string,
    oppId: string
  ): Observable<object> {
    return this.http.get(
      `/api/user_questionnaire/${candidateId}/init?questionnaire=${questionnaireId}&opportunity=${oppId}`
    );
  }

  saveUserProgress(
    candidateId: string,
    responseId: string,
    obj: CandidateQuestionnaireDTO
  ): Observable<any> {
    return this.http.post(
      `/api/user_questionnaire/${candidateId}/saveProgress?response=${responseId}`,
      obj
    );
  }

  navigateToQuestionnaire(questionnaireId, jobId) {
    let route = LINKS.QUESTIONNAIRE_URL + "/" + questionnaireId;
    if (jobId) {
      route += "/" + jobId;
    }
    return this.router.navigate([route]);
  }

  getAdminQuestionnairesList(): Observable<IQuestionnaire[]> {
    const id = this.portalService.getURLParam();
    return this.http.get<IQuestionnaire[]>(
      `/api/agency/portal/${id}/questionnaires`
    );
  }

}
