import { State, Action, StateContext, Selector } from '@ngxs/store';
import { UserStateModel } from '../models/user-state.model';
import { UserAction } from '../actions/user.actions';
import { Injectable } from '@angular/core';

@State<UserStateModel>({
    name: 'user',
    defaults: {
        user: null,
        suggested: JSON.parse(sessionStorage.getItem('suggested')) || null
    }
  })
  @Injectable()
  export class UserState {

    @Selector()
    static user(state: UserStateModel) {
        return state.user;
    }

    @Selector()
    static suggested(state: UserStateModel) {
        return state.suggested || null;
    }

    @Selector()
    static isAdminRole(state: UserStateModel) {
        return (
            state?.user?.login?.roles.includes("admin") ||
            state?.user?.login?.roles.includes("agencyAdmin")
          );
    }

    @Selector()
    static applied(state: UserStateModel) {
        return state.applied;
    }

    @Action(UserAction.Setup)
    setup(ctx: StateContext<UserStateModel>, { payload }: UserAction.Setup) {
        ctx.setState({
            ...ctx.getState(), 
            user: payload
        });

        ctx.dispatch(new UserAction.Changed());
    }

    @Action(UserAction.Update)
    update(ctx: StateContext<UserStateModel>, { payload }: UserAction.Update) {
        ctx.setState({
            ...ctx.getState(), 
            user: payload.user
        });
        ctx.dispatch(new UserAction.Changed(payload.context));
    }

    @Action(UserAction.Remove)
    remove(ctx: StateContext<UserStateModel>) {
        ctx.setState({
            ...ctx.getState(), 
            user: null
        });
        ctx.dispatch(new UserAction.Changed());
    }

    @Action(UserAction.GetApplied)
    getApplied(ctx: StateContext<UserStateModel>, { payload }: UserAction.GetApplied) {
        ctx.setState({
            ...ctx.getState(),
            applied: payload
        });
    }

    @Action(UserAction.ClearApplied)
    clearApplied(ctx: StateContext<UserStateModel>) {
        ctx.patchState({applied: null});
    }

    @Action(UserAction.ClearSuggested)
    clearSuggested(ctx: StateContext<UserStateModel>) {
        sessionStorage.removeItem('suggested');
        ctx.patchState({suggested: null});
    }

    @Action(UserAction.GetSuggested)
    getSuggested(ctx: StateContext<UserStateModel>, { payload }: UserAction.GetSuggested) {
        sessionStorage.setItem('suggested', JSON.stringify(payload));
        ctx.setState({
            ...ctx.getState(),
            suggested: payload
        });
    }
}
