<div class="wrapper">
    <h1 class="title">{{ configMessages.headers[configType] }}</h1>
    <app-error-summary [error]="error" *ngIf="error"></app-error-summary>

    <div mat-dialog-content class="config-editor-container">
        <ng-container *ngTemplateOutlet="questionsContainer"></ng-container>
    </div>
    <ng-template #questionsContainer>
        <ng-container *ngFor="let question of questionsList">
            <div class="question-container">
                <div class="slider-container" *ngIf="question.type === 'slider'">
                    <span class="question"> {{question.question }}</span>
                    <mat-slide-toggle [(ngModel)]="sliderToggleValues[[question.key]]">
                    </mat-slide-toggle>
                </div>
                <div class="input-container" *ngIf="question.type === 'input'">
                    <span class="question"> {{question.question }}</span>
                    <mat-form-field appearance="outline" class="form__field question_config_input">
                        <input matInput [(ngModel)]="sliderToggleValues[[question.key]]">
                    </mat-form-field>
                </div>
                <div class="select-container" *ngIf="question.type === 'select'">
                    <span class="question"> {{question.question }}</span>
                    <mat-form-field appearance="outline" class="form__field question_config_input">
                        <mat-select [(ngModel)]="sliderToggleValues[[question.key]]">
                            <mat-option value="">Select an option</mat-option>
                            <mat-option *ngFor="let questionnaire of adminQuestionnaireList" [value]="questionnaire.id">
                                {{questionnaire.name}}
                            </mat-option>
                        </mat-select>
                    </mat-form-field>
                </div>
            </div>
        </ng-container>
    </ng-template>
    <button mat-flat-button color="primary" (click)="onSave()">Save</button>
</div>