import { ChangeDetectorRef, Component, Input, OnDestroy, OnInit } from '@angular/core';
import { MatLegacyDialog as MatDialog } from '@angular/material/legacy-dialog';
import { ConsentService } from '@career/core/services/consent.service';
import { TemplateComponent, TemplateConfiguration } from '@career/core/models/component.model';
import { ConfirmationDialog } from '@career/core/models/confirmation-dialog';
import { ErrorSummary } from '@career/core/models/error.model';
import { CandidateService } from '@career/core/services/candidate.service';
import { PortalService } from '@career/core/services/portal.service';
import { UtilsService } from '@career/core/services/utils.service';
import { FieldConfig } from '@career/pocket-forms/models/field';
import { userComponentDef } from '@career/user/component-definition';
import { Observable, of, Subscription } from 'rxjs';
import { filter, map, switchMap } from 'rxjs/operators';
import { UserService } from '@career/core/services/user.service';

@Component({
  selector: 'app-manage-consents',
  template: `
  <app-base-template [component]="component" [componentDef]="componentDef" [scope]="this" *ngIf="component"></app-base-template>
  `
})
export class ManageConsentsComponent implements OnInit, OnDestroy {
  @Input() candidateConsents;
  component: TemplateComponent;
  config: TemplateConfiguration;
  getText: Function;
  portalServiceSubscription: Subscription;
  componentDef = userComponentDef['manage-consents'];
  consentsConfig$: Observable<FieldConfig[]>;
  error: ErrorSummary;
  loading: boolean;
  updated: boolean;
  //check in dashboard before showing if there are any consent questionnaires
  constructor(private utils: UtilsService, private dialog: MatDialog, private candidateService: CandidateService, 
    private consentsService: ConsentService, private portalService: PortalService, private userSvc : UserService,
    private changeDetectorRef: ChangeDetectorRef) { }

  showWarning(obj): Observable<any> {
    if( typeof obj.privacy !== 'undefined' && !obj.privacy ) {
      return new ConfirmationDialog(this.dialog).confirm( 
        this.getText('THIS_COULD_ARCHIVE_YOUR_ACCOUNT'), 
        this.getText('THIS_COULD_ARCHIVE_YOUR_ACCOUNT_WARNING'),
        {
          cancelButtonText: this.getText('CANCEL'), 
          confirmButtonText:  this.getText('I_UNDERSTAND')
        }
      );
    }
    return of(true);
  }

  updateConsents(event: {[k: string]: boolean}) {
    this.updated = false;
    this.showWarning(event).pipe(
      switchMap((accepted) => {
        if( accepted ) {
          return this.userSvc.getUser();
        }
        return of(null);
      }),
      filter(user => user && user._id),
      switchMap(user => this.candidateService.updateCandidateConsents(event, user._id))
    ).subscribe(
      () => {
        this.updated = true;
        this.changeDetectorRef.markForCheck();
      },
      (err) => {
        this.error = new ErrorSummary('AN_ERROR_OCCURED_UPDATING_CONSENTS', `Error Code: ${err.status}`);
        this.createConfig();
        this.changeDetectorRef.markForCheck();
      }
    );
  }

  setQuestionnaireFields(questionnaires, userConsents) {
    return this.utils.createConsentQuestionnaireFields(questionnaires, this.getText, false, userConsents);
  }

  createConfig(): void {
    this.loading = true;
    this.consentsConfig$ = this.consentsService.getConsents().pipe(
      map(consents => {
        const fields = this.setQuestionnaireFields(consents, this.candidateConsents);
        return (fields || []).length < 1?null:[
          ...fields,
          {
            type: "button",
            color: 'primary',
            label: this.getText('UPDATE_CONSENT_RESPONSES')
          }
        ];
      }),
      map(data => {
        this.loading = false;
        return data;
      })
    );
    
    
  }
  
  ngOnInit(): void {
    this.portalServiceSubscription = this.portalService.getComponentData('manage-consents').subscribe((data) => {
      this.component = data.component;
      this.config = data.configuration;
      this.getText = this.utils.getText(this.component);
      this.createConfig();
    });
  }

  ngOnDestroy(): void {
    this.portalServiceSubscription.unsubscribe();
  }


}
