<div class="data-hero">
    <app-embedded-toolbar (onConfigure)="scope.openConfiguration()" [configurationName]="scope.translations.HOME_CONFIG"></app-embedded-toolbar>
    <ng-container *ngIf="scope.config.enableHomePage; else showNoAccessMsg">
        <div class="data-hero__top">
            <div class="data-hero__top-container">
                <div class=" data-hero__top-left">
                    <h2 class="data-hero__main-heading">
                        <div appTextEditContainer="home" name="Main Heading" [title]="'MAIN_HEADING'">
                            <span>
                                {{ scope.component.textMap.MAIN_HEADING }}
                            </span>
                        </div>
                    </h2>
                </div>
                <div appImgEditContainer="home" name="Main Heading Image">
                    <div class="data-hero__top-right">
                        <div class="data-hero__img-container">
                            <img [src]="scope.component.assets.image" class="data-hero__img"
                                [alt]="scope.getText | transform:'MAIN_HEADING'">
                        </div>
                    </div>
                </div>
            </div>
        </div>
        <div class="search" disabledDiv *ngIf="!scope.config.disableJobSearchPage || !scope.config.disableRegisterPage">
            <div class="search__container" *ngIf="!scope.config.disableJobSearchPage">
                <form class="connected-field connected-field--center connected-field--limited"
                    (submit)="scope.search()">
                    <div class="connected-field__wrapper">
                        <div class="connected-field__field">
                            <mat-form-field appearance="none">
                                <label for="jobText" class="connected-field__label">{{scope.getText |
                                    transform:'FIND_JOBS'}}</label>
                                <input name="jobText" matInput
                                    [placeholder]="scope.getText | transform:'FIND_JOBS_PLACEHOLDER'"
                                    [formControl]="scope.jobText">
                            </mat-form-field>
                        </div>
                        <div class="connected-field__field">
                            <mat-form-field appearance="none">
                                <label for="locSearch" class="connected-field__label"
                                    [innerText]="scope.getText | transform:'NEAR_LOCATION'"></label>
                                <input name="locSearch" matInput
                                    [placeholder]="scope.getText | transform:'NEAR_LOCATION_PLACEHOLDER'"
                                    [matAutocomplete]="auto" [formControl]="scope.selectedLocation">
                            </mat-form-field>
                            <mat-autocomplete #auto="matAutocomplete" [displayWith]="scope.createLocationString">
                                <mat-option *ngFor="let location of scope.locations" [value]="location">
                                    {{scope.createLocationString | transform:location}}
                                </mat-option>
                            </mat-autocomplete>
                        </div>
                    </div>
                    <div class="connected-field__button-wrapper">
                        <button class="connected-field__button" mat-button type="submit" mat-flat-button
                            color="primary">{{scope.getText | transform:'SEARCH'}}</button>
                    </div>
                </form>
            </div>
            <div class="banner" *ngIf="!scope.config.disableRegisterPage">
                <i class="icon-cloud banner__upload-cloud"></i>
                <ng-container *ngIf="scope.config.enableSuggestions">
                    <a [routerLink]="['/suggestions']" class="banner__link"
                        [innerText]="scope.getText | transform:'CLICK_TO_UPLOAD_YOUR_RESUME':{resumeOrCv: resumeOrCvText}"></a>
                    <span class="banner__caption">{{scope.getText | transform:'LET_THE_RIGHT_JOBS_FIND_YOU'}}</span>
                </ng-container>
                <a *ngIf="!scope.config.enableSuggestions" [routerLink]="['/register']" class="banner__link"
                    [innerText]="scope.getText | transform:'CLICK_HERE_REGISTER'"></a>
            </div>
        </div>
        <div class="list">
            <div class="data-with-image data-with-image--list data-with-image--left">
                <div class="data-with-image__img-container" appImgEditContainer="home"
                    name="About Image"
                    [path]="scope.uploadImageIcon.section1" >
                    <div class="home__header_img">
                        <img class="data-with-image__img" [src]="scope.component.assets.section1"
                            [alt]="scope.getText | transform:'SECTION1_TITLE'">
                    </div>
                </div>
                <div class="data-with-image__text-container" appTextEditContainer="home"
                    name="About"
                    [body]="'SECTION1_BODY'" [title]="'SECTION1_TITLE'">
                    <div>
                        <h2 class="data-with-image__heading section-header">
                            <span class="header__title-flex">
                                {{ scope.component.textMap.SECTION1_TITLE }}
                            </span>
                        </h2>
                        <div class="section-content">
                            <p [innerHTML]="scope.component.textMap.SECTION1_BODY"></p>
                        </div>
                    </div>
                </div>
            </div>
            <div class="data-with-image data-with-image--data-hero">
                <div class="data-with-image__img-container" appImgEditContainer="home"
                name="About (Continued) Image"    
                [path]="scope.uploadImageIcon.section2" >
                    <div class="home__header_img">
                        <img class="data-with-image__img" [src]="scope.component.assets.section2"
                            [alt]="scope.getText | transform:'SECTION2_TITLE'">
                    </div>
                </div>
                <div class="data-with-image__text-container" appTextEditContainer="home" 
                    name="About (continued)"    
                    [body]="'SECTION2_BODY'" [title]="'SECTION2_TITLE'">
                    <div>
                        <h2 class="data-with-image__heading section-header">
                            <span class="header__title-flex">
                                {{ scope.component.textMap.SECTION2_TITLE }}
                            </span>
                        </h2>
                        <div class="section-content">
                            <p [innerHTML]="scope.component.textMap.SECTION2_BODY"></p>
                        </div>
                    </div>
                </div>

            </div>
        </div>
    </ng-container>
    <ng-template #showNoAccessMsg>
        <div class="no-access-msg">
            <mat-icon>settings</mat-icon>
            <h1>
                {{scope.translations.NO_ACCESS_HOME_PAGE}}
            </h1>
        </div>
    </ng-template>
</div>