import { Injectable } from '@angular/core';
import { Match } from '../models/match.model'
import { Store } from '@ngxs/store';
import { map } from 'rxjs/operators';
import { HttpClient } from '@angular/common/http';
import { of, Observable } from 'rxjs';
import { JobService } from '@career/core/services/job.service';
import { PortalService } from '@career/core/services/portal.service';
import { UserState } from '@career/user/store/states/user.state';
import { TemplateConfiguration } from '../models/component.model';

type MatchDataCardTextMap = {'TOP_MATCH': string};
@Injectable({
  providedIn: 'root'
})
export class MatchService {

  constructor(private store: Store, private jobService: JobService, private portalService: PortalService, private http: HttpClient) { }

  findMatchInStore(id: string) {
  return this.store.selectOnce(UserState.suggested).pipe(map(piped => { 
      if(piped && piped.matches) {
        return piped.matches.find(x => x._id === id);
      }
      return null;
    }));
  }

  // removeWelcomeContent(): void {
  //   this.store.dispatch(RelevantUserAction.RemoveWelcomeContent);
  // }
  // getWelcomeToken(): string|null {
  //   const content = this.store.selectSnapshot(state => state.user.welcomeContent);
  //   return  content && content.token ? content.token : null;
  // }

  // getExistingWelcomeContent(): Match[]|null {
  //   const content = this.store.selectSnapshot(state => state.user.welcomeContent);
  //   if(content && content.matches) {
  //     return content.matches;
  //   } else {
  //     return null;
  //   }
  // }

  // getWelcomeContent(token: string): Observable<WelcomeContent> {
  //   return this.http.get(`/api/portal/welcome_content/${token}`).pipe(map((content: WelcomeContent) => {
  //     if(content) {
  //       content.matches = content.matches.map(x => this.convertToMatch(x));
  //       this.store.dispatch([new RelevantUserAction.GetWelcomeContent({matches: content.matches, token: token}), new RelevantUserAction.Setup(content.candidate)]);
  //     }
  //     return content;
  //   }));
  // }

  //auth required
  generateMatch(jobId: string, textMap: MatchDataCardTextMap, config: TemplateConfiguration) {
    return this.http.get(`/api/portal/${this.portalService.getPortalId()}/generate_match/${jobId}`).pipe(map((match: Match) => {
      return this.convertToMatch(match, textMap, config);
    }))
  }

  convertToMatch(match: Match, textMap: MatchDataCardTextMap, config: TemplateConfiguration) {
    match.display = {pills: [], terms: []};
    if(match.opportunity) {
      match.opportunity = this.jobService.convertToOpp(match.opportunity, config);
    }
    if(match.entries) {
      const index = match.entries.findIndex(x => x.category === 'TERMS');
      if(~index) {
        const entry = match.entries[index];
        if(entry.details && entry.details.length > 0) {
          for(let m of entry.details) {
           match.display.terms.push({name: m.requirement, score: m.score, yearsOfExperience: m.matchDetails && m.matchDetails.yearsOfExperience ? Math.floor(m.matchDetails.yearsOfExperience) : 0, requiredYearsOfExperience: m.requirementDetails && m.requirementDetails.yearsOfExperience ? m.requirementDetails.yearsOfExperience : 0});
          }
        }
      }
    }
    if(match.score > 75) {
      match.display.pills.push({name: 'TOPMATCH', value: textMap['TOP_MATCH'], icon: "local_fire_department"})
    }

    return match;
  }
}
