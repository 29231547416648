import {
  Component,
  EventEmitter,
  Input,
  OnChanges,
  OnInit,
  Output,
  SimpleChanges,
} from "@angular/core";
import { UntypedFormGroup, UntypedFormBuilder, Validators } from "@angular/forms";
import { UserService } from "@career/core/services/user.service";
import { FieldConfig } from "@career/pocket-forms/models/field";
import { Subscription } from "rxjs";
import { take } from "rxjs/operators";

@Component({
  exportAs: "dynamicForm",
  selector: "dynamic-form",
  template: `
    <form [formGroup]="form" (submit)="onSubmit($event)">
      <app-error-form-summary
        [form]="form"
        [fields]="fields"
      ></app-error-form-summary>
      <ng-container
        *ngFor="let field of fields"
        dynamicField
        [field]="field"
        [group]="form"
      ></ng-container>
    </form>
  `,
  styles: [],
})
export class DynamicFormComponent implements OnChanges {
  @Input() fields: FieldConfig[] = [];
  @Input() groupValidators: Validators[];
  @Input() ignoreErrors;
  @Output() submit: EventEmitter<any> = new EventEmitter<any>();

  form: UntypedFormGroup;
  subs: Subscription[] = [];

  get value() {
    return this.form.value;
  }

  constructor(private fb: UntypedFormBuilder, private userSvc: UserService) {}

  ngOnChanges(changes: SimpleChanges): void {
    if (changes.fields) {
      this.fields = (changes.fields.currentValue as FieldConfig[]).filter(field => !field.hasOwnProperty('showIf') || field.showIf !== false);
      this.form = this.createControl();
    }
  }

  onSubmit(event: Event) {
    event.preventDefault();
    event.stopPropagation();
    if (this.ignoreErrors || this.form.valid) {
      this.submit.emit(this.form.value);
    } else {
      this.form.markAsTouched();
      (<EventEmitter<any>>this.form.statusChanges).emit(this.form.status);
    }
  }

  createControl() {
    const group = this.fb.group(
      {},
      { validators: this.groupValidators || [], updateOn: "submit" }
    );
    this.fields.forEach((field) => {
      if (field.type === "button") return;
      const control = this.fb.control(
        field.value,
        this.bindValidations(field.validations || [])
      );
      group.addControl(field.name, control);
    });
    return group;
  }

  bindValidations(validations: any) {
    if (validations.length > 0) {
      const validList = [];
      validations.forEach((valid) => {
        validList.push(valid.validator);
      });
      return Validators.compose(validList);
    }
    return null;
  }
}
