import { Component, OnDestroy, OnInit } from "@angular/core";
import {
  TemplateComponent,
  TemplateConfiguration,
} from "@career/core/models/component.model";
import { Match } from "@career/core/models/match.model";
import { CandidateService } from "@career/core/services/candidate.service";
import { MatchService } from "@career/core/services/match.service";
import { PortalService } from "@career/core/services/portal.service";
import { UtilsService } from "@career/core/services/utils.service";
import { userComponentDef } from "@career/user/component-definition";
import { UserState } from "@career/user/store/states/user.state";
import { Store } from "@ngxs/store";
import { Observable, Subscription } from "rxjs";
import { map, switchMap } from "rxjs/operators";

@Component({
  selector: "app-dashboard-suggested-jobs",
  template: `
    <app-base-template
      [component]="component"
      [componentDef]="componentDef"
      [scope]="this"
      *ngIf="component"
    ></app-base-template>
  `,
})
export class DashboardSuggestedJobsComponent implements OnInit, OnDestroy {
  component: TemplateComponent;
  config: TemplateConfiguration;
  getText: Function;
  portalServiceSubscription: Subscription;
  componentDef = userComponentDef["dashboard-suggested-jobs"];
  suggestions$: Observable<Match[]>;
  loading: boolean;

  constructor(
    private candidateService: CandidateService,
    private utils: UtilsService,
    private matchService: MatchService,
    private store: Store,
    private portalService: PortalService
  ) {}

  ngOnInit(): void {
    this.portalServiceSubscription = this.portalService
      .getComponentData("dashboard-suggested-jobs")
      .subscribe((data) => {
        this.component = data.component;
        this.config = data.configuration;
        this.getText = this.utils.getText(this.component);
        this.loading = true;
        this.suggestions$ = this.store.selectOnce(UserState.user).pipe(
          switchMap((user) => {
            return this.candidateService.getSuggested(user._id);
          }),
          map((data) => {
            this.loading = false;
            return (
              data &&
              (data.matches || []).map((m) =>
                this.matchService.convertToMatch(m, {
                  TOP_MATCH: this.getText("TOP_MATCH"),
                }, this.config)
              )
            );
          })
        );
      });
  }

  ngOnDestroy(): void {
    this.portalServiceSubscription.unsubscribe();
  }
}
