<div class="match-card" *ngIf="opp">
    <div class="match-card__banner" *ngIf="banner" tabindex="0">
        <i class="icon-check match-card__icon-check"></i>
        {{banner}}
    </div>
    <div class="match-card__padding">
        <a class="match-card__name" disabledDiv
            [routerLink]="token ? ['/details', opp._id, token] : ['/details', opp._id]">{{opp.name}}</a>
        <span class="match-card__client-name" *ngIf="opp.display.client">at {{opp.display.client}}</span>
        <app-progress-bar [progress]="match.score" *ngIf="match" showNum="true"></app-progress-bar>
        <app-data-pills [pills]="pills" *ngIf="pills && pills.length > 0" class="match-card__pills"></app-data-pills>
        <ng-container *ngIf="!shortVersion">
            <div class="match-card__summary-container">
                <span class="match-card__summary" [innerHtml]="opp.display.summary"></span>
            </div>
        </ng-container>
    </div>
</div>